import React from 'react';

function Home(props) {
    return (
        
        <svg className={props.className} width="21" height="20" viewBox="0 0 21 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5003 18.4582C5.8348 18.4582 2.04199 14.6654 2.04199 9.99984C2.04199 5.33431 5.8348 1.5415 10.5003 1.5415C15.1659 1.5415 18.9587 5.33431 18.9587 9.99984C18.9587 14.6654 15.1659 18.4582 10.5003 18.4582ZM10.5003 1.7915C5.97418 1.7915 2.29199 5.47369 2.29199 9.99984C2.29199 14.526 5.97418 18.2082 10.5003 18.2082C15.0265 18.2082 18.7087 14.526 18.7087 9.99984C18.7087 5.47369 15.0265 1.7915 10.5003 1.7915Z"
                stroke={props.color}/>
            <path
                d="M10.5 10.9582C10.4699 10.9582 10.4386 10.9459 10.4129 10.9202C10.3873 10.8946 10.375 10.8633 10.375 10.8332V6.6665C10.375 6.63636 10.3873 6.60508 10.4129 6.57943C10.4386 6.55379 10.4699 6.5415 10.5 6.5415C10.5301 6.5415 10.5614 6.55379 10.5871 6.57943C10.6127 6.60508 10.625 6.63636 10.625 6.6665V10.8332C10.625 10.8633 10.6127 10.8946 10.5871 10.9202C10.5614 10.9459 10.5301 10.9582 10.5 10.9582Z"
                stroke={props.color}/>
            <path
                d="M10.2003 13.4707L10.2005 13.4707L10.1952 13.4579C10.1748 13.4091 10.167 13.366 10.167 13.3336C10.167 13.3011 10.1748 13.2581 10.1952 13.2092C10.2114 13.1703 10.2335 13.1329 10.2632 13.0964C10.2997 13.0667 10.3371 13.0446 10.376 13.0284C10.4529 12.9964 10.5478 12.9964 10.6247 13.0284C10.6636 13.0446 10.701 13.0667 10.7375 13.0964C10.7672 13.1329 10.7892 13.1703 10.8055 13.2092C10.8258 13.2581 10.8337 13.3011 10.8337 13.3336C10.8337 13.366 10.8258 13.4091 10.8055 13.4579L10.8053 13.4578L10.8003 13.4707C10.7886 13.5011 10.7729 13.5291 10.7386 13.5698C10.7018 13.6 10.664 13.6223 10.6247 13.6387L10.817 14.1002L10.6247 13.6387C10.5758 13.659 10.5328 13.6669 10.5003 13.6669C10.4678 13.6669 10.4248 13.659 10.376 13.6387C10.3367 13.6223 10.2989 13.6 10.262 13.5698C10.2277 13.5291 10.212 13.5011 10.2003 13.4707Z"
                stroke={props.color}/>
        </svg>

    );
}

export default Home;