import React from 'react';
import Calendar from "./Icons/Calendar";
import User from "./Icons/User";

function CardDetailThree(props) {
    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };
    return (
        <div>
            {props.data.slice(0, 3).map((item, index) => {
                return (<>
                        <p className="metric-header padding-top">
                            {item.employee_First_Name} {item.employee_Last_Name}
                        </p>
                        <p className="metric-data">
                            {item.type}
                            <span className="dot margin-left">.</span>
                            <Calendar className="sidebar-icon small margin-left margin-right"
                                      color="var(--primary-100)"/>
                            {item.type == "Work Anniversary" ? formatDateName(item.eE_Orig_Hire_Date) : formatDateName(item.eE_Birth_Date)}
                        </p>
                    </>
                )
            })
            }

        </div>
    );
}

export default CardDetailThree;