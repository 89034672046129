import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import DropdownItemsInput from "./DropdownItemsInput";
import task_type from "../assets/json/task_type.json";
import assign_list from "../assets/json/assign_to_list.json";
import Calendar from "./Icons/Calendar";
import Trash from "./Icons/Trash";
import PencilEdit from "./Icons/PencilEdit";
import {useSelector} from "react-redux";

function DynamicProcessCreate(props) {
    const notesTypeRef = useRef();
    const taskTypeRef = useRef();
    const [teacher, setTeacher] = useState([])
    const [taskType, setTaskType] = useState([])

    const [fieldIsValid, setFieldIsValid] = useState({
        isTaskValid: false,
        isAssignValid: false,
        isNotesValid: false,
    });

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);
    const getTaskTypeList = useSelector(state => state.getTaskType)
    const {task_type_list} = getTaskTypeList

    useEffect(() => {
        if (!getTaskTypeList.task_type_list <= 0) {
            getTaskTypeList.task_type_list.map((item, index) => {
                setTaskType((prevData) => [
                    ...prevData,
                    {
                        id: item.taskId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getTaskTypeList.task_type_list]);
    const notesKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(notesTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: false,
            });
            props.result(["3", props.task, notesTypeRef.current.value])
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: true,
            });
            props.result(["3", props.task, notesTypeRef.current.value])
        }
    }

    const taskKeyPress = (event, task) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(taskTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
            props.result("1",taskTypeRef.current.value,task)
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: true,
            });
            props.result(["1",task,taskTypeRef.current.value])
        }
    }
    const taskData = (task, data) => {
        console.log("Task: ", data)
        props.result(["1", task, data])
    }
    const assignData = (task, data) => {
        console.log("Assign: ", data)
        props.result(["2", task, data])
    }
    function taskDelete(data) {
        console.log("Delete: ", data)
        props.result(["0", data])
    }
    function taskCancel(data) {
        console.log("Cancel: ", data)
        props.result(["5", data])
    }

    function taskEdit(data,value) {
        if (props.task_name === '' || props.assign === '') {
            props.result(["0", data])
        } else {
            props.result(["4", data, value])
        }
    }

    return (
        <>
            {props.editable && <Row className="background-1 border-bottom-div pb-4" key={props.task}>
                <Row className="background-1 pt-4">
                    <div className="col-9 float-left-child template-card-title padding-left-unset">
                        Task {props.task}
                    </div>
                    {props.count > 1 && <div onClick={() => taskDelete(props.task)}
                                             className="col-3 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <Trash className="sidebar-icon-form"
                               color="var(--red)"/> <span className="message-6">Delete</span>
                    </div>}
                </Row>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Tasks<span className="asterics">*</span></label>
                        <input ref={taskTypeRef} onKeyUp={(e) => taskKeyPress(e, props.task)}
                               type="text" autoComplete="off"
                               defaultValue={props.task_name}
                               className={!fieldIsValid.isTaskValid ?
                                   "input-error-form input-spacing" : "input-valid-form input-spacing"}
                               placeholder="Enter Process Type"
                               id="document_title"/>
                        {/*<div>*/}
                        {/*    <DropdownItemsInput data={taskType} result={(data) => taskData(props.task, data)}*/}
                        {/*                        value={props.task_name}*/}
                        {/*                        className={props.task_name !== '' ?*/}
                        {/*                            "background-1 dropdown-input-content-valid" : "background-1 dropdown-input-content"}*/}
                        {/*                        title="Select Task"/>*/}
                        {/*</div>*/}
                    </div>
                </Col>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-right-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Assign To<span className="asterics">*</span></label>
                        <div>
                            <DropdownItemsInput data={teacher} result={(data) => assignData(props.task, data)}
                                                value={props.assign}
                                                className={props.assign !== '' ?
                                                    "background-1 dropdown-input-content-valid" : "background-1 dropdown-input-content"}
                                                title="Select Assign To"/>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                     className="padding-right-unset padding-left-unset">
                    <div className={!fieldIsValid.isNotesValid ? "document-form-textarea-error"
                        : "document-form-textarea"}
                    >
                                                <span className="text-label form-spacing">
                                                    Note<span className="asterics">*</span></span>
                        <textarea ref={notesTypeRef} onChange={notesKeyPress} defaultValue={props.note}
                                  className="textarea-input-error textarea-spacing"
                                  placeholder="Add Notes" rows="4"
                                  id="document_title"/>
                    </div>
                </Col>
                <Row className="background-1 pt-4">
                    <div className="col-9 float-left-child template-card-title padding-left-unset">

                    </div>
                    {(props.task_name === '' || props.assign === '' || props.note === '') && <div
                        className="col-3 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <div className="button-solid button-inactive button-size-50 float-right-child"
                             onClick={() => taskEdit(props.task, false)}>
                            Save
                        </div>

                        {/*<div className="button-solid button-transparent-active button-size-50 float-left-child"*/}
                        {/*     onClick={() => taskCancel(props.task)}>*/}
                        {/*    Cancel*/}
                        {/*</div>*/}
                    </div>}
                    {props.task_name !== '' && props.assign !== '' && props.note !== '' && <div
                        className="col-3 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <div className="button-solid button-active button-size-50 float-right-child"
                             onClick={() => taskEdit(props.task, false)}>
                            Save
                        </div>

                        {props.processTemplateId !== "" && <div className="button-solid button-transparent-active button-size-50 float-left-child"
                              onClick={() => taskCancel(props.task)}>
                            Cancel
                        </div>}
                    </div>}
                </Row>
            </Row>}
            {!props.editable && <Row className="background-1 pt-4" key={props.task}>
                <div className="card-process">
                    <Row className="background-1">
                        <div className="col-10 float-left-child template-card-title padding-left-unset">
                            <span className="description-data">Task {props.task}</span>
                        </div>
                        <div className="col-2 template-card-title template-card-text-right padding-right-unset button-cursor">
                            <div className="float-left-child" onClick={() => taskEdit(props.task,true)}>
                                <PencilEdit className="sidebar-icon-form"
                                            color="var(--primary-100)"/> <span
                                className="message-6 text-blue">Edit</span>
                            </div>
                            <div className="float-right-child" onClick={() => taskDelete(props.task)}>
                                <Trash className="sidebar-icon-form"
                                       color="var(--red)"/> <span className="message-6">Delete</span>
                            </div>
                        </div>
                    </Row>
                    <Row className="background-1 mt-4">
                        <div className="col-4 float-left-child padding-left-unset">
                            <p className="description-heading">
                                Task Name
                            </p>
                            <p className="description-data">
                                {props.task_name_value}
                            </p>
                        </div>
                        <div className="col-4 float-left-child padding-left-unset">
                            <p className="description-heading">
                                Assign To
                            </p>
                            <p className="description-data">
                                {props.assign_value}
                            </p>
                        </div>
                        <div className="col-4 float-left-child padding-left-unset">
                            <p className="description-heading">
                                Notes to Assignee
                            </p>
                            <p className="description-data">
                                {props.note}
                            </p>
                        </div>
                    </Row>
                </div>
            </Row>}
        </>
    );
}

export default DynamicProcessCreate;