import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import {Card, Col, Row} from "react-bootstrap";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import "../../assets/fontawesome-free/css/all.min.css"
import "../../datatable/css/jquery.dataTables.css";

import AlertModal from "../../components/AlertModal";

import Loader from "../../components/Loader";
import {getAllClients} from "../../actions/ClientManagementActions";
import DropdownItems from "../../components/DropdownItems";
import DropdownCalendarRangeSingle from "../../components/DropdownCalendarRangeSingle";
import {GrPowerReset} from "react-icons/gr";
import {getAnalyticsData} from "../../actions/AnalyticsActions";
import user_tick from "../../assets/images/login_user.svg"
import document_text from "../../assets/images/document-text.svg"
import medal_star from "../../assets/images/medal-star.svg"
import document from "../../assets/images/document.svg"
import calendar from "../../assets/images/calendar.svg"
import process from "../../assets/images/programming-arrows.svg"


function Analytics(props) {
    const pageName = 'analytics';
    const dispatch = useDispatch()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [fieldData, setFieldData] = useState({
        dataClient: "",
        dataFrom: "",
        dataTo: "",
    })
    const [clients, setClients] = React.useState([]);
    const [analyticsData, setAnalyticsData] = useState()

    const getAllClientDetails = useSelector(state => state.getAllClient)
    const {error, loading, client_data} = getAllClientDetails

    useEffect(() => {

        if (!getAllClientDetails.client_data <= 0) {
            setClients([])
            getAllClientDetails.client_data.map((item, index) => {
                setClients((prevData) => [
                    ...prevData,
                    {
                        data: item.clientName,
                        id: item.clientId,
                    }
                ])
            });
        }
    }, [getAllClientDetails.client_data]);

    const getAnalyticsDetails = useSelector(state => state.getAnalytics)
    const {errorAnalytics, loadingAnalytics, analytics} = getAnalyticsDetails

    useEffect(() => {
        dispatch(getAllClients())
    }, [])

    useEffect(() => {
        dispatch(getAnalyticsData(fieldData.dataClient, formatDate(fieldData.dataFrom), formatDate(fieldData.dataTo)))
    }, [fieldData])

    function formatDate(dateString) {
        if (dateString !== null) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it is zero-indexed
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`
        } else {
            return ""
        }
    }

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }
    const resetData = (e) => {
        // window.location.href = "/letters-notices"
        setFieldData({
            ...fieldData,
            dataClient: null,
            dataFrom: null,
            dataTo: null,
        })
    }
    const clientResult = (data) => {
        setFieldData({
            ...fieldData,
            dataClient: data.id
        })
    }
    const fromResult = (data) => {
        setFieldData({
            ...fieldData,
            dataFrom: data
        })
    }
    const toResult = (data) => {
        setFieldData({
            ...fieldData,
            dataTo: data
        })
    }


    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingAnalytics && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}

            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="analytics" title="Analytics"/>
                <Row className="background-1 m-3 letters">
                    <Col lg={{span: 4}} md={{span: 3}}>
                        <DropdownItems data={clients} title="Select Client" result={clientResult}
                                       value={fieldData.dataClient}/>
                    </Col>
                    <Col lg={{span: 3}} md={{span: 3}}>
                        <DropdownCalendarRangeSingle title="From" result={fromResult} value={fieldData.dataFrom}/>
                    </Col>
                    <Col lg={{span: 3}} md={{span: 3}} className="to-calendar">
                        <DropdownCalendarRangeSingle title="To" result={toResult} value={fieldData.dataTo}/>
                    </Col>
                    <Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>
                        <div className="float-right-child add-button button-solid reset-filter-button"
                             onClick={resetData}>
                            <GrPowerReset classname="sidebar-icon" color="var(--white)"/> Reset
                        </div>
                    </Col>
                </Row>
                <Row className="app-body-analytics background-1 m-3">
                    {getAnalyticsDetails.analytics && <Row className="background-1 mt-4">
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={user_tick} alt="user" className="circle-div-image-analytics"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Employees
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of logins
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.loginCount}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={document_text} alt="user"
                                                 className="circle-div-image-analytics-2"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Documents
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of documents created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.documents}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={medal_star} alt="user" className="circle-div-image-analytics-2"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Certification
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of certificates created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.certifications}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={calendar} alt="user" className="circle-div-image-analytics-2"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Calendar
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of events created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.events}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={document} alt="user" className="circle-div-image-analytics-2"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Letters
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of letters template created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.letterTemplates}
                                            </p>
                                            <p className="metric-header padding-top">
                                                Number of letters sent
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.letterNotices}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 4, offset: 0}} md={{span: 6, offset: 0}}>
                            <Card className="dashboard-card">
                                <Row className="background-1 center">
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                        <div className="circle-div" align="center">
                                            <img src={process} alt="user" className="circle-div-image-analytics-2"/>
                                        </div>
                                    </Col>
                                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}/>
                                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                                        <div className="card-dashboard-header">
                                            Processes
                                        </div>
                                    </Col>
                                    <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}/>
                                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <Row className="background-1">
                                            <p className="metric-header padding-top">
                                                Number of process template created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.processTemplates}
                                            </p>
                                            <p className="metric-header padding-top">
                                                Number of process started
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.processes}
                                            </p>
                                            <p className="metric-header padding-top">
                                                Number of task created
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.processTasks}
                                            </p>
                                            <p className="metric-header padding-top">
                                                Number of task completed
                                            </p>
                                            <p className="metric-data">
                                                {getAnalyticsDetails.analytics.processTasksCompleted}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>}
                    <Footer/>
                </Row>
            </div>

        </Row>
    </>);
}

export default Analytics;