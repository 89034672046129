import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22.75C6.34614 22.75 1.75 18.1539 1.75 12.5C1.75 6.84614 6.34614 2.25 12 2.25C17.6539 2.25 22.25 6.84614 22.25 12.5C22.25 18.1539 17.6539 22.75 12 22.75ZM12 2.75C6.62386 2.75 2.25 7.12386 2.25 12.5C2.25 17.8761 6.62386 22.25 12 22.25C17.3761 22.25 21.75 17.8761 21.75 12.5C21.75 7.12386 17.3761 2.75 12 2.75Z"
                stroke={props.color}/>
            <path
                d="M12 13.75C11.8661 13.75 11.75 13.6339 11.75 13.5V8.5C11.75 8.36614 11.8661 8.25 12 8.25C12.1339 8.25 12.25 8.36614 12.25 8.5V13.5C12.25 13.6339 12.1339 13.75 12 13.75Z"
                stroke={props.color}/>
            <path
                d="M12 17.4999C11.87 17.4999 11.74 17.4699 11.62 17.4199C11.5 17.3699 11.39 17.2999 11.29 17.2099C11.2 17.1099 11.13 17.0099 11.08 16.8799C11.03 16.7599 11 16.6299 11 16.4999C11 16.3699 11.03 16.2399 11.08 16.1199C11.13 15.9999 11.2 15.8899 11.29 15.7899C11.39 15.6999 11.5 15.6299 11.62 15.5799C11.86 15.4799 12.14 15.4799 12.38 15.5799C12.5 15.6299 12.61 15.6999 12.71 15.7899C12.8 15.8899 12.87 15.9999 12.92 16.1199C12.97 16.2399 13 16.3699 13 16.4999C13 16.6299 12.97 16.7599 12.92 16.8799C12.87 17.0099 12.8 17.1099 12.71 17.2099C12.61 17.2999 12.5 17.3699 12.38 17.4199C12.26 17.4699 12.13 17.4999 12 17.4999Z"
                fill={props.color}/>
        </svg>
    )
        ;
}

export default Home;

