import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AlertModal from "../../components/AlertModal";

import Switch from "../../components/SuperAdmin/Switch";
import DeleteModal from "../../components/DeleteModal";
import {
    addTrustedAdvisor,
    deleteTrustedAdvisorById, editTrustedAdvisor,
    getTrustedAdvisorById, resetAddTrustedAdvisor,
    resetDeleteTrustedAdvisorById
} from "../../actions/TrustedAdvisorActions";
import Loader from "../../components/Loader";
import {getAllSchoolYearExist} from "../../actions/CalendarActions";

const EditTrustedAdvisor = (props) => {
    const pageName = 'client-management';
    const advisorName = useRef()
    const advisorId = useRef()
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const [showDelete, setShowDelete] = useState(false)
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [displaySwitch, setDisplaySwitch] = useState(true)
    const [fieldIsValid, setFieldIsValid] = useState({
        dataNameValid: true,
        dataIdValid: true,
    });
    const [errorMessages, setErrorMessages] = useState({
        dataNameError: false,
        dataIdError: false,
    });
    const [fieldData, setFieldData] = useState({
        dataName: null,
        dataUsername: null,
        dataAdvisorId: null,
        dataPayroll: true,
        dataLetters: false,
        dataDocuments: true,
        dataProcess: false,
        dataSchoolIncident: false,
        dataTimeEntry: true,
    });

    useEffect(() => {
        dispatch(getTrustedAdvisorById(params.advisor))
    }, []);

    const onReset = (e) => {
        e.preventDefault()
        dispatch(getTrustedAdvisorById(params.advisor))
    }

    const getTrustedAdvisorByIdDetails = useSelector(state => state.getTrustedAdvisorById)
    const {errorGetTrustedAdvisor, loadingGetTrustedAdvisor, ta_data_get} = getTrustedAdvisorByIdDetails

    useEffect(() => {
        if (!getTrustedAdvisorByIdDetails.ta_data_get <= 0) {
            setFieldData({
                ...fieldData,
                dataName: getTrustedAdvisorByIdDetails.ta_data_get.name,
                dataUsername: getTrustedAdvisorByIdDetails.ta_data_get.userName,
                dataAdvisorId: getTrustedAdvisorByIdDetails.ta_data_get.trustedAdvisorUserId,
                dataRole: getTrustedAdvisorByIdDetails.ta_data_get.roleId,
                dataPayroll: getTrustedAdvisorByIdDetails.ta_data_get.isPayrollEnable,
                dataLetters: getTrustedAdvisorByIdDetails.ta_data_get.isLettersEnable,
                dataDocuments: getTrustedAdvisorByIdDetails.ta_data_get.isDocumentsEnable,
                dataProcess: getTrustedAdvisorByIdDetails.ta_data_get.isProcessesEnable,
                dataSchoolIncident: getTrustedAdvisorByIdDetails.ta_data_get.isSchoolIncidentEnable,
                dataTimeEntry: getTrustedAdvisorByIdDetails.ta_data_get.isTimeEntryEnable,
            })
        }
    }, [getTrustedAdvisorByIdDetails.ta_data_get]);

    const goBack = (data) => {
        document.body.style.overflow = 'hidden';
        if (data == 1) {
            history(`/client-teacher-list/${location.state.client_id}`, {state: {clientName: location.state.clientName}})
        } else {
            setShowDelete(true)
        }

    }
    const openLink = (e) => {
        e.preventDefault()
        dispatch(editTrustedAdvisor(params.advisor, advisorName.current.value, advisorId.current.value, location.state.client_id, fieldData.dataPayroll, fieldData.dataLetters,
            fieldData.dataDocuments, fieldData.dataProcess, fieldData.dataSchoolIncident, fieldData.dataTimeEntry))
        // history(`/client-teacher-list/${location.state.client_id}`, {state: {clientName: location.state.clientName, advisor: true}})
    }

    const addTrustedAdvisorDetails = useSelector(state => state.addTrustedAdvisor)
    const {error, loading, ta_data} = addTrustedAdvisorDetails

    useEffect(() => {
        if (addTrustedAdvisorDetails.ta_data === true) {
            dispatch(resetAddTrustedAdvisor())
            history(`/client-teacher-list/${location.state.client_id}`, {state: {clientName: location.state.clientName, edit_advisor: true}})
        } if (addTrustedAdvisorDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Trusted Advisor couldn't be edited"
            })
        }
    }, [addTrustedAdvisorDetails]);

    const closeAlert = (data) => {
        setAlertModal({
            display: false,
        })
    }
    const advisorNameKeyPress = (event) => {
        event.preventDefault();
        console.log(advisorName.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(advisorName.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                dataNameValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                dataNameError: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                dataNameValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                dataNameError: ""
            });
        }
    }
    const advisorIdKeyPress = (event) => {
        event.preventDefault();
        console.log(advisorId.current.value)
        if (!/.*/.test(advisorId.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                dataIdValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                dataIdError: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                dataIdValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                dataIdError: ""
            });
        }
    }

    const toggleResult = (data) => {
        if (data == 1) {
            if (fieldData.dataPayroll == true) {
                setFieldData({
                    ...fieldData,
                    dataPayroll: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataPayroll: true
                })
            }
        }
        if (data == 2) {
            if (fieldData.dataLetters == true) {
                setFieldData({
                    ...fieldData,
                    dataLetters: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataLetters: true
                })
            }
        }
        if (data == 3) {
            if (fieldData.dataDocuments == true) {
                setFieldData({
                    ...fieldData,
                    dataDocuments: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataDocuments: true
                })
            }
        }
        if (data == 4) {
            if (fieldData.dataProcess == true) {
                setFieldData({
                    ...fieldData,
                    dataProcess: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataProcess: true
                })
            }
        }
        if (data == 5) {
            if (fieldData.dataSchoolIncident == true) {
                setFieldData({
                    ...fieldData,
                    dataSchoolIncident: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataSchoolIncident: true
                })
            }
        }
        if (data == 6) {
            if (fieldData.dataTimeEntry == true) {
                setFieldData({
                    ...fieldData,
                    dataTimeEntry: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataTimeEntry: true
                })
            }
        }
    }

    const hideDeleteModal = (data) => {
        if (data == 1) {
            dispatch(deleteTrustedAdvisorById(params.advisor))
            // history(`/client-teacher-list/${location.state.client_id}`, {state: {clientName: location.state.clientName, delete: true}})
        }
        setShowDelete(false)
        document.body.style.overflow = 'unset';
    }

    const deleteTrustedAdvisorByIdDetails = useSelector(state => state.deleteTrustedAdvisorById)
    const {errorDelete, loadingDelete, ta_delete_data} = deleteTrustedAdvisorByIdDetails

    useEffect(() => {
        if (deleteTrustedAdvisorByIdDetails.ta_delete_data === true) {
            dispatch(resetDeleteTrustedAdvisorById())
            history(`/client-teacher-list/${location.state.client_id}`, {state: {clientName: location.state.clientName, delete: true}})
        } if (deleteTrustedAdvisorByIdDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Trusted advisor not deleted"
            })
        }
    }, [deleteTrustedAdvisorByIdDetails]);

    return (
        <>
            <Row className="sidebar-background">
                {loadingGetTrustedAdvisor && <Loader/>}
                {loadingDelete && <Loader/>}
                {loading && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {showDelete && <DeleteModal heading="Delete Trusted Advisor"
                                            type="delete"
                                            question="Are you sure want to delete this trusted advisor user?"
                                            answer=""
                                            result={hideDeleteModal}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="edit_trusted_advisor" title={location.state.clientName} result={goBack}/>
                    {getTrustedAdvisorByIdDetails.ta_data_get && <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Advisor Name<span className="asterics">*</span></label>
                                                <input ref={advisorName}
                                                       defaultValue={getTrustedAdvisorByIdDetails.ta_data_get.name}
                                                       type="text" autoComplete="off"
                                                       onChange={advisorNameKeyPress}
                                                       className={!fieldIsValid.dataNameValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Advisor Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                {/*categoryStatusData*/}
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Advisor User ID<span className="asterics">*</span></label>
                                                <input ref={advisorId}
                                                       defaultValue={getTrustedAdvisorByIdDetails.ta_data_get.userName}
                                                       type="text" autoComplete="off"
                                                       onChange={advisorIdKeyPress}
                                                       className={!fieldIsValid.dataIdValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter User ID"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    {displaySwitch && <Row className="background-1">
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataPayroll ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Payroll
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataPayroll} type={1}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataLetters ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Letters
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataLetters} type={2}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataDocuments ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Documents
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataDocuments} type={3}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataProcess ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Processes
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataProcess} type={4}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    {displaySwitch && <Row className="background-1">
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataSchoolIncident ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    School Incident
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataSchoolIncident} type={5}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataTimeEntry ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Time Entry
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataTimeEntry} type={6}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Row className="background-1 document-form submit-buttons">
                                        {(
                                                !fieldIsValid.dataNameValid
                                                || !fieldIsValid.dataIdValid
                                            )
                                            &&
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive"
                                                >
                                                    Update
                                                </button>
                                            </Col>}
                                        {(
                                                fieldIsValid.dataNameValid
                                                && fieldIsValid.dataIdValid
                                            )
                                            &&
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={openLink}
                                                >
                                                    Update
                                                </button>
                                            </Col>}
                                        <Col lg={{span: 2}} className="padding-right-unset">
                                            <button className="button-solid button-transparent-active"
                                                    onClick={onReset}
                                            >
                                                Reset
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                                <Footer/>
                            </Card>
                        </Row>

                    </Row>}

                </div>
            </Row>
        </>
    );
}

export default EditTrustedAdvisor;