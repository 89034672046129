import {
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_REQUEST,
    ADD_CLIENT_FAIL,
    ADD_CLIENT_RESET,

    GET_ALL_CLIENTS_REQUEST,
    GET_ALL_CLIENTS_SUCCESS,
    GET_ALL_CLIENTS_FAIL,
    GET_ALL_CLIENTS_RESET,

    GET_CLIENT_BY_ID_REQUEST,
    GET_CLIENT_BY_ID_SUCCESS,
    GET_CLIENT_BY_ID_FAIL,
    GET_CLIENT_BY_ID_RESET,

    DELETE_CLIENT_BY_ID_REQUEST,
    DELETE_CLIENT_BY_ID_SUCCESS,
    DELETE_CLIENT_BY_ID_FAIL,
    DELETE_CLIENT_BY_ID_RESET,

    GET_CLIENT_USERS_BY_CLIENT_REQUEST,
    GET_CLIENT_USERS_BY_CLIENT_SUCCESS,
    GET_CLIENT_USERS_BY_CLIENT_FAIL,
    GET_CLIENT_USERS_BY_CLIENT_RESET,

    GET_CLIENT_USER_BY_ID_REQUEST,
    GET_CLIENT_USER_BY_ID_SUCCESS,
    GET_CLIENT_USER_BY_ID_FAIL,
    GET_CLIENT_USER_BY_ID_RESET,

    UPDATE_CLIENT_USER_REQUEST,
    UPDATE_CLIENT_USER_SUCCESS,
    UPDATE_CLIENT_USER_FAIL,
    UPDATE_CLIENT_USER_RESET

} from "../constants/ClientManagementConstants"
const initialState = {};
export const addClientReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CLIENT_REQUEST:
            return { loading: true }

        case ADD_CLIENT_SUCCESS:
            return {
                loading: false,
                client_data: action.payload,
            }

        case ADD_CLIENT_FAIL:
            return { loading: false, error: action.payload }

        case ADD_CLIENT_RESET:
            return initialState;

        default:
            return state
    }
}
export const getClientByIdReducer = (state = {client_data: []}, action) => {
    switch (action.type) {
        case GET_CLIENT_BY_ID_REQUEST:
            return { loadingGetClient: true }

        case GET_CLIENT_BY_ID_SUCCESS:
            return {
                loadingGetClient: false,
                client_data: action.payload,
            }

        case GET_CLIENT_BY_ID_FAIL:
            return { loadingGetClient: false, errorGetClient: action.payload }

        case GET_CLIENT_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteClientByIdReducer = (state = {client_data: []}, action) => {
    switch (action.type) {
        case DELETE_CLIENT_BY_ID_REQUEST:
            return { loadingError: true }

        case DELETE_CLIENT_BY_ID_SUCCESS:
            return {
                loadingError: false,
                client_delete: action.payload,
            }

        case DELETE_CLIENT_BY_ID_FAIL:
            return { loadingError: false, errorDelete: action.payload }

        case DELETE_CLIENT_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const updateClientUserReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CLIENT_USER_REQUEST:
            return { loadingEdit: true }

        case UPDATE_CLIENT_USER_SUCCESS:
            return {
                loadingEdit: false,
                client_user_data: action.payload,
            }

        case UPDATE_CLIENT_USER_FAIL:
            return { loadingEdit: false, errorEdit: action.payload }

        case UPDATE_CLIENT_USER_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllClientReducer = (state = {client_data: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_CLIENTS_REQUEST:
            return { loading: true }

        case GET_ALL_CLIENTS_SUCCESS:
            return {
                loading: false,
                client_data: action.payload,
            }

        case GET_ALL_CLIENTS_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_CLIENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getClientUsersReducer = (state = {client_data: [], records: ""}, action) => {
    switch (action.type) {
        case GET_CLIENT_USERS_BY_CLIENT_REQUEST:
            return { loading: true }

        case GET_CLIENT_USERS_BY_CLIENT_SUCCESS:
            return {
                loading: false,
                client_data: action.payload,
            }

        case GET_CLIENT_USERS_BY_CLIENT_FAIL:
            return { loading: false, error: action.payload }

        case GET_CLIENT_USERS_BY_CLIENT_RESET:
            return initialState;

        default:
            return state
    }
}
export const getClientUserByIdReducer = (state = {client_user_data: [], records: ""}, action) => {
    switch (action.type) {
        case GET_CLIENT_USER_BY_ID_REQUEST:
            return { loading: true }

        case GET_CLIENT_USER_BY_ID_SUCCESS:
            return {
                loading: false,
                client_user_data: action.payload,
            }

        case GET_CLIENT_USER_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_CLIENT_USER_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
