import React from 'react';
import {Navbar, Container} from "react-bootstrap";
import User from "./../Icons/User";
import {useNavigate} from "react-router-dom";
import Setting from "../Icons/Setting";

function Navigation(props) {
    const history = useNavigate();
    const goToClient = () => {
      history('/client')
    }
    const goToMfa = () => {
        history('/mfa')
    }

    const currentHour = new Date().getHours();

    
    let greeting;
    if (currentHour >= 5 && currentHour < 12) {
        greeting = 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
        greeting = 'Good Afternoon';
    } else {
        greeting = 'Good Evening';
    }

    return (
        <Navbar className="nav-body">
            <Container className="nav-container">
                <Navbar.Brand>
                    <div>
                        <span className="school-name-label">{greeting}</span>
                    </div>
                    <div>
                        <span className="school-name-input">Hey, Super Admin</span>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <div className="row nav-row">
                        <div className="col-4" align="right">
                        </div>
                        <div className="col-2" align="right">
                            <div onClick={goToMfa}>
                                <Setting className="sidebar-icon" color='var(--primary-100)'/>
                            </div>
                        </div>
                        <div className="col-6 padding-last-item" align="right">
                            <User className="sidebar-icon" color='var(--red)'/>
                            <span className="user-name">{localStorage.getItem('userName')}</span>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;