import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import signature_status from "../assets/json/signature_status.json"
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import Folder from "../assets/images/Folder.svg"
import Document from "../components/Icons/Document"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addDocument, resetAddDocument} from "../actions/DocumentActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {decoded} from "../components/PrivateRoute";
import {getTeacherAssociates} from "../actions/ConfigActions";

const AddDocument = (props) => {
    let file_doc
    const history = useNavigate();
    const dispatch = useDispatch();
    const documentTitleRef = useRef();
    const categoryRef = useRef();
    const [alertModal, setAlertModal] = useState(false)
    const [accessLevel, setAccessLevel] = useState({
        access: 0,
        isPayrollEnable: false,
        isLettersEnable: false,
        isDocumentsEnable: false,
        isProcessesEnable: false,
    })
    const [teacher, setTeacher] = useState([])
    const [category, setCategory] = useState([])
    const [imgFile, uploading] = useState()
    const hiddenFileInput = React.useRef(null);

    const addDocumentDetails = useSelector(state => state.addDocument)
    const {error, loading, documentInfo} = addDocumentDetails

    const getCategoryList = useSelector(state => state.getCategory)
    const {category_list} = getCategoryList

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        dispatch(getTeacherAssociates())
        setAccessLevel({
            ...accessLevel,
            access: decoded.RoleAccessLevels,
            isPayrollEnable: decoded.IsPayrollEnable,
            isLettersEnable: decoded.IsLettersEnable,
            isDocumentsEnable: decoded.IsDocumentsEnable,
            isProcessesEnable: decoded.IsProcessesEnable,
        })
        if (decoded.RoleAccessLevels == 0 || decoded.IsDocumentsEnable == "False") {
            setFieldIsValid({
                ...fieldIsValid,
                isTeacherValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                teacherErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataTeacher: 0
            })
        }
        // if (JSON.parse(localStorage.getItem("isPayrollEnable"))) {
        //     setFieldIsValid({
        //         ...fieldIsValid,
        //         isTeacherValid: true,
        //     });
        //     setErrorMessages({
        //         ...errorMessages,
        //         teacherErrorMessage: ""
        //     });
        //     setFieldData({
        //         ...fieldData,
        //         dataTeacher: 0
        //     })
        // }
    }, []);

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    useEffect(() => {
        if (!getCategoryList.category_list <= 0) {
            getCategoryList.category_list.map((item, index) => {
                setCategory((prevData) => [
                    ...prevData,
                    {
                        id: item.documentCategoryId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getCategoryList.category_list]);

    useEffect(() => {
        if (addDocumentDetails.document === true) {
            dispatch(resetAddDocument())
            history('/documents',{state:{success: "1"}})
        } if (addDocumentDetails.error) {
            setAlertModal(true)
        }
    }, [addDocumentDetails]);

    const [fieldIsValid, setFieldIsValid] = useState({
        isDocumentTitleValid: false,
        isTeacherValid: false,
        isDocumentTypeValid: false,
        isDocumentSignatureStatusValid: false,
        isCategoryStatusValid: false,
        isDocumentDateValid: false,
        isFileValid: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        documentTitleErrorMessage: "",
        teacherErrorMessage: "",
        documentTypeErrorMessage: "",
        documentSignatureStatusErrorMessage: "",
        documentDateErrorMessage: "",
        categoryStatusErrorMessage: "",
        fileErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        dataDocumentTitle: null,
        dataTeacher: null,
        dataDocumentCategory: null,
        dataSignature: null,
        dataDate: null,
        dataFile: null,
    });

    const onReset = (e) => {
        e.preventDefault()
        documentTitleRef.current.value = ""
        setFieldData({
            ...fieldData,
            dataTeacher: null,
            dataDocumentCategory: null,
            dataSignature: null,
            dataDate: null,
            dataFile: null,
        })
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentTitleValid: false,
            isTeacherValid: false,
            isDocumentTypeValid: false,
            isDocumentSignatureStatusValid: false,
            isCategoryStatusValid: false,
            isDocumentDateValid: false,
            isFileValid: false,
        })
    }

    const logoClick = event => {
        hiddenFileInput.current.click();
    };
    const imageFileHandler = (e) => {
        if (e.target.files.length !== 0) {
            file_doc = e.target.files[0]
            console.log(file_doc)
            uploading(URL.createObjectURL(e.target.files[0]))
            setFieldIsValid({
                ...fieldIsValid,
                isFileValid: true
            });
            setFieldData({
                ...fieldData,
                dataFile: file_doc
            })
        }
        console.log(file_doc)
    }

    const documentTitleKeyPress = (event) => {
        event.preventDefault();
        console.log(documentTitleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(documentTitleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentTitleValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                documentTitleErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentTitleValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                documentTitleErrorMessage: ""
            });
        }
    }
    const categoryKeyPress = (event) => {
        event.preventDefault();
        console.log(categoryRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(categoryRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCategoryStatusValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                categoryStatusErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCategoryStatusValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                categoryStatusErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataDocumentCategory: categoryRef.current.value
            })
        }
    }
    const goBack = () => {
        history('/documents')
    }

    const openLink = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('Title', documentTitleRef.current.value);
        formData.append('AssociateId', fieldData.dataTeacher);
        formData.append('DocDate', fieldData.dataDate);
        formData.append('DocumentCategory', categoryRef.current.value);
        formData.append('SignatureStatusId', fieldData.dataSignature);
        formData.append('FormFile', fieldData.dataFile);

        // for (const [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }
        dispatch(addDocument(formData));
    }

    const teacherData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isTeacherValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            teacherErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const signatureStatusData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentSignatureStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            documentSignatureStatusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataSignature: data.id
        })
    }
    const categoryStatusData = (data) => {
        // console.log(data.id)
        setFieldIsValid({
            ...fieldIsValid,
            isCategoryStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            categoryStatusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataDocumentCategory: data.id
        })
    }
    const dateData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            documentDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataDate: formatDate(data)
        })
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const closeAlert = (data) => {
        setAlertModal(false)
        dispatch(resetAddDocument())
    }
    const pageName = 'documents';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal &&
                    <AlertModal heading="Document couldn't be added" type="error" result={closeAlert}/>}
                {loading && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="doc_add" title="Add Document" result={goBack}/>
                    <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Document Title<span className="asterics">*</span></label>
                                                <input ref={documentTitleRef} onChange={documentTitleKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isDocumentTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Document Title"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        {(decoded.IsDocumentsEnable != "False" && accessLevel.access != 0) ? (<Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                                                                                                      className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Teacher/Associate<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={teacher} result={teacherData}
                                                                        value={fieldData.dataTeacher}
                                                                        className={!fieldIsValid.isTeacherValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Teacher/Associate"/>
                                                </div>
                                            </div>
                                        </Col>) : (null)}
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={(decoded.IsDocumentsEnable != "False" && accessLevel.access != 0) ? "padding-left-unset" : "padding-right-unset"}>
                                            <div className="document-form">
                                                {/*categoryStatusData*/}
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Document Type<span className="asterics">*</span></label>
                                                <input ref={categoryRef} onChange={categoryKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isCategoryStatusValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Document Type"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={(decoded.IsDocumentsEnable != "False" && accessLevel.access != 0) ? "padding-right-unset" : "padding-left-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Document Signature Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={signature_status}
                                                                        value={fieldData.dataSignature}
                                                                        result={signatureStatusData}
                                                                        className={!fieldIsValid.isDocumentSignatureStatusValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Document Signature Status"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className={(decoded.IsDocumentsEnable != "False" && accessLevel.access != 0) ? "padding-left-unset" : "padding-right-unset"}>
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Document Date<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select Document Date"
                                                                            value={fieldData.dataDate}
                                                                            result={dateData}
                                                                            className={!fieldIsValid.isDocumentDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {fieldData.dataFile == null && <Row className="background-1 file-box">
                                        <Row className="background-1" onClick={logoClick}>
                                            <img src={Folder} alt="Extensis HR" className="icon-upload"/>
                                            <span className="span-upload">Select file to upload</span>
                                        </Row>
                                        <input type="file"
                                               id='image'
                                               accept='.doc, .docx, .pdf'
                                               onChange={imageFileHandler}
                                               ref={hiddenFileInput}
                                               style={{display: 'none'}}
                                        />
                                    </Row>}
                                    {fieldData.dataFile != null && <Row className="background-1 file-box-small">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                            <Row className="background-1" onClick={logoClick}>
                                                <Col lg={{span: 1}} className="doc-icon">
                                                    <Document className="sidebar-icon"
                                                              color="var(--primary-100)"/>
                                                </Col>
                                                <Col lg={{span: 11}} className="doc-name-outer">
                                                    <Row className="background-1">
                                                        {fieldData.dataFile != null ? fieldData.dataFile.name : ''}
                                                    </Row>
                                                    <Row className="background-1 doc-size">
                                                        File
                                                        size: {fieldData.dataFile != null ? Math.round(fieldData.dataFile.size / 102400).toFixed(2) : ''} MB
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <input type="file"
                                                   id='image'
                                                   accept='.doc, .docx, .pdf'
                                                   onChange={imageFileHandler}
                                                   ref={hiddenFileInput}
                                                   style={{display: 'none'}}
                                            />
                                        </Col>
                                    </Row>}
                                    {(
                                            !fieldIsValid.isDocumentTitleValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isCategoryStatusValid
                                            || !fieldIsValid.isDocumentSignatureStatusValid
                                            || !fieldIsValid.isDocumentDateValid
                                            || !fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Add
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isDocumentTitleValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isCategoryStatusValid
                                            && fieldIsValid.isDocumentSignatureStatusValid
                                            && fieldIsValid.isDocumentDateValid
                                            && fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={openLink}
                                                >
                                                    Add
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                                <Footer/>
                            </Card>
                        </Row>

                    </Row>

                </div>
            </Row>
        </>
    );
}

export default AddDocument;