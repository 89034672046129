export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_RESET = 'LOGIN_RESET'
export const LOGOUT = 'LOGOUT'
export const CREATE_VERIFICATION_REQUEST = 'CREATE_VERIFICATION_REQUEST'
export const CREATE_VERIFICATION_SUCCESS = 'CREATE_VERIFICATION_SUCCESS'
export const CREATE_VERIFICATION_FAIL = 'CREATE_VERIFICATION_FAIL'
export const CREATE_VERIFICATION_RESET = 'CREATE_VERIFICATION_RESET'
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'
export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET'
export const GET_USER_ACCESS_LEVEL_REQUEST = 'GET_USER_ACCESS_LEVEL_REQUEST'
export const GET_USER_ACCESS_LEVEL_SUCCESS = 'GET_USER_ACCESS_LEVEL_SUCCESS'
export const GET_USER_ACCESS_LEVEL_FAIL = 'GET_USER_ACCESS_LEVEL_FAIL'
export const GET_USER_ACCESS_LEVEL_RESET = 'GET_USER_ACCESS_LEVEL_RESET'
export const GET_USER_MFA_DETAILS_REQUEST = 'GET_USER_MFA_DETAILS_REQUEST'
export const GET_USER_MFA_DETAILS_SUCCESS = 'GET_USER_MFA_DETAILS_SUCCESS'
export const GET_USER_MFA_DETAILS_FAIL = 'GET_USER_MFA_DETAILS_FAIL'
export const GET_USER_MFA_DETAILS_RESET = 'GET_USER_MFA_DETAILS_RESET'