import {useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row, Card} from "react-bootstrap";
import Logo from "../assets/images/logo.svg"
import Main from "../assets/images/main.png"
import user from "../assets/images/icons/user.svg"
import lock from "../assets/images/icons/lock.svg"
import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {TbAlertHexagon} from "react-icons/tb";
import {login, logout} from "../actions/LoginActions";
import Loader from "../components/Loader";
import {jwtDecode} from "jwt-decode";


function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const history = useNavigate()
    const [passwordType, setPasswordType] = useState("password");
    const dispatch = useDispatch()

    const [fieldIsValid, setFieldIsValid] = useState({
        isEmailValid: true,
        isPasswordValid: true,
        isEmailTextValid: true,
        isPasswordTextValid: true,
        isSubmit: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        emailErrorMessage: "",
        passwordErrorMessage: "",
    });

    useEffect(() => {
        if (localStorage.getItem("access_token")) {
            if (!localStorage.getItem('client_id')) {
                history('/verification-code')
            } else {
                const decoded = jwtDecode(localStorage.getItem("access_token"));
                const user = decoded.IsSuperAdmin
                if (user === "True") {
                    window.location.href = "/super-admin-management"
                } else {
                    history("/client")
                }
            }
        }
    }, []);

    const loginUser = useSelector(state => state.login)
    const {error, loading, userInfo} = loginUser

    useEffect(() => {
        if (userInfo && !localStorage.getItem('client_id')) {
            window.location.href = '/verification-code'
        }
    }, [userInfo])

    useEffect(() => {
        if (error) {
            setFieldIsValid({
                ...fieldIsValid,
                isEmailTextValid: false,
                isPasswordTextValid: false,
                isPasswordValid: false,
                isSubmit: false
            });
            setErrorMessages({
                ...errorMessages,
                passwordErrorMessage: <textarea className="message" readOnly>
                    {"Please Contact ExtensisHR:<br/>Phone: 877-773-8770<br/>Email: employee@extensishr.com".split("<br/>").join("\n")}
                </textarea>,
            });
        }
    }, [error])

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const emailKeyPress = (event) => {
        event.preventDefault();
        const password = passwordRef.current.value;
        if (!/.*/i.test(emailRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isEmailValid: false,
                isEmailTextValid: false
            });
            setErrorMessages({
                ...errorMessages,
                emailErrorMessage: "Incorrect Username and/or Password provided. Please re-enter your information."
            });
            return;
        }
        if ((password.length === 0) && (/.*/i.test(emailRef.current.value))) {
            setFieldIsValid({
                ...fieldIsValid,
                isSubmit: false,
                isEmailValid: true,
                isEmailTextValid: true,
            });
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isEmailValid: true,
                isEmailTextValid: true,
                isPasswordTextValid: true,
                isSubmit: true
            });
            setErrorMessages({
                ...errorMessages,
                emailErrorMessage: "",
            });
        }
    }
    const handlePasswordChange = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(passwordRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isPasswordValid: false,
                isPasswordTextValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                passwordErrorMessage: "Incorrect Username and/or Password provided. Please re-enter your information.",
            });
            return;
        }
        if ((email.length === 0) && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(passwordRef.current.value))) {
            setFieldIsValid({
                ...fieldIsValid,
                isSubmit: false,
                isPasswordValid: true,
                isPasswordTextValid: true,
            });
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isPasswordValid: true,
                isPasswordTextValid: true,
                isEmailTextValid: true,
                isSubmit: true
            });
            setErrorMessages({
                ...errorMessages,
                passwordErrorMessage: "",
            });
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
        dispatch(login(emailRef.current.value, passwordRef.current.value))
        // history('/verification-code')
    };

    return (
        <Row className="login-background">
            {loading && <Loader/>}
            <Col xl={{span: 4, offset: 1}} lg={{span: 4, offset: 1}} md={{span: 6, offset: 0}}>
                <Card className="card-body">
                    <img src={Logo} alt="logo" className="logo"/>
                    <form className="margin-top">
                        <p className="header">Sign In Now</p>
                        <p className="sub-header">Enter your Username & Password</p>
                        <div className="login-form">
                            <label htmlFor="email" id="emailLabel"
                                   className="form-label">
                                Username</label>
                            <img src={user} alt="icon user-icon" className="login-icon"/>
                            <input ref={emailRef} onChange={emailKeyPress}
                                   type="email" autoComplete="off"
                                   className={!fieldIsValid.isEmailTextValid ? "input-error" : "input-login"}
                                   placeholder="Enter Username"
                                   id="email"
                            />
                            {!fieldIsValid.isEmailValid && <div className="error-message">
                                <TbAlertHexagon className="icon error-icon float-left-child"/>
                                <p className="d-inline message-1 ms-1">{errorMessages.emailErrorMessage}</p>
                            </div>}
                        </div>
                        <div className="login-form">
                            <label htmlFor="password" id="passwordLabel"
                                   className="form-label">
                                Password</label>
                            <img src={lock} alt="icon lock-icon" className="login-icon"/>
                            {passwordType === "password" ?
                                <AiOutlineEyeInvisible className="icon password-icon" onClick={togglePassword}/>
                                :
                                <AiOutlineEye className="icon password-icon" onClick={togglePassword}/>
                            }
                            <input ref={passwordRef} onChange={handlePasswordChange}
                                   type={passwordType} autoComplete="off"
                                   className={!fieldIsValid.isPasswordTextValid ? "input-error" : "input-login"}
                                   placeholder="Enter Password"
                                   id="password" aria-describedby="emailHelp"
                            />
                            {!fieldIsValid.isPasswordValid && <div className="error-message">
                                <TbAlertHexagon className="icon error-icon float-left-child"/>
                                <p className="d-inline message-1 ms-1">{errorMessages.passwordErrorMessage}</p>
                            </div>}
                        </div>
                        <div className="login-button">
                            {(!fieldIsValid.isEmailValid || !fieldIsValid.isSubmit ||
                                    !fieldIsValid.isPasswordValid) &&
                                (<button disabled={true} className="button-solid button-inactive">
                                    Sign In
                                </button>)}
                            {(fieldIsValid.isEmailValid && fieldIsValid.isSubmit &&
                                    fieldIsValid.isPasswordValid) &&
                                (<button onClick={submitHandler} disabled={false} type="submit"
                                         className="button-solid button-active">
                                    Sign In
                                </button>)}
                        </div>
                        <p className="sub-footer">By logging in to this application you agree to our Terms of use and
                            privacy policy</p>
                    </form>
                    <div className="copyright">
                        <p className="sub-footer">v5.2</p>
                        <p className="sub-footer">copyright text</p>
                    </div>
                </Card>
            </Col>
            <Col xl={{span: 7, offset: 0}} lg={{span: 7, offset: 0}} md={{span: 6, offset: 0}}>
                <img src={Main} alt="logo" className="main_image"/>
            </Col>
        </Row>
    );
}

export default Login;