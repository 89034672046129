import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 5.25C7.86614 5.25 7.75 5.13386 7.75 5V2C7.75 1.86614 7.86614 1.75 8 1.75C8.13386 1.75 8.25 1.86614 8.25 2V5C8.25 5.13386 8.13386 5.25 8 5.25Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M16 5.25C15.8661 5.25 15.75 5.13386 15.75 5V2C15.75 1.86614 15.8661 1.75 16 1.75C16.1339 1.75 16.25 1.86614 16.25 2V5C16.25 5.13386 16.1339 5.25 16 5.25Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M8.31231 13.9585L8.31238 13.9584L8.29949 13.9534C8.24695 13.9332 8.20146 13.9055 8.14035 13.8524C8.04864 13.7496 8 13.6255 8 13.5001C8 13.4459 8.01285 13.3812 8.04154 13.3124C8.06614 13.2533 8.09993 13.1973 8.14509 13.1436C8.20384 13.0932 8.24832 13.0664 8.29949 13.0467L8.29956 13.0469L8.31231 13.0416C8.49002 12.9675 8.71948 13.011 8.85184 13.1391C8.94682 13.242 9 13.3785 9 13.5001C9 13.5167 8.99673 13.5469 8.98624 13.6209C8.9848 13.6276 8.98136 13.6393 8.97278 13.6565L8.95689 13.6882L8.94566 13.722C8.94151 13.7344 8.9327 13.7546 8.91398 13.7827L8.90738 13.7926L8.90526 13.7961C8.90299 13.799 8.89917 13.8037 8.8928 13.8112C8.88714 13.8179 8.88246 13.8233 8.87646 13.8302C8.87264 13.8346 8.86828 13.8396 8.86279 13.846C8.8581 13.8514 8.85285 13.8575 8.84724 13.8642C8.74614 13.9515 8.61624 14.0001 8.5 14.0001C8.44582 14.0001 8.38113 13.9872 8.31231 13.9585Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M11.8123 13.9583L11.8124 13.9582L11.7995 13.9532C11.747 13.933 11.7015 13.9054 11.6403 13.8523C11.5486 13.7494 11.5 13.6253 11.5 13.4999C11.5 13.4457 11.5128 13.381 11.5415 13.3122C11.5661 13.2531 11.5999 13.1971 11.6451 13.1434C11.7038 13.093 11.7483 13.0662 11.7995 13.0465L11.8114 13.042L11.8231 13.0368C11.9857 12.9645 12.2125 13.0037 12.3518 13.1388C12.4468 13.2417 12.5 13.3783 12.5 13.4999C12.5 13.5165 12.4967 13.5467 12.4862 13.6207C12.4848 13.6274 12.4814 13.6391 12.4728 13.6563L12.4569 13.6881L12.4457 13.7218C12.4415 13.7342 12.4327 13.7544 12.414 13.7825L12.4074 13.7924L12.4053 13.796C12.403 13.7988 12.3992 13.8035 12.3928 13.811C12.3871 13.8177 12.3825 13.8231 12.3765 13.83C12.3726 13.8344 12.3683 13.8394 12.3628 13.8458C12.3581 13.8512 12.3528 13.8573 12.3472 13.864C12.2461 13.9513 12.1162 13.9999 12 13.9999C11.9458 13.9999 11.8811 13.987 11.8123 13.9583Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M15.5 14.4999C15.37 14.4999 15.24 14.4699 15.12 14.4199C14.99 14.3699 14.89 14.2999 14.79 14.2099C14.75 14.1599 14.71 14.1099 14.67 14.0599C14.63 13.9999 14.6 13.9399 14.58 13.8799C14.55 13.8199 14.53 13.7599 14.52 13.6999C14.51 13.6299 14.5 13.5599 14.5 13.4999C14.5 13.2399 14.61 12.9799 14.79 12.7899C14.89 12.6999 14.99 12.6299 15.12 12.5799C15.49 12.4199 15.93 12.5099 16.21 12.7899C16.39 12.9799 16.5 13.2399 16.5 13.4999C16.5 13.5599 16.49 13.6299 16.48 13.6999C16.47 13.7599 16.45 13.8199 16.42 13.8799C16.4 13.9399 16.37 13.9999 16.33 14.0599C16.3 14.1099 16.25 14.1599 16.21 14.2099C16.02 14.3899 15.76 14.4999 15.5 14.4999Z"
                fill={props.color}/>
            <path
                d="M8.04154 16.8122L8.04172 16.8123L8.04667 16.7994C8.07395 16.7285 8.10751 16.6795 8.14355 16.6435C8.22044 16.5666 8.34834 16.5125 8.5 16.5125C8.64873 16.5125 8.7746 16.5645 8.85193 16.6391C8.94685 16.7419 9 16.8784 9 16.9999C9 17.1197 8.94845 17.2538 8.8562 17.3561C8.75389 17.4484 8.61971 17.4999 8.5 17.4999C8.44582 17.4999 8.38113 17.4871 8.31231 17.4584C8.252 17.4333 8.19483 17.3985 8.13999 17.3519C8.05014 17.2501 8 17.118 8 16.9999C8 16.9458 8.01285 16.8811 8.04154 16.8122Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M11.5415 16.8122L11.5417 16.8123L11.5467 16.7994C11.5739 16.7285 11.6075 16.6795 11.6436 16.6435C11.7204 16.5666 11.8483 16.5125 12 16.5125C12.1517 16.5125 12.2796 16.5666 12.3564 16.6435C12.3925 16.6795 12.4261 16.7285 12.4533 16.7994L12.4531 16.7995L12.4585 16.8122C12.4872 16.8811 12.5 16.9458 12.5 16.9999C12.5 17.1197 12.4484 17.2538 12.3562 17.3561C12.2539 17.4484 12.1197 17.4999 12 17.4999C11.8803 17.4999 11.7461 17.4484 11.6438 17.3561C11.5516 17.2538 11.5 17.1197 11.5 16.9999C11.5 16.9458 11.5128 16.8811 11.5415 16.8122Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M15.0467 17.2004L15.0469 17.2004L15.0415 17.1876C15.0128 17.1188 15 17.0541 15 16.9999C15 16.9458 15.0128 16.8811 15.0415 16.8122L15.0417 16.8123L15.0467 16.7994C15.0739 16.7285 15.1075 16.6795 15.1436 16.6435C15.2586 16.5284 15.437 16.4764 15.5832 16.5084L15.6011 16.5123L15.6193 16.5149C15.6316 16.5166 15.6402 16.519 15.6564 16.5271L15.6882 16.543L15.7219 16.5542C15.7342 16.5583 15.7545 16.5672 15.7827 16.586L15.7926 16.5926L15.7961 16.5947C15.799 16.5969 15.8037 16.6008 15.8112 16.6071C15.8178 16.6128 15.8232 16.6175 15.8302 16.6235C15.8346 16.6273 15.8396 16.6317 15.8459 16.6371C15.8514 16.6418 15.8575 16.6471 15.8641 16.6527C15.9514 16.7538 16 16.8837 16 16.9999C16 17.1196 15.9484 17.2538 15.8562 17.3561C15.7539 17.4484 15.6197 17.4999 15.5 17.4999C15.3784 17.4999 15.242 17.4468 15.1391 17.3518C15.1047 17.3162 15.0728 17.2684 15.0467 17.2004Z"
                fill={props.color} stroke={props.color}/>
            <path
                d="M20.5 9.83984H3.5C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984Z"
                fill={props.color}/>
            <path
                d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;