import React, {useRef, useState} from 'react';
import "../assets/css/modal.css"
import {IoClose} from "react-icons/io5";
import Trash from "./Icons/Trash";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import DropdownCalendarSingle from "./DropdownCalendarSingle";
import DropdownItemsInput from "./DropdownItemsInput";
import event_data from "../assets/json/event_type.json";
import link from "../assets/images/icons/link.svg";
import Folder from "../assets/images/Folder.svg";
import Document from "./Icons/Document";

function FormModal1(props) {
    let file_doc
    const eventNameRef = useRef();
    const [imgFile, uploading] = useState()
    const [file, setFile] = useState()
    const hiddenFileInput = React.useRef(null);
    const [isDragging, setIsDragging] = useState(false);


    const [fieldIsValid, setFieldIsValid] = useState({
        isFileValid: false,
    });
    const [fieldData, setFieldData] = useState({
        dataFile: null,
    });

    const logoClick = event => {
        hiddenFileInput.current.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = Array.from(e.dataTransfer.files);
    };
    const imageFileHandler = (e) => {
        if (e.target.files.length !== 0) {
            file_doc = e.target.files[0]
            console.log(file_doc)
            uploading(URL.createObjectURL(e.target.files[0]))
            setFieldIsValid({
                ...fieldIsValid,
                isFileValid: true
            });
            setFieldData({
                ...fieldData,
                dataFile: file_doc
            })
            setFile(file_doc)
        }
        console.log(file_doc)
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    function toggleYes() {
        props.result([0])
    }

    function toggleNo() {
        props.result([0])
    }
    function toggleData() {
        props.result([1, file])
    }

    return (
        <>
            <div className="darkBG" data-backdrop="static">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-heading-title">
                            {props.heading}
                        </div>
                        <div className="modal-heading-title" onClick={toggleNo}>
                            <IoClose/>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="body-text-form">
                            <p className="p-question">
                                {props.question}
                            </p>
                            <p className="p-answer">
                                {props.answer}
                            </p>
                            <Row className="background-1">
                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     // className="padding-left-unset padding-right-unset"
                                     className={`padding-left-unset padding-right-unset ${isDragging ? 'drag-over' : ''}`}
                                     onDragEnter={handleDragEnter}
                                     onDragOver={handleDragEnter}
                                     onDragLeave={handleDragLeave}
                                     onDrop={handleDrop}
                                >
                                    {fieldData.dataFile == null && <Row className="background-1 file-box">
                                        <Row className="background-1" onClick={logoClick}>
                                            <img src={Folder} alt="Extensis HR" className="icon-upload"/>
                                            <span className="span-upload">Select file to upload</span>
                                        </Row>
                                        <input type="file"
                                               id='image'
                                               accept='.csv, .xls, .xlsx'
                                               onChange={imageFileHandler}
                                               ref={hiddenFileInput}
                                               style={{display: 'none'}}
                                        />
                                    </Row>}
                                    {fieldData.dataFile != null && <Row className="background-1 file-box-small">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className={`${isDragging ? 'drag-over' : ''}`}
                                             onDragEnter={handleDragEnter}
                                             onDragOver={handleDragEnter}
                                             onDragLeave={handleDragLeave}
                                             onDrop={handleDrop}
                                        >
                                            <Row className="background-1" onClick={logoClick}>
                                                <Col lg={{span: 1}} className="doc-icon">
                                                    <Document className="sidebar-icon"
                                                              color="var(--primary-100)"/>
                                                </Col>
                                                <Col lg={{span: 11}} className="doc-name-outer">
                                                    <Row className="background-1">
                                                        {fieldData.dataFile != null ? fieldData.dataFile.name : ''}
                                                    </Row>
                                                    <Row className="background-1 doc-size">
                                                        File
                                                        size: {fieldData.dataFile != null ? Math.round(fieldData.dataFile.size / 102400).toFixed(2) : ''} MB
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <input type="file"
                                                   id='image'
                                                   accept='.doc, .docx, .pdf'
                                                   onChange={imageFileHandler}
                                                   ref={hiddenFileInput}
                                                   style={{display: 'none'}}
                                            />
                                        </Col>
                                    </Row>}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {(
                                !fieldIsValid.isFileValid
                            )
                            &&
                            <Row className="background-1 document-form submit-buttons-upload">
                                <Col lg={{span: 6}} className="padding-left-unset">
                                <button type="button" className="button-solid button-transparent-active modal-button"
                                        onClick={toggleNo}>
                                    Cancel
                                </button>
                                </Col>

                                <Col lg={{span: 6}} className="padding-right-unset">
                                <button type="button" className="button-solid button-inactive modal-button">
                                    Import
                                </button>
                                </Col>
                            </Row>
                        }
                        {(
                                fieldIsValid.isFileValid
                            )
                            && <Row className="background-1 document-form submit-buttons-upload">
                                <Col lg={{span: 6}} className="padding-left-unset">
                                    <button type="button" className="button-solid button-transparent-active modal-button"
                                            onClick={toggleYes}>
                                        Cancel
                                    </button>
                                </Col>

                                <Col lg={{span: 6}} className="padding-right-unset">
                                    <button type="button" className="button-solid button-active modal-button"
                                            onClick={toggleData}>
                                        Import
                                    </button>
                                </Col>
                            </Row>}
                    </div>

                </div>
            </div>
        </>
    );
}

export default FormModal1;