import {
    ADD_LETTERS_TEMPLATE_SUCCESS,
    ADD_LETTERS_TEMPLATE_REQUEST,
    ADD_LETTERS_TEMPLATE_FAIL,
    ADD_LETTERS_TEMPLATE_RESET,

    EDIT_LETTERS_TEMPLATE_SUCCESS,
    EDIT_LETTERS_TEMPLATE_REQUEST,
    EDIT_LETTERS_TEMPLATE_FAIL,
    EDIT_LETTERS_TEMPLATE_RESET,

    GET_LETTERS_TEMPLATE_BY_ID_SUCCESS,
    GET_LETTERS_TEMPLATE_BY_ID_REQUEST,
    GET_LETTERS_TEMPLATE_BY_ID_FAIL,
    GET_LETTERS_TEMPLATE_BY_ID_RESET,

    GET_ALL_LETTERS_TEMPLATES_REQUEST,
    GET_ALL_LETTERS_TEMPLATES_SUCCESS,
    GET_ALL_LETTERS_TEMPLATES_FAIL,
    GET_ALL_LETTERS_TEMPLATES_RESET,

    GET_LETTERS_TEMPLATE_COLUMNS_REQUEST,
    GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS,
    GET_LETTERS_TEMPLATE_COLUMNS_FAIL,
    GET_LETTERS_TEMPLATE_COLUMNS_RESET,

    SEND_LETTERS_NOTICES_REQUEST,
    SEND_LETTERS_NOTICES_SUCCESS,
    SEND_LETTERS_NOTICES_FAIL,
    SEND_LETTERS_NOTICES_RESET,

    GET_LETTERS_NOTICES_BY_ID_REQUEST,
    GET_LETTERS_NOTICES_BY_ID_SUCCESS,
    GET_LETTERS_NOTICES_BY_ID_FAIL,
    GET_LETTERS_NOTICES_BY_ID_RESET,

    GET_ALL_LETTERS_NOTICES_REQUEST,
    GET_ALL_LETTERS_NOTICES_SUCCESS,
    GET_ALL_LETTERS_NOTICES_FAIL,
    GET_ALL_LETTERS_NOTICES_RESET,


} from "../constants/LetterNoticesConstants"
const initialState = {};
export const addLettersTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_LETTERS_TEMPLATE_REQUEST:
            return { loading: true }

        case ADD_LETTERS_TEMPLATE_SUCCESS:
            return {
                loading: false,
                letters_template: action.payload,
            }

        case ADD_LETTERS_TEMPLATE_FAIL:
            return { loading: false, error: action.payload }

        case ADD_LETTERS_TEMPLATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const editLettersTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_LETTERS_TEMPLATE_REQUEST:
            return { loadingEdit: true }

        case EDIT_LETTERS_TEMPLATE_SUCCESS:
            return {
                loadingEdit: false,
                letters_template: action.payload,
            }

        case EDIT_LETTERS_TEMPLATE_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_LETTERS_TEMPLATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getLettersTemplateByIdReducer = (state = {letters_template_data: []}, action) => {
    switch (action.type) {
        case GET_LETTERS_TEMPLATE_BY_ID_REQUEST:
            return { loadingLetter: true }

        case GET_LETTERS_TEMPLATE_BY_ID_SUCCESS:
            return {
                loadingLetter: false,
                letters_template_data: action.payload,
            }

        case GET_LETTERS_TEMPLATE_BY_ID_FAIL:
            return { loadingLetter: false, errorLetter: action.payload }

        case GET_LETTERS_TEMPLATE_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllLettersTemplateReducer = (state = {letters_template_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_LETTERS_TEMPLATES_REQUEST:
            return { loading: true }

        case GET_ALL_LETTERS_TEMPLATES_SUCCESS:
            return {
                loading: false,
                letters_template_list: action.payload,
                records: action.records
            }

        case GET_ALL_LETTERS_TEMPLATES_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_LETTERS_TEMPLATES_RESET:
            return initialState;

        default:
            return state
    }
}
export const getTemplateColumnReducer = (state = {template_column: [], records: ""}, action) => {
    switch (action.type) {
        case GET_LETTERS_TEMPLATE_COLUMNS_REQUEST:
            return { loading: true }

        case GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS:
            return {
                loading: false,
                template_column: action.payload,
            }

        case GET_LETTERS_TEMPLATE_COLUMNS_FAIL:
            return { loading: false, error: action.payload }

        case GET_LETTERS_TEMPLATE_COLUMNS_RESET:
            return initialState;

        default:
            return state
    }
}

export const sendLettersNoticesReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_LETTERS_NOTICES_REQUEST:
            return { loadingSend: true }

        case SEND_LETTERS_NOTICES_SUCCESS:
            return {
                loadingSend: false,
                letters_notices: action.payload,
            }

        case SEND_LETTERS_NOTICES_FAIL:
            return { loadingSend: false, errorSend: action.payload }

        case SEND_LETTERS_NOTICES_RESET:
            return initialState;

        default:
            return state
    }
}
export const getLettersNoticesByIdReducer = (state = {letters_notices_data: []}, action) => {
    switch (action.type) {
        case GET_LETTERS_NOTICES_BY_ID_REQUEST:
            return { loadingLetter: true }

        case GET_LETTERS_NOTICES_BY_ID_SUCCESS:
            return {
                loadingLetter: false,
                letters_notices_data: action.payload,
            }

        case GET_LETTERS_NOTICES_BY_ID_FAIL:
            return { loadingLetter: false, errorLetter: action.payload }

        case GET_LETTERS_NOTICES_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllLettersNoticesReducer = (state = {letters_notices_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_LETTERS_NOTICES_REQUEST:
            return { loadingAll: true }

        case GET_ALL_LETTERS_NOTICES_SUCCESS:
            return {
                loadingAll: false,
                letters_notices_list: action.payload,
                records: action.records
            }

        case GET_ALL_LETTERS_NOTICES_FAIL:
            return { loadingAll: false, errorAll: action.payload }

        case GET_ALL_LETTERS_NOTICES_RESET:
            return initialState;

        default:
            return state
    }
}
