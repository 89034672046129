import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import document_type from "../assets/json/process_type.json";
import {useNavigate} from "react-router-dom";
import AddCircle from "../components/Icons/AddCircle";
import DynamicProcessTemplate from "../components/DynamicProcessTemplate";
import {addProcessTemplate, resetAddProcessTemplate} from "../actions/ProcessActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import DeleteModal from "../components/DeleteModal";

const CreateProcessTemplate = (props) => {
    const pageName = 'processes';
    const history = useNavigate();
    const dispatch = useDispatch();
    const titleRef = useRef();
    const typeRef = useRef();
    const [tasks, setTasks] = useState([]);
    const [count, setCount] = useState(1);
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [showDelete, setShowDelete] = useState({
        display: false,
        data: null
    })
    const [fieldIsValid, setFieldIsValid] = useState({
        isProcessTypeValid: false,
        isProcessTitleValid: false,
        isTaskValid: false,
    });
    const [fieldData, setFieldData] = useState({
        processTypeData: "",
        processNameData: "",
        taskData: ""
    });
    // addProcessTemplate
    const addProcessTemplateDetails = useSelector(state => state.addProcessTemplate)
    const {error, loading, processTemplateInfo} = addProcessTemplateDetails

    useEffect(() => {
        if (addProcessTemplateDetails.process_template === true) {
            dispatch(resetAddProcessTemplate())
            history('/processes',{state:{success: "1"}})
        } if (addProcessTemplateDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Template couldn't be added"
            })
        }
    }, [addProcessTemplateDetails]);
    const titleKeyPress = (event) => {
        event.preventDefault();
        console.log(titleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTitleValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTitleValid: true,
            })
            setFieldData({
                ...fieldData,
                processNameData: titleRef.current.value
            })
        }
    }
    const typeKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(typeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTypeValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTypeValid: true,
            })
            setFieldData({
                ...fieldData,
                processTypeData: typeRef.current.value
            })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(addProcessTemplate(typeRef.current.value, fieldData.processNameData, fieldData.taskData))
    }
    const openLink = () => {
        history('/processes')
    }
    const onReset = (e) => {
        e.preventDefault();
        titleRef.current.value = ""
        setFieldData({
            ...fieldData,
            processTypeData: null
        })
        setFieldIsValid({
            ...fieldIsValid,
            isProcessTitleValid: false,
            isProcessTypeValid: false
        })
        setTasks([])
        setCount(1)
        //
    }

    useEffect(() => {
        if (tasks.length <= 0) {
            setCount(1)
            addTask()
        }
    }, [tasks]);

    useEffect(() => {
        console.log(tasks)
        if (tasks.some(item => item.title === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        } else if (tasks.some(item => item.assignToId === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        } else if (tasks.some(item => item.notes === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        }
        else {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: true,
            });
            setFieldData({
                ...fieldData,
                taskData: tasks
            })
        }
    }, [tasks]);

    const addTask = () => {
        setCount(count+1)
        const newTask = {
            id: count, // Simple unique ID for demo purposes
            title: "",
            assignToId: "",
            notes: "",
        };
        setTasks([...tasks, newTask]);

    };
    const deleteTask = (data) => {
        // type value task
        if (data[0] === "0") {
            setShowDelete({
                display: true,
                data: data[1]
            })
        }
            // setTasks(tasks.filter(task => task.id !== data[1]));
        if (data[0] === "1") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, title: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "2") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, assignToId: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "3") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, notes: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
    };
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }
    const hideDeleteModal = (data) => {
        if (data === 1) {
            setTasks(tasks.filter(task => task.id !== showDelete.data));
            // dispatch(deleteCertificate(showDelete.data))
        }
        setShowDelete({
            display: false
        })
    }
    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {showDelete.display && <DeleteModal heading="Delete Task"
                                                    type="delete"
                                                    question="Are you sure want to delete this Task?"
                                                    answer=""
                                                    result={hideDeleteModal}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Create Process Template" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1 border-bottom-div pb-4">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Process Type<span className="asterics">*</span></label>
                                                <input ref={typeRef} onChange={typeKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isProcessTypeValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Process Type"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Process Name<span className="asterics">*</span></label>
                                                <input ref={titleRef} onChange={titleKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isProcessTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Process Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        {tasks.map((task) => (
                                            <DynamicProcessTemplate key={task.id} task={task.id}
                                                                    task_name={task.title}
                                                                    assign={task.assignToId}
                                                                    note={task.notes}
                                                                    count={tasks.length}
                                                                    result={deleteTask}/>
                                        ))}
                                    </div>

                                    <Row className="background-1 mb-4 mt-4">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset" onClick={addTask}>
                                            <AddCircle className="sidebar-icon mb-1"
                                                       color="var(--red)"/> <span
                                            className="description-data button-cursor">Add New Task</span>
                                        </Col>
                                    </Row>

                                    {(
                                            !fieldIsValid.isProcessTypeValid
                                            || !fieldIsValid.isProcessTitleValid
                                            || !fieldIsValid.isTaskValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isProcessTypeValid
                                            && fieldIsValid.isProcessTitleValid
                                            && fieldIsValid.isTaskValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={submitHandler}
                                                >
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default CreateProcessTemplate;