import api from './api';
import {
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_REQUEST,
    ADD_CLIENT_FAIL,
    ADD_CLIENT_RESET,

    GET_ALL_CLIENTS_REQUEST,
    GET_ALL_CLIENTS_SUCCESS,
    GET_ALL_CLIENTS_FAIL,
    GET_ALL_CLIENTS_RESET,

    GET_CLIENT_BY_ID_REQUEST,
    GET_CLIENT_BY_ID_SUCCESS,
    GET_CLIENT_BY_ID_FAIL,
    GET_CLIENT_BY_ID_RESET,

    DELETE_CLIENT_BY_ID_REQUEST,
    DELETE_CLIENT_BY_ID_SUCCESS,
    DELETE_CLIENT_BY_ID_FAIL,
    DELETE_CLIENT_BY_ID_RESET,

    GET_CLIENT_USERS_BY_CLIENT_REQUEST,
    GET_CLIENT_USERS_BY_CLIENT_SUCCESS,
    GET_CLIENT_USERS_BY_CLIENT_FAIL,
    GET_CLIENT_USERS_BY_CLIENT_RESET,

    GET_CLIENT_USER_BY_ID_REQUEST,
    GET_CLIENT_USER_BY_ID_SUCCESS,
    GET_CLIENT_USER_BY_ID_FAIL,
    GET_CLIENT_USER_BY_ID_RESET,

    UPDATE_CLIENT_USER_REQUEST,
    UPDATE_CLIENT_USER_SUCCESS,
    UPDATE_CLIENT_USER_FAIL,
    UPDATE_CLIENT_USER_RESET

} from "../constants/ClientManagementConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addClient = (clientName, clientId, isTrainingEnable) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_CLIENT_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/InsertClientMapping`,
            {
                clientName: clientName,
                clientId: clientId,
                isTrainingEnable: isTrainingEnable,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_CLIENT_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_CLIENT_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_CLIENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editClient = (clientMappingId, clientName, clientId, isTrainingEnable) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_CLIENT_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',

                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/UpdateClientMapping`,
            {
                clientMappingId: clientMappingId,
                clientName: clientName,
                clientId: clientId,
                isTrainingEnable: isTrainingEnable,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_CLIENT_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_CLIENT_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_CLIENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllClients = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_CLIENTS_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetAllClientMapping`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_CLIENTS_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_CLIENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_CLIENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getClientById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CLIENT_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetClientMappingId?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_CLIENT_BY_ID_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_CLIENT_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CLIENT_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteClientById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_CLIENT_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/SuperAdmin/DeleteClientMapping?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_CLIENT_BY_ID_SUCCESS,
                payload: data.success,
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_CLIENT_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_CLIENT_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getClientUsersByClient = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CLIENT_USERS_BY_CLIENT_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetClientUsersByClientId?ClientId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_CLIENT_USERS_BY_CLIENT_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_CLIENT_USERS_BY_CLIENT_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CLIENT_USERS_BY_CLIENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getClientUsersById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CLIENT_USER_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetClientUserEntityId?id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_CLIENT_USER_BY_ID_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_CLIENT_USER_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CLIENT_USER_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const updateClientUser = (clientUserId, userId, roleId, isPayrollEnable, isLettersEnable,
                                 isDocumentsEnable, isProcessesEnable, isSchoolIncidentEnable, isTimeEntryEnable) => async (dispatch, getState) => {
    try {
        dispatch({type: UPDATE_CLIENT_USER_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',

                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/UpdateClientUser`,
            {
                clientUserId: clientUserId,
                userId: userId,
                roleId: roleId,
                isPayrollEnable: isPayrollEnable,
                isLettersEnable: isLettersEnable,
                isDocumentsEnable: isDocumentsEnable,
                isProcessesEnable: isProcessesEnable,
                isSchoolIncidentEnable: isSchoolIncidentEnable,
                isTimeEntryEnable: isTimeEntryEnable,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: UPDATE_CLIENT_USER_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: UPDATE_CLIENT_USER_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: UPDATE_CLIENT_USER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// RESET

export const resetAddClient = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_CLIENT_RESET,
    })
};
export const resetGetAllClient = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_CLIENTS_RESET,
    })
};
export const resetGetClientUsersByClient = () => (dispatch, getState) => {
    dispatch({
        type: GET_CLIENT_USERS_BY_CLIENT_RESET,
    })
};
export const resetGetClientUsersById = () => (dispatch, getState) => {
    dispatch({
        type: GET_CLIENT_USER_BY_ID_RESET,
    })
};
export const resetUpdateClientUser = () => (dispatch, getState) => {
    dispatch({
        type: UPDATE_CLIENT_USER_RESET,
    })
};
export const resetGetClientById = () => (dispatch, getState) => {
    dispatch({
        type: GET_CLIENT_BY_ID_RESET,
    })
};
export const resetDeleteClientById = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_CLIENT_BY_ID_RESET,
    })
};