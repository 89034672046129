import React, {useRef, useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import WyEditor from "../components/WYEditor";
import DropdownItemsInput from "../components/DropdownItemsInput";
import document_type from "../assets/json/document_type.json";
import {useNavigate, useParams} from "react-router-dom";
import '../assets/css/react-draft.css'
import template_data from '../assets/json/template_data.json'
import {getProcessTemplateById} from "../actions/ProcessActions";
import {
    addLettersTemplate,
    editLettersTemplate,
    getLettersTemplateById, resetAddLettersTemplate,
    resetEditLettersTemplate
} from "../actions/LetterNoticesActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {resetEditCertificate} from "../actions/CertificateActions";

const EditTemplate = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const titleRef = useRef();
    const desciptionRef = useRef();
    const textRef = useRef();
    const params = useParams();

    const [fieldIsValid, setFieldIsValid] = useState({
        isTitleValid: true,
        isDescriptionValid: true,
        isTextValid: true,
        isCopyValid: true,
    });
    const [fieldData, setFieldData] = useState({
        dataDocument: null,
        dataTitle: null,
        dataDescription: null,
        dataText: null,
        dataCopy: null,
        dataStatus: null,
        dataChecked: false,
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })

    const [category, setCategory] = useState([])

    const getCategoryList = useSelector(state => state.getCategory)
    const {category_list} = getCategoryList

    useEffect(() => {
        if (!getCategoryList.category_list <= 0) {
            getCategoryList.category_list.map((item, index) => {
                setCategory((prevData) => [
                    ...prevData,
                    {
                        id: item.documentCategoryId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getCategoryList.category_list]);

    const getLettersTemplateDetails = useSelector(state => state.getLettersTemplateById)
    const {errorLetter, loadingLetter, letters_template_data} = getLettersTemplateDetails

    useEffect(() => {
        if (!getLettersTemplateDetails.letters_template_data <= 0) {
            setFieldData({
                ...fieldData,
                dataText: getLettersTemplateDetails.letters_template_data.documentText,
                dataCopy: getLettersTemplateDetails.letters_template_data.isHardcopyRequired,
                dataStatus: getLettersTemplateDetails.letters_template_data.isActive
            })
        }
    }, [getLettersTemplateDetails.letters_template_data]);

    const editLettersTemplateDetails = useSelector(state => state.editLettersTemplate)
    const {errorEdit, loadingEdit, letters_template} = editLettersTemplateDetails

    useEffect(() => {
        if (editLettersTemplateDetails.letters_template === true) {
            dispatch(resetEditLettersTemplate())
            setAlertModal({
                display: true,
                type: "success",
                message: "Template updated successfully"
            })
            // history('/documents',{state:{success: "1"}})
        } if (editLettersTemplateDetails.error) {
            dispatch(resetEditLettersTemplate())
            setAlertModal({
                display: true,
                type: "error",
                message: "Template couldn't be updated"
            })
        }
    }, [editLettersTemplateDetails]);

    const addTemplateDetails = useSelector(state => state.addLettersTemplate)
    const {error, loading} = addTemplateDetails

    useEffect(() => {
        if (addTemplateDetails.letters_template === true) {
            dispatch(resetAddLettersTemplate())
            history('/letters-notices',{state:{success: "1"}})
        } if (addTemplateDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Couldn't add template"
            })
        }
    }, [addTemplateDetails]);

    const getLettersTemplate = () => {
        dispatch(getLettersTemplateById(params.id))
    }

    useEffect(() => {
        getLettersTemplate()
    }, []);

    const checkEditor = (data) => {
        if (data[0].length < 10) {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: true,
            });
            setFieldData({
                ...fieldData,
                dataText: data[0]
            })
        }
    };

    const titleKeyPress = (event) => {
        event.preventDefault();
        console.log(titleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isTitleValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTitleValid: true,
            });
        }
    }
    const descriptionKeyPress = (event) => {
        event.preventDefault();
        console.log(desciptionRef.current.value)
        if (!/.*?$/.test(desciptionRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isDescriptionValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isDescriptionValid: true,
            });
        }
    }
    const selectCopy = (data) => {
        setFieldData({
            ...fieldData,
            dataCopy: data == 1 ? true : false
        })
    }
    const selectStatus = (data) => {
        setFieldData({
            ...fieldData,
            dataStatus: data == 1 ? true : false
        })
    }
    const selectDuplicate = () => {
        fieldData.dataChecked === true ? setFieldData({
            ...fieldData,
            dataChecked: false
        }) : setFieldData({
            ...fieldData,
            dataChecked: true
        })
    }

    const openLink = () => {
        history('/letters-notices')
    }
    const onSubmit = (e) => {
        e.preventDefault()
        if (fieldData.dataChecked) {
            dispatch(addLettersTemplate(null,titleRef.current.value, desciptionRef.current.value, null,fieldData.dataText,fieldData.dataCopy,fieldData.dataStatus))
        } else {
            dispatch(editLettersTemplate(parseInt(params.id), titleRef.current.value, desciptionRef.current.value, fieldData.dataText, fieldData.dataCopy, fieldData.dataStatus))
        }
    }
    const onReset = () => {
        dispatch(getLettersTemplateById(params.id))
        // history(`/edit-template/${params.id}`)
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        history('/letters-notices')
    }
    const pageName = 'letters';
    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                {loadingLetter && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type}
                                result={closeAlert}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Update Template" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getLettersTemplateDetails.letters_template_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Title<span className="asterics">*</span></label>
                                                <input ref={titleRef} onChange={titleKeyPress}
                                                       defaultValue={getLettersTemplateDetails.letters_template_data.title}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Title"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div
                                                className={!fieldIsValid.isDescriptionValid ? "document-form-textarea-error"
                                                    : "document-form-textarea"}
                                            >
                                                <span className="text-label form-spacing">
                                                    Description<span className="asterics">*</span></span>
                                                <textarea ref={desciptionRef} onChange={descriptionKeyPress}
                                                          defaultValue={getLettersTemplateDetails.letters_template_data.descriptions}
                                                          className="textarea-input-error textarea-spacing"
                                                          placeholder="Enter Description" rows="4"
                                                          id="document_title"/>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="">
                                        </Col>
                                        <div className="document-form padding-left-unset padding-right-unset">
                                            <WyEditor result={checkEditor} para={getLettersTemplateDetails.letters_template_data.documentText} dynamicValue={true}/>
                                        </div>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset padding-right-unset hardcopy-div">
                                            <div className="document-form">
                                                <div className="label-form-checkbox">
                                                    Hardcopy Required? <span className="asterics">*</span>
                                                </div>
                                                <div className="form-checkbox">
                                                    <Form.Check
                                                        className="float-left-child"
                                                        name="hardcopy"
                                                        label="Yes"
                                                        type="radio"
                                                        id="1"
                                                        checked={fieldData.dataCopy == true ? true : false}
                                                        onClick={() => selectCopy(1)}
                                                    />
                                                    <Form.Check
                                                        className="float-right-child"
                                                        name="hardcopy"
                                                        label="No"
                                                        type="radio"
                                                        id="0"
                                                        checked={fieldData.dataCopy == false ? true : false}
                                                        onClick={() => selectCopy(0)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset padding-right-unset hardcopy-div">
                                            <div className="document-form">
                                                <div className="label-form-checkbox">
                                                    Document Status <span className="asterics">*</span>
                                                </div>
                                                <div className="form-checkbox">
                                                    <Form.Check
                                                        className="float-left-child"
                                                        name="active"
                                                        label="Yes"
                                                        type="radio"
                                                        id="1"
                                                        checked={fieldData.dataStatus == true ? true : false}
                                                        onClick={() => selectStatus(1)}
                                                    />
                                                    <Form.Check
                                                        className="float-right-child"
                                                        name="active"
                                                        label="No"
                                                        type="radio"
                                                        id="0"
                                                        checked={fieldData.dataStatus == false ? true : false}
                                                        onClick={() => selectStatus (0)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div className="document-form">
                                                <Form.Check
                                                    className="template-check"
                                                    name="new_template"
                                                    label="Would you like to save and update as a new template?"
                                                    type="checkbox"
                                                    id="1"
                                                    checked={fieldData.dataChecked}
                                                    onClick={() => selectDuplicate()}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {(
                                            !fieldIsValid.isTitleValid
                                            || !fieldIsValid.isDescriptionValid
                                            || !fieldIsValid.isTextValid
                                            || !fieldIsValid.isCopyValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isTitleValid
                                            && fieldIsValid.isDescriptionValid
                                            && fieldIsValid.isTextValid
                                            && fieldIsValid.isCopyValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={onSubmit}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditTemplate;