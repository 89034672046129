import React from 'react';
import {Navigate, Outlet} from "react-router-dom"
import {jwtDecode} from "jwt-decode";
function SuperAdminRoute() {
    const decoded = jwtDecode(localStorage.getItem("access_token"));
    const user = decoded.IsSuperAdmin
    if (user === "True") {
        return <Outlet />;
    } else {
        return <Navigate to="*" replace />;
    }
}

export {SuperAdminRoute};