import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    SubMenu
} from "react-pro-sidebar";
import Logo from "../../assets/images/logo.svg"
import "../../assets/css/header.css";
import Logout from "./../Icons/Logout";
import {
    getTeacherAssociates,getAllDepartments
} from "../../actions/ConfigActions";
import UserAdmin from "../Icons/UserAdmin";
import ClientManagement from "../Icons/ClientManagement";
import {logout} from "../../actions/LoginActions";
import Processes from "../Icons/Processes";

function Sidebar(props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [menuCollapse, setMenuCollapse] = useState(false)

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    const getAllDepartmentList = useSelector(state => state.getAllDepartment)
    const {department_list} = getAllDepartmentList

    useEffect(() => {
        if (getTeacherList.teacher_list <= 0) {
            dispatch(getTeacherAssociates())
        } if (getAllDepartmentList.department_list <= 0) {
            dispatch(getAllDepartments())
        }
        // dispatch(getSignatureStatus())
    }, [dispatch]);

    const logoutUser = () => {
        dispatch(logout())
    }

    return (
        <>
            <div id="header">
                <ProSidebar
                    collapsed={menuCollapse}
                    breakPoint="md"
                >
                    <SidebarHeader>
                        <div className="logo-image" align="center" onClick={() => history('/super-admin-management')}>
                            <img src={Logo} alt="Extensis HR" className="logo-image-resize"/>
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <MenuItem active={props.pageDetails == "super-admin-management" ? true : false}
                                      onClick={() => history('/super-admin-management')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <UserAdmin className="sidebar-icon"
                                              color={props.pageDetails === "super-admin-management" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Super Admin Management</div>
                                </div>
                            </MenuItem>
                            <MenuItem active={props.pageDetails == "client-management" ? true : false}
                                      onClick={() => history('/client-management')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <ClientManagement className="sidebar-icon"
                                                color={props.pageDetails === "client-management" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Client Management</div>
                                </div>
                            </MenuItem>
                            <MenuItem active={props.pageDetails == "analytics" ? true : false}
                                      onClick={() => history('/analytics')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Processes className="sidebar-icon"
                                                color={props.pageDetails === "analytics" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Analytics</div>
                                </div>
                            </MenuItem>
                        </Menu>
                    </SidebarContent>
                </ProSidebar>
            </div>
            <div className="sidebar-footer" onClick={() => logoutUser()}>
                <Logout className="sidebar-icon" color='var(--black-100)'/>
                <span className="menu-font-footer">Logout</span>
            </div>
        </>
    );
}

export default Sidebar;