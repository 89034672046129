import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
} from "react-pro-sidebar";
import Logo from "../assets/images/logo.svg"
import Home from "./Icons/Home"
import Letter from "./Icons/Letter"
import "../assets/css/header.css";
import Calendar from "./Icons/Calendar";
import Document from "./Icons/Document";
import Certification from "./Icons/Certification";
import Processes from "./Icons/Processes";
import Logout from "./Icons/Logout";
import Training from "./Icons/Training";
import Worker from "./Icons/Worker";
import SchoolIncident from "./Icons/SchoolIncident";
import TimeEntry from "./Icons/TimeEntry";
import Payroll from "./Icons/Payroll";
import {
    getEventType,
    getTeacherAssociates,
    getTasksType, getStatus, getDynamicValue, getAllDepartments, getRedirectUrl, resetGetRedirectUrl
} from "../actions/ConfigActions";
import ClientManagement from "./Icons/ClientManagement";
import Note2 from "./Icons/Note2";
import {logout} from "../actions/LoginActions";
import {decoded} from "./PrivateRoute";

function Sidebar(props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [menuCollapse, setMenuCollapse] = useState(false)
    const [accessLevel, setAccessLevel] = useState({
        access: false,
        isPayrollEnable: false,
        isLettersEnable: false,
        isDocumentsEnable: false,
        isProcessesEnable: false,
    })

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    const getEventTypeList = useSelector(state => state.getEventType)
    const {event_type_list} = getEventTypeList

    const getTaskTypeList = useSelector(state => state.getTaskType)
    const {task_type_list} = getTaskTypeList

    const getStatusList = useSelector(state => state.getStatus)
    const {status_list} = getStatusList

    const getDynamicList = useSelector(state => state.getDynamic)
    const {dynamic_list} = getDynamicList

    const getAllDepartmentList = useSelector(state => state.getAllDepartment)
    const {department_list} = getAllDepartmentList

    const getRedirectUrlData = useSelector(state => state.getRedirectUrl)
    const {error, loading, url_redirect} = getRedirectUrlData

    useEffect(() => {
        if(getRedirectUrlData.url_redirect !== undefined) {
            if (getRedirectUrlData.url_redirect.success === true) {
                window.open(getRedirectUrlData.url_redirect.data.redirectUrl)
                dispatch(resetGetRedirectUrl())
            }
            if (getRedirectUrlData.error) {
                alert("Couldn't redirect right now")
            }
        }
    }, [getRedirectUrlData.url_redirect]);

    useEffect(() => {
        if (decoded) {
            setAccessLevel({
                ...accessLevel,
                access: decoded.RoleAccessLevels,
                isPayrollEnable: decoded.IsPayrollEnable,
                isLettersEnable: decoded.IsLettersEnable,
                isDocumentsEnable: decoded.IsDocumentsEnable,
                isProcessesEnable: decoded.IsProcessesEnable,
                isSchoolIncidentEnable: decoded.IsSchoolIncidentEnable,
                isTimeEntryEnable: decoded.IsTimeEntryEnable,
            })
        }
        if (getTeacherList.teacher_list <= 0) {
            dispatch(getTeacherAssociates())
        }
        if (getEventTypeList.event_type_list <= 0) {
            dispatch(getEventType())
        }
        if (getTaskTypeList.task_type_list <= 0) {
            dispatch(getTasksType())
        }
        if (getStatusList.status_list <= 0) {
            dispatch(getStatus())
        }
        if (getDynamicList.dynamic_value <= 0) {
            dispatch(getDynamicValue())
        }
        if (getAllDepartmentList.department_list <= 0) {
            dispatch(getAllDepartments())
        }
        // dispatch(getSignatureStatus())
    }, [dispatch]);

    const logoutUser = () => {
        dispatch(logout())
    }

    const redirectDirectLink = (data) => {
        window.open(data)
    };

    const redirectUrl = (data) => {
        dispatch(getRedirectUrl(data))
    };
    return (
        <>
            <div id="header">
                <ProSidebar
                    collapsed={menuCollapse}
                    breakPoint="md"
                >
                    <SidebarHeader>
                        <div className="logo-image" align="center" onClick={() => history('/dashboard')}>
                            <img src={Logo} alt="Extensis HR" className="logo-image-resize"/>
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <MenuItem active={props.pageDetails == "dashboard" ? true : false}
                                      onClick={() => history('/dashboard')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Home className="sidebar-icon"
                                              color={props.pageDetails === "dashboard" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Home</div>
                                </div>
                            </MenuItem>
                            {accessLevel.access != 0 && decoded.IsLettersEnable != "False" &&
                                <MenuItem active={props.pageDetails == "letters" ? true : false}
                                          onClick={() => history('/letters-notices')}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <Letter className="sidebar-icon"
                                                    color={props.pageDetails === "letters" ? "var(--primary-100)" : "var(--black-75)"}/>
                                        </div>
                                        <div className="col-10 menu-font">Letters</div>
                                    </div>
                                </MenuItem>}
                            {/*{accessLevel.access == 2 &&*/}
                            {/*    <MenuItem active={props.pageDetails == "letters" ? true : false}*/}
                            {/*              onClick={() => history('/letters-notices')}>*/}
                            {/*        <div className="row menu-row">*/}
                            {/*            <div className="col-1">*/}
                            {/*                <Letter className="sidebar-icon"*/}
                            {/*                        color={props.pageDetails === "letters" ? "var(--primary-100)" : "var(--black-75)"}/>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-10 menu-font">Letters</div>*/}
                            {/*        </div>*/}
                            {/*    </MenuItem>}*/}
                            <MenuItem active={props.pageDetails == "calendar" ? true : false}
                                      onClick={() => history('/calendar')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Calendar className="sidebar-icon"
                                                  color={props.pageDetails === "calendar" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Calendar</div>
                                </div>
                            </MenuItem>
                            {<MenuItem active={props.pageDetails == "documents" ? true : false}
                                       onClick={() => history('/documents')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Document className="sidebar-icon"
                                                  color={props.pageDetails === "documents" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Document</div>
                                </div>
                            </MenuItem>}

                            <MenuItem active={props.pageDetails == "certifications" ? true : false}
                                      onClick={() => history('/certifications')}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Certification className="sidebar-icon"
                                                       color={props.pageDetails === "certifications" ? "var(--primary-100)" : "var(--black-75)"}/>
                                    </div>
                                    <div className="col-10 menu-font">Certification</div>
                                </div>
                            </MenuItem>
                            {accessLevel.access != 0 && decoded.IsProcessesEnable != "False" &&
                                <MenuItem active={props.pageDetails == "processes" ? true : false}
                                          onClick={() => history('/processes')}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <Processes className="sidebar-icon"
                                                       color={props.pageDetails === "processes" ? "var(--primary-100)" : "var(--black-75)"}/>
                                        </div>
                                        <div className="col-10 menu-font">Processes</div>
                                    </div>
                                </MenuItem>}
                            {/*{accessLevel.access == 2 &&*/}
                            {/*    <MenuItem active={props.pageDetails == "processes" ? true : false}*/}
                            {/*              onClick={() => history('/processes')}>*/}
                            {/*        <div className="row menu-row">*/}
                            {/*            <div className="col-1">*/}
                            {/*                <Processes className="sidebar-icon"*/}
                            {/*                           color={props.pageDetails === "processes" ? "var(--primary-100)" : "var(--black-75)"}/>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-10 menu-font">Processes</div>*/}
                            {/*        </div>*/}
                            {/*    </MenuItem>}*/}
                            {accessLevel.access != 0 && localStorage.getItem("IsTrainingEnable") != "false" &&
                                <MenuItem onClick={() => redirectDirectLink("https://extensishr-nj.safeschools.com/login")}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <Training className="sidebar-icon" color='var(--black-75)'/>
                                        </div>
                                        <div className="col-10 menu-font">Training</div>
                                    </div>
                                </MenuItem>}
                            {accessLevel.access != 0 && decoded.IsSchoolIncidentEnable != "False" &&
                                <MenuItem onClick={() => redirectDirectLink("https://app.industrysafe.com/")}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <SchoolIncident className="sidebar-icon" color='var(--black-75)'/>
                                        </div>
                                        <div className="col-10 menu-font">School Incident</div>
                                    </div>
                                </MenuItem>}
                            {/*{accessLevel.access == 2 &&*/}
                            {/*    <MenuItem onClick={() => redirectDirectLink("https://app.industrysafe.com/")}>*/}
                            {/*        <div className="row menu-row">*/}
                            {/*            <div className="col-1">*/}
                            {/*                <SchoolIncident className="sidebar-icon" color='var(--black-75)'/>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-10 menu-font">School Incident</div>*/}
                            {/*        </div>*/}
                            {/*    </MenuItem>}*/}
                            {accessLevel.access != 0 && decoded.IsTimeEntryEnable != "False" &&
                                <MenuItem onClick={() => redirectUrl("HRPY.WORK*TCP")}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <TimeEntry className="sidebar-icon" color='var(--black-75)'/>
                                        </div>
                                        <div className="col-10 menu-font">Time Entry</div>
                                    </div>
                                </MenuItem>}
                            {/*{accessLevel.access == 2 &&*/}
                            {/*    <MenuItem onClick={() => redirectUrl("HRPY.WORK*TCP")}>*/}
                            {/*        <div className="row menu-row">*/}
                            {/*            <div className="col-1">*/}
                            {/*                <TimeEntry className="sidebar-icon" color='var(--black-75)'/>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-10 menu-font">Time Entry</div>*/}
                            {/*        </div>*/}
                            {/*    </MenuItem>}*/}
                            {accessLevel.access != 0 && <MenuItem onClick={() => redirectUrl("EMPLOYEE.ACTION.FORMS_NEW.HIRE.ENTRY")}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <ClientManagement className="sidebar-icon" color='var(--black-75)'/>
                                    </div>
                                    <div className="col-10 menu-font">Onboard Teacher</div>
                                </div>
                            </MenuItem>}
                            {accessLevel.access != 0 && <MenuItem onClick={() => redirectUrl("HOMEPAGE")}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Note2 className="sidebar-icon" color='var(--black-75)'/>
                                    </div>
                                    <div className="col-10 menu-font">View Teacher List</div>
                                </div>
                            </MenuItem>}
                            {accessLevel.access != 0 && decoded.IsPayrollEnable != "False" &&
                                <MenuItem onClick={() => redirectUrl("HRPY.WORK*PAYROLL.PROCESSING")}>
                                    <div className="row menu-row">
                                        <div className="col-1">
                                            <Payroll className="sidebar-icon" color='var(--black-75)'/>
                                        </div>
                                        <div className="col-10 menu-font">Process Payroll</div>
                                    </div>
                                </MenuItem>}
                            {/*{accessLevel.access == 2 &&*/}
                            {/*    <MenuItem onClick={() => redirectUrl("HRPY.WORK*PAYROLL.PROCESSING")}>*/}
                            {/*        <div className="row menu-row">*/}
                            {/*            <div className="col-1">*/}
                            {/*                <Payroll className="sidebar-icon" color='var(--black-75)'/>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-10 menu-font">Process Payroll</div>*/}
                            {/*        </div>*/}
                            {/*    </MenuItem>}*/}
                            {accessLevel.access != 0 && <MenuItem onClick={() => redirectUrl("HRPY.WORK*SIGRC")}>
                                <div className="row menu-row">
                                    <div className="col-1">
                                        <Worker className="sidebar-icon" color='var(--black-75)'/>
                                    </div>
                                    <div className="col-10 menu-font">HRCloud</div>
                                </div>
                            </MenuItem>}
                        </Menu>
                    </SidebarContent>
                </ProSidebar>
            </div>
            <div className="sidebar-footer" onClick={() => logoutUser()}>
                <Logout className="sidebar-icon" color='var(--black-100)'/>
                <span className="menu-font-footer">Logout</span>
            </div>
        </>
    );
}

export default Sidebar;