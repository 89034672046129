import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0103 16.9998C11.1603 16.9998 10.3003 16.7798 9.63031 16.3498L3.61031 12.4198C2.49031 11.6898 1.82031 10.4598 1.82031 9.11979C1.82031 7.77979 2.49031 6.54979 3.61031 5.81979L9.64031 1.89979C10.9803 1.02979 13.0703 1.02979 14.4003 1.90979L20.3903 5.83979C21.5003 6.56979 22.1703 7.79979 22.1703 9.12979C22.1703 10.4598 21.5003 11.6898 20.3903 12.4198L14.4003 16.3498C13.7303 16.7898 12.8703 16.9998 12.0103 16.9998ZM12.0103 2.74979C11.4403 2.74979 10.8703 2.87979 10.4603 3.15979L4.44031 7.07979C3.74031 7.53979 3.33031 8.27979 3.33031 9.11979C3.33031 9.95979 3.73031 10.6998 4.44031 11.1598L10.4603 15.0898C11.2903 15.6298 12.7503 15.6298 13.5803 15.0898L19.5703 11.1598C20.2703 10.6998 20.6703 9.95979 20.6703 9.11979C20.6703 8.27979 20.2703 7.53979 19.5703 7.07979L13.5803 3.14979C13.1603 2.88979 12.5903 2.74979 12.0103 2.74979Z"
                fill={props.color}/>
            <path
                d="M12.0002 22.7501C11.5602 22.7501 11.1102 22.6901 10.7502 22.5701L7.56018 21.5101C6.05018 21.0101 4.86018 19.3601 4.87018 17.7701L4.88018 13.0801C4.88018 12.6701 5.22018 12.3301 5.63018 12.3301C6.04018 12.3301 6.38018 12.6701 6.38018 13.0801L6.37018 17.7701C6.37018 18.7101 7.15018 19.7901 8.04018 20.0901L11.2302 21.1501C11.6302 21.2801 12.3702 21.2801 12.7702 21.1501L15.9602 20.0901C16.8502 19.7901 17.6302 18.7101 17.6302 17.7801V13.1401C17.6302 12.7301 17.9702 12.3901 18.3802 12.3901C18.7902 12.3901 19.1302 12.7301 19.1302 13.1401V17.7801C19.1302 19.3701 17.9502 21.0101 16.4402 21.5201L13.2502 22.5801C12.8902 22.6901 12.4402 22.7501 12.0002 22.7501Z"
                fill={props.color}/>
            <path
                d="M21.4004 15.75C20.9904 15.75 20.6504 15.41 20.6504 15V9C20.6504 8.59 20.9904 8.25 21.4004 8.25C21.8104 8.25 22.1504 8.59 22.1504 9V15C22.1504 15.41 21.8104 15.75 21.4004 15.75Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;