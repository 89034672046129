export const GET_TEACHER_ASSOCIATE_REQUEST = 'GET_TEACHER_ASSOCIATE_REQUEST'
export const GET_TEACHER_ASSOCIATE_SUCCESS = 'GET_TEACHER_ASSOCIATE_SUCCESS'
export const GET_TEACHER_ASSOCIATE_FAIL = 'GET_TEACHER_ASSOCIATE_FAIL'
export const GET_TEACHER_ASSOCIATE_RESET = 'GET_TEACHER_ASSOCIATE_RESET'
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL'
export const GET_CATEGORY_RESET = 'GET_CATEGORY_RESET'
export const GET_SIGNATURE_STATUS_REQUEST = 'GET_SIGNATURE_STATUS_REQUEST'
export const GET_SIGNATURE_STATUS_SUCCESS = 'GET_SIGNATURE_STATUS_SUCCESS'
export const GET_SIGNATURE_STATUS_FAIL = 'GET_SIGNATURE_STATUS_FAIL'
export const GET_SIGNATURE_STATUS_RESET = 'GET_SIGNATURE_STATUS_RESET'
export const GET_EVENT_TYPE_REQUEST = 'GET_EVENT_TYPE_REQUEST'
export const GET_EVENT_TYPE_SUCCESS = 'GET_EVENT_TYPE_SUCCESS'
export const GET_EVENT_TYPE_FAIL = 'GET_EVENT_TYPE_FAIL'
export const GET_EVENT_TYPE_RESET = 'GET_EVENT_TYPE_RESET'
export const GET_TASK_TYPE_REQUEST = 'GET_TASK_REQUEST'
export const GET_TASK_TYPE_SUCCESS = 'GET_TASK_SUCCESS'
export const GET_TASK_TYPE_FAIL = 'GET_TASK_FAIL'
export const GET_TASK_TYPE_RESET = 'GET_TASK_RESET'
export const GET_STATUS_REQUEST = 'GET_STATUS_REQUEST'
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS'
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL'
export const GET_STATUS_RESET = 'GET_STATUS_RESET'
export const GET_DYNAMIC_VALUE_REQUEST = 'GET_DYNAMIC_VALUE_REQUEST'
export const GET_DYNAMIC_VALUE_SUCCESS = 'GET_DYNAMIC_VALUE_SUCCESS'
export const GET_DYNAMIC_VALUE_FAIL = 'GET_DYNAMIC_VALUE_FAIL'
export const GET_DYNAMIC_VALUE_RESET = 'GET_DYNAMIC_VALUE_RESET'
export const GET_ALL_DEPARTMENTS_REQUEST = 'GET_ALL_DEPARTMENTS_REQUEST'
export const GET_ALL_DEPARTMENTS_SUCCESS = 'GET_ALL_DEPARTMENTS_SUCCESS'
export const GET_ALL_DEPARTMENTS_FAIL = 'GET_ALL_DEPARTMENTS_FAIL'
export const GET_ALL_DEPARTMENTS_RESET = 'GET_ALL_DEPARTMENTS_RESET'
export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST'
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL'
export const GET_DASHBOARD_DATA_RESET = 'GET_DASHBOARD_DATA_RESET'
export const GET_PAYROLL_DATA_REQUEST = 'GET_PAYROLL_DATA_REQUEST'
export const GET_PAYROLL_DATA_SUCCESS = 'GET_PAYROLL_DATA_SUCCESS'
export const GET_PAYROLL_DATA_FAIL = 'GET_PAYROLL_DATA_FAIL'
export const GET_PAYROLL_DATA_RESET = 'GET_PAYROLL_DATA_RESET'
export const GET_BA_DATA_REQUEST = 'GET_BA_DATA_REQUEST'
export const GET_BA_DATA_SUCCESS = 'GET_BA_DATA_SUCCESS'
export const GET_BA_DATA_FAIL = 'GET_BA_DATA_FAIL'
export const GET_BA_DATA_RESET = 'GET_BA_DATA_RESET'
export const GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST = 'GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST'
export const GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS = 'GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS'
export const GET_ALL_LETTERS_TEMPLATES_LIST_FAIL = 'GET_ALL_LETTERS_TEMPLATES_LIST_FAIL'
export const GET_ALL_LETTERS_TEMPLATES_LIST_RESET = 'GET_ALL_LETTERS_TEMPLATES_LIST_RESET'
export const GET_REDIRECT_URL_REQUEST = 'GET_REDIRECT_URL_REQUEST'
export const GET_REDIRECT_URL_SUCCESS = 'GET_REDIRECT_URL_SUCCESS'
export const GET_REDIRECT_URL_FAIL = 'GET_REDIRECT_URL_FAIL'
export const GET_REDIRECT_URL_RESET = 'GET_REDIRECT_URL_RESET'