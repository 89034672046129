import {
    ADD_SCHOOL_YEAR_SUCCESS,
    ADD_SCHOOL_YEAR_REQUEST,
    ADD_SCHOOL_YEAR_FAIL,
    ADD_SCHOOL_YEAR_RESET,

    EDIT_SCHOOL_YEAR_SUCCESS,
    EDIT_SCHOOL_YEAR_REQUEST,
    EDIT_SCHOOL_YEAR_FAIL,
    EDIT_SCHOOL_YEAR_RESET,

    DELETE_SCHOOL_YEAR_SUCCESS,
    DELETE_SCHOOL_YEAR_REQUEST,
    DELETE_SCHOOL_YEAR_FAIL,
    DELETE_SCHOOL_YEAR_RESET,

    GET_SCHOOL_YEAR_BY_ID_SUCCESS,
    GET_SCHOOL_YEAR_BY_ID_REQUEST,
    GET_SCHOOL_YEAR_BY_ID_FAIL,
    GET_SCHOOL_YEAR_BY_ID_RESET,

    GET_ALL_SCHOOL_YEARS_REQUEST,
    GET_ALL_SCHOOL_YEARS_SUCCESS,
    GET_ALL_SCHOOL_YEARS_FAIL,
    GET_ALL_SCHOOL_YEARS_RESET,

    GET_ALL_SCHOOL_YEAR_EXIST_REQUEST,
    GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS,
    GET_ALL_SCHOOL_YEAR_EXIST_FAIL,
    GET_ALL_SCHOOL_YEAR_EXIST_RESET,

    UPLOAD_CALENDAR_EVENTS_REQUEST,
    UPLOAD_CALENDAR_EVENTS_SUCCESS,
    UPLOAD_CALENDAR_EVENTS_FAIL,
    UPLOAD_CALENDAR_EVENTS_RESET

} from "../constants/CalendarConstants"
const initialState = {};
export const addSchoolYearReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_SCHOOL_YEAR_REQUEST:
            return { loading: true }

        case ADD_SCHOOL_YEAR_SUCCESS:
            return {
                loading: false,
                school_year: action.payload,
            }

        case ADD_SCHOOL_YEAR_FAIL:
            return { loading: false, error: action.payload }

        case ADD_SCHOOL_YEAR_RESET:
            return initialState;

        default:
            return state
    }
}
export const uploadCalenderEventsReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_CALENDAR_EVENTS_REQUEST:
            return { loadingUpload: true }

        case UPLOAD_CALENDAR_EVENTS_SUCCESS:
            return {
                loadingUpload: false,
                upload_events: action.payload,
            }

        case UPLOAD_CALENDAR_EVENTS_FAIL:
            return { loadingUpload: false, errorUpload: action.payload }

        case UPLOAD_CALENDAR_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const editSchoolYearReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_SCHOOL_YEAR_REQUEST:
            return { loadingEdit: true }

        case EDIT_SCHOOL_YEAR_SUCCESS:
            return {
                loadingEdit: false,
                school_year: action.payload,
            }

        case EDIT_SCHOOL_YEAR_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_SCHOOL_YEAR_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteSchoolYearReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SCHOOL_YEAR_REQUEST:
            return { loadingDelete: true }

        case DELETE_SCHOOL_YEAR_SUCCESS:
            return {
                loadingDelete: false,
                school_year: action.payload,
            }

        case DELETE_SCHOOL_YEAR_FAIL:
            return { loadingDelete: false, errorDelete: action.payload }

        case DELETE_SCHOOL_YEAR_RESET:
            return initialState;

        default:
            return state
    }
}
export const getSchoolYearByIdReducer = (state = {school_year_data: ""}, action) => {
    switch (action.type) {
        case GET_SCHOOL_YEAR_BY_ID_REQUEST:
            return { loading: true }

        case GET_SCHOOL_YEAR_BY_ID_SUCCESS:
            return {
                loading: false,
                school_year_data: action.payload,
            }

        case GET_SCHOOL_YEAR_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_SCHOOL_YEAR_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllSchoolYearReducer = (state = {school_year_list: ""}, action) => {
    switch (action.type) {
        case GET_ALL_SCHOOL_YEARS_REQUEST:
            return { loading: true }

        case GET_ALL_SCHOOL_YEARS_SUCCESS:
            return {
                loading: false,
                school_year_list: action.payload,
            }

        case GET_ALL_SCHOOL_YEARS_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_SCHOOL_YEARS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllSchoolYearExistReducer = (state = {school_year_exist: ""}, action) => {
    switch (action.type) {
        case GET_ALL_SCHOOL_YEAR_EXIST_REQUEST:
            return { loading: true }

        case GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS:
            return {
                loading: false,
                school_year_exist: action.payload,
            }

        case GET_ALL_SCHOOL_YEAR_EXIST_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_SCHOOL_YEAR_EXIST_RESET:
            return initialState;

        default:
            return state
    }
}