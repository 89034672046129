import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Row} from "react-bootstrap";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import "../assets/fontawesome-free/css/all.min.css"
import "jquery/dist/jquery.min.js";
import "../datatable/css/jquery.dataTables.css";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import dt from 'datatables.net-dt';
import {useNavigate, useParams} from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import Calendar from "../components/Icons/Calendar";
import {getUpcomingTask, markTaskAsComplete, resetMarkTaskAsComplete} from "../actions/ProcessActions";
import DataTable from "../components/DataTable/DataTable";
import {getUpcomingSchoolEvents} from "../actions/EventActions";
import Loader from "../components/Loader";
import EmptyTable from "../assets/images/empty_table/letters.svg"
import {getEmployeeBaData, getPayrollData, getTeacherAssociates} from "../actions/ConfigActions";
import PencilEdit from "../components/Icons/PencilEdit";
import TickCircle from "../components/Icons/TickCircle";
import AlertModal from "../components/AlertModal";

$.fn.DataTable = dt

function LandingPage(props) {
    const pageName = 'dashboard';
    const [showDelete, setShowDelete] = useState(false)
    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    const [record, setRecord] = useState(true)
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10)

    const [dataDetails, setDataDetails] = useState([])
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    });

    const dateFormat = (date, days) => {
        var today = new Date(date);
        today.setDate(today.getDate() + days);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        if (params.id == 1) {
            dispatch(getUpcomingSchoolEvents(pageNumber, pageSize))
        }
        if (params.id == 2) {
            dispatch(getUpcomingTask(pageNumber, pageSize))
        }
        if (params.id == 3) {
            dispatch(getEmployeeBaData())
        }
        if (params.id == 4) {
            dispatch(getPayrollData())
        }
        if (params.id == 5 || params.id == 6 || params.id == 7 || params.id == 8) {
            dispatch(getTeacherAssociates())
        }
    }, [pageNumber]);

    const getUpcomingSchoolEventsDetails = useSelector(state => state.getUpcomingSchoolEvents)
    const {error, loading, events_data} = getUpcomingSchoolEventsDetails

    useEffect(() => {
        if (params.id == 1) {
            if (!getUpcomingSchoolEventsDetails.events_data <= 0) {
                setDataDetails([])
                console.log(getUpcomingSchoolEventsDetails.events_data)
                getUpcomingSchoolEventsDetails.events_data.map((item, index) => {
                    setDataDetails((prevData) => [
                        ...prevData,
                        {
                            title: item.title,
                            fromDate: item.fromDate,
                        }
                    ])
                });
                setRecord(getUpcomingSchoolEventsDetails.records)
            }
        }
    }, [getUpcomingSchoolEventsDetails.events_data]);

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        let count = 0
        if (params.id == 5 || params.id == 6 || params.id == 7 || params.id == 8) {
            if (!getTeacherList.teacher_list <= 0) {
                setDataDetails([])
                getTeacherList.teacher_list.map((item, index) => {
                    if (params.id == 5) {
                        count = count + 1
                        setDataDetails((prevData) => [
                            ...prevData,
                            {
                                id: item.employee_ID,
                                data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                            }
                        ])
                        setRecord(count)
                    }
                    if (params.id == 6) {
                        if (item.Employee_Status_Code == "L") {
                            count = count + 1
                            setDataDetails((prevData) => [
                                ...prevData,
                                {
                                    id: item.employee_ID,
                                    data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                                }
                            ])
                            setRecord(count + 1)
                        }
                    }
                    if (params.id == 7) {
                        if (item.onboarD_INPROGRESS == "Y") {
                            count = count + 1
                            setDataDetails((prevData) => [
                                ...prevData,
                                {
                                    id: item.employee_ID,
                                    data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                                }
                            ])
                            setRecord(count)
                        }
                    }
                    if (params.id == 8) {
                        if (item.openEnrollmentStatus == "Y") {
                            count = count + 1
                            setDataDetails((prevData) => [
                                ...prevData,
                                {
                                    id: item.employee_ID,
                                    data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                                }
                            ])
                            setRecord(count)
                        }
                    }
                })
            }
        }
    }, [getTeacherList.teacher_list]);

    const getEmployeeBaDetails = useSelector(state => state.getEmployeeBa)
    const {errorBa, loadingBa, employee_ba} = getEmployeeBaDetails

    useEffect(() => {
        if (params.id == 3) {
            if (!getEmployeeBaDetails.employee_ba <= 0) {
                setDataDetails([])
                console.log(getEmployeeBaDetails.employee_ba)
                getEmployeeBaDetails.employee_ba.map((item, index) => {
                    setDataDetails((prevData) => [
                        ...prevData,
                        {
                            employee_ID: item.employee_ID,
                            employee_Name: item.employee_First_Name + " " + item.employee_Last_Name,
                            eE_Birth_Date: item.eE_Birth_Date,
                            eE_Orig_Hire_Date: item.eE_Orig_Hire_Date,
                            type: item.type,
                        }
                    ])
                });

                setRecord(getEmployeeBaDetails.employee_ba.length)
            }
        }
    }, [getEmployeeBaDetails.employee_ba]);

    const getEmployeePayrollDetails = useSelector(state => state.getEmployeePayroll)
    const {errorPayroll, loadingPayroll, employee_payroll} = getEmployeePayrollDetails

    useEffect(() => {
        if (params.id == 4) {
            if (!getEmployeePayrollDetails.employee_payroll <= 0) {
                setDataDetails([])
                console.log(getEmployeePayrollDetails.employee_payroll)
                getEmployeePayrollDetails.employee_payroll.map((item, index) => {
                    setDataDetails((prevData) => [
                        ...prevData,
                        {
                            eE_ID: item.eE_ID,
                            employee_First_Name: item.employee_First_Name,
                            pay_Date: item.pay_Date,
                        }
                    ])
                });

                setRecord(getEmployeePayrollDetails.employee_payroll.length)
            }
        }
    }, [getEmployeePayrollDetails.employee_payroll]);

    const getUpcomingTaskDetails = useSelector(state => state.getUpcomingTask)
    const {errorUpcoming, loadingUpcoming, upcoming_task_list} = getUpcomingTaskDetails

    useEffect(() => {
        if (params.id == 2) {
            if (!getUpcomingTaskDetails.upcoming_task_list <= 0) {
                setDataDetails([])
                console.log(getUpcomingTaskDetails.upcoming_task_list)
                getUpcomingTaskDetails.upcoming_task_list.map((item, index) => {
                    setDataDetails((prevData) => [
                        ...prevData,
                        {
                            taskName: item.title,
                            assignToName: item.assignToName,
                            notes: item.notes,
                            processId: item.processId,
                            processTaskId: item.processTaskId,
                            createdDate: item.createdDate,
                        }
                    ])
                });
                if (getUpcomingTaskDetails.records !== undefined) {
                    setRecord(getUpcomingTaskDetails.records)
                }
            }
        }
    }, [getUpcomingTaskDetails.upcoming_task_list]);

    const markTaskAsCompleteDetails = useSelector(state => state.markTaskAsComplete)
    const {mark_task} = markTaskAsCompleteDetails

    useEffect(() => {
        if (markTaskAsCompleteDetails.mark_task === true) {
            dispatch(resetMarkTaskAsComplete())
            setAlertModal({
                display: true,
                type: "success",
                message: "Task marked as complete"
            })
            setTimeout(() => {
                history("/")
            }, 1000);
        }
        if (markTaskAsCompleteDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Task couldn't be marked as complete"
            })
        }
    }, [markTaskAsCompleteDetails]);

    const completeTask = (row) => {
        // console.log(row)
        dispatch(markTaskAsComplete(row.processTaskId))
    }

    const columnsUpcomingEvents = React.useMemo(() => [
        {
            Header: 'Event Title',
            accessor: 'title',
        },
        {
            Header: 'Event Date',
            accessor: 'fromDate',
        }
    ], []);
    const columnsUpcomingTasks = React.useMemo(() => [
        {
            Header: 'Task Name',
            accessor: 'taskName',
            // width: 20
        },
        {
            Header: 'Teacher/Associate',
            accessor: 'assignToName',
            // width: 20
        },
        {
            Header: 'Task Date',
            accessor: 'createdDate',
            // width: 20
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            // width: 20
        },
        {
            Header: 'Action',
            id: 'actions',
            // width: 20,
            Cell: ({row}) => (
                <div className="row background-1 complete-button button-cursor">
                    <div onClick={() => completeTask(row.original)} className="padding-left-unset">
                        <TickCircle className="sidebar-icon"
                                    color="var(--primary-100)"/>
                        <span className="text-blue">  Complete Task</span>
                    </div>
                </div>
            ),
        },
    ], []);
    const columnsPayroll = React.useMemo(() => [
        {
            Header: 'Payroll',
            accessor: 'eE_ID',
        },
        {
            Header: 'Description',
            accessor: 'employee_First_Name',
        },
        {
            Header: 'Pay Date',
            accessor: 'pay_Date',
        }
    ], []);
    const columnsTeachers = React.useMemo(() => [
        {
            Header: 'Teacher/Associate Name',
            accessor: 'data',
        },
        {
            Header: 'Associate ID',
            accessor: 'id',
        }
    ], []);
    const columnsBa = React.useMemo(() => [
        {
            Header: 'Teacher/Associate Name',
            accessor: 'employee_Name',
        },
        {
            Header: 'Event Name',
            accessor: 'type',
        },
        {
            Header: 'Event Date',
            accessor: 'eventDate',
            Cell: ({row}) => {
                // Check the type to determine which date to display
                if (row.original.type === 'Birthday') {
                    return (
                        <div>
                            <Calendar className="sidebar-icon margin-right"
                                      color="var(--primary-100)"/>
                            {formatDateName(row.original.eE_Birth_Date)}
                        </div>
                    )
                } else if (row.original.type === 'Work Anniversary') {
                    return (
                        <div>
                            <Calendar className="sidebar-icon margin-right"
                                      color="var(--primary-100)"/>
                            {formatDateName(row.original.eE_Orig_Hire_Date)}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <Calendar className="sidebar-icon margin-right"
                                      color="var(--primary-100)"/>
                            NA
                        </div>
                    )
                }
            },
        }
    ], []);

    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };

    const hideDeleteModal = (data) => {
        setShowDelete(false)
    }
    const changePage = (data) => {
        setDataDetails([])
        setPageNumber(data)
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }

    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingUpcoming && <Loader/>}
            {loadingPayroll && <Loader/>}
            {loadingBa && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type}
                            result={closeAlert}/>}
            {showDelete && <DeleteModal heading="Delete Document"
                                        type="delete"
                                        question="Are you sure want to delete this Document ?"
                                        answer=""
                                        result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                {params.id == 1 && <Heading group="calendar_landing" title="Upcoming School Event"/>}
                {params.id == 2 && <Heading group="upcoming_landing" title="Assigned Tasks"/>}
                {params.id == 3 && <Heading group="upcoming_landing" title="This Month’s Birthdays and Anniversaries"/>}
                {params.id == 4 && <Heading group="upcoming_landing" title="Payroll History"/>}
                {params.id == 5 && <Heading group="upcoming_landing" title="Teacher/Associates"/>}
                {params.id == 6 && <Heading group="upcoming_landing" title="On LOA"/>}
                {params.id == 7 && <Heading group="upcoming_landing" title="In Onboarding"/>}
                {params.id == 8 && <Heading group="upcoming_landing" title="In Enrollment"/>}

                <Row className="app-body-landing background-1">
                    {/*<div className="pl-5 pb-2 background-1"*/}
                    {/*>*/}

                    {dataDetails && record >= 0 && params.id == 1 &&
                        <div className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={10}
                                       pageSize={pageSize} columns={columnsUpcomingEvents} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </div>}
                    {dataDetails && params.id == 2 &&
                        <div className="background-1 mt-4">
                            <DataTable defaultPageSize={5}
                                       pageSize={pageSize} columns={columnsUpcomingTasks} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </div>
                    }
                    {params.id == 3 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={pageSize} columns={columnsBa} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {params.id == 4 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={pageSize} columns={columnsPayroll} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {params.id == 5 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={record} columns={columnsTeachers} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {params.id == 6 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={record} columns={columnsTeachers} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {params.id == 7 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={record} columns={columnsTeachers} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {params.id == 8 &&
                        <Row className="background-1 mt-4 ps-2">
                            <DataTable defaultPageSize={5}
                                       pageSize={record} columns={columnsTeachers} data={dataDetails}
                                       recordData={record}
                                       result={changePage} empty_table={EmptyTable}
                                       empty_text={"No records available to display"}
                            />
                            <Footer/>
                        </Row>}
                    {/*</div>*/}

                </Row>
            </div>

        </Row>
    </>);
}

export default LandingPage;