import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import DropdownItems from "../components/DropdownItems";
import "../assets/fontawesome-free/css/all.min.css"
import "jquery/dist/jquery.min.js";
import "../assets/css/card-table.css"
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import dt from 'datatables.net-dt';
import PencilEdit from "../components/Icons/PencilEdit";
import {useNavigate, useLocation} from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import AlertModal from "../components/AlertModal";
import EmptyTable from "../assets/images/empty_table/process.svg"
import {
    getAllProcesses,
    getAllProcessTemplate,
    resetAddProcessTemplate, resetCancelAllTasks, resetCompleteAllTasks, resetEditProcessTemplate,
    resetGetProcessTemplateById
} from "../actions/ProcessActions";
import Loader from "../components/Loader";
import DataTableCard from "../components/DataTable/DataTableCard";
import DataTable from "../components/DataTable/DataTable";
import {GrPowerReset} from "react-icons/gr";

$.fn.DataTable = dt

function Processes(props) {
    const processTitleRef = useRef();
    const [showDelete, setShowDelete] = useState(false)
    const [type, setType] = useState("")
    const [templates, setTemplate] = useState(true)
    const [letters, setLetters] = useState(false)
    const [processTemplates, setProcessTemplates] = React.useState([]);
    const [status, setStatus] = React.useState([]);
    const [processes, setProcesses] = React.useState([]);
    const [record, setRecord] = React.useState();
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = useState(9)
    const [pageSizeProcesses, setPageSizeProcesses] = useState(5)
    const [recordProcesses, setRecordProcesses] = React.useState(1);
    const [pageNumberProcesses, setPageNumberProcesses] = React.useState(1);
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [fieldData, setFieldData] = useState({
        dataProcessTitle: "",
        dataProcessStatus: "",
    })
    const [processData, setProcessData] = useState([])
    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        setProcesses([])
        dispatch(resetEditProcessTemplate())
        reloadProcesses();
    }, [pageNumberProcesses, fieldData]);

    const reloadProcesses = () => {
        dispatch(getAllProcesses(pageSizeProcesses, pageNumberProcesses, fieldData.dataProcessTitle, fieldData.dataProcessStatus))
    }

    const reloadTemplate = () => {
        dispatch(getAllProcessTemplate(pageSize, pageNumber))
    }

    useEffect(() => {
        reloadTemplate()
    }, [pageNumber]);

    const getProcessTemplate = useSelector(state => state.getAllProcessTemplate)
    const {error, loading, processTemplateInfo} = getProcessTemplate

    useEffect(() => {
        setProcessTemplates([])
        if (!getProcessTemplate.process_template_list <= 0) {
            getProcessTemplate.process_template_list.map((item, index) => {
                setProcessTemplates((prevData) => [
                    ...prevData,
                    {
                        processTemplateId: item.processTemplateId,
                        title: item.title,
                        templateType: item.templateType,
                        typeName: item.typeName,
                        processTemplateTasksCount: item.processTemplateTasksCount,
                    }
                ])
            });
            if (getProcessTemplate.records !== undefined) {
                setRecord(getProcessTemplate.records)
            }
        }
    }, [getProcessTemplate.process_template_list]);

    const getStatusList = useSelector(state => state.getStatus)
    const {status_list} = getStatusList

    useEffect(() => {
        if (!getStatusList.status_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getStatusList.status_list.map((item, index) => {
                setStatus((prevData) => [
                    ...prevData,
                    {
                        id: item.processStatusId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getStatusList.status_list]);

    const columns = React.useMemo(() => [
        {
            Header: 'Id',
            accessor: 'processTemplateId',
        },
        {
            Header: 'Item',
            accessor: 'item',
        },
        {
            Header: 'Type Name',
            accessor: 'typeName',
        },
        {
            Header: 'Task Count',
            accessor: 'processTemplateTasksCount',
        },
    ], []);

    const getProcessContent = useSelector(state => state.getAllProcesses)
    const {errorProcess, loadingProcess, processInfo} = getProcessContent

    useEffect(() => {
        setProcesses([])
        if (!getProcessContent.process_list <= 0) {
            getProcessContent.process_list.map((item, index) => {
                setProcesses((prevData) => [
                    ...prevData,
                    {
                        processId: item.processId,
                        processTemplateId: item.processTemplateId,
                        title: item.title,
                        assignToId: item.assignToId,
                        assignToName: item.assignToName,
                        statusId: item.statusId,
                        statusTitle: item.statusTitle,
                        isActive: item.isActive,
                        dateStarted: item.dateStarted,
                        dateCompleted: item.dateCompleted
                    }
                ])
            });
            if (getProcessContent.records !== undefined) {
                setRecordProcesses(getProcessContent.records)
            }
        }
    }, [getProcessContent.process_list]);

    const columns_process = React.useMemo(() => [
        {
            Header: 'Process Name',
            accessor: 'title',
            width: "30%"
        },
        {
            Header: 'Teacher/Associate',
            accessor: 'assignToName',
            width: "20%"
        },
        {
            Header: 'Status',
            accessor: 'statusId',
            width: "15%"
        },
        {
            Header: 'Start Date',
            accessor: 'dateStarted',
            width: "15%"
        },
        {
            Header: 'End Date',
            accessor: 'dateCompleted',
            width: "15%"
        },
        {
            Header: 'Action',
            id: 'actions',
            width: "15%",
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDoc(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                </div>
            ),
        },
    ], []);

    useEffect(() => {
        if (localStorage.getItem('processes') === "1") {
            setType("1")
            setTemplate(true)
            setLetters(false)
            setProcessTemplates([])
        }
        if (localStorage.getItem('processes') == null || localStorage.getItem('processes') === "2") {
            reloadProcesses()
            setType("2");
            setTemplate(false)
            setLetters(true)
            // setTimeout(() => {
            //     dataLettersDocument();
            // }, 1000);
        }
        if (location.state?.success_process) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Process started successfully"
            })

            history('/processes', {state: {}})
        }
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Process Template added successfully"
            })

            history('/processes', {state: {}})
        }
        if (location.state?.success_process_complete) {
            setAlertModal({
                display: true,
                type: "success",
                message: "All tasks has been completed"
            })

            history('/processes', {state: {}})
        }
        if (location.state?.success_process_cancel) {
            setAlertModal({
                display: true,
                type: "success",
                message: "All tasks has been cancelled"
            })

            history('/processes', {state: {}})
        }
        dispatch(resetGetProcessTemplateById())
        dispatch(resetCompleteAllTasks())
        dispatch(resetCancelAllTasks())
        // alert(location.state?.toString())
    }, [])

    const pageName = 'processes';


    const editDoc = (data) => {
        console.log(data)
        history(`/manage-process/${data.processId}/${data.statusId}`)
    }
    const hideDeleteModal = (data) => {
        setShowDelete(false)
    }
    const templateHeader = (data) => {
        if (data === "1") {
            setType("1")
            setTemplate(true)
            setLetters(false)
            reloadTemplate()
            localStorage.setItem('processes', "1")
        }
        if (data === "2") {
            setTemplate(false)
            setLetters(true)
            setType(data)
            reloadProcesses()
            localStorage.setItem('processes', "2")
        }

    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddProcessTemplate())
    }

    const handleResult = (data) => {
        if (data[0] === "1") {
            setProcessTemplates([])
            setPageNumber(data[1])
        }
        if (data[0] === "2") {
            history(`/edit-process-template/${data[1]}`)
        }
    }

    const handleResultProcesses = (data) => {
        setProcesses([])
        setPageNumberProcesses(data)
    }
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setFieldData({
                ...fieldData,
                dataProcessTitle: processTitleRef.current.value
            })
        }
    }
    const changeSearch = (e) => {
        if (e.target.value == "") {
            setFieldData({
                ...fieldData,
                dataProcessTitle: processTitleRef.current.value
            })
        }
    }
    const statusAdd = (data) => {
        setFieldData({
            ...fieldData,
            dataProcessStatus: data.id
        })
    }
    const resetData = (e) => {
        e.preventDefault()
        processTitleRef.current.value = ""
        setFieldData({
            ...fieldData,
            dataProcessTitle: null,
            dataProcessStatus: null,
        })
    }

    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingProcess && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type}
                            result={closeAlert}/>}
            {showDelete && <DeleteModal heading="Delete Document"
                                        type="delete"
                                        question="Are you sure want to delete this Document ?"
                                        answer=""
                                        result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="processes" title="Document List" type={type} result={templateHeader}/>
                {letters && <Row className="background-1 m-3 process">
                    <Col lg={{span: 5}} md={{span: 12}}>
                        <div className="">
                            <input
                                ref={processTitleRef}
                                onKeyPress={handleSearch}
                                onChange={changeSearch}
                                type="text" autoComplete="off"
                                className="input-document input-spacing-document"
                                placeholder="Process Name"
                                id="document_title"/>
                        </div>
                    </Col>
                    <Col lg={{span: 5}} md={{span: 12}}>
                        <DropdownItems data={status} title="Process Status" result={statusAdd} value={fieldData.dataProcessStatus}/>
                    </Col>
                    <Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>
                        <div className="float-right-child add-button button-solid reset-filter-button" onClick={resetData}>
                            <GrPowerReset classname="button-icon"/> Reset
                        </div>
                    </Col>
                </Row>}

                {templates && <Row className="app-body-template background-1">
                    {processTemplates && <Row className="background-1">
                        <div className="card-body-table mt-4">
                            <DataTableCard
                                pageSize={pageSize} columns={columns} data={processTemplates} recordData={record}
                                result={handleResult} empty_table={EmptyTable} empty_text={"You have not created any process template"}
                            />
                        </div>
                    </Row>}
                    <Footer/>
                </Row>}
                {letters && <Row className="app-body-template-letters background-1">
                    {processes && <Row className="background-2 app-body-template-letters-padding">
                        <DataTable
                            pageSize={pageSizeProcesses} columns={columns_process} data={processes}
                            recordData={recordProcesses}
                            result={handleResultProcesses} empty_table={EmptyTable} empty_text={"You have not created and processes yet"}
                        />
                    </Row>}
                    <Footer/>
                </Row>}
            </div>

        </Row>
    </>);
}

export default Processes;