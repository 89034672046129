export const ADD_SCHOOL_YEAR_REQUEST = 'ADD_SCHOOL_YEAR_REQUEST'
export const ADD_SCHOOL_YEAR_SUCCESS = 'ADD_SCHOOL_YEAR_SUCCESS'
export const ADD_SCHOOL_YEAR_FAIL = 'ADD_SCHOOL_YEAR_FAIL'
export const ADD_SCHOOL_YEAR_RESET = 'ADD_SCHOOL_YEAR_RESET'
export const GET_SCHOOL_YEAR_BY_ID_REQUEST = 'GET_SCHOOL_YEAR_BY_ID_REQUEST'
export const GET_SCHOOL_YEAR_BY_ID_SUCCESS = 'GET_SCHOOL_YEAR_BY_ID_SUCCESS'
export const GET_SCHOOL_YEAR_BY_ID_FAIL = 'GET_SCHOOL_YEAR_BY_ID_FAIL'
export const GET_SCHOOL_YEAR_BY_ID_RESET = 'GET_SCHOOL_YEAR_BY_ID_RESET'
export const EDIT_SCHOOL_YEAR_REQUEST = 'EDIT_SCHOOL_YEAR_REQUEST'
export const EDIT_SCHOOL_YEAR_SUCCESS = 'EDIT_SCHOOL_YEAR_SUCCESS'
export const EDIT_SCHOOL_YEAR_FAIL = 'EDIT_SCHOOL_YEAR_FAIL'
export const EDIT_SCHOOL_YEAR_RESET = 'EDIT_SCHOOL_YEAR_RESET'
export const DELETE_SCHOOL_YEAR_REQUEST = 'DELETE_SCHOOL_YEAR_REQUEST'
export const DELETE_SCHOOL_YEAR_SUCCESS = 'DELETE_SCHOOL_YEAR_SUCCESS'
export const DELETE_SCHOOL_YEAR_FAIL = 'DELETE_SCHOOL_YEAR_FAIL'
export const DELETE_SCHOOL_YEAR_RESET = 'DELETE_SCHOOL_YEAR_RESET'
export const GET_ALL_SCHOOL_YEARS_REQUEST = 'GET_ALL_SCHOOL_YEARS_REQUEST'
export const GET_ALL_SCHOOL_YEARS_SUCCESS = 'GET_ALL_SCHOOL_YEARS_SUCCESS'
export const GET_ALL_SCHOOL_YEARS_FAIL = 'GET_ALL_SCHOOL_YEARS_FAIL'
export const GET_ALL_SCHOOL_YEARS_RESET = 'GET_ALL_SCHOOL_YEARS_RESET'
export const GET_ALL_SCHOOL_YEAR_EXIST_REQUEST = 'GET_ALL_SCHOOL_YEAR_EXIST_REQUEST'
export const GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS = 'GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS'
export const GET_ALL_SCHOOL_YEAR_EXIST_FAIL = 'GET_ALL_SCHOOL_YEAR_EXIST_FAIL'
export const GET_ALL_SCHOOL_YEAR_EXIST_RESET = 'GET_ALL_SCHOOL_YEAR_EXIST_RESET'
export const UPLOAD_CALENDAR_EVENTS_REQUEST = 'UPLOAD_CALENDAR_EVENTS_REQUEST'
export const UPLOAD_CALENDAR_EVENTS_SUCCESS = 'UPLOAD_CALENDAR_EVENTS_SUCCESS'
export const UPLOAD_CALENDAR_EVENTS_FAIL = 'UPLOAD_CALENDAR_EVENTS_FAIL'
export const UPLOAD_CALENDAR_EVENTS_RESET = 'UPLOAD_CALENDAR_EVENTS_RESET'