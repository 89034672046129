import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import {Row, Card} from "react-bootstrap";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import "../../assets/fontawesome-free/css/all.min.css"
import "../../datatable/css/jquery.dataTables.css";
import Advisors from "../../assets/json/advisor_list.json"
import PencilEdit from "../../components/Icons/PencilEdit";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import EmptyTable from "../../assets/images/empty_table/letters.svg"
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable/DataTable";
import {getClientUsersByClient} from "../../actions/ClientManagementActions";
import LeftArrow from "../../components/Icons/LeftArrow";
import { FaAngleDown, FaAngleUp  } from "react-icons/fa6";
import {getAllTrustedAdvisors, resetAddTrustedAdvisor} from "../../actions/TrustedAdvisorActions";
import DataTableSorting from "../../components/DataTable/DataTableSorting";

function TeacherList(props) {
    const pageName = 'client-management';
    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [pageSize, setPageSize] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [record, setRecord] = React.useState();
    const [documents, setDocuments] = React.useState([]);

    const [pageSizeAdvisor, setPageSizeAdvisor] = useState()
    const [pageNumberAdvisor, setPageNumberAdvisor] = useState(1)
    const [recordAdvisor, setRecordAdvisor] = React.useState();
    const [advisor, setAdvisor] = React.useState([]);

    const [viewEmployees, setViewEmployees] = useState(true)
    const [viewAdvisor, setViewAdvisor] = useState(true)
    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            width: '35%',
        },
        {
            Header: 'User Id',
            accessor: 'userId',
            width: '30%',
        },
        {
            Header: 'Role',
            accessor: 'roleId',
            width: '30%',
            Cell: ({value}) => (value == 1 ? <span>Admin</span> :
                <span>Teacher/Associate</span>)
        },
        {
            Header: 'Action',
            id: 'actions', // The 'id' is necessary here since this column doesn't correspond to a data field
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDoc(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                </div>
            ),
            width: '5%',
        },
    ], []);
    const columnsAdvisor = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            width: '25%',
        },
        {
            Header: 'Username',
            accessor: 'userName',
            width: '20%',
        },
        {
            Header: 'Permission',
            accessor: 'permission',
            width: '50%',
            Cell: ({row}) => {
                let cellContent = [
                    row.original.isPayrollEnable,
                    row.original.isLettersEnable,
                    row.original.isDocumentsEnable,
                    row.original.isProcessesEnable,
                    row.original.isSchoolIncidentEnable,
                    row.original.isTimeEntryEnable
                ]
                    .filter(Boolean) // Removes any false values (like empty strings or nulls)
                    .join(', '); // Joins the remaining values with a comma

                // Check if the last character is a comma, and if so, remove it (extra safeguard)
                if (cellContent.endsWith(',')) {
                    cellContent = cellContent.slice(0, -1);
                }

                return (
                    <div className="row background-1">
                        {cellContent}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            id: 'actions', // The 'id' is necessary here since this column doesn't correspond to a data field
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDocAdvisor(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                </div>
            ),
            width: '5%',
        },
    ], []);

    const getClientUsersDetails = useSelector(state => state.getClientUsers)
    const {error, loading, client_data_list} = getClientUsersDetails

    useEffect(() => {
        // setDocuments(getDocuments.document_list)
        if (!getClientUsersDetails.client_data <= 0) {
            setRecord(getClientUsersDetails.client_data.length)
            setPageSize(getClientUsersDetails.client_data.length)
            // console.log(getClientUsersDetails.client_data)
            getClientUsersDetails.client_data.map((item, index) => {
                setDocuments((prevData) => [
                    ...prevData,
                    {
                        clientUserId: item.clientUserId,
                        name: item.name,
                        userId: item.userId,
                        roleId: item.roleId,
                        clientId: item.clientId
                    }
                ])
            });
        }
    }, [getClientUsersDetails.client_data]);

    const getAllTrustedAdvisorDetails = useSelector(state => state.getAllTrustedAdvisor)
    const {errorTa, loadingTa, taData} = getAllTrustedAdvisorDetails

    useEffect(() => {
        // setDocuments(getDocuments.document_list)
        if (!getAllTrustedAdvisorDetails.taData <= 0) {
            setRecordAdvisor(getAllTrustedAdvisorDetails.taData.length)
            setPageSizeAdvisor(getAllTrustedAdvisorDetails.taData.length)

            getAllTrustedAdvisorDetails.taData.map((item, index) => {
                setAdvisor((prevData) => [
                    ...prevData,
                    {
                        trustedAdvisorUserId: item.trustedAdvisorUserId,
                        name: item.name,
                        clientId: item.clientId,
                        userName: item.userName,
                        isPayrollEnable: item.isPayrollEnable == true ? "Payroll" : "",
                        isLettersEnable: item.isLettersEnable == true ? "Letters" : "",
                        isDocumentsEnable: item.isDocumentsEnable == true ? "Documents" : "",
                        isProcessesEnable: item.isProcessesEnable == true ? "Process" : "",
                        isSchoolIncidentEnable: item.isSchoolIncidentEnable == true ? "School Incident" : "",
                        isTimeEntryEnable: item.isTimeEntryEnable == true ? "Time entry" : ""
                    }
                ])
            });
        }
    }, [getAllTrustedAdvisorDetails.taData]);


    const changePage = (data) => {
        setDocuments([])
        setPageNumber(data)
    }
    const changePageAdvisor = (data) => {
        setAdvisor([])
        setPageNumberAdvisor(data)
    }


    useEffect(() => {
        dataTableReload()
        dispatch(resetAddTrustedAdvisor())
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Super admin user created"
            })
            history('/client-teacher-list', {state: {}})
        }
        if (location.state?.advisor) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Trusted Advisor added successfully"
            })
            history(`/client-teacher-list/${params.id}`, {state: {}})
        }
        if (location.state?.edit_advisor) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Trusted Advisor edited successfully"
            })
            history(`/client-teacher-list/${params.id}`, {state: {}})
        }
        if (location.state?.delete) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Trusted Advisor deleted successfully"
            })
            history(`/client-teacher-list/${params.id}`, {state: {}})
        }
    }, [])


    const dataTableReload = () => {
        setDocuments([])
        setAdvisor([])
        dispatch(getClientUsersByClient(params.id))
        dispatch(getAllTrustedAdvisors(params.id))
    }
    const editDoc = (data) => {
        history(`/client-user-edit/${data.clientUserId}/${params.id}`, {state: {clientName: location.state.clientName}})
    }
    const editDocAdvisor = (data) => {
        history(`/trusted-advisor-edit/${data.trustedAdvisorUserId}`, {state: {clientName: location.state.clientName, client_id: params.id}})
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }
    const hideAdvisor = () => {
        setViewAdvisor(false)
    }
    const showAdvisor = () => {
        setViewAdvisor(true)
        dispatch(getAllTrustedAdvisors(params.id))
    }
    const hideEmployees = () => {
        setViewEmployees(false)
    }
    const showEmployees = () => {
        setViewEmployees(true)
        dispatch(getClientUsersByClient(params.id))
    }


    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingTa && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}

            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="client_user_list" title={location.state.clientName} client_id={params.id}/>

                <Row className="app-body-teacher-list background-1 m-3">
                    <div className="card-employee-view">
                        <div>
                            <span className="employee-sub-header float-left-child">
                                Trusted Advisor
                            </span>
                            <div className="float-right-child">
                                {viewAdvisor && <FaAngleUp className="employee-sub-header-icon" onClick={hideAdvisor}/>}
                                {!viewAdvisor &&
                                    <FaAngleDown className="employee-sub-header-icon" onClick={showAdvisor}/>}
                            </div>
                        </div>
                        {viewAdvisor && <div>
                            {documents && <div className="background-2">
                                <DataTable defaultPageSize={20}
                                           pageSize={pageSizeAdvisor} columns={columnsAdvisor} data={advisor}
                                           recordData={recordAdvisor}
                                           result={changePageAdvisor} empty_table={EmptyTable}
                                           empty_text={"No records available to display"}
                                />
                            </div>}
                        </div>}
                    </div>
                    <div className="card-employee-view">
                        <div>
                            <span className="employee-sub-header float-left-child">
                                Employees
                            </span>
                            <div className="float-right-child">
                                {viewEmployees && <FaAngleUp className="employee-sub-header-icon" onClick={hideEmployees}/>}
                                {!viewEmployees && <FaAngleDown className="employee-sub-header-icon" onClick={showEmployees}/>}
                            </div>
                        </div>
                        {viewEmployees && <div>
                            {documents && <div className="background-2">
                                <DataTableSorting defaultPageSize={20}
                                           pageSize={pageSize} columns={columns} data={documents} recordData={record}
                                           result={changePage} empty_table={EmptyTable}
                                           empty_text={"No records available to display"}
                                />
                            </div>}
                        </div>}
                    </div>
                    <Footer/>
                </Row>
            </div>

        </Row>
    </>);
}

export default TeacherList;