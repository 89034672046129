import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.20639 12.1466L3.85283 12.5001L4.20639 12.8537L9.74639 18.3937C9.84113 18.4884 9.84113 18.6518 9.74639 18.7466L9.74029 18.7527L9.73441 18.759C9.70417 18.7914 9.6453 18.8201 9.56994 18.8201C9.50539 18.8201 9.44478 18.7979 9.39349 18.7466L3.32349 12.6766C3.22876 12.5818 3.22876 12.4184 3.32349 12.3237L9.39349 6.25367C9.48823 6.15893 9.65165 6.15893 9.74639 6.25367C9.84113 6.34841 9.84113 6.51183 9.74639 6.60656L4.20639 12.1466Z"
                stroke={props.color}/>
            <path
                d="M20.4999 12.75H3.66992C3.53606 12.75 3.41992 12.6339 3.41992 12.5C3.41992 12.3661 3.53606 12.25 3.66992 12.25H20.4999C20.6338 12.25 20.7499 12.3661 20.7499 12.5C20.7499 12.6339 20.6338 12.75 20.4999 12.75Z"
                stroke={props.color}/>
        </svg>
    );
}

export default Home;