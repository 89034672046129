import React from 'react';
import Calendar from "./Icons/Calendar";

function CardDetailOne(props) {
    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };
    return (
        <div>
            {props.data.slice(0, 3).map((item, index) => {
                    return (<>
                            <p className="metric-header padding-top">
                                {item.title}
                            </p>
                            <p className="metric-data">
                                <Calendar className="sidebar-icon small margin-right"
                                          color="var(--primary-100)"/> {formatDateName(item.fromDate)}
                            </p></>
                    )
                }
            )}
        </div>
    );
}

export default CardDetailOne;