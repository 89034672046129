import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import {Col, Row} from "react-bootstrap";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import "../../assets/fontawesome-free/css/all.min.css"
import "../../datatable/css/jquery.dataTables.css";

import PencilEdit from "../../components/Icons/PencilEdit";
import Trash from "../../components/Icons/Trash";
import {useLocation, useNavigate} from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import AlertModal from "../../components/AlertModal";
import {
    resetAddDocument,
    resetGetDocumentById
} from "../../actions/DocumentActions";
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable/DataTable";
import {deleteSuperAdminById, getAllSuperAdmin, resetDeleteSuperAdmin} from "../../actions/SuperAdminActions";
import EmptyTable from "../../assets/images/super_admin_empty.svg";

function SuperAdminManagement(props) {
    const pageName = 'super-admin-management';
    const [showDelete, setShowDelete] = useState({
        display: false,
        data: null,
        name: null
    })
    const history = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [pageSize, setPageSize] = useState(5)
    const [pageNumber, setPageNumber] = useState(1)
    const [record, setRecord] = React.useState();
    const [documents, setDocuments] = React.useState([]);
    const [fieldData, setFieldData] = useState({
        dataTitle: "",
        dataTeacher: "",
        dataDocumentCategory: "",
        dataSignature: "",
        dataFromDate: "",
        dataToDate: "",
        dataImportFromDate: "",
        dataImportToDate: "",
    });
    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'adminName',
            width: '35%',
        },
        {
            Header: 'Username',
            accessor: 'prismaUserId',
            width: '35%',
        },
        {
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({value}) => (value ? <span className="text-active">Active</span> :
                <span className="text-inactive">Inactive</span>),
            width: '20%',
        },
        {
            Header: 'Action',
            id: 'actions', // The 'id' is necessary here since this column doesn't correspond to a data field
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDoc(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                    <div onClick={() => showDeleteModal(row.original)} className="col-4 padding-left-unset">
                        <Trash className="sidebar-icon margin-left"
                               color="var(--red)"/>
                    </div>
                </div>
            ),
            width: '10%',
        },
    ], []);

    const getAllSuperAdminDetails = useSelector(state => state.getAllSuperAdmin)
    const {error, loading, super_admin_list} = getAllSuperAdminDetails

    useEffect(() => {
        // setDocuments(getDocuments.document_list)
        if (!getAllSuperAdminDetails.super_admin_data <= 0) {
            console.log(getAllSuperAdminDetails.super_admin_data)
            setRecord(getAllSuperAdminDetails.super_admin_data.length)
            getAllSuperAdminDetails.super_admin_data.map((item, index) => {
                setDocuments((prevData) => [
                    ...prevData,
                    {
                        superAdminMappingId: item.superAdminMappingId,
                        adminName: item.adminName,
                        prismaUserId: item.prismaUserId,
                        isActive: item.isActive
                    }
                ])
            });
        }
    }, [getAllSuperAdminDetails.super_admin_data]);

    const deleteSuperAdminDetails = useSelector(state => state.deleteSuperAdmin)
    const {errorDelete, loadingDelete} = deleteSuperAdminDetails


    const changePage = (data) => {
        setDocuments([])
        setPageNumber(data)
    }

    useEffect(() => {
        if (deleteSuperAdminDetails.super_admin_data === true) {
            dispatch(resetDeleteSuperAdmin())
            setDocuments([])
            dataTableReload()
        }
        if (deleteSuperAdminDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Super admin user not deleted"
            })
        }
    }, [deleteSuperAdminDetails]);

    useEffect(() => {
        dataTableReload()
        dispatch(resetGetDocumentById())
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Super admin user created"
            })
            history('/super-admin-management', {state: {}})
        }
    }, [])


    const dataTableReload = () => {
        setDocuments([])
        dispatch(getAllSuperAdmin())
    }
    const editDoc = (data) => {
        history(`/super-admin-edit/${data.superAdminMappingId}`)
    }
    const showDeleteModal = (data) => {
        setShowDelete({
            display: true,
            data: data.superAdminMappingId,
            name: data.adminName
        })
    }
    const hideDeleteModal = (data) => {
        setShowDelete({
            display: false
        })
        if (data == 1) {
            dispatch(deleteSuperAdminById(showDelete.data))
        }
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddDocument())
        // window.location.href='/documents'
    }


    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingDelete && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {showDelete.display && <DeleteModal heading="Delete Document"
                                                type="delete"
                                                question="Are you sure want to delete this super admin user"
                                                answer=""
                                                result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="super_admin_list" title="Super Admin List"/>

                <Row className="app-body-super-admin background-1 m-3">
                    {/*<div className="pl-5 pb-2 background-1"*/}
                    {/*>*/}
                    {documents && <div className="background-2">
                        <DataTable defaultPageSize={5}
                                   pageSize={record} columns={columns} data={documents} recordData={record}
                                   result={changePage} empty_table={EmptyTable}
                        />
                    </div>}
                    <Footer/>
                </Row>
            </div>

        </Row>
    </>);
}

export default SuperAdminManagement;