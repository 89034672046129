import {
    ADD_EDIT_TRUSTED_ADVISOR_SUCCESS,
    ADD_EDIT_TRUSTED_ADVISOR_REQUEST,
    ADD_EDIT_TRUSTED_ADVISOR_FAIL,
    ADD_EDIT_TRUSTED_ADVISOR_RESET,

    GET_ALL_TRUSTED_ADVISORS_REQUEST,
    GET_ALL_TRUSTED_ADVISORS_SUCCESS,
    GET_ALL_TRUSTED_ADVISORS_FAIL,
    GET_ALL_TRUSTED_ADVISORS_RESET,

    GET_TRUSTED_ADVISOR_BY_ID_REQUEST,
    GET_TRUSTED_ADVISOR_BY_ID_SUCCESS,
    GET_TRUSTED_ADVISOR_BY_ID_FAIL,
    GET_TRUSTED_ADVISOR_BY_ID_RESET,

    DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST,
    DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS,
    DELETE_TRUSTED_ADVISOR_BY_ID_FAIL,
    DELETE_TRUSTED_ADVISOR_BY_ID_RESET,


} from "../constants/TrustedAdvisorConstants"
const initialState = {};
export const addTrustedAdvisorReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_EDIT_TRUSTED_ADVISOR_REQUEST:
            return { loading: true }

        case ADD_EDIT_TRUSTED_ADVISOR_SUCCESS:
            return {
                loading: false,
                ta_data: action.payload,
            }

        case ADD_EDIT_TRUSTED_ADVISOR_FAIL:
            return { loading: false, error: action.payload }

        case ADD_EDIT_TRUSTED_ADVISOR_RESET:
            return initialState;

        default:
            return state
    }
}
export const getTrustedAdvisorByIdReducer = (state = {ta_data_get: []}, action) => {
    switch (action.type) {
        case GET_TRUSTED_ADVISOR_BY_ID_REQUEST:
            return { loadingGetTrustedAdvisor: true }

        case GET_TRUSTED_ADVISOR_BY_ID_SUCCESS:
            return {
                loadingGetTrustedAdvisor: false,
                ta_data_get: action.payload,
            }

        case GET_TRUSTED_ADVISOR_BY_ID_FAIL:
            return { loadingGetTrustedAdvisor: false, errorGetTrustedAdvisor: action.payload }

        case GET_TRUSTED_ADVISOR_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllTrustedAdvisorReducer = (state = {taData: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_TRUSTED_ADVISORS_REQUEST:
            return { loadingTa: true }

        case GET_ALL_TRUSTED_ADVISORS_SUCCESS:
            return {
                loading: false,
                taData: action.payload,
            }

        case GET_ALL_TRUSTED_ADVISORS_FAIL:
            return { loadingTa: false, errorTa: action.payload }

        case GET_ALL_TRUSTED_ADVISORS_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteTrustedAdvisorByIdReducer = (state = {ta_delete_data: []}, action) => {
    switch (action.type) {
        case DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST:
            return { loadingDelete: true }

        case DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS:
            return {
                loadingDelete: false,
                ta_delete_data: action.payload,
            }

        case DELETE_TRUSTED_ADVISOR_BY_ID_FAIL:
            return { loadingDelete: false, errorDelete: action.payload }

        case DELETE_TRUSTED_ADVISOR_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}