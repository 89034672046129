import api from './api';
import {
    ADD_SUPER_ADMIN_SUCCESS,
    ADD_SUPER_ADMIN_REQUEST,
    ADD_SUPER_ADMIN_FAIL,
    ADD_SUPER_ADMIN_RESET,

    EDIT_SUPER_ADMIN_SUCCESS,
    EDIT_SUPER_ADMIN_REQUEST,
    EDIT_SUPER_ADMIN_FAIL,
    EDIT_SUPER_ADMIN_RESET,

    GET_SUPER_ADMIN_BY_ID_SUCCESS,
    GET_SUPER_ADMIN_BY_ID_REQUEST,
    GET_SUPER_ADMIN_BY_ID_FAIL,
    GET_SUPER_ADMIN_BY_ID_RESET,

    GET_ALL_SUPER_ADMINS_REQUEST,
    GET_ALL_SUPER_ADMINS_SUCCESS,
    GET_ALL_SUPER_ADMINS_FAIL,
    GET_ALL_SUPER_ADMINS_RESET,

    DELETE_SUPER_ADMIN_REQUEST,
    DELETE_SUPER_ADMIN_SUCCESS,
    DELETE_SUPER_ADMIN_FAIL,
    DELETE_SUPER_ADMIN_RESET

} from "../constants/SuperAdminConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addSuperAdmin = (adminName, prismaUserId) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_SUPER_ADMIN_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/InsertUpdateSuperAdminMapping`,
            {
                adminName: adminName,
                prismaUserName: prismaUserId,
                isActive: true
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_SUPER_ADMIN_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_SUPER_ADMIN_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_SUPER_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editSuperAdmin = (superAdminMappingId, adminName, prismaUserId, isActive) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_SUPER_ADMIN_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/InsertUpdateSuperAdminMapping`,
            {
                superAdminMappingId: superAdminMappingId,
                adminName: adminName,
                prismaUserName: prismaUserId,
                isActive: isActive
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_SUPER_ADMIN_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_SUPER_ADMIN_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_SUPER_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getSuperAdminById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_SUPER_ADMIN_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetSuperAdminMappingById?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_SUPER_ADMIN_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_SUPER_ADMIN_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_SUPER_ADMIN_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllSuperAdmin = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_SUPER_ADMINS_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetAllSuperAdminMapping`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_SUPER_ADMINS_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_SUPER_ADMINS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_SUPER_ADMINS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteSuperAdminById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_SUPER_ADMIN_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',

                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/SuperAdmin/DeleteSuperAdminMapping?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_SUPER_ADMIN_SUCCESS,
                payload: data.success
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_SUPER_ADMIN_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_SUPER_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// RESET

export const resetAddSuperAdmin = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_SUPER_ADMIN_RESET,
    })
};
export const resetEditSuperAdmin = () => async  (dispatch, getState) => {
    dispatch({
        type: EDIT_SUPER_ADMIN_RESET,
    })
};
export const resetGetSuperAdminById  = () => async   (dispatch, getState) => {
    dispatch({
        type: GET_SUPER_ADMIN_BY_ID_RESET,
    })
};
export const resetGetAllSuperAdmin = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_SUPER_ADMINS_RESET,
    })
};
export const resetDeleteSuperAdmin = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_SUPER_ADMIN_RESET,
    })
};