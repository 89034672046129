import React from 'react';
import {useDispatch} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import {GoArrowRight} from "react-icons/go";
import CardDetailOne from "./CardDetailOne";
import CardDetailTwo from "./CardDetailTwo";
import CardDetailThree from "./CardDetailThree";
import CardDetailFour from "./CardDetailFour";
import {useNavigate} from "react-router-dom";
import {getRedirectUrl} from "../actions/ConfigActions";

function Cards(props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const redirectUrl = (data) => {
        dispatch(getRedirectUrl(data))
    };
    const landingPage = () => {
        if (props.type === 4) {
            redirectUrl("HRPY.WORK*PAYROLL.HISTORY")
        } else {
            history(`/landing-page/${props.type}`)
        }
    }
    return (
        <>
            <Card className={props.classname}>
                <Row className="background-1 center">
                    <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                        <div className="circle-div" align="center">
                            <img src={props.image} alt={props.image} className="circle-div-image"/>
                        </div>
                    </Col>
                    <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                        <div className="card-dashboard-header">
                            {props.header}
                        </div>
                    </Col>
                    <Col lg={{span: 4, offset: 0}} md={{span: 4, offset: 0}} align="right" onClick={landingPage}>
                        <span className="view-all-text button-cursor">View All</span> <GoArrowRight className="view-all-icon button-cursor"/>
                    </Col>
                    <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                        <Row className="background-1">
                            {props.header === "Upcoming School Events" ? <CardDetailOne data={props.data}/>
                                : props.header === "Assigned Tasks" ? <CardDetailTwo data={props.data}/>
                                    : props.header === "Birthdays and Anniversaries" ? <CardDetailThree data={props.data}/>
                                        : <CardDetailFour data={props.data}/>
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default Cards;