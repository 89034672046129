import api from './api';
import {
    ADD_SCHOOL_YEAR_SUCCESS,
    ADD_SCHOOL_YEAR_REQUEST,
    ADD_SCHOOL_YEAR_FAIL,
    ADD_SCHOOL_YEAR_RESET,

    EDIT_SCHOOL_YEAR_SUCCESS,
    EDIT_SCHOOL_YEAR_REQUEST,
    EDIT_SCHOOL_YEAR_FAIL,
    EDIT_SCHOOL_YEAR_RESET,

    DELETE_SCHOOL_YEAR_SUCCESS,
    DELETE_SCHOOL_YEAR_REQUEST,
    DELETE_SCHOOL_YEAR_FAIL,
    DELETE_SCHOOL_YEAR_RESET,

    GET_SCHOOL_YEAR_BY_ID_SUCCESS,
    GET_SCHOOL_YEAR_BY_ID_REQUEST,
    GET_SCHOOL_YEAR_BY_ID_FAIL,
    GET_SCHOOL_YEAR_BY_ID_RESET,

    GET_ALL_SCHOOL_YEARS_REQUEST,
    GET_ALL_SCHOOL_YEARS_SUCCESS,
    GET_ALL_SCHOOL_YEARS_FAIL,
    GET_ALL_SCHOOL_YEARS_RESET,

    GET_ALL_SCHOOL_YEAR_EXIST_REQUEST,
    GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS,
    GET_ALL_SCHOOL_YEAR_EXIST_FAIL,
    GET_ALL_SCHOOL_YEAR_EXIST_RESET,

    UPLOAD_CALENDAR_EVENTS_REQUEST,
    UPLOAD_CALENDAR_EVENTS_SUCCESS,
    UPLOAD_CALENDAR_EVENTS_FAIL,
    UPLOAD_CALENDAR_EVENTS_RESET
} from "../constants/CalendarConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addSchoolYear = (dataYear, dataFromDate, dataToDate, link) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_SCHOOL_YEAR_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Calender/AddUpdateSchoolYear`,
            {
                title: dataYear,
                FromDate: dataFromDate,
                ToDate: dataToDate,
                LinkUrl: link
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_SCHOOL_YEAR_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_SCHOOL_YEAR_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_SCHOOL_YEAR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const uploadEvent = (formData) => async (dispatch, getState) => {
    try {
        dispatch({type: UPLOAD_CALENDAR_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Calender/ImportEventByExcel`,
            formData, config
        )
        if (data.success === true) {
            dispatch({
                type: UPLOAD_CALENDAR_EVENTS_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: UPLOAD_CALENDAR_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: UPLOAD_CALENDAR_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editSchoolYear = (yearId, title, dataFromDate, dataToDate, link) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_SCHOOL_YEAR_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Calender/AddUpdateSchoolYear`,
            {
                schoolYearId: yearId,
                title: title,
                FromDate: dataFromDate,
                ToDate: dataToDate,
                LinkUrl: link
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_SCHOOL_YEAR_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_SCHOOL_YEAR_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_SCHOOL_YEAR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteSchoolYear = (yearId) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_SCHOOL_YEAR_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/Calender/DeleteSchoolYearById?id=${yearId}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_SCHOOL_YEAR_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: DELETE_SCHOOL_YEAR_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_SCHOOL_YEAR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getSchoolYearById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_SCHOOL_YEAR_BY_ID_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetSchoolYearById?SchoolYearId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_SCHOOL_YEAR_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_SCHOOL_YEAR_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_SCHOOL_YEAR_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllSchoolYear = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_SCHOOL_YEARS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetAllSchoolYear`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_SCHOOL_YEARS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_SCHOOL_YEARS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_SCHOOL_YEAR_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllSchoolYearExist = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_SCHOOL_YEAR_EXIST_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/CheckIsAnySchoolYear`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_SCHOOL_YEAR_EXIST_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_SCHOOL_YEAR_EXIST_FAIL,
                payload: data.data,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_SCHOOL_YEAR_EXIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetAddSchoolYear = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_SCHOOL_YEAR_RESET,
    })
};
export const resetEditSchoolYear = () => async  (dispatch, getState) => {
    dispatch({
        type: EDIT_SCHOOL_YEAR_RESET,
    })
};
export const resetGetSchoolYearById = (dispatch, getState) => {
    dispatch({
        type: GET_SCHOOL_YEAR_BY_ID_RESET,
    })
};
export const resetGetAllSchoolYear = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_SCHOOL_YEARS_RESET,
    })
};
export const resetGetAllSchoolYearExist = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_SCHOOL_YEAR_EXIST_RESET,
    })
};
export const resetUploadCalendarEvents = () => (dispatch, getState) => {
    dispatch({
        type: UPLOAD_CALENDAR_EVENTS_RESET,
    })
};
export const resetDeleteSchoolYear = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_SCHOOL_YEAR_RESET,
    })
};