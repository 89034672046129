import React, {useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Button, Form} from "react-bootstrap";
import Logo from "../assets/images/logo.svg"
import Main from "../assets/images/main.png"
import Client from "../assets/images/school_svgrepo.com_1.svg"
import client_data from "../assets/json/client.json"
import {useNavigate} from "react-router-dom";
import {getUserAccessLevel, logout} from "../actions/LoginActions";
import {resetGetRedirectUrl} from "../actions/ConfigActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import LoginImage from "../components/LoginImage";

function SelectClient() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [client, setClient] = useState();
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })

    const getUserAccessLevelDetails = useSelector(state => state.getUserAccessLevel)
    const {error, loading, userInfo} = getUserAccessLevelDetails

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            dispatch(logout())
        }
        if (localStorage.getItem('mfa_verify') === "false") {
            history('/verification-code')
        }
    }, []);

    useEffect(() => {

        if (getUserAccessLevelDetails.userInfo === true) {
            if (getUserAccessLevelDetails.result.token !== null) {
                localStorage.setItem('access_token', JSON.stringify(getUserAccessLevelDetails.result.token))
                localStorage.setItem('IsTrainingEnable', getUserAccessLevelDetails.result.isTrainingEnable)
                window.location.href = "/"
            }
            if (getUserAccessLevelDetails.result.token === null) {
                setAlertModal({
                    display: true,
                    type: "error",
                    message: "Client could not be selected at the moment"
                })
            }
        }
        if (getUserAccessLevelDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Client could not be selected at the moment"
            })
        }
    }, [getUserAccessLevelDetails.userInfo]);

    const changeStyle = (data) => {
        setClient(data);
    };
    const showContacts = (data) => {
        console.log(data)
        if (data !== null)
            return data.map((item, index) => {
                try {
                    return (
                        <div className="col-lg-4 col-md-6 client-div">
                            <Form.Check
                                reverse
                                name="client"
                                label=<img src={Client} alt="client" className="centered-image"/>
                            type="radio"
                            id={item.id}
                            onClick={() => changeStyle([item.id, item.name])}
                            />
                            <p className="message-5">{item.name}</p>
                        </div>
                    )
                } catch (e) {
                    alert(e.message);
                }
            });
    }
    const submitHandler = (event) => {
        event.preventDefault();
        window.scrollTo(0, 0)
        localStorage.setItem('client_id', client[0])
        localStorage.setItem('client_name', client[1])
        dispatch(getUserAccessLevel(client[0]))
        if (localStorage.getItem("timestamp_type") == 1) {
            localStorage.removeItem("timestamp")
            localStorage.removeItem("timestamp_type")
        }
    };
    const closeAlert = () => {
        setAlertModal({
            display: false
        })
    }

    return (
        <Row className="login-background">
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {loading && <Loader/>}
            <Col lg={{span: 4, offset: 1}} md={{span: 5, offset: 1}}>
                <Card className="card-body">
                    <img src={Logo} alt="logo" className="logo"/>
                    <form className="margin-top">
                        <p className="header">Select Client</p>
                        <p className="sub-header">Please select which Client you are accessing</p>
                        <div className="row background-1">
                            {/*{showContacts(client_data)}*/}
                            {showContacts(JSON.parse(localStorage.getItem('clients')))}
                        </div>
                        <div className="login-button">
                            {(!client) &&
                                (<button disabled={true} className="button-solid button-inactive">
                                    Submit
                                </button>)}
                            {(client) &&
                                (<button onClick={submitHandler} disabled={false} type="submit"
                                         className="button-solid button-active">
                                    Submit
                                </button>)}
                        </div>
                    </form>
                    <div className="copyright">
                        <p className="sub-footer">v5.2</p>
                        <p className="sub-footer">copyright text</p>
                    </div>
                </Card>
            </Col>
            <Col lg={{span: 7, offset: 0}} md={{span: 5, offset: 0}}>
                <LoginImage/>
            </Col>
        </Row>
    );
}

export default SelectClient;