export const ADD_EVENTS_REQUEST = 'ADD_EVENTS_REQUEST'
export const ADD_EVENTS_SUCCESS = 'ADD_EVENTS_SUCCESS'
export const ADD_EVENTS_FAIL = 'ADD_EVENTS_FAIL'
export const ADD_EVENTS_RESET = 'ADD_EVENTS_RESET'
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL'
export const GET_EVENTS_RESET = 'GET_EVENTS_RESET'
export const EDIT_EVENTS_REQUEST = 'EDIT_EVENTS_REQUEST'
export const EDIT_EVENTS_SUCCESS = 'EDIT_EVENTS_SUCCESS'
export const EDIT_EVENTS_FAIL = 'EDIT_EVENTS_FAIL'
export const EDIT_EVENTS_RESET = 'EDIT_EVENTS_RESET'
export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST'
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS'
export const GET_ALL_EVENTS_FAIL = 'GET_ALL_EVENTS_FAIL'
export const GET_ALL_EVENTS_RESET = 'GET_ALL_EVENTS_RESET'
export const GET_EVENTS_BY_DATE_REQUEST = 'GET_EVENTS_BY_DATE_REQUEST'
export const GET_EVENTS_BY_DATE_SUCCESS = 'GET_EVENTS_BY_DATE_SUCCESS'
export const GET_EVENTS_BY_DATE_FAIL = 'GET_EVENTS_BY_DATE_FAIL'
export const GET_EVENTS_BY_DATE_RESET = 'GET_EVENTS_BY_DATE_RESET'
export const DELETE_EVENTS_REQUEST = 'DELETE_EVENTS_REQUEST'
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS'
export const DELETE_EVENTS_FAIL = 'DELETE_EVENTS_FAIL'
export const DELETE_EVENTS_RESET = 'DELETE_EVENTS_RESET'
export const GET_UPCOMING_SCHOOL_EVENTS_REQUEST = 'GET_UPCOMING_SCHOOL_EVENTS_REQUEST'
export const GET_UPCOMING_SCHOOL_EVENTS_SUCCESS = 'GET_UPCOMING_SCHOOL_EVENTS_SUCCESS'
export const GET_UPCOMING_SCHOOL_EVENTS_FAIL = 'GET_UPCOMING_SCHOOL_EVENTS_FAIL'
export const GET_UPCOMING_SCHOOL_EVENTS_RESET = 'GET_UPCOMING_SCHOOL_EVENTS_RESET'