import {
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_REQUEST,
    ADD_DOCUMENT_FAIL,
    ADD_DOCUMENT_RESET,

    EDIT_DOCUMENT_SUCCESS,
    EDIT_DOCUMENT_REQUEST,
    EDIT_DOCUMENT_FAIL,
    EDIT_DOCUMENT_RESET,

    GET_DOCUMENT_BY_ID_SUCCESS,
    GET_DOCUMENT_BY_ID_REQUEST,
    GET_DOCUMENT_BY_ID_FAIL,
    GET_DOCUMENT_BY_ID_RESET,

    GET_ALL_DOCUMENTS_SUCCESS,
    GET_ALL_DOCUMENTS_REQUEST,
    GET_ALL_DOCUMENTS_FAIL,
    GET_ALL_DOCUMENTS_RESET,

    DELETE_DOCUMENTS_REQUEST,
    DELETE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS_FAIL,
    DELETE_DOCUMENTS_RESET,
} from "../constants/DocumentConstants"

const initialState = {};
export const addDocumentReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_DOCUMENT_REQUEST:
            return { loading: true }

        case ADD_DOCUMENT_SUCCESS:
            return {
                loading: false,
                document: action.payload,
            }

        case ADD_DOCUMENT_FAIL:
            return { loading: false, error: action.payload }

        case ADD_DOCUMENT_RESET:
            return initialState;

        default:
            return state
    }
}
export const editDocumentReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_DOCUMENT_REQUEST:
            return { loadingEdit: true }

        case EDIT_DOCUMENT_SUCCESS:
            return {
                loadingEdit: false,
                document: action.payload,
            }

        case EDIT_DOCUMENT_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_DOCUMENT_RESET:
            return initialState;

        default:
            return state
    }
}
export const getDocumentByIdReducer = (state = {document_data: []}, action) => {
    switch (action.type) {
        case GET_DOCUMENT_BY_ID_REQUEST:
            return { loading: true }

        case GET_DOCUMENT_BY_ID_SUCCESS:
            return {
                loading: false,
                document_data: action.payload,
            }

        case GET_DOCUMENT_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_DOCUMENT_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteDocumentReducer = (state = {document_delete: []}, action) => {
    switch (action.type) {
        case DELETE_DOCUMENTS_REQUEST:
            return { loadingDelete: true }

        case DELETE_DOCUMENTS_SUCCESS:
            return {
                loadingDelete: false,
                document_delete: action.payload,
            }

        case DELETE_DOCUMENTS_FAIL:
            return { loadingDelete: false, error: action.payload }

        case DELETE_DOCUMENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllDocumentReducer = (state = {document_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_DOCUMENTS_REQUEST:
            return { loading: true }

        case GET_ALL_DOCUMENTS_SUCCESS:
            return {
                loading: false,
                document_list: action.payload,
                records: action.records
            }

        case GET_ALL_DOCUMENTS_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_DOCUMENTS_RESET:
            return initialState;

        default:
            return state
    }
}