import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import DropdownItemsInput from "../components/DropdownItemsInput";
import DropdownMultiselect from "../components/DropdownMultiselect";
import DynamicProcessCreate from "../components/DynamicProcessCreate";
import Footer from "../components/Footer";
import AddCircle from "../components/Icons/AddCircle";
import DeleteModal from "../components/DeleteModal";
import {
    addProcess,
    getAllProcessTemplate,
    getTasksProcessById, resetAddProcess,
    resetGetTasksProcessById
} from "../actions/ProcessActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {getTeacherAssociates} from "../actions/ConfigActions";

function SendProcess(props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [processTemplates, setProcessTemplates] = React.useState([]);
    const [teacher, setTeacher] = useState([])
    const [userEmail, setUserEmail] = useState([])
    const [tasks, setTasks] = useState([]);
    const [taskNumber, setTaskNumber] = useState([]);
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    });

    const [infoModal, setInfoModal] = useState(false)
    const [taskId, setTaskId] = useState();
    const [count, setCount] = useState(0);
    const [alert, showAlert] = useState({
        show: false,
        data: '',
    });
    const [startTask, showStartTask] = useState(false);
    const [start, showStart] = useState(true);

    const pageName = 'processes';

    const [fieldIsValid, setFieldIsValid] = useState({
        isProcessTypeValid: false,
        isTeacherValid: false,
        isTaskValid: true,
    });
    const [fieldData, setFieldData] = useState({
        processTemplateIdData: "",
        titleData: "",
        assignToIdData: "",
        assignToIdsData: [],
        processTasksData: "",
    });

    useEffect(() => {
        setTasks([])
        dispatch(getTeacherAssociates())
        dispatch(resetAddProcess())
        dispatch(resetGetTasksProcessById())
        dispatch(getAllProcessTemplate(1000, 1))
    }, []);

    const getTasks = (taskId) => {
        dispatch(getTasksProcessById(taskId))
        setTaskId(null)
    }

    const addProcessDetails = useSelector(state => state.addProcess)
    const {errorAdd, loadingAdd, processesAdd} = addProcessDetails

    useEffect(() => {
        if (addProcessDetails.processesAdd === true) {
            dispatch(resetAddProcess())
            history('/processes', {state: {success_process: "1"}})
        }
        if (addProcessDetails.error) {
            setAlertModal({
                display: true,
                type: error,
                message: "Certificate couldn't be added"
            })
        }
    }, [addProcessDetails]);

    const getProcessTemplate = useSelector(state => state.getAllProcessTemplate)
    const {error, loading, processTemplateInfo} = getProcessTemplate

    useEffect(() => {
        setProcessTemplates([])
        // setDocuments(getDocuments.document_list)
        if (!getProcessTemplate.process_template_list <= 0) {

            getProcessTemplate.process_template_list.map((item, index) => {
                setProcessTemplates((prevData) => [
                    ...prevData,
                    {
                        id: item.processTemplateId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getProcessTemplate.process_template_list]);

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        setTeacher([])
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                        employee_Personal_Email: item.employee_Personal_Email,
                        employee_Work_Email: item.employee_Work_Email,
                        preferred_email: item.preferred_email,
                        employee_Department_Code: item.employee_Department_Code,
                        dept_Desc: item.dept_Desc,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const getProcessTask = useSelector(state => state.getTaskProcessById)
    const {errorTask, loadingTask, processTaskInfo} = getProcessTask

    useEffect(() => {
        setTasks([])
        if (!getProcessTask.process_task_data <= 0) {
            getProcessTask.process_task_data.map((item, index) => {
                setTasks((prevData) => [
                    ...prevData,
                    {
                        id: index + 1,
                        processTemplateTasksId: item.processTemplateTasksId,
                        processTemplateId: item.processTemplateId,
                        title: item.title,
                        taskName: item.title,
                        assignToId: item.assignToId,
                        assignToName: item.assignToName,
                        notes: item.notes,
                        editable: false,
                    }
                ])
            });
        }
    }, [getProcessTask.process_task_data]);
    const closeModal = (data) => {
        if (data == 0) {
            showStartTask(false)
        }
        if (data == 1) {
            const assignIdArray = fieldData.assignToIdsData.map(item => item.id);
            dispatch(addProcess(fieldData.processTemplateIdData, fieldData.titleData, assignIdArray[0], assignIdArray, tasks))
            showStartTask(false)
        }
        // history('/processes')
    }
    const checkUserEmail = (id) => {
        const employee = teacher.find(emp => emp.id === id);
        if (!employee) {
            setUserEmail(prevState => [
                ...prevState,
                {
                    id: id,
                    email: false,
                    name: ""
                }
            ])
        } else if (employee.employee_Personal_Email === "" && employee.employee_Work_Email === "") {
            console.log(employee.id)
            setUserEmail(prevState => [
                ...prevState,
                {
                    id: id,
                    email: false,
                    name: employee.data
                }])
        }
    }

    const openLink = () => {
        history('/processes')
    }
    const updateLink = () => {
        showStart(true)
    }
    const processData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isProcessTypeValid: true,
        });
        setFieldData({
            ...fieldData,
            processTemplateIdData: data.id,
            titleData: data.data
        })
        setTasks([])
        setTaskNumber(data.id)
        getTasks(data.id)
    }
    const processTeacher = (data) => {
        setUserEmail([])
        if (data.length <= 0) {
            setFieldIsValid({
                ...fieldIsValid,
                isTeacherValid: false,
            });
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTeacherValid: true,
            });
            setFieldData({
                ...fieldData,
                assignToIdsData: data
            })
            // checkUserEmail()
            data.map((item) => {
                console.log("Employee: ", item.id)
                checkUserEmail(item.id)
            });
        }
    }

    useEffect(() => {
        console.log(tasks)
        if (tasks.some(item => item.task === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        }
        if (tasks.some(item => item.assign === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        }
        if (tasks.some(item => item.note === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: true,
            });
        }
    }, [tasks]);

    const addTask = () => {
        const newTask = {
            id: tasks[tasks.length - 1].id + 1, // Simple unique ID for demo purposes
            processTemplateId: "",
            title: "",
            assignToId: "",
            notes: "",
            editable: true,
        };
        setTasks([...tasks, newTask]);
        showStart(false)
    };
    const deleteTask = (data) => {
        if (data[0] === "0") {
            console.log("Delete: ", data[1])
            showAlert({
                show: true,
                data: data[1],
            })
        }
        if (data[0] === "1") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        title: data[2],
                        taskName: data[2],
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
            showStart(false)
        }
        if (data[0] === "2") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        assignToId: data[2].id,
                        assignToName: data[2].data,
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
            showStart(false)
        }
        if (data[0] === "3") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {...task, notes: data[2]};
                }
                return task;
            });
            setTasks(updatedTasks);
            showStart(false)
        }
        if (data[0] === "4") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {...task, editable: data[2]};
                }
                return task;
            });
            setTasks(updatedTasks);
            showStart(false)
        }
        if (data[0] === "5") {
            setTasks([])
            dispatch(getTasksProcessById(taskNumber))
            showStart(true)
        }
    };
    const onReset = (e) => {
        e.preventDefault()
        resetData()
        // history('/send-process')
    }

    const resetData = () => {
        setFieldData({
            ...fieldData,
            processTemplateIdData: null,
            assignToIdsData: null
        })
        setFieldIsValid({
            ...fieldIsValid,
            isProcessTypeValid: false,
            isTeacherValid: false
        })
        setTasks([])
        setCount(1)
        showStart(true)
    }

    const onStartTask = (e) => {
        e.preventDefault()
        if (userEmail.length > 0) {
            setInfoModal(true)
        } else {
            setInfoModal(false)
            const assignIdArray = fieldData.assignToIdsData.map(item => item.id);
            dispatch(addProcess(fieldData.processTemplateIdData, fieldData.titleData, assignIdArray[0], assignIdArray, tasks))
            showStartTask(false)
        }
    }
    const infoClose = (data) => {
        if (data == 0) {
            setInfoModal(false)
            showStartTask(false)
        }
        if (data == 1) {
            setInfoModal(false)
            const assignIdArray = fieldData.assignToIdsData.map(item => item.id);
            dispatch(addProcess(fieldData.processTemplateIdData, fieldData.titleData, assignIdArray[0], assignIdArray, tasks))
            showStartTask(false)
        }
    }
    const importResult = (data, value) => {
        showAlert({
            show: false,
            data: data,
        })
        if (data === "1") {
            setTasks(tasks.filter(task => task.id !== value));
        }
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }

    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {loadingTask && <Loader/>}
                {loadingAdd && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type}
                                result={closeAlert}/>}
                {infoModal && <DeleteModal heading="Missing an email address"
                                           type="import"
                                           button_true="Continue"
                                           button_false="Cancel"
                                           add_on="true"
                                           user_list={userEmail}
                                           question="These selected users are missing an email address and will not receive the process. Do you wish to continue?"
                                           answer=""
                                           result={infoClose}
                />}
                {alert.show && <DeleteModal heading="Delete Task"
                                            type="delete" value={alert.data}
                                            question="Are you sure want to delete this Task ?"
                                            answer=""
                                            result={importResult}
                />}
                {startTask && <DeleteModal heading="Start Process"
                                           type="import"
                                           button_true="Start"
                                           button_false="Cancel"
                                           question="Are you sure want to start this Process ?"
                                           answer=""
                                           result={closeModal}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Start Process" result={openLink}/>
                    <Row className="app-body-send-process background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Process Name<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={processTemplates} result={processData}
                                                                        value={fieldData.processTemplateIdData}
                                                                        className={!fieldIsValid.isProcessTypeValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Process Name"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Teacher/Associate<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownMultiselect data={teacher} result={processTeacher}
                                                                         value={fieldData.assignToIdsData}
                                                                         valid={!fieldIsValid.isTeacherValid ? false : true}
                                                                         className={"background-1 dropdown-input-content-valid"}
                                                                         title="Select Teacher/Associates"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="">
                                        {tasks.length > 0 && tasks.map((task, index) => (
                                            <DynamicProcessCreate key={task.processTemplateTasksId} task={task.id}
                                                                  processTemplateId={task.processTemplateId}
                                                                  task_name={task.title}
                                                                  task_name_value={task.taskName}
                                                                  assign={task.assignToId}
                                                                  assign_value={task.assignToName}
                                                                  note={task.notes}
                                                                  count={tasks.length}
                                                                  editable={task.editable}
                                                                  result={deleteTask}/>
                                        ))}
                                    </div>
                                    <Row className="background-1 mb-4 mt-4">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset" onClick={addTask}>
                                            <AddCircle className="sidebar-icon mb-1"
                                                       color="var(--red)"/> <span
                                            className="description-data button-cursor">Add New Task</span>
                                        </Col>
                                    </Row>
                                    {(
                                            !fieldIsValid.isProcessTypeValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isTaskValid
                                            || tasks.length <= 0
                                        )
                                        && start && <Row className="background-1 submit-buttons-proposal mt-4">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Start
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isProcessTypeValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isTaskValid
                                            && tasks.length > 0
                                        )
                                        && start && <Row className="background-1 submit-buttons-proposal mt-4">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active" onClick={onStartTask}>
                                                    Start
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            !fieldIsValid.isProcessTypeValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isTaskValid
                                        )
                                        && !start
                                        &&
                                        <Row className="background-1 submit-buttons-proposal mt-4">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isProcessTypeValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isTaskValid
                                            && !start
                                        )
                                        &&
                                        <Row className="background-1 submit-buttons-proposal mt-4">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={updateLink}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                            </Card>
                        </Row>
                        <Footer/>
                    </Row>
                </div>
            </Row>
        </>
    );
}

export default SendProcess;