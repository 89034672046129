import React from 'react';
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom"
import { jwtDecode } from "jwt-decode";
import {logout} from "../actions/LoginActions";
var token
var decoded
// var expiry/
function PrivateRoute() {
    const userLogin = useSelector(state => state.getUserAccessLevel)
    const {userInfo} = userLogin
    if (userInfo) {
        decoded = jwtDecode(localStorage.getItem("access_token"));
        token = userInfo;
    }
    return userInfo
        ? <Outlet/>
        : <Navigate to='/sign-in'/>
}

export {PrivateRoute, token, decoded};