import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,

    CREATE_VERIFICATION_REQUEST,
    CREATE_VERIFICATION_SUCCESS,
    CREATE_VERIFICATION_FAIL,
    CREATE_VERIFICATION_RESET,

    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAIL,
    VERIFY_OTP_RESET,

    GET_USER_ACCESS_LEVEL_REQUEST,
    GET_USER_ACCESS_LEVEL_SUCCESS,
    GET_USER_ACCESS_LEVEL_FAIL,
    GET_USER_ACCESS_LEVEL_RESET,

    GET_USER_MFA_DETAILS_REQUEST,
    GET_USER_MFA_DETAILS_SUCCESS,
    GET_USER_MFA_DETAILS_FAIL,
    GET_USER_MFA_DETAILS_RESET,

    LOGIN_RESET
} from "../constants/LoginConstants"

const initialState = {};
export const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {loading: true}

        case LOGIN_SUCCESS:
            return {loading: false, userInfo: action.payload}

        case LOGIN_FAIL:
            return {loading: false, error: action.payload}

        case LOGIN_RESET:
            return initialState;

        case LOGOUT:
            return {}

        default:
            return state
    }
}
export const createVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_VERIFICATION_REQUEST:
            return {loading: true}

        case CREATE_VERIFICATION_SUCCESS:
            return {loading: false, verificationInfo: action.payload}

        case CREATE_VERIFICATION_FAIL:
            return {loading: false, error: action.payload}

        case CREATE_VERIFICATION_RESET:
            return initialState;

        case LOGOUT:
            return {}

        default:
            return state
    }
}
export const verifyOtpReducer = (state = {}, action) => {
    switch (action.type) {
        case VERIFY_OTP_REQUEST:
            return {loadingOtp: true}

        case VERIFY_OTP_SUCCESS:
            return {loadingOtp: false, verificationOtp: action.payload}

        case VERIFY_OTP_FAIL:
            return {loadingOtp: false, errorOtp: action.payload}

        case VERIFY_OTP_RESET:
            return initialState;

        case LOGOUT:
            return {}

        default:
            return state
    }
}
export const getUserAccessLevelReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_ACCESS_LEVEL_REQUEST:
            return {loading: true}

        case GET_USER_ACCESS_LEVEL_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload,
                result: action.result
            }

        case GET_USER_ACCESS_LEVEL_FAIL:
            return {loading: false, error: action.payload}

        case GET_USER_ACCESS_LEVEL_RESET:
            return initialState;

        case LOGOUT:
            return {}

        default:
            return state
    }
}
export const getUserMfaDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_MFA_DETAILS_REQUEST:
            return {loadingMfa: true}

        case GET_USER_MFA_DETAILS_SUCCESS:
            return {
                loadingMfa: false,
                mfaInfo: action.payload,
            }

        case GET_USER_MFA_DETAILS_FAIL:
            return {loadingMfa: false, errorMfa: action.payload}

        case GET_USER_MFA_DETAILS_RESET:
            return initialState;

        default:
            return state
    }
}