import React, {useEffect, useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import "../assets/css/dropdown.css"

function DropdownItems(props) {
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = props.data

    let selectedOptionObject = cities.find(cities => cities.id === selectedCity);

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            setSelectedCity(null)
        }
    }, [props.value]);
    const setDropdownResult = (e) => {
        setSelectedCity(e.value)
        if (props.eventId) {
            props.result([e.value,props.eventId])
        } else {
            props.result(e.value)
        }
    }

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedOptionObject || selectedCity}  onChange={setDropdownResult} options={cities} optionLabel="data"
                      editable placeholder={props.title} className="w-full md:w-14rem" />
        </div>
    );
}

export default DropdownItems;