export const ADD_PROCESS_TEMPLATE_REQUEST = 'ADD_PROCESS_TEMPLATE_REQUEST'
export const ADD_PROCESS_TEMPLATE_SUCCESS = 'ADD_PROCESS_TEMPLATE_SUCCESS'
export const ADD_PROCESS_TEMPLATE_FAIL = 'ADD_PROCESS_TEMPLATE_FAIL'
export const ADD_PROCESS_TEMPLATE_RESET = 'ADD_PROCESS_TEMPLATE_RESET'
export const GET_PROCESS_TEMPLATE_BY_ID_REQUEST = 'GET_PROCESS_TEMPLATE_BY_ID_REQUEST'
export const GET_PROCESS_TEMPLATE_BY_ID_SUCCESS = 'GET_PROCESS_TEMPLATE_BY_ID_SUCCESS'
export const GET_PROCESS_TEMPLATE_BY_ID_FAIL = 'GET_PROCESS_TEMPLATE_BY_ID_FAIL'
export const GET_PROCESS_TEMPLATE_BY_ID_RESET = 'GET_PROCESS_TEMPLATE_BY_ID_RESET'
export const EDIT_PROCESS_TEMPLATE_REQUEST = 'EDIT_PROCESS_TEMPLATE_REQUEST'
export const EDIT_PROCESS_TEMPLATE_SUCCESS = 'EDIT_PROCESS_TEMPLATE_SUCCESS'
export const EDIT_PROCESS_TEMPLATE_FAIL = 'EDIT_PROCESS_TEMPLATE_FAIL'
export const EDIT_PROCESS_TEMPLATE_RESET = 'EDIT_PROCESS_TEMPLATE_RESET'
export const GET_ALL_PROCESS_TEMPLATES_REQUEST = 'GET_ALL_PROCESS_TEMPLATES_REQUEST'
export const GET_ALL_PROCESS_TEMPLATES_SUCCESS = 'GET_ALL_PROCESS_TEMPLATES_SUCCESS'
export const GET_ALL_PROCESS_TEMPLATES_FAIL = 'GET_ALL_PROCESS_TEMPLATES_FAIL'
export const GET_ALL_PROCESS_TEMPLATES_RESET = 'GET_ALL_PROCESS_TEMPLATES_RESET'

export const ADD_PROCESS_REQUEST = 'ADD_PROCESS_REQUEST'
export const ADD_PROCESS_SUCCESS = 'ADD_PROCESS_SUCCESS'
export const ADD_PROCESS_FAIL = 'ADD_PROCESS_FAIL'
export const ADD_PROCESS_RESET = 'ADD_PROCESS_RESET'
export const GET_PROCESS_BY_ID_REQUEST = 'GET_PROCESS_BY_ID_REQUEST'
export const GET_PROCESS_BY_ID_SUCCESS = 'GET_PROCESS_BY_ID_SUCCESS'
export const GET_PROCESS_BY_ID_FAIL = 'GET_PROCESS_BY_ID_FAIL'
export const GET_PROCESS_BY_ID_RESET = 'GET_PROCESS_BY_ID_RESET'
export const EDIT_PROCESS_TASK_REQUEST = 'EDIT_PROCESS_TASK_REQUEST'
export const EDIT_PROCESS_TASK_SUCCESS = 'EDIT_PROCESS_TASK_SUCCESS'
export const EDIT_PROCESS_TASK_FAIL = 'EDIT_PROCESS_TASK_FAIL'
export const EDIT_PROCESS_TASK_RESET = 'EDIT_PROCESS_TASK_RESET'
export const GET_ALL_PROCESS_REQUEST = 'GET_ALL_PROCESS_REQUEST'
export const GET_ALL_PROCESS_SUCCESS = 'GET_ALL_PROCESS_SUCCESS'
export const GET_ALL_PROCESS_FAIL = 'GET_ALL_PROCESS_FAIL'
export const GET_ALL_PROCESS_RESET = 'GET_ALL_PROCESS_RESET'
export const GET_TASK_BY_PROCESS_ID_REQUEST = 'GET_TASK_BY_PROCESS_ID_REQUEST'
export const GET_TASK_BY_PROCESS_ID_SUCCESS = 'GET_TASK_BY_PROCESS_ID_SUCCESS'
export const GET_TASK_BY_PROCESS_ID_FAIL = 'GET_TASK_BY_PROCESS_ID_FAIL'
export const GET_TASK_BY_PROCESS_ID_RESET = 'GET_TASK_BY_PROCESS_ID_RESET'
export const EDIT_TASK_BY_ID_REQUEST = 'EDIT_TASK_BY_ID_REQUEST'
export const EDIT_TASK_BY_ID_SUCCESS = 'EDIT_TASK_BY_ID_SUCCESS'
export const EDIT_TASK_BY_ID_FAIL = 'EDIT_TASK_BY_ID_FAIL'
export const EDIT_TASK_BY_ID_RESET = 'EDIT_TASK_BY_ID_RESET'
export const COMPLETE_ALL_TASK_REQUEST = 'COMPLETE_ALL_TASK_REQUEST'
export const COMPLETE_ALL_TASK_SUCCESS = 'COMPLETE_ALL_TASK_SUCCESS'
export const COMPLETE_ALL_TASK_FAIL = 'COMPLETE_ALL_TASK_FAIL'
export const COMPLETE_ALL_TASK_RESET = 'COMPLETE_ALL_TASK_RESET'
export const CANCEL_ALL_TASK_REQUEST = 'CANCEL_ALL_TASK_REQUEST'
export const CANCEL_ALL_TASK_SUCCESS = 'CANCEL_ALL_TASK_SUCCESS'
export const CANCEL_ALL_TASK_FAIL = 'CANCEL_ALL_TASK_FAIL'
export const CANCEL_ALL_TASK_RESET = 'CANCEL_ALL_TASK_RESET'
export const GET_UPCOMING_TASK_REQUEST = 'GET_UPCOMING_TASK_REQUEST'
export const GET_UPCOMING_TASK_SUCCESS = 'GET_UPCOMING_TASK_SUCCESS'
export const GET_UPCOMING_TASK_FAIL = 'GET_UPCOMING_TASK_FAIL'
export const GET_UPCOMING_TASK_RESET = 'GET_UPCOMING_TASK_RESET'
export const SEND_NUDGE_REQUEST = 'SEND_NUDGE_REQUEST'
export const SEND_NUDGE_SUCCESS = 'SEND_NUDGE_SUCCESS'
export const SEND_NUDGE_FAIL = 'SEND_NUDGE_FAIL'
export const SEND_NUDGE_RESET = 'SEND_NUDGE_RESET'
export const MARK_TASK_AS_COMPLETE_REQUEST = 'MARK_TASK_AS_COMPLETE_REQUEST'
export const MARK_TASK_AS_COMPLETE_SUCCESS = 'MARK_TASK_AS_COMPLETE_SUCCESS'
export const MARK_TASK_AS_COMPLETE_FAIL = 'MARK_TASK_AS_COMPLETE_FAIL'
export const MARK_TASK_AS_COMPLETE_RESET = 'MARK_TASK_AS_COMPLETE_RESET'