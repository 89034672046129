import React, {useEffect, useRef, useState} from 'react';
import "../assets/css/modal.css"
import {IoClose} from "react-icons/io5";
import Trash from "./Icons/Trash";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import DropdownCalendarSingle from "./DropdownCalendarSingle";
import DropdownItemsInput from "./DropdownItemsInput";
import event_data from "../assets/json/event_type.json";
import link from "../assets/images/icons/link.svg";
import {useSelector} from "react-redux";
import Loader from "./Loader";
import Calendar from "./Icons/Calendar";

function FormModal1(props) {
    // Add Event
    const eventNameRef = useRef();
    const [eventType, setEventType] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateEvent, setUpdateEvent] = useState([])

    const [fieldIsValid, setFieldIsValid] = useState({
        isEventNameValid: false,
        isToDateValid: false,
        isFromDateValid: false,
        isTypeValid: false,
    });
    const [fieldData, setFieldData] = useState({
        dataEventName: null,
        dataToDate: null,
        dataFromDate: null,
        dataType: null,
    });

    const getEventTypeList = useSelector(state => state.getEventType)
    const {event_type_list} = getEventTypeList

    useEffect(() => {
        if (!getEventTypeList.event_type_list <= 0) {
            getEventTypeList.event_type_list.map((item, index) => {
                setEventType((prevData) => [
                    ...prevData,
                    {
                        id: item.eventTypesId,
                        data: item.title,
                    }
                ])
            });
            //
        }
    }, [getEventTypeList.event_type_list]);

    useEffect(() => {
        if (props.value != null) {
            setFieldIsValid({
                ...fieldIsValid,
                isFromDateValid: true,
                isToDateValid: true,
            });
            setFieldData({
                ...fieldData,
                dataFromDate: props.value,
                dataToDate: props.value
            })
            if (props.type === "edit_event") {
                props.value.map((item, index) => {
                    setUpdateEvent((prevData) => [
                        ...prevData,
                        {
                            eventId: item.eventId,
                            schoolYearId: item.schoolYearId,
                            title: item.title,
                            fromDate: item.fromDate,
                            toDate: item.toDate,
                            typeId: item.typeId
                        }
                    ])
                });
            }
        }
    }, [props.value]);
    useEffect(() => {
        // console.log("Updated vents", updateEvent)
    }, [updateEvent])

    const eventKeyPress = (e, id) => {
        e.preventDefault();

        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,1000}$/.test(eventNameRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isEventNameValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isEventNameValid: true,
            });

            setUpdateEvent(updateEvent =>
                updateEvent.map(event =>
                    event.eventId === id ? {...event, title: e.target.value} : event
                )
            )
        }
    }

    const eventDataEdit = (data) => {
        console.log(data)
        setFieldIsValid({
            ...fieldIsValid,
            isTypeValid: true,
        });
        setFieldData({
            ...fieldData,
            dataType: data[0].id
        })
        setUpdateEvent(updateEvent =>
            updateEvent.map(event =>
                event.eventId === data[1] ? {...event, typeId: data[0].id} : event
            )
        )
    }
    const eventData = (data) => {
        console.log(data)
        setFieldIsValid({
            ...fieldIsValid,
            isTypeValid: true,
        });
        setFieldData({
            ...fieldData,
            dataType: data.id
        })
    }
    const fromData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isFromDateValid: true,
        });
        setFieldData({
            ...fieldData,
            dataFromDate: data
        })
        setUpdateEvent(updateEvent =>
            updateEvent.map(event =>
                event.eventId === data[1] ? {...event, fromDate: formatDate(data[0])} : event
            )
        )
    }
    const toData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isToDateValid: true,
        });
        setFieldData({
            ...fieldData,
            dataToDate: data
        })
        setUpdateEvent(updateEvent =>
            updateEvent.map(event =>
                event.eventId === data[1] ? {...event, toDate: formatDate(data[0])} : event
            )
        )
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function toggleYes() {
        props.result([updateEvent, 1])
    }

    function toggleNo() {
        props.result(0)
    }

    function deleteEvent(data) {
        props.result(["delete", data])
    }

    function toggleData() {
        console.log(fieldData.dataFromDate)
        props.result([eventNameRef.current.value, fieldData.dataFromDate, fieldData.dataToDate, fieldData.dataType])
    }

    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };

    const formView = (data) => {
        if (!props.value <= 0) {
            // setLoading(false)
            return data.map((item, index) => {
                try {
                    return (
                        <>
                            <Row className="background-1">

                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     className="padding-left-unset padding-right-unset">
                                    <div className="float-right-child mb-3 button-cursor"
                                         onClick={() => deleteEvent(item.eventId)}>
                                        <Trash className="sidebar-icon-form margin-left mb-1"
                                               color="var(--red)"/> <span className="message-6 mt-4">Delete Event</span>
                                    </div>
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing">
                                            Event Name<span className="asterics">*</span></label>
                                        <input ref={eventNameRef} onChange={(e) => eventKeyPress(e, item.eventId)}
                                               type="text" autoComplete="off"
                                               defaultValue={item.title}
                                               key={item.eventId}
                                            // className={!fieldIsValid.isEventNameValid ? "input-error-form" : "input-valid-form"}
                                               className="input-valid-form input-spacing"
                                               placeholder="Enter Event Name"
                                               id="document_title"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="background-1" key={item.eventId}>
                                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                     className="padding-left-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing-calendar">
                                            From<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownCalendarSingle title="Select From"
                                                                    value={item.fromDate}
                                                                    eventId={item.eventId}
                                                                    result={fromData}
                                                                    className={"background-1 calendar-content-valid"}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                     className="padding-right-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing-calendar">
                                            To<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownCalendarSingle title="Select To"
                                                                    value={item.toDate}
                                                                    eventId={item.eventId}
                                                                    result={toData}
                                                                    className={"background-1 calendar-content-valid"}
                                            />
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row className="background-1 padding-bottom-modal">
                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     className="padding-left-unset padding-right-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="teacher-associate"
                                               className="form-label form-spacing">
                                            Type of Events<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownItemsInput data={eventType} result={eventDataEdit}
                                                                value={item.typeId}
                                                                eventId={item.eventId}
                                                                className="background-1 dropdown-input-content-valid"
                                                                title="Select Event Type"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )
                } catch (e) {
                    alert(e.message);
                }
            });
        }
        //
    }
    const viewEvents = (data) => {
        if (!props.value <= 0) {
            // setLoading(false)
            return data.map((item, index) => {
                try {
                    return (
                        <>
                            <Row className="background-1">
                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     className="padding-left-unset padding-right-unset mb-3 padding-bottom-modal">
                                    <div className="row background-1">
                                        <div className="float-left-child button-cursor">
                                            <span className="event-name">{item.title}</span>
                                        </div>
                                    </div>
                                    <div className="row background-1">
                                        <div className="col-4">
                                            <p className="metric-data padding-top">
                                                From
                                            </p>
                                            <p className="event-details">
                                                <Calendar className="sidebar-icon small"
                                                          color="var(--primary-100)"/> {formatDateName(item.fromDate)}
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="metric-data padding-top">
                                                To
                                            </p>
                                            <p className="event-details">
                                                <Calendar className="sidebar-icon small"
                                                          color="var(--primary-100)"/> {formatDateName(item.toDate)}
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="metric-data padding-top">
                                                Type of Event
                                            </p>
                                            <p className="event-name">
                                                {item.typeName}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )
                } catch (e) {
                    alert(e.message);
                }
            });
        }
        //
    }

    return (
        <>
            <div className="darkBG" data-backdrop="static">
                {loading && <Loader/>}
                <div className={props.type === "edit_event" ? "modal-content form-modal" : "modal-content"}>
                    <div className="modal-header">
                        <div className="modal-heading-title">
                            {props.heading}
                        </div>
                        <div className="modal-heading-title" onClick={toggleYes}>
                            <IoClose/>
                        </div>
                    </div>
                    {props.type === "add_event" && <div className="modal-body">
                        <div className="body-text-form">
                            <Row className="background-1">
                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     className="padding-left-unset padding-right-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing">
                                            Event Name<span className="asterics">*</span></label>
                                        <input ref={eventNameRef} onChange={eventKeyPress}
                                               type="text" autoComplete="off"
                                            // className={!fieldIsValid.isEventNameValid ? "input-error-form" : "input-valid-form"}
                                               className={!fieldIsValid.isEventNameValid ?
                                                   "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                               placeholder="Enter Event Name"
                                               id="document_title"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="background-1">
                                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                     className="padding-left-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing-calendar">
                                            From<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownCalendarSingle title="Select From"
                                                                    value={props.value}
                                                                    result={fromData}
                                                                    className={!fieldIsValid.isFromDateValid ?
                                                                        "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                     className="padding-right-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="certificateNameLabel"
                                               className="form-label form-spacing-calendar">
                                            To<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownCalendarSingle title="Select To"
                                                                    value={props.value}
                                                                    result={toData}
                                                                    className={!fieldIsValid.isToDateValid ?
                                                                        "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                            />
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row className="background-1">
                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                     className="padding-left-unset padding-right-unset">
                                    <div className="document-form">
                                        <label htmlFor="document_title" id="teacher-associate"
                                               className="form-label form-spacing">
                                            Type of Events<span className="asterics">*</span></label>
                                        <div>
                                            <DropdownItemsInput data={eventType} result={eventData}
                                                                className={!fieldIsValid.isTypeValid ?
                                                                    "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                title="Select Event Type"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>}
                    {props.type === "add_event" && <div className="modal-footer">
                        {(
                                !fieldIsValid.isEventNameValid
                                || !fieldIsValid.isToDateValid
                                || !fieldIsValid.isFromDateValid
                                || !fieldIsValid.isTypeValid
                            )
                            &&
                            <Row className="background-1 document-form submit-buttons-modal">
                                <Col lg={{span: 6}} className="padding-left-unset">
                                    <button type="button"
                                            className="button-solid button-transparent-active modal-button"
                                            onClick={toggleYes}>
                                        Cancel
                                    </button>
                                </Col>

                                <Col lg={{span: 6}} className="padding-right-unset">
                                    <button type="button" className="button-solid button-inactive modal-button">
                                        Add
                                    </button>
                                </Col>
                            </Row>
                        }
                        {(
                                fieldIsValid.isEventNameValid
                                && fieldIsValid.isToDateValid
                                && fieldIsValid.isFromDateValid
                                && fieldIsValid.isTypeValid
                            )
                            && <Row className="background-1 document-form submit-buttons-modal">
                                <Col lg={{span: 6}} className="padding-left-unset">
                                    <button type="button"
                                            className="button-solid button-transparent-active modal-button"
                                            onClick={toggleYes}>
                                        Cancel
                                    </button>
                                </Col>

                                <Col lg={{span: 6}} className="padding-right-unset">
                                    <button type="button" className="button-solid button-active modal-button"
                                            onClick={toggleData}>
                                        Add
                                    </button>
                                </Col>
                            </Row>}
                    </div>}

                    {props.type === "edit_event" && <div className="modal-body-update">
                        <div className="body-text-form edit-form">
                            {formView(props.value)}
                        </div>
                    </div>}
                    {props.type === "edit_event" && <div className="modal-footer">
                        <Row className="background-1 document-form submit-buttons-modal">
                            <Col lg={{span: 6}} className="padding-left-unset">
                                <button type="button" className="button-solid button-transparent-active modal-button"
                                        onClick={toggleNo}>
                                    Cancel
                                </button>
                            </Col>

                            <Col lg={{span: 6}} className="padding-right-unset">
                                <button type="button" className="button-solid button-active modal-button"
                                        onClick={toggleYes}>
                                    Save
                                </button>
                            </Col>
                        </Row>
                    </div>}

                    {props.type === "view_event" && <div className="modal-body-update">
                        <div className="body-text-form edit-form">
                            {viewEvents(props.value)}
                        </div>
                    </div>}

                </div>
            </div>
        </>
    );
}

export default FormModal1;