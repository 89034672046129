import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 16.75C18.59 16.75 18.25 16.41 18.25 16V6.5C18.25 5.81 17.69 5.25 17 5.25H11.5C11.09 5.25 10.75 4.91 10.75 4.5C10.75 4.09 11.09 3.75 11.5 3.75H17C18.52 3.75 19.75 4.98 19.75 6.5V16C19.75 16.41 19.41 16.75 19 16.75Z"
                fill={props.color}/>
            <path
                d="M14 7.74993C13.83 7.74993 13.66 7.68995 13.52 7.57995L10.52 5.07995C10.35 4.93995 10.25 4.72993 10.25 4.49993C10.25 4.26993 10.35 4.06991 10.52 3.91991L13.52 1.41991C13.84 1.14991 14.31 1.19995 14.58 1.51995C14.85 1.83995 14.8 2.30995 14.48 2.57995L12.17 4.49993L14.48 6.41991C14.8 6.68991 14.84 7.15991 14.58 7.47991C14.43 7.65991 14.21 7.74993 14 7.74993Z"
                fill={props.color}/>
            <path
                d="M19 22.75C16.93 22.75 15.25 21.07 15.25 19C15.25 16.93 16.93 15.25 19 15.25C21.07 15.25 22.75 16.93 22.75 19C22.75 21.07 21.07 22.75 19 22.75ZM19 16.75C17.76 16.75 16.75 17.76 16.75 19C16.75 20.24 17.76 21.25 19 21.25C20.24 21.25 21.25 20.24 21.25 19C21.25 17.76 20.24 16.75 19 16.75Z"
                fill={props.color}/>
            <path
                d="M12.5 20.25H7C5.48 20.25 4.25 19.02 4.25 17.5V8C4.25 7.59 4.59 7.25 5 7.25C5.41 7.25 5.75 7.59 5.75 8V17.5C5.75 18.19 6.31 18.75 7 18.75H12.5C12.91 18.75 13.25 19.09 13.25 19.5C13.25 19.91 12.91 20.25 12.5 20.25Z"
                fill={props.color}/>
            <path
                d="M9.99993 22.7497C9.78993 22.7497 9.56994 22.6597 9.41994 22.4797C9.14994 22.1597 9.19992 21.6897 9.51992 21.4197L11.8299 19.4997L9.51992 17.5797C9.19992 17.3097 9.15994 16.8397 9.41994 16.5197C9.68994 16.1997 10.1599 16.1597 10.4799 16.4197L13.4799 18.9197C13.6499 19.0597 13.7499 19.2697 13.7499 19.4997C13.7499 19.7297 13.6499 19.9297 13.4799 20.0797L10.4799 22.5797C10.3399 22.6897 10.1699 22.7497 9.99993 22.7497Z"
                fill={props.color}/>
            <path
                d="M5 8.75C2.93 8.75 1.25 7.07 1.25 5C1.25 2.93 2.93 1.25 5 1.25C7.07 1.25 8.75 2.93 8.75 5C8.75 7.07 7.07 8.75 5 8.75ZM5 2.75C3.76 2.75 2.75 3.76 2.75 5C2.75 6.24 3.76 7.25 5 7.25C6.24 7.25 7.25 6.24 7.25 5C7.25 3.76 6.24 2.75 5 2.75Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;