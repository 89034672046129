import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 15.292C7.41667 15.292 7.34167 15.2753 7.25833 15.242C7.025 15.1503 6.875 14.917 6.875 14.667V9.66699C6.875 9.32533 7.15833 9.04199 7.5 9.04199C7.84167 9.04199 8.125 9.32533 8.125 9.66699V13.1587L8.725 12.5587C8.96667 12.317 9.36667 12.317 9.60833 12.5587C9.85 12.8003 9.85 13.2003 9.60833 13.442L7.94167 15.1087C7.825 15.2253 7.65833 15.292 7.5 15.292Z"
                fill={props.color}/>
            <path
                d="M7.50003 15.2915C7.3417 15.2915 7.18337 15.2332 7.05837 15.1082L5.3917 13.4415C5.15003 13.1999 5.15003 12.7999 5.3917 12.5582C5.63337 12.3165 6.03337 12.3165 6.27503 12.5582L7.9417 14.2249C8.18337 14.4665 8.18337 14.8665 7.9417 15.1082C7.8167 15.2332 7.65837 15.2915 7.50003 15.2915Z"
                fill={props.color}/>
            <path
                d="M12.5001 19.4587H7.50008C2.97508 19.4587 1.04175 17.5253 1.04175 13.0003V8.00033C1.04175 3.47533 2.97508 1.54199 7.50008 1.54199H11.6667C12.0084 1.54199 12.2917 1.82533 12.2917 2.16699C12.2917 2.50866 12.0084 2.79199 11.6667 2.79199H7.50008C3.65841 2.79199 2.29175 4.15866 2.29175 8.00033V13.0003C2.29175 16.842 3.65841 18.2087 7.50008 18.2087H12.5001C16.3417 18.2087 17.7084 16.842 17.7084 13.0003V8.83366C17.7084 8.49199 17.9917 8.20866 18.3334 8.20866C18.6751 8.20866 18.9584 8.49199 18.9584 8.83366V13.0003C18.9584 17.5253 17.0251 19.4587 12.5001 19.4587Z"
                fill={props.color}/>
            <path
                d="M18.3334 9.45841H15.0001C12.1501 9.45841 11.0417 8.35007 11.0417 5.50007V2.16674C11.0417 1.91674 11.1917 1.68341 11.4251 1.59174C11.6584 1.49174 11.9251 1.55007 12.1084 1.72507L18.7751 8.39174C18.9501 8.56674 19.0084 8.84174 18.9084 9.07507C18.8084 9.3084 18.5834 9.45841 18.3334 9.45841ZM12.2917 3.67507V5.50007C12.2917 7.65007 12.8501 8.20841 15.0001 8.20841H16.8251L12.2917 3.67507Z"
                fill={props.color}/>
        </svg>

    );
}

export default Home;