import {
    GET_ANALYTICS_REQUEST,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAIL,
    GET_ANALYTICS_RESET,

} from "../constants/AnalyticsConstants"
const initialState = {};
export const getAnalyticsReducer = (state = {analytics: []}, action) => {
    switch (action.type) {
        case GET_ANALYTICS_REQUEST:
            return { loadingAnalytics: true }

        case GET_ANALYTICS_SUCCESS:
            return {
                loadingAnalytics: false,
                analytics: action.payload,
            }

        case GET_ANALYTICS_FAIL:
            return { loadingAnalytics: false, errorAnalytics: action.payload }

        case GET_ANALYTICS_RESET:
            return initialState;

        default:
            return state
    }
}