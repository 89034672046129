import api from './api';
import {
    ADD_CERTIFICATE_SUCCESS,
    ADD_CERTIFICATE_REQUEST,
    ADD_CERTIFICATE_FAIL,
    ADD_CERTIFICATE_RESET,

    EDIT_CERTIFICATE_SUCCESS,
    EDIT_CERTIFICATE_REQUEST,
    EDIT_CERTIFICATE_FAIL,
    EDIT_CERTIFICATE_RESET,

    GET_CERTIFICATE_BY_ID_SUCCESS,
    GET_CERTIFICATE_BY_ID_REQUEST,
    GET_CERTIFICATE_BY_ID_FAIL,
    GET_CERTIFICATE_BY_ID_RESET,

    GET_ALL_CERTIFICATES_SUCCESS,
    GET_ALL_CERTIFICATES_REQUEST,
    GET_ALL_CERTIFICATES_FAIL,
    GET_ALL_CERTIFICATES_RESET,

    DELETE_CERTIFICATES_REQUEST,
    DELETE_CERTIFICATES_SUCCESS,
    DELETE_CERTIFICATES_FAIL,
    DELETE_CERTIFICATES_RESET,

    EXPORT_CERTIFICATES_REQUEST,
    EXPORT_CERTIFICATES_SUCCESS,
    EXPORT_CERTIFICATES_FAIL,
    EXPORT_CERTIFICATES_RESET
} from "../constants/CertificateConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addCertificate = (formData) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_CERTIFICATE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Certification/AddEdit`,
            formData, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_CERTIFICATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_CERTIFICATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_CERTIFICATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editCertificate = (formData) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_CERTIFICATE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Certification/AddEdit`,
            formData, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_CERTIFICATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_CERTIFICATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_CERTIFICATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getCertificateById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CERTIFICATE_BY_ID_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Certification/GetCertificationsById?CertificationId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_CERTIFICATE_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_CERTIFICATE_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CERTIFICATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteCertificate = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_CERTIFICATES_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/Certification/DeleteCertificationById?id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_CERTIFICATES_SUCCESS,
                payload: data.success
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_CERTIFICATES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_CERTIFICATES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllCertificate = (pageSize, pageNumber, associate, category, status) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_CERTIFICATES_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }
        var associateString = associate == undefined ? '' : associate
        var categoryString = category == undefined ? '' : category
        var statusString = status == undefined ? '' : status

        const {data} = await api.get(`${url}/Certification/GetCertificationsByFilter?AssociateId=${associateString}&Title=${categoryString}&StatusId=${statusString}&PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_CERTIFICATES_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_CERTIFICATES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_CERTIFICATES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const exportAllCertificate = () => async (dispatch, getState) => {
    try {
        dispatch({type: EXPORT_CERTIFICATES_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/xml',
                'Accept': 'application/xml',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            },
            responseType: 'blob'
        }
        await api.get(`${url}/Certification/ExportAll`, config).then(
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certificate.xlsx');
                document.body.appendChild(link)
                link.click();

                dispatch({
                    type: EXPORT_CERTIFICATES_SUCCESS,
                    payload: response,
                })
            }
        )

    } catch (error) {
        dispatch({
            type: EXPORT_CERTIFICATES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetAddCertificate = () => (dispatch, getState) => {
    dispatch({
        type: ADD_CERTIFICATE_RESET,
    })
};
export const resetEditCertificate = () => (dispatch, getState) => {
    dispatch({
        type: EDIT_CERTIFICATE_RESET,
    })
};
export const resetGetDocumentById = () => (dispatch, getState) => {
    dispatch({
        type: GET_CERTIFICATE_BY_ID_RESET,
    })
};
export const resetDeleteDocument = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_CERTIFICATES_RESET,
    })
};
export const resetGetAllCertificates = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_CERTIFICATES_RESET,
    })
};
export const resetExportAllCertificates = () => (dispatch, getState) => {
    dispatch({
        type: EXPORT_CERTIFICATES_RESET,
    })
};