import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0001 19.4582C5.05841 19.4582 1.04175 15.4415 1.04175 10.4998C1.04175 5.55817 5.05841 1.5415 10.0001 1.5415C14.9417 1.5415 18.9584 5.55817 18.9584 10.4998C18.9584 15.4415 14.9417 19.4582 10.0001 19.4582ZM10.0001 2.7915C5.75008 2.7915 2.29175 6.24984 2.29175 10.4998C2.29175 14.7498 5.75008 18.2082 10.0001 18.2082C14.2501 18.2082 17.7084 14.7498 17.7084 10.4998C17.7084 6.24984 14.2501 2.7915 10.0001 2.7915Z"
                fill={props.color}/>
            <path
                d="M13.3334 11.125H6.66675C6.32508 11.125 6.04175 10.8417 6.04175 10.5C6.04175 10.1583 6.32508 9.875 6.66675 9.875H13.3334C13.6751 9.875 13.9584 10.1583 13.9584 10.5C13.9584 10.8417 13.6751 11.125 13.3334 11.125Z"
                fill={props.color}/>
            <path
                d="M10 14.4582C9.65833 14.4582 9.375 14.1748 9.375 13.8332V7.1665C9.375 6.82484 9.65833 6.5415 10 6.5415C10.3417 6.5415 10.625 6.82484 10.625 7.1665V13.8332C10.625 14.1748 10.3417 14.4582 10 14.4582Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;