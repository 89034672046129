export const ADD_EDIT_TRUSTED_ADVISOR_REQUEST = 'ADD_EDIT_TRUSTED_ADVISOR_REQUEST'
export const ADD_EDIT_TRUSTED_ADVISOR_SUCCESS = 'ADD_EDIT_TRUSTED_ADVISOR_SUCCESS'
export const ADD_EDIT_TRUSTED_ADVISOR_FAIL = 'ADD_EDIT_TRUSTED_ADVISOR_FAIL'
export const ADD_EDIT_TRUSTED_ADVISOR_RESET = 'ADD_EDIT_TRUSTED_ADVISOR_RESET'
export const GET_ALL_TRUSTED_ADVISORS_REQUEST = 'GET_ALL_TRUSTED_ADVISORS_REQUEST'
export const GET_ALL_TRUSTED_ADVISORS_SUCCESS = 'GET_ALL_TRUSTED_ADVISORS_SUCCESS'
export const GET_ALL_TRUSTED_ADVISORS_FAIL = 'GET_ALL_TRUSTED_ADVISORS_FAIL'
export const GET_ALL_TRUSTED_ADVISORS_RESET = 'GET_ALL_TRUSTED_ADVISORS_RESET'
export const GET_TRUSTED_ADVISOR_BY_ID_REQUEST = 'GET_TRUSTED_ADVISOR_BY_ID_REQUEST'
export const GET_TRUSTED_ADVISOR_BY_ID_SUCCESS = 'GET_TRUSTED_ADVISOR_BY_ID_SUCCESS'
export const GET_TRUSTED_ADVISOR_BY_ID_FAIL = 'GET_TRUSTED_ADVISOR_BY_ID_FAIL'
export const GET_TRUSTED_ADVISOR_BY_ID_RESET = 'GET_TRUSTED_ADVISOR_BY_ID_RESET'
export const DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST = 'DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST'
export const DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS = 'DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS'
export const DELETE_TRUSTED_ADVISOR_BY_ID_FAIL = 'DELETE_TRUSTED_ADVISOR_BY_ID_FAIL'
export const DELETE_TRUSTED_ADVISOR_BY_ID_RESET = 'DELETE_TRUSTED_ADVISOR_BY_ID_RESET'
