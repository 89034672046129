import React, {useEffect, useMemo, useState} from 'react';
import {useTable, usePagination, useGlobalFilter} from 'react-table';
import "../DataTable/css/DataTable.css"
import edit_button from "../../assets/images/icons/pencil.svg"
import user_edit from "../../assets/images/icons/user-edit.svg"
import show_dash from "../../assets/images/icons/show-dash.svg"
import Client from "../../assets/images/client-school.svg"
import {useNavigate} from "react-router-dom";
import EmptyTable from "../../assets/images/client_empty_table.svg";

const DataTableClient = (props) => {
    const history = useNavigate();
    const [pageCount, setPageCount] = useState(1);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: {pageIndex, globalFilter, pageSize, rowCount},
        setGlobalFilter,
    } = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: {pageIndex: 0},
            manualPagination: true, // Tell the usePagination hook that we'll handle pagination ourselves
            pageCount
        },
        useGlobalFilter,
        usePagination
    );

    const memoizedColumns = useMemo(() => props.columns, [props.columns]);

    const startIndex = pageIndex * props.pageSize + 1;
    const endIndex = pageIndex * props.pageSize + page.length;
    const totalRecords = props.recordData;

    useEffect(() => {
        setPageCount(Math.ceil(props.recordData / props.pageSize))
    }, [props.recordData]);

    useEffect(() => {
        props.result(pageIndex + 1)
    }, [pageIndex]);

    useEffect(() => {
        if (props.filter)
            gotoPage(0)
    }, [props.filter]);

    const pagesToShow = 5; // Number of pages to show before and after ellipsis
    let firstPage = Math.max(0, pageIndex - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageCount - 1, firstPage + pagesToShow - 1);
    if (lastPage - firstPage < pagesToShow - 1) {
        firstPage = Math.max(0, lastPage - pagesToShow + 1);
    }
    const pages = Array.from({length: lastPage - firstPage + 1}, (_, i) => firstPage + i);

    const renderEmptyState = () => {
        return (
            <tr className="react-table-empty-card">
                <td colSpan={props.columns.length}>
                    <div className="react-calendar-empty">
                        <img src={EmptyTable} alt="No data"/>
                    </div>
                </td>
            </tr>
        );
    };

    const editTemplate = (id, data) => {
        history(`/client-teacher-list/${id}`, {state: {clientName: data}})
    }
    const editClient = (id) => {
        history(`/client-edit/${id}`)
    }
    const showDashboard = (id, data, training) => {
        localStorage.setItem('client_id', id)
        localStorage.setItem('client_name', data)
        localStorage.setItem('IsTrainingEnable', training)
        history(`/dashboard`, {
            state:
                {
                    clientId: id,
                    clientName: data
                }
        })
    }

    return (
        <>
            <div className="table-container">
                <table {...getTableProps()} id="table-grid-card" className="table-react">
                    <thead style={{display: "none"}}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {props.recordData > 0 ? (
                        page.map(row => {
                            prepareRow(row);
                            const item = row.original;
                            return (
                                <tr {...row.getRowProps()} className="card">
                                    <td className="client-td">
                                        <div className="float-left-child width-80">
                                            <img src={Client} alt="client" className=""/>
                                            <br/>
                                            <span className="span-client-name">{item.clientName}</span>
                                        </div>
                                        <div className="float-right-child">
                                            <div className=" image-button mb-1"
                                                 onClick={() => editClient(item.clientMappingId)}>
                                                <img src={edit_button} alt="edit" className="button-cursor"/>
                                            </div>
                                            <div className=" image-button mb-1"
                                                 onClick={() => editTemplate(item.clientId, item.clientName)}>
                                                <img src={user_edit} alt="edit" className="button-cursor"/>
                                            </div>
                                            <div className=" image-button mb-1"
                                                 onClick={() => showDashboard(item.clientId, item.clientName, item.isTrainingEnable)}>
                                                <img src={show_dash} alt="edit" className="button-cursor"/>
                                            </div>

                                        </div>
                                    </td>
                                    <td className="client-td">
                                        <div className="row background-1">
                                            <div className="col-6">
                                                <p className="client-question">No. of Admins</p>
                                                <p className="client-answer">{item.adminUserCount}</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="client-question">No. of Teacher/Associates</p>
                                                <p className="client-answer">{item.teacherAssociateCount}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })) : (
                        // setTimeout(() => {
                        renderEmptyState()
                        // }, 2000)
                    )
                    }
                    </tbody>
                </table>
            </div>
            <div className="row background-1">
                <div className="col-6 dataTables_info">
                    <div>
                        Displaying: {startIndex} to {endIndex} of {totalRecords} records
                    </div>
                </div>
                <div className="col-6 react-table-pagination" align="right">
                    <button className="paginate_button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fas fa-chevron-left table-icon"/>
                    </button>
                    {firstPage > 0 && <button onClick={() => gotoPage(firstPage - 1)}>...</button>}
                    {pages.map((pageNumber, index) =>
                        <button
                            key={pageNumber}
                            onClick={() => gotoPage(pageNumber)}
                            disabled={pageIndex === pageNumber}
                            className={`page-button ${pageIndex === pageNumber ? `active` : ``}`}
                        >
                            {pageNumber + 1}
                        </button>
                    )}
                    {lastPage < pageCount - 1 && <button onClick={() => gotoPage(lastPage + 1)}>...</button>}
                    <button className="paginate_button" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fas fa-chevron-right table-icon"/>
                    </button>
                </div>
            </div>
        </>
    );
};

export default DataTableClient;
