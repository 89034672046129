import React, {useEffect, useState} from 'react';
import { Calendar } from 'primereact/calendar';
import {Row, Col} from 'react-bootstrap'
import "../assets/css/dropdown.css"
import Calendar_1 from "../components/Icons/Calendar";
import DownArrow from "../components/Icons/DownArrow";

function DropdownCalendarDisabled(props) {
    const [dates, setDates] = useState([]);
    const setDropdownResult = (e) => {
        setDates(e.value)
        props.result(e.value)
        console.log(e.value)
    }
    // console.log(dates[0])
    return (
        <Row className="background-1 calendar-content">
            <Col lg={{span: 1}} md={{span: 1}}>
                <Calendar_1 className="sidebar-icon-input" disabled
                            color="var(--primary-100)"/>
            </Col>
            <Col lg={{span: 10}} md={{span: 10}} className="padding-calendar-single">
                {props.title}
            </Col>
            <Col lg={{span: 1}} md={{span: 1}}>
                <DownArrow className="icon-down-arrow"
                            color="var(--primary-100)"/>
            </Col>

        </Row>
    );
}

export default DropdownCalendarDisabled;