// CustomSortIcons.js
import React from 'react';
import Swap from "../assets/images/icons/sort/arrow-swap.svg"
import SwapLeft from "../assets/images/icons/sort/arrow-swap-left.svg"
import SwapRight from "../assets/images/icons/sort/arrow-swap-right.svg"

export const SortUpIcon = () => (
    <span><img src={Swap} alt="sort" height={18}/></span>
);

export const SortDownIcon = () => (
    <span><img src={Swap} alt="sort" height={18}/></span>
);

export const SortIcon = () => (
    <span><img src={Swap} alt="sort" height={18}/></span> // Default sort icon
);
