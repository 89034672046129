import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import DropdownItems from "../components/DropdownItems";
import signature_status from "../assets/json/signature_status.json"
import DropdownCalendar from "../components/DropdownCalendar";
import "../assets/fontawesome-free/css/all.min.css"
import "jquery/dist/jquery.min.js";
import "../datatable/css/jquery.dataTables.css";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import dt from 'datatables.net-dt';
import PencilEdit from "../components/Icons/PencilEdit";
import Trash from "../components/Icons/Trash";
import {useLocation, useNavigate} from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import AlertModal from "../components/AlertModal";
import {
    deleteDocument,
    getAllDocuments,
    resetAddDocument,
    resetDeleteDocument,
    resetGetDocumentById
} from "../actions/DocumentActions";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable/DataTable";
import {GrPowerReset} from "react-icons/gr";
import EmptyTable from "../assets/images/empty_document_table.svg"
import {decoded} from "../components/PrivateRoute";
import {getTeacherAssociates} from "../actions/ConfigActions";

$.fn.DataTable = dt


function Documents(props) {
    const [showDelete, setShowDelete] = useState({
        display: false,
        data: null
    })
    const history = useNavigate()
    const dispatch = useDispatch()
    const documentTitleRef = useRef();
    const categoryRef = useRef();
    const location = useLocation()
    const [alertModal, setAlertModal] = useState(false)
    const [accessLevel, setAccessLevel] = useState(false)
    const [teacher, setTeacher] = useState([])
    const [category, setCategory] = useState([])
    const [pageSize, setPageSize] = useState(5)
    const [pageNumber, setPageNumber] = useState(1)
    const [record, setRecord] = React.useState();
    const [documents, setDocuments] = React.useState([]);
    const [fieldData, setFieldData] = useState({
        dataTitle: "",
        dataTeacher: "",
        dataDocumentCategory: "",
        dataSignature: "",
        dataFromDate: "",
        dataToDate: "",
        dataImportFromDate: "",
        dataImportToDate: "",
    });
    const columns = React.useMemo(() => [
        {
            Header: 'Title',
            accessor: 'title',
            width: "15%",
        },
        {
            Header: 'Associate Name',
            accessor: 'associateName',
            width: "15%",
        },
        {
            Header: 'Document Date',
            accessor: 'docDate',
            width: "15%",
        },
        {
            Header: 'Import Date',
            accessor: 'createdDate',
            width: "15%",
        },
        {
            Header: 'Document Type',
            accessor: 'categoryName',
            width: "15%",
        },
        {
            Header: 'Signature Status',
            accessor: 'signatureStatus',
            width: "15%",
        },
        {
            Header: 'Action',
            id: 'actions',
            width: "10%",
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDoc(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                    <div onClick={() => showDeleteModal(row.original)} className="col-4 padding-left-unset">
                        <Trash className="sidebar-icon margin-left"
                               color="var(--red)"/>
                    </div>
                </div>
            )
        },
    ], []);
    const columnsTeacher = React.useMemo(() => [
        {
            Header: 'Title',
            accessor: 'title',
            width: "20%",
        },
        {
            Header: 'Associate Name',
            accessor: 'associateName',
            width: "20%",
        },
        {
            Header: 'Document Date',
            accessor: 'docDate',
            width: "15%",
        },
        {
            Header: 'Import Date',
            accessor: 'createdDate',
            width: "15%",
        },
        {
            Header: 'Document Type',
            accessor: 'categoryName',
            width: "15%",
        },
        {
            Header: 'Signature Status',
            accessor: 'signatureStatus',
            width: "15%",
        },

    ], []);

    const getDocuments = useSelector(state => state.getAllDocuments)
    const {error, loading, documentInfo} = getDocuments

    const deleteDocumentDetails = useSelector(state => state.deleteDocument)
    const {errorDelete, loadingDelete} = deleteDocumentDetails

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    const getCategoryList = useSelector(state => state.getCategory)
    const {category_list} = getCategoryList


    const changePage = (data) => {
        setDocuments([])
        setPageNumber(data)
    }

    useEffect(() => {
        if (deleteDocumentDetails.document_delete === true) {
            dispatch(resetDeleteDocument())
            setDocuments([])
            dataTableReload()
        }
        if (deleteDocumentDetails.error) {
            setAlertModal(true)
        }
    }, [deleteDocumentDetails]);

    useEffect(() => {
        dispatch(getTeacherAssociates())
        setAccessLevel(decoded.RoleAccessLevels)
        dispatch(resetGetDocumentById())
        if (location.state?.success) {
            setAlertModal(true)
            history('/documents', {state: {}})
        }
    }, [])

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    useEffect(() => {
        if (!getCategoryList.category_list <= 0) {
            getCategoryList.category_list.map((item, index) => {
                setCategory((prevData) => [
                    ...prevData,
                    {
                        id: item.documentCategoryId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getCategoryList.category_list]);

    const dataTableReload = () => {
        setDocuments([])
        console.log(formatDate(fieldData.dataFromDate),formatDate(fieldData.dataToDate))
        dispatch(getAllDocuments(pageSize, pageNumber,fieldData.dataTitle,fieldData.dataTeacher,fieldData.dataDocumentCategory,fieldData.dataSignature,formatDate(fieldData.dataFromDate),formatDate(fieldData.dataToDate),formatDate(fieldData.dataImportFromDate),formatDate(fieldData.dataImportToDate)))
    }

    useEffect(() => {
        // setDocuments(getDocuments.document_list)
        if (!getDocuments.document_list <= 0) {

            getDocuments.document_list.map((item, index) => {
                setDocuments((prevData) => [
                    ...prevData,
                    {
                        documentId: item.documentId,
                        title: item.title,
                        associateName: item.associateName,
                        docDate: item.docDate,
                        createdDate: item.createdDate,
                        categoryName: item.documentCategory,
                        signatureStatus: item.signatureStatus,
                    }
                ])
            });
            if (getDocuments.records !== undefined) {
                setRecord(getDocuments.records)
            }
        }
    }, [getDocuments.document_list]);


    const pageName = 'documents';

    function formatDate(dateString) {
        if (dateString != null) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        } else {
            return ""
        }
    }
    const editDoc = (data) => {
        history(`/edit-documents/${data.documentId}`)
    }
    const showDeleteModal = (data) => {
        setShowDelete({
            display: true,
            data: data.documentId
        })
    }
    const hideDeleteModal = (data) => {
        setShowDelete({
            display: false
        })
        if (data == 1) {
            dispatch(deleteDocument(showDelete.data))
        }
    }
    const closeAlert = (data) => {
        setAlertModal(false)
        dispatch(resetAddDocument())
        // window.location.href='/documents'
    }

    useEffect(() => {
        setDocuments([])
        dataTableReload()
    }, [fieldData, pageNumber]);

    const filterData = (e) => {
        e.preventDefault()
    }
    const resetData = (e) => {
        // window.location.href = "/documents"
        documentTitleRef.current.value = ""
        categoryRef.current.value = ""
        setFieldData({
            dataTeacher: null,
            dataSignature: null,
            dataFromDate: null,
            dataToDate: null,
            dataImportFromDate: null,
            dataImportToDate: null,
        })
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setFieldData({
                ...fieldData,
                dataTitle: documentTitleRef.current.value
            })
        }
    }
    const changeSearch = (e) => {
        if (e.target.value == "") {
            setFieldData({
                ...fieldData,
                dataTitle: documentTitleRef.current.value
            })
        }
    }

    const handleCategorySearch = (e) => {
        if (e.key === 'Enter') {
            setFieldData({
                ...fieldData,
                dataDocumentCategory: categoryRef.current.value
            })
        }
    }
    const changeCategorySearch = (e) => {
        if (e.target.value == "") {
            setFieldData({
                ...fieldData,
                dataDocumentCategory: categoryRef.current.value
            })
        }
    }

    const filterDataTeacher = (data) => {
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const filterDataSignature = (data) => {
        setFieldData({
            ...fieldData,
            dataSignature: data.id
        })
    }
    const filterDocumentDate = (data) => {
        setFieldData({
            ...fieldData,
            dataFromDate: data[0],
            dataToDate: data[1],
        })
    }
    const filterImportDate = (data) => {
        setFieldData({
            ...fieldData,
            dataImportFromDate: data[0],
            dataImportToDate: data[1],
        })
    }

    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingDelete && <Loader/>}
            {alertModal &&
                <AlertModal heading="Document added successfully" type="success" result={closeAlert}/>}
            {showDelete.display && <DeleteModal heading="Delete Document"
                                                type="delete"
                                                question="Are you sure want to delete this Document ?"
                                                answer=""
                                                result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="doc_list" title="Document List"/>
                <Row className="background-1 m-3">
                    <Col lg={accessLevel != 0 ? {span: 3} : {span: 4}} md={{span: 4}} sm={{span: 4}}>
                        <div className="">
                            <input
                                ref={documentTitleRef}
                                onKeyPress={handleSearch}
                                onChange={changeSearch}
                                type="text" autoComplete="off"
                                className="input-document input-spacing-document"
                                placeholder="Enter Document Title"
                                defaultValue={fieldData.dataTitle}
                                id="document_title"/>
                        </div>
                    </Col>
                    {accessLevel != 0 && decoded.IsDocumentsEnable != "False" && <Col lg={{span: 3}} md={{span: 4}} sm={{span: 4}}>
                        <DropdownItems data={teacher} title="Teacher Associate" result={filterDataTeacher}
                                       value={fieldData.dataTeacher}/>
                    </Col>}
                    <Col lg={accessLevel != 0 ? {span: 3} : {span: 4}} md={{span: 4}} sm={{span: 4}}>
                        <div className="">
                            <input
                                ref={categoryRef}
                                onKeyPress={handleCategorySearch}
                                onChange={changeCategorySearch}
                                type="text" autoComplete="off"
                                className="input-document input-spacing-document"
                                placeholder="Enter Document Type"
                                id="document_title"/>
                        </div>
                        {/*<DropdownItems data={category} title="Document Category" result={filterDataCategory}/>*/}
                    </Col>
                    <Col lg={accessLevel != 0 ? {span: 3} : {span: 4}} md={{span: 4}} sm={{span: 4}}>
                        <DropdownItems data={signature_status} title="Signature Status" result={filterDataSignature} value={fieldData.dataSignature}/>
                    </Col>
                </Row>
                <Row className="background-1 m-3">
                    <Col lg={{span: 4}} md={{span: 6}} sm={{span: 6}}>
                        <DropdownCalendar title="Document Date Range" datesValue={fieldData.dataFromDate} result={filterDocumentDate}/>
                    </Col>
                    <Col lg={{span: 4}} md={{span: 6}} sm={{span: 6}}>
                        <DropdownCalendar title="Import Date Range" datesValue={fieldData.dataImportFromDate} result={filterImportDate}/>
                    </Col>
                    {/*<Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>*/}
                    {/*    <div className="float-left-child add-button button-solid" onClick={filterData}>*/}
                    {/*        <GrFilter classname="sidebar-icon" color="var(--white)"/> Filter*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>
                        <div className="float-right-child add-button button-solid reset-filter-button" onClick={resetData}>
                            <GrPowerReset classname="sidebar-icon" color="var(--white)"/> Reset
                        </div>
                    </Col>
                </Row>

                <Row className="app-body-document background-1">
                    {/*<div className="pl-5 pb-2 background-1"*/}
                    {/*>*/}
                    {documents && record >= 0 && <div className="background-2">
                        <DataTable defaultPageSize={5}
                                   pageSize={pageSize}
                                   columns={accessLevel != 0 && decoded.IsDocumentsEnable != "False" ? columns : columnsTeacher}
                                   data={documents}
                                   recordData={record}
                                   result={changePage} empty_table={EmptyTable}
                        />
                        <Footer/>
                    </div>}
                </Row>
            </div>

        </Row>
    </>);
}

export default Documents;