import {
    GET_TEACHER_ASSOCIATE_SUCCESS,
    GET_TEACHER_ASSOCIATE_REQUEST,
    GET_TEACHER_ASSOCIATE_FAIL,
    GET_TEACHER_ASSOCIATE_RESET,

    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_RESET,

    GET_SIGNATURE_STATUS_REQUEST,
    GET_SIGNATURE_STATUS_SUCCESS,
    GET_SIGNATURE_STATUS_FAIL,
    GET_SIGNATURE_STATUS_RESET,

    GET_EVENT_TYPE_REQUEST,
    GET_EVENT_TYPE_SUCCESS,
    GET_EVENT_TYPE_FAIL,
    GET_EVENT_TYPE_RESET,

    GET_TASK_TYPE_REQUEST,
    GET_TASK_TYPE_SUCCESS,
    GET_TASK_TYPE_FAIL,
    GET_TASK_TYPE_RESET,

    GET_STATUS_REQUEST,
    GET_STATUS_SUCCESS,
    GET_STATUS_FAIL,
    GET_STATUS_RESET,

    GET_DYNAMIC_VALUE_REQUEST,
    GET_DYNAMIC_VALUE_SUCCESS,
    GET_DYNAMIC_VALUE_FAIL,
    GET_DYNAMIC_VALUE_RESET,

    GET_ALL_DEPARTMENTS_REQUEST,
    GET_ALL_DEPARTMENTS_SUCCESS,
    GET_ALL_DEPARTMENTS_FAIL,
    GET_ALL_DEPARTMENTS_RESET,

    GET_DASHBOARD_DATA_REQUEST,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_RESET,

    GET_PAYROLL_DATA_REQUEST,
    GET_PAYROLL_DATA_SUCCESS,
    GET_PAYROLL_DATA_FAIL,
    GET_PAYROLL_DATA_RESET,

    GET_BA_DATA_REQUEST,
    GET_BA_DATA_SUCCESS,
    GET_BA_DATA_FAIL,
    GET_BA_DATA_RESET,

    GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST,
    GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS,
    GET_ALL_LETTERS_TEMPLATES_LIST_FAIL,
    GET_ALL_LETTERS_TEMPLATES_LIST_RESET,

    GET_REDIRECT_URL_REQUEST,
    GET_REDIRECT_URL_SUCCESS,
    GET_REDIRECT_URL_FAIL,
    GET_REDIRECT_URL_RESET

} from "../constants/ConfigConstants"

const initialState = {};
export const getDashboardDataReducer = (state = { dashboard_data: [] }, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA_REQUEST:
            return {loading: true}

        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                loading: false,
                dashboard_data: action.payload,
            }

        case GET_DASHBOARD_DATA_FAIL:
            return {loading: false, error: action.payload}

        case GET_DASHBOARD_DATA_RESET:
            return initialState;

        default:
            return state
    }
}
export const getEmployeePayrollReducer = (state = { employee_payroll: [], record: "" }, action) => {
    switch (action.type) {
        case GET_PAYROLL_DATA_REQUEST:
            return {loadingPayroll: true}

        case GET_PAYROLL_DATA_SUCCESS:
            return {
                loadingPayroll: false,
                employee_payroll: action.payload,
                record: action.records
            }

        case GET_PAYROLL_DATA_FAIL:
            return {loadingPayroll: false, errorPayroll: action.payload}

        case GET_PAYROLL_DATA_RESET:
            return initialState;

        default:
            return state
    }
}
export const getEmployeeBaReducer = (state = { employee_ba: [], record: "" }, action) => {
    switch (action.type) {
        case GET_BA_DATA_REQUEST:
            return {loadingBa: true}

        case GET_BA_DATA_SUCCESS:
            return {
                loadingBa: false,
                employee_ba: action.payload,
                record: action.records
            }

        case GET_BA_DATA_FAIL:
            return {loadingBa: false, errorBa: action.payload}

        case GET_BA_DATA_RESET:
            return initialState;

        default:
            return state
    }
}
export const getTeacherAssociateReducer = (state = { teacher_list: [] }, action) => {
    switch (action.type) {
        case GET_TEACHER_ASSOCIATE_REQUEST:
            return {loading: true}

        case GET_TEACHER_ASSOCIATE_SUCCESS:
            return {
                loading: false,
                teacher_list: action.payload,
            }

        case GET_TEACHER_ASSOCIATE_FAIL:
            return {loading: false, error: action.payload}

        case GET_TEACHER_ASSOCIATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllDepartmentReducer = (state = { department_list: [] }, action) => {
    switch (action.type) {
        case GET_ALL_DEPARTMENTS_REQUEST:
            return {loading: true}

        case GET_ALL_DEPARTMENTS_SUCCESS:
            return {
                loading: false,
                department_list: action.payload,
            }

        case GET_ALL_DEPARTMENTS_FAIL:
            return {loading: false, error: action.payload}

        case GET_ALL_DEPARTMENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getCategoryReducer = (state = { category_list: [] }, action) => {
    switch (action.type) {
        case GET_CATEGORY_REQUEST:
            return {loading: true}

        case GET_CATEGORY_SUCCESS:
            return {
                loading: false,
                category_list: action.payload,
            }

        case GET_CATEGORY_FAIL:
            return {loading: false, error: action.payload}

        case GET_CATEGORY_RESET:
            return initialState;

        default:
            return state
    }
}
export const getSignatureStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SIGNATURE_STATUS_REQUEST:
            return {loading: true}

        case GET_SIGNATURE_STATUS_SUCCESS:
            return {
                loading: false,
                signature_list: action.payload,
            }

        case GET_SIGNATURE_STATUS_FAIL:
            return {loading: false, error: action.payload}

        case GET_SIGNATURE_STATUS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getEventTypeReducer = (state = { event_type_list: [] }, action) => {
    switch (action.type) {
        case GET_EVENT_TYPE_REQUEST:
            return {loading: true}

        case GET_EVENT_TYPE_SUCCESS:
            return {
                loading: false,
                event_type_list: action.payload,
            }

        case GET_EVENT_TYPE_FAIL:
            return {loading: false, error: action.payload}

        case GET_EVENT_TYPE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getTaskTypeReducer = (state = { task_type_list: [] }, action) => {
    switch (action.type) {
        case GET_TASK_TYPE_REQUEST:
            return {loading: true}

        case GET_TASK_TYPE_SUCCESS:
            return {
                loading: false,
                task_type_list: action.payload,
            }

        case GET_TASK_TYPE_FAIL:
            return {loading: false, error: action.payload}

        case GET_TASK_TYPE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getStatusReducer = (state = { status_list: [] }, action) => {
    switch (action.type) {
        case GET_STATUS_REQUEST:
            return {loading: true}

        case GET_STATUS_SUCCESS:
            return {
                loading: false,
                status_list: action.payload,
            }

        case GET_STATUS_FAIL:
            return {loading: false, error: action.payload}

        case GET_STATUS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getDynamicReducer = (state = { dynamic_value: [] }, action) => {
    switch (action.type) {
        case GET_DYNAMIC_VALUE_REQUEST:
            return {loading: true}

        case GET_DYNAMIC_VALUE_SUCCESS:
            return {
                loading: false,
                dynamic_value: action.payload,
            }

        case GET_DYNAMIC_VALUE_FAIL:
            return {loading: false, error: action.payload}

        case GET_DYNAMIC_VALUE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllLettersTemplateListReducer = (state = { letter_template_list: [] }, action) => {
    switch (action.type) {
        case GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST:
            return {loadingAllList: true}

        case GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS:
            return {
                loadingAllList: false,
                letter_template_list: action.payload,
            }

        case GET_ALL_LETTERS_TEMPLATES_LIST_FAIL:
            return {loadingAllList: false, errorAllList: action.payload}

        case GET_ALL_LETTERS_TEMPLATES_LIST_RESET:
            return initialState;

        default:
            return state
    }
}
export const getRedirectUrlReducer = (state = { url_redirect: [] }, action) => {
    switch (action.type) {
        case GET_REDIRECT_URL_REQUEST:
            return {loadingAllList: true}

        case GET_REDIRECT_URL_SUCCESS:
            return {
                loadingAllList: false,
                url_redirect: action.payload,
            }

        case GET_REDIRECT_URL_FAIL:
            return {loadingAllList: false, errorAllList: action.payload}

        case GET_REDIRECT_URL_RESET:
            return initialState;

        default:
            return state
    }
}