import React from 'react';

function Home(props) {
    return (

        <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4354 22.6717L11.4355 22.6716L11.4227 22.668C6.44256 21.2959 2.83984 16.6379 2.83984 11.61V7.22003C2.83984 6.30342 3.52677 5.27572 4.38015 4.92245C4.3804 4.92235 4.38065 4.92224 4.38089 4.92214L9.9483 2.64318C9.94847 2.64311 9.94863 2.64304 9.9488 2.64298C11.2783 2.10217 12.7323 2.10273 14.0503 2.64272L14.0504 2.64278L19.6188 4.92214C19.619 4.92223 19.6192 4.92232 19.6195 4.92242C20.4729 5.27566 21.1599 6.30339 21.1599 7.22003V11.61C21.1599 16.627 17.5478 21.2862 12.5759 22.6683L12.5759 22.6682L12.5643 22.6717C12.3887 22.7252 12.1988 22.7501 11.9998 22.7501C11.8009 22.7501 11.611 22.7252 11.4354 22.6717ZM10.1412 3.11703L10.1404 3.11734L4.57045 5.39731L4.57044 5.3973L4.56755 5.3985C4.21162 5.54681 3.91198 5.83852 3.7051 6.14579C3.4984 6.45279 3.33984 6.84141 3.33984 7.23004V11.6201C3.33984 16.4423 6.79639 20.8715 11.5517 22.1906C11.8421 22.2766 12.1576 22.2766 12.448 22.1906C17.2033 20.8715 20.6599 16.4423 20.6599 11.6201V7.23004C20.6599 6.84141 20.5013 6.45279 20.2946 6.14579C20.0877 5.83852 19.7881 5.54681 19.4321 5.39849L19.4292 5.39731L13.8593 3.11734L13.8585 3.11703C13.2595 2.87298 12.6355 2.75006 11.9998 2.75006C11.3642 2.75006 10.7402 2.87298 10.1412 3.11703Z"
                stroke={props.color}/>
            <path
                d="M12 13.25C10.7561 13.25 9.75 12.2439 9.75 11C9.75 9.75614 10.7561 8.75 12 8.75C13.2439 8.75 14.25 9.75614 14.25 11C14.25 12.2439 13.2439 13.25 12 13.25ZM12 9.25C11.0339 9.25 10.25 10.0339 10.25 11C10.25 11.9661 11.0339 12.75 12 12.75C12.9661 12.75 13.75 11.9661 13.75 11C13.75 10.0339 12.9661 9.25 12 9.25Z"
                stroke={props.color}/>
            <path
                d="M12 16.25C11.8661 16.25 11.75 16.1339 11.75 16V13C11.75 12.8661 11.8661 12.75 12 12.75C12.1339 12.75 12.25 12.8661 12.25 13V16C12.25 16.1339 12.1339 16.25 12 16.25Z"
                stroke={props.color}/>
        </svg>
    );
}

export default Home;