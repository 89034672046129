import api from './api';
import {
    ADD_EVENTS_SUCCESS,
    ADD_EVENTS_REQUEST,
    ADD_EVENTS_FAIL,
    ADD_EVENTS_RESET,

    EDIT_EVENTS_SUCCESS,
    EDIT_EVENTS_REQUEST,
    EDIT_EVENTS_FAIL,
    EDIT_EVENTS_RESET,

    GET_EVENTS_SUCCESS,
    GET_EVENTS_REQUEST,
    GET_EVENTS_FAIL,
    GET_EVENTS_RESET,

    GET_ALL_EVENTS_REQUEST,
    GET_ALL_EVENTS_SUCCESS,
    GET_ALL_EVENTS_FAIL,
    GET_ALL_EVENTS_RESET,

    DELETE_EVENTS_SUCCESS,
    DELETE_EVENTS_REQUEST,
    DELETE_EVENTS_FAIL,
    DELETE_EVENTS_RESET,

    GET_EVENTS_BY_DATE_REQUEST,
    GET_EVENTS_BY_DATE_SUCCESS,
    GET_EVENTS_BY_DATE_FAIL,
    GET_EVENTS_BY_DATE_RESET,

    GET_UPCOMING_SCHOOL_EVENTS_REQUEST,
    GET_UPCOMING_SCHOOL_EVENTS_SUCCESS,
    GET_UPCOMING_SCHOOL_EVENTS_FAIL,
    GET_UPCOMING_SCHOOL_EVENTS_RESET
} from "../constants/EventConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addEvents = (title, dataFromDate, dataToDate, typeId, SchoolYearId) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Calender/AddUpdateEvent`,
            {
                title: title,
                fromDate: dataFromDate,
                toDate: dataToDate,
                typeId: typeId,
                SchoolYearId: SchoolYearId
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_EVENTS_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editEvents = (eventsData) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Calender/UpdateEventBulk`,
            eventsData, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_EVENTS_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getEventsByDate = (fromDate, toDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetEventByFilter?StartDate=${fromDate}&EndDate=${toDate}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_EVENTS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getUpcomingSchoolEvents = (PageNumber, PageSize) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_UPCOMING_SCHOOL_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetUpcomingSchoolEvents?PageNumber=${PageNumber}&PageSize=${PageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_UPCOMING_SCHOOL_EVENTS_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_UPCOMING_SCHOOL_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_UPCOMING_SCHOOL_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getEventsBySingleDate = (fromDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_EVENTS_BY_DATE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetEventByDate?Date=${fromDate}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_EVENTS_BY_DATE_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_EVENTS_BY_DATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_EVENTS_BY_DATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllEvent = (fromDate, toDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }
        var fromDateString = fromDate == undefined ? '' : fromDate
        var toDateString = toDate == undefined ? '' : toDate

        const {data} = await api.get(`${url}/Calender/GetEventByFilter?StartDate=${fromDateString}&EndDate=${toDateString}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_EVENTS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteEvent = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_EVENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/Calender/DeleteEventById?id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_EVENTS_SUCCESS,
                payload: data.success
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_EVENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_EVENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetAddEvent = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_EVENTS_RESET,
    })
};
export const resetEditEvents = () => async  (dispatch, getState) => {
    dispatch({
        type: EDIT_EVENTS_REQUEST,
    })
};
export const resetGetSchoolYearById = (dispatch, getState) => {
    dispatch({
        type: GET_EVENTS_RESET,
    })
};
export const resetGetAllEvent = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_EVENTS_RESET,
    })
};
export const resetDeleteEvent = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_EVENTS_RESET,
    })
};
export const resetGetEventsByDate = () => (dispatch, getState) => {
    dispatch({
        type: GET_EVENTS_BY_DATE_RESET,
    })
};
export const resetUpcomingSchoolEvents = () => (dispatch, getState) => {
    dispatch({
        type: GET_UPCOMING_SCHOOL_EVENTS_RESET,
    })
};