export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST'
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS'
export const ADD_DOCUMENT_FAIL = 'ADD_DOCUMENT_FAIL'
export const ADD_DOCUMENT_RESET = 'ADD_DOCUMENT_RESET'
export const GET_DOCUMENT_BY_ID_REQUEST = 'GET_DOCUMENT_BY_ID_REQUEST'
export const GET_DOCUMENT_BY_ID_SUCCESS = 'GET_DOCUMENT_BY_ID_SUCCESS'
export const GET_DOCUMENT_BY_ID_FAIL = 'GET_DOCUMENT_BY_ID_FAIL'
export const GET_DOCUMENT_BY_ID_RESET = 'GET_DOCUMENT_BY_ID_RESET'
export const EDIT_DOCUMENT_REQUEST = 'EDIT_DOCUMENT_REQUEST'
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS'
export const EDIT_DOCUMENT_FAIL = 'EDIT_DOCUMENT_FAIL'
export const EDIT_DOCUMENT_RESET = 'EDIT_DOCUMENT_RESET'
export const GET_ALL_DOCUMENTS_REQUEST = 'GET_ALL_DOCUMENTS_REQUEST'
export const GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS'
export const GET_ALL_DOCUMENTS_FAIL = 'GET_ALL_DOCUMENTS_FAIL'
export const GET_ALL_DOCUMENTS_RESET = 'GET_ALL_DOCUMENTS_RESET'
export const DELETE_DOCUMENTS_REQUEST = 'DELETE_DOCUMENTS_REQUEST'
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS'
export const DELETE_DOCUMENTS_FAIL = 'DELETE_DOCUMENTS_FAIL'
export const DELETE_DOCUMENTS_RESET = 'DELETE_DOCUMENTS_RESET'