import React from 'react';

function Home(props) {
    return (
       
        <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 13.25C8.83 13.25 6.25 10.67 6.25 7.5C6.25 4.33 8.83 1.75 12 1.75C15.17 1.75 17.75 4.33 17.75 7.5C17.75 10.67 15.17 13.25 12 13.25ZM12 3.25C9.66 3.25 7.75 5.16 7.75 7.5C7.75 9.84 9.66 11.75 12 11.75C14.34 11.75 16.25 9.84 16.25 7.5C16.25 5.16 14.34 3.25 12 3.25Z"
                fill={props.color}/>
            <path
                d="M20.5901 23.25C20.1801 23.25 19.8401 22.91 19.8401 22.5C19.8401 19.05 16.3202 16.25 12.0002 16.25C7.68015 16.25 4.16016 19.05 4.16016 22.5C4.16016 22.91 3.82016 23.25 3.41016 23.25C3.00016 23.25 2.66016 22.91 2.66016 22.5C2.66016 18.23 6.85015 14.75 12.0002 14.75C17.1502 14.75 21.3401 18.23 21.3401 22.5C21.3401 22.91 21.0001 23.25 20.5901 23.25Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;