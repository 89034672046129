import React, {useRef, useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";

import process_data from "../assets/json/process_data.json";
import {useNavigate, useParams} from "react-router-dom";
import DynamicProcessManage from "../components/DynamicProcessManage";
import DeleteModal from "../components/DeleteModal";
import Calendar from "../components/Icons/Calendar";
import {
    cancelAllTask,
    completeAllTask,
    editProcessTask,
    getProcessesById,
    resetAddProcess, resetCancelAllTasks, resetCompleteAllTasks,
    resetEditTaskById, resetSendTaskNudge, sendTaskNudge
} from "../actions/ProcessActions";
import Loader from "../components/Loader";
import {editTaskByIdReducer} from "../reducers/ProcessReducers";
import AlertModal from "../components/AlertModal";
import {getTeacherAssociates} from "../actions/ConfigActions";

const ManageProcess = (props) => {
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [tasks, setTasks] = useState([]);
    const [teachers, setTeacher] = useState([]);
    const [userEmail, setUserEmail] = useState("")
    const [count, setCount] = useState(3);
    const [alert, showAlert] = useState({
        display: false,
        data: null
    });
    const [alertCancel, showAlertCancel] = useState(false);
    const [alertAsignee, showAlertAsignee] = useState({
        display: false,
        type: "",
        data: ""
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    });

    const [fieldIsValid, setFieldIsValid] = useState({
        isProcessTypeValid: true,
        isProcessTitleValid: true,
        isActiveValid: true,
        isTaskValid: true,
    });
    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                        employee_Personal_Email: item.employee_Personal_Email,
                        employee_Work_Email: item.employee_Work_Email,
                        preferred_email: item.preferred_email,
                        employee_Department_Code: item.employee_Department_Code,
                        dept_Desc: item.dept_Desc,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const getProcessDetails = useSelector(state => state.getProcessById)
    const {error, loading, processes} = getProcessDetails

    useEffect(() => {
        setTasks([])
        if (!getProcessDetails.process_task <= 0) {
            console.log(getProcessDetails.process_task)
            getProcessDetails.process_task.map((item, index) => {
                setTasks((prevData) => [
                    ...prevData,
                    {
                        id: index+1,
                        processTaskId: item.processTaskId,
                        title: item.title,
                        taskName: item.title,
                        assignToId: item.assignToId,
                        assignToName: item.assignToName,
                        notes: item.notes,
                        statusId: item.statusId,
                        statusTitle: item.statusTitle,
                        update: false,
                    }
                ])
            });
        }
    }, [getProcessDetails.process_data]);

    const editTaskDetails = useSelector(state => state.editTaskById)
    const {errorEdit, loadingEdit, processesEdit} = editTaskDetails

    useEffect(() => {
        if (editTaskDetails.taskEdit === true) {
            dispatch(resetEditTaskById())
            setAlertModal({
                display: true,
                type: "success",
                message: "One task updated successfully"
            })
        } if (editTaskDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Task couldn't be updated"
            })
        }
    }, [editTaskDetails]);

    const completeAllTaskDetails = useSelector(state => state.completeAllTask)
    const {errorComplete, loadingComplete, processesComplete} = completeAllTaskDetails

    useEffect(() => {
        if (completeAllTaskDetails.taskComplete === true) {
            showAlert({
                display: false,
            });
            dispatch(resetCompleteAllTasks())
            history('/processes',{state:{success_process_complete: "1"}})
        } if (completeAllTaskDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Tasks couldn't be completed"
            })
        }
    }, [completeAllTaskDetails]);

    const cancelAllTaskDetails = useSelector(state => state.cancelAllTask)
    const {errorCancel, loadingCancel, processesCancel} = cancelAllTaskDetails

    useEffect(() => {
        if (cancelAllTaskDetails.taskCancel === true) {
            showAlert({
                display: false,
            });
            dispatch(resetCancelAllTasks())
            history('/processes',{state:{success_process_cancel: "1"}})
        } if (cancelAllTaskDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Tasks couldn't be canceled"
            })
        }
    }, [cancelAllTaskDetails]);

    const sendTaskNudgeDetails = useSelector(state => state.sendTaskNudge)
    const {errorNudge, loadingNudge, nudge_data} = sendTaskNudgeDetails

    useEffect(() => {
        if (sendTaskNudgeDetails.nudge_data === true) {
            dispatch(resetSendTaskNudge())
            setAlertModal({
                display: true,
                type: "success",
                message: "Nudge sent successfully"
            })

        } if (sendTaskNudgeDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Nudge couldn't be sent"
            })
        }
    }, [sendTaskNudgeDetails]);

    useEffect(() => {
        dispatch(getTeacherAssociates())
        dispatch(getProcessesById(params.id))
    }, []);

    const openLink = () => {
        history('/processes')
    }
    const onReset = () => {
        history('/edit-process-template/1')
    }
    const pageName = 'processes';


    useEffect(() => {
        console.log(tasks)
        if (tasks.some(item => item.task === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        }
        if (tasks.some(item => item.assign === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        }
        if (tasks.some(item => item.note === '')) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: true,
            });
        }
    }, [tasks]);

    useEffect(() => {
        processDetails();
    }, [getProcessDetails.process_data])

    const processDetails = () => {
        // console.log(getProcessDetails.process_data)
        if (getProcessDetails.process_data !== [] && getProcessDetails.process_data !== undefined) {
            if (getProcessDetails.process_data.processId === params.id) {
                return (
                    <>
                        <div className="col-4 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Process Name
                            </p>
                            <p className="description-data">
                                {getProcessDetails.process_data.title}
                            </p>
                        </div>
                        <div className="col-4 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Teacher/Associate Name
                            </p>
                            <p className="description-data">
                                {getProcessDetails.process_data.assignToName}
                            </p>
                        </div>
                        <div className="col-4 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Status
                            </p>
                            <p className="description-data">
                                {getProcessDetails.process_data.statusId == 2 && <span className="text-active">Completed</span>}
                                {getProcessDetails.process_data.statusId == 3 && <span className="text-inactive">Cancelled</span>}
                                {getProcessDetails.process_data.statusId == 1 && <span className="text-yellow">In Progress</span>}
                                {getProcessDetails.process_data.statusId == 4 && <span className="text-error">Expired</span>}
                            </p>
                        </div>
                        <div className="col-4 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Start Date
                            </p>
                            <p className="description-data">
                                <Calendar className="sidebar-icon mb-1"
                                          color="var(--primary-100)"/> <span className="">{getProcessDetails.process_data.dateStarted}</span>
                            </p>
                        </div>
                        <div className="col-4 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                End Date
                            </p>
                            <p className="description-data">
                                <Calendar className="sidebar-icon mb-1"
                                          color="var(--primary-100)"/> <span className="">{getProcessDetails.process_data.dateCompleted}</span>
                            </p>
                        </div>
                    </>);
            }
        }
    }
    const taskDetails = (data) => {
        return data.map((item, index) => {
            console.log("Item value: ", item)
            try {
                return (
                    <Row className="background-1 task-view">
                        <div className="col-12 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                            </p>
                            <p className="description-data">
                                Task {index+1}
                            </p>
                        </div>
                        <div className="col-3 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Task Name
                            </p>
                            <p className="description-data">
                                {item.taskName}
                            </p>
                        </div>
                        <div className="col-3 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Assign To
                            </p>
                            <p className="description-data">
                                {item.assignToName}
                            </p>
                        </div>
                        <div className="col-3 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Notes to Assignee
                            </p>
                            <p className="description-data">
                                {item.notes}
                            </p>
                        </div>
                        <div className="col-3 template-card-title padding-left-unset mt-3">
                            <p className="description-heading">
                                Status
                            </p>
                            <p className="description-data">
                                {item.statusTitle}
                            </p>
                        </div>
                    </Row>);

            } catch (e) {
                alert(e.message);
            }
        })
    }

    const addTask = () => {
        setCount(count + 1)
        const newTask = {
            id: count, // Simple unique ID for demo purposes
            task: "",
            assign: "",
            note: "",
        };
        setTasks([...tasks, newTask]);

    };
    const deleteTask = (data) => {
        if (data[0] === "0") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        update: false};
                }
                return task;
            });
            tasks.map(task => {
                if (task.id === data[1]) {
                    checkUserEmailEdit(task.assignToId, task)
                    // dispatch(editProcessTask(task.processTaskId, task.assignToId, task.notes, task.statusId))
                }
            });
            setTasks(updatedTasks);
        }
        // setTasks(tasks.filter(task => task.id !== data));
        if (data[0] === "1") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        update: true,
                        statusId: data[2]
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "2") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        update: true,
                        assignToId: data[2]
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "3") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[1]) {
                    return {
                        ...task,
                        update: true,
                        notes: data[2]
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "5") {
            setUserEmail([])
            const task = tasks.find(task => task.id === data[1]);
            checkUserEmail(task.assignToId, task.processTaskId)
        }
    };
    const checkUserEmail = (id, taskId) => {
        const employee = teachers.find(emp => emp.id === id);
        if (!employee) {
            showAlertAsignee({
                ...alertAsignee,
                display: true,
                type: 1
            })
        } else {
            if (employee.employee_Personal_Email === "" && employee.employee_Work_Email === "") {
                showAlertAsignee({
                    ...alertAsignee,
                    display: true,
                    data: taskId,
                    type: 1
            })
            } else if (employee.preferred_email) {
                dispatch(sendTaskNudge(taskId))
            }
        }
    }

    const checkUserEmailEdit = (id, task) => {
        const employee = teachers.find(emp => emp.id === id);
        if (!employee) {
            showAlertAsignee({
                ...alertAsignee,
                display: true,
                type: 2
            })
        } else {
            if (employee.employee_Personal_Email === "" && employee.employee_Work_Email === "") {
                showAlertAsignee({
                    ...alertAsignee,
                    display: true,
                    data: task,
                    type: 2
            })
            } else if (employee.preferred_email) {
                dispatch(editProcessTask(task.processTaskId, task.assignToId, task.notes, task.statusId))
            }
        }
    }

    const importResultAsignee = (data) => {
        if (data == 1) {
            if (alertAsignee.type == 1) {
                showAlertAsignee({
                    ...alertAsignee,
                    display: false
                })
                dispatch(sendTaskNudge(alertAsignee.data))
            }
            if (alertAsignee.type == 2) {
                dispatch(editProcessTask(alertAsignee.data.processTaskId, alertAsignee.data.assignToId, alertAsignee.data.notes, alertAsignee.data.statusId))
            }
        }
        showAlertAsignee({
            ...alertAsignee,
            display: false
        })
    }
    const importResult = (data) => {
        if (data == 1) {
            dispatch(completeAllTask(params.id))
            // history('/processes')
        }
        if (data == 0) {
            showAlert({
                display: false
            })
            showAlertCancel(false)
            showAlertAsignee({
                ...alertAsignee,
                display: false
            })
        }
    }
    const importResultCancel = (data) => {
        if (data == 1) {
            dispatch(cancelAllTask(params.id))
            // history('/processes')
        }
        if (data == 0) {
            showAlert({
                display: false
            })
            showAlertCancel(false)
            showAlertAsignee({
                ...alertAsignee,
                display: false
            })
        }
    }

    const completeLink = (e) => {
        e.preventDefault()
        showAlert({
            display: true
        })
    }

    const cancelLink = (e) => {
        e.preventDefault()
        showAlertCancel(true)
    }
    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }

    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type}
                                result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                {loadingComplete && <Loader/>}
                {loadingNudge && <Loader/>}
                {alert.display && <DeleteModal heading="Complete Tasks"
                                       type="import"
                                       button_true="Yes, Complete"
                                       button_false="Cancel"
                                       question="Are you sure want to complete all the tasks ?"
                                       answer=""
                                       result={importResult}
                />}
                {alertCancel && <DeleteModal heading="Cancel Tasks"
                                             type="import"
                                             button_true="Yes, Cancel"
                                             button_false="Cancel"
                                             question="Are you sure want to cancel all the tasks ?"
                                             answer=""
                                             result={importResultCancel}
                />}
                {alertAsignee.display && <DeleteModal heading="Assignee Missing"
                                              type="import"
                                              button_true="Continue"
                                              button_false="Cancel"
                                              question="The chosen assignee is missing both a work and personal email address and will not receive the task. Do you wish to continue?"
                                              answer=""
                                              result={importResultAsignee}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Manage Process" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getProcessDetails.process_data && <form>
                                    <Row className="background-1 pt-4">
                                        <div className="card-process-manage">
                                            <Row className="background-1">
                                                <>
                                                    <div className="col-4 template-card-title padding-left-unset mt-3">
                                                        <p className="description-heading">
                                                            Process Name
                                                        </p>
                                                        <p className="description-data">
                                                            {getProcessDetails.process_data.title}
                                                        </p>
                                                    </div>
                                                    <div className="col-4 template-card-title padding-left-unset mt-3">
                                                        <p className="description-heading">
                                                            Teacher/Associate Name
                                                        </p>
                                                        <p className="description-data">
                                                            {getProcessDetails.process_data.assignToName}
                                                        </p>
                                                    </div>
                                                    <div className="col-4 template-card-title padding-left-unset mt-3">
                                                        <p className="description-heading">
                                                            Status
                                                        </p>
                                                        <p className="description-data">
                                                            {getProcessDetails.process_data.statusId == 2 &&
                                                                <span className="text-active">Completed</span>}
                                                            {getProcessDetails.process_data.statusId == 3 &&
                                                                <span className="text-inactive">Cancelled</span>}
                                                            {getProcessDetails.process_data.statusId == 1 &&
                                                                <span className="text-yellow">In Progress</span>}
                                                            {getProcessDetails.process_data.statusId == 4 &&
                                                                <span className="text-error">Expired</span>}
                                                        </p>
                                                    </div>
                                                    <div className="col-4 template-card-title padding-left-unset mt-3">
                                                        <p className="description-heading">
                                                            Start Date
                                                        </p>
                                                        <p className="description-data">
                                                            <Calendar className="sidebar-icon mb-1"
                                                                      color="var(--primary-100)"/> <span
                                                            className="">{formatDateName(getProcessDetails.process_data.dateStarted)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-4 template-card-title padding-left-unset mt-3">
                                                        <p className="description-heading">
                                                            End Date
                                                        </p>
                                                        <p className="description-data">
                                                            <Calendar className="sidebar-icon mb-1"
                                                                      color="var(--primary-100)"/> <span
                                                            className="">{formatDateName(getProcessDetails.process_data.dateCompleted)}</span>
                                                        </p>
                                                    </div>
                                                </>
                                            </Row>
                                            <Row className="background-1">
                                                {params.type != 1 && taskDetails(tasks)}
                                            </Row>
                                        </div>
                                    </Row>
                                    {params.type == 1 && <div>
                                        {tasks.map((task, index) => (
                                            <DynamicProcessManage key={task.title} task={index}
                                                                  id={task.id}
                                                                  processTaskId={task.processTaskId}
                                                                  task_name={task.taskName}
                                                                  assign={task.assignToId}
                                                                  note={task.notes}
                                                                  status={task.statusId}
                                                                  count={tasks.length}
                                                                  update={task.update}
                                                                  result={deleteTask}/>
                                        ))}
                                    </div>}

                                    {(
                                            !fieldIsValid.isProcessTypeValid
                                            || !fieldIsValid.isProcessTitleValid
                                            || !fieldIsValid.isTaskValid
                                            || !fieldIsValid.isActiveValid
                                        )
                                        && params.type == 1
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Complete All
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={cancelLink}
                                                >
                                                    Cancel All
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isProcessTypeValid
                                            && fieldIsValid.isProcessTitleValid
                                            && fieldIsValid.isTaskValid
                                            && fieldIsValid.isActiveValid
                                        )
                                        && params.type == 1
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={completeLink}
                                                >
                                                    Complete All
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={cancelLink}
                                                >
                                                    Cancel All
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default ManageProcess;