import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import teacher_associate from "../assets/json/teacher_associate.json";
import status from "../assets/json/status.json"
import DropdownCalendar from "../components/DropdownCalendar";
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import Folder from "../assets/images/Folder.svg"
import Document from "../components/Icons/Document"
import {useNavigate, useParams} from "react-router-dom";
import DropdownCalendarDisabled from "../components/DropdownCalendarDisabled";
import certificate_list from "../assets/json/certificate.json";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";
import {
    editCertificate,
    getCertificateById,
    resetAddCertificate,
    resetEditCertificate
} from "../actions/CertificateActions";
import { IoCloudDownloadOutline } from "react-icons/io5";
import {getTeacherAssociates} from "../actions/ConfigActions";

const EditCertificate = (props) => {
    const params = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();
    let file_doc
    const certificateNameRef = useRef();
    const certificateTypeRef = useRef();
    const notesTypeRef = useRef();
    const [imgFile, uploading] = useState()
    const [teacher, setTeacher] = useState([])
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const hiddenFileInput = useRef(null);
    useEffect(() => {
        dispatch(getTeacherAssociates())
        dispatch(getCertificateById(params.id))
    }, []);

    const [fieldIsValid, setFieldIsValid] = useState({
        isCertificateNameValid: true,
        isTeacherValid: true,
        isCertificateTypeValid: true,
        isStatusValid: true,
        isToDateValid: true,
        isFromDateValid: true,
        isNotesValid: false,
        isFileValid: true,
    });
    const [errorMessages, setErrorMessages] = useState({
        certificateNameErrorMessage: "",
        teacherErrorMessage: "",
        certificateTypeErrorMessage: "",
        statusErrorMessage: "",
        toDateErrorMessage: "",
        fromDateErrorMessage: "",
        notesErrorMessage: "",
        fileErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        dataCertificateName: null,
        dataTeacher: null,
        dataCertificateType: null,
        dataStatus: null,
        dataToDate: null,
        dataFromDate: null,
        dataNote: null,
        dataFile: null,
        dataUrl: null,
    });

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const getCertificate = useSelector(state => state.getCertificateById)
    const {error, loading, certificateInfo} = getCertificate

    useEffect(() => {
        if (!getCertificate.certificate_data <= 0) {
            setFieldData({
                ...fieldData,
                dataCertificateName: getCertificate.certificate_data.title,
                dataTeacher: getCertificate.certificate_data.associateId,
                dataCertificateType: getCertificate.certificate_data.type,
                dataStatus: getCertificate.certificate_data.statusId,
                dataToDate: formatDateServer(getCertificate.certificate_data.validToDate),
                dataFromDate: formatDateServer(getCertificate.certificate_data.validFromDate),
                dataNote: getCertificate.certificate_data.notes,
                dataFile: null,
                dataFileSize: null,
                dataUrl: getCertificate.certificate_data.docUrl,
            })
            if (getCertificate.certificate_data.notes) {
                setFieldIsValid({
                    ...fieldIsValid,
                    isNotesValid:true
                })
            }
        }
    }, [getCertificate.certificate_data]);

    const editCertificateDetails = useSelector(state => state.editCertificate)
    const {errorEdit, loadingEdit, certificateEdit} = editCertificateDetails

    useEffect(() => {
        if (editCertificateDetails.certificate === true) {
            dispatch(resetEditCertificate())
            setAlertModal({
                display: true,
                type: "success",
                message: "Certificate updated successfully"
            })
            // history('/documents',{state:{success: "1"}})
        } if (editCertificateDetails.error) {
            dispatch(resetEditCertificate())
            setAlertModal({
                display: true,
                type: "error",
                message: "Certificate couldn't be updated"
            })
        }
    }, [editCertificateDetails]);

    function formatDate(dateString) {
        if (dateString != null) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${month}/${day}/${year}`;
        } else {
            return null
        }
    }

    function formatDateServer(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const logoClick = event => {
        hiddenFileInput.current.click();
    };
    const imageFileHandler = (e) => {
        if (e.target.files.length !== 0) {
            file_doc = e.target.files[0]
            console.log(file_doc)
            uploading(URL.createObjectURL(e.target.files[0]))
            setFieldIsValid({
                ...fieldIsValid,
                isFileValid: true
            });
            setFieldData({
                ...fieldData,
                dataFile: file_doc
            })
        }
        console.log(file_doc)
    }

    const notesKeyPress = (event) => {
        event.preventDefault();
        console.log(notesTypeRef.current.value.length)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(notesTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                notesErrorMessage: ""
            });
            return;
        } else if (notesTypeRef.current.value.length > 1) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                notesErrorMessage: ""
            });
        }
    }
    const certificateNameKeyPress = (event) => {
        event.preventDefault();
        console.log(certificateNameRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(certificateNameRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateNameValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                certificateNameErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateNameValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                certificateNameErrorMessage: ""
            });
        }
    }
    const certificateTypeKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(certificateTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateTypeValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                certificateTypeErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCertificateTypeValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                certificateTypeErrorMessage: ""
            });
        }
    }

    const goBack = () => {
        history('/certifications')
    }

    const openLink = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('CertificationId', params.id);
        formData.append('Title', certificateNameRef.current.value);
        formData.append('Notes', notesTypeRef.current.value);
        formData.append('AssociateId', fieldData.dataTeacher);
        formData.append('Type', certificateTypeRef.current.value);
        formData.append('Status', fieldData.dataStatus);
        if (fieldData.dataFile !== null)
            formData.append('FormFile', fieldData.dataFile);
        for (const [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        dispatch(editCertificate(formData))
    }
    const onReset = () => {
        dispatch(getCertificateById(params.id))
    }
    const teacherData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isTeacherValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            teacherErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const certificateData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isCertificateTypeValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            certificateTypeErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataCertificateType: data.id
        })
    }
    const signatureStatusData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            statusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataStatus: data.id
        })
    }
    const fromData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isFromDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            fromDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataFromDate: data
        })
    }
    const toData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isToDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            toDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataToDate: data
        })
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddCertificate())
        history('/certifications')
        // window.location.href='/documents'
    }
    const downloadFile = (url) => {
        window.open(url, '_blank');
    }
    const pageName = 'certifications';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    {getCertificate.certificate_data &&
                        <Heading group="certificate_edit" title="Certification List" result={goBack}
                                 import_date={formatDate(getCertificate.certificate_data.createdDate)}/>}
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getCertificate.certificate_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Teacher/Associate<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={teacher} result={teacherData}
                                                                        value={getCertificate.certificate_data.associateId}
                                                                        className={!fieldIsValid.isTeacherValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Teacher/Associate"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing">
                                                    Certificate Name<span className="asterics">*</span></label>
                                                <input ref={certificateNameRef} onChange={certificateNameKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getCertificate.certificate_data.title}
                                                       className={!fieldIsValid.isCertificateNameValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Certificate Name" disabled
                                                       id="document_title"/>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form inactive">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Valid From<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarDisabled
                                                        title={formatDate(getCertificate.certificate_data.validFromDate)}/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form inactive">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Valid To<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarDisabled
                                                        title={formatDate(getCertificate.certificate_data.validToDate)}/>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing">
                                                    Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={status}
                                                                        result={signatureStatusData}
                                                                        value={getCertificate.certificate_data.statusId}
                                                                        className={!fieldIsValid.isStatusValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Status"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Certificate Type<span className="asterics">*</span></label>
                                                <input ref={certificateTypeRef} onChange={certificateTypeKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getCertificate.certificate_data.type}
                                                       className={!fieldIsValid.isCertificateTypeValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Certificate Type"
                                                       id="document_title"/>
                                                {/*<div>*/}
                                                {/*    <DropdownItemsInput data={certificate_list} result={certificateData}*/}
                                                {/*                        value={parseInt(getCertificate.certificate_data.type)}*/}
                                                {/*                        className={!fieldIsValid.isCertificateTypeValid ?*/}
                                                {/*                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}*/}
                                                {/*                        title="Select Certificate Type"/>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-right-unset padding-left-unset">
                                            <div className={!fieldIsValid.isNotesValid ? "document-form-textarea-error"
                                                : "document-form-textarea"}
                                            >
                                                <span className="text-label form-spacing">
                                                    Note (Optional)</span>
                                                <textarea ref={notesTypeRef} onChange={notesKeyPress}
                                                          defaultValue={getCertificate.certificate_data.notes}
                                                          className="textarea-input-error textarea-spacing"
                                                          placeholder="Add Notes" rows="4"
                                                          id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="background-1 file-box-small">
                                        <Col lg={{span: 11, offset: 0}} md={{span: 11, offset: 0}}>
                                            <Row className="background-1" onClick={logoClick}>
                                                <Col lg={{span: 1}} className="doc-icon">
                                                    <Document className="sidebar-icon"
                                                              color="var(--primary-100)"/>
                                                </Col>
                                                <Col lg={{span: 11}} className="doc-name-outer">
                                                    <Row className="background-1">
                                                        {fieldData.dataFile != null ? fieldData.dataFile.name : getCertificate.certificate_data.docName}
                                                    </Row>
                                                    <Row className="background-1 doc-size">
                                                        File
                                                        size: {fieldData.dataFile != null ? Math.round(fieldData.dataFile.size / getCertificate.certificate_data.docSize).toFixed(2) : '13'} MB
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <input type="file"
                                                   id='image'
                                                   accept='.doc, .docx, .pdf'
                                                   onChange={imageFileHandler}
                                                   ref={hiddenFileInput}
                                                   style={{display: 'none'}}
                                            />
                                        </Col>
                                        <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                            <div className="download-icon" onClick={() => downloadFile(fieldData.dataUrl)}>
                                                <IoCloudDownloadOutline />
                                            </div>
                                        </Col>
                                    </Row>
                                    {(
                                            !fieldIsValid.isCertificateNameValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isCertificateTypeValid
                                            || !fieldIsValid.isStatusValid
                                            || !fieldIsValid.isToDateValid
                                            || !fieldIsValid.isFromDateValid
                                            || !fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button disabled={true}
                                                        className="button-solid button-transparent-inactive">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isCertificateNameValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isCertificateTypeValid
                                            && fieldIsValid.isStatusValid
                                            && fieldIsValid.isToDateValid
                                            && fieldIsValid.isFromDateValid
                                            && fieldIsValid.isFileValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={openLink}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditCertificate;