import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Col, Row, Card, Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import Sidebar from '../components/Sidebar'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import Metrics from "../components/Metrics";

import Cards from "../components/Cards";

import user_tick from "../assets/images/user-tick.png"
import on_boarding from "../assets/images/onboarding.png"
import red_loa from "../assets/images/red_loa.png"
import user_search from "../assets/images/user-search.svg"
import ranking from "../assets/images/icons/ranking.svg"
import receipt from "../assets/images/icons/receipt-edit.svg"
import cake from "../assets/images/icons/cake.svg"
import dollar from "../assets/images/icons/dollar-circle.svg"
import {getDashboardData} from "../actions/ConfigActions";
import Loader from "../components/Loader";
import {decoded} from "../components/PrivateRoute";
import {jwtDecode} from "jwt-decode";


function Dashboard(props) {
    const pageName = 'dashboard';
    const dispatch = useDispatch()
    const history = useNavigate()
    const [accessLevel, setAccessLevel] = useState({
        access: false,
        isPayrollEnable: false,
        isLettersEnable: false,
        isDocumentsEnable: false,
        isProcessesEnable: false,
    })
    useEffect(() => {
        if (!localStorage.getItem("client_id")) {
            const decoded = jwtDecode(localStorage.getItem("access_token"));
            const user = decoded.IsSuperAdmin
            if (user === "True") {
               history("super-admin-management")
            } else {
                history('/client')
            }
        }
        setAccessLevel({
            ...accessLevel,
            access: decoded.RoleAccessLevels,
            isPayrollEnable: decoded.IsPayrollEnable,
            isLettersEnable: decoded.IsLettersEnable,
            isDocumentsEnable: decoded.IsDocumentsEnable,
            isProcessesEnable: decoded.IsProcessesEnable,
        })
        dispatch(getDashboardData())
    }, []);

    const getDashboardDataDetails = useSelector(state => state.getDashboardData)
    const {error, loading, dashboard_data} = getDashboardDataDetails

    const landingPage = (data) => {
        history(`/landing-page/${data}`)
    }

    return (
        <div>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    {getDashboardDataDetails.dashboard_data && <Row
                        className={accessLevel.access != 0 ? "app-body background-1" : "app-body-teacher background-1"}>
                        {accessLevel.access != 0 && <Row className="background-1">
                            <Col lg={{span: 3, offset: 0}} md={{span: 4, offset: 0}} sm={{span: 6, offset: 0}}>
                                <div onClick={() => landingPage(5)}>
                                    <Metrics className="card-body-metrics card-blue"
                                             image={user_tick}
                                             header="Active Employees"
                                             body={getDashboardDataDetails.dashboard_data.activeAssociates}
                                    />
                                </div>
                            </Col>
                            <Col lg={{span: 3, offset: 0}} md={{span: 4, offset: 0}} sm={{span: 6, offset: 0}}>
                                <div onClick={() => landingPage(6)}>
                                    <Metrics className="card-body-metrics card-red"
                                             image={red_loa}
                                             header="On LOA"
                                             body={getDashboardDataDetails.dashboard_data.onLOA}/>
                                </div>
                            </Col>
                            <Col lg={{span: 3, offset: 0}} md={{span: 4, offset: 0}} sm={{span: 6, offset: 0}}>
                                <div onClick={() => landingPage(7)}>
                                    <Metrics className="card-body-metrics card-blue"
                                             image={on_boarding}
                                             header="In Onboarding"
                                             body={getDashboardDataDetails.dashboard_data.inOnboarding}/>
                                </div>
                            </Col>
                            <Col lg={{span: 3, offset: 0}} md={{span: 4, offset: 0}} sm={{span: 6, offset: 0}}>
                                <div onClick={() => landingPage(8)}>
                                    <Metrics className="card-body-metrics card-red"
                                             image={user_search}
                                             header="In Enrollment"
                                             body={getDashboardDataDetails.dashboard_data.inEnrollement}/>
                                </div>
                            </Col>
                        </Row>}
                        <Row className={accessLevel.access != 0 ? "background-1" : "background-1 mt-3"}>
                            {getDashboardDataDetails.dashboard_data.upcomingSchoolEvents &&
                                <Col lg={{span: 6, offset: 0}} md={{span: 12, offset: 0}}>
                                    <Cards classname="dashboard-card" image={ranking} header="Upcoming School Events"
                                           type={1} data={getDashboardDataDetails.dashboard_data.upcomingSchoolEvents}/>
                                </Col>}
                            {accessLevel.isProcessesEnable && getDashboardDataDetails.dashboard_data.upcomingTasks &&
                                <Col lg={{span: 6, offset: 0}} md={{span: 12, offset: 0}}>
                                    <Cards classname="dashboard-card" image={receipt} header="Assigned Tasks"
                                           type={2} data={getDashboardDataDetails.dashboard_data.upcomingTasks}/>
                                </Col>}
                            {accessLevel.access != 0 && <Col lg={{span: 6, offset: 0}} md={{span: 12, offset: 0}}>
                                {getDashboardDataDetails.dashboard_data.upcomingBAEmployeeList &&
                                    <Cards classname="dashboard-card" image={cake} header="Birthdays and Anniversaries"
                                           type={3}
                                           data={getDashboardDataDetails.dashboard_data.upcomingBAEmployeeList}/>}
                            </Col>}
                            {accessLevel.access != 0 && accessLevel.isPayrollEnable && getDashboardDataDetails.dashboard_data.payrollHistory &&
                                <Col lg={{span: 6, offset: 0}} md={{span: 12, offset: 0}}>
                                    <Cards classname="dashboard-card" image={dollar} header="Payroll History"
                                           type={4} data={getDashboardDataDetails.dashboard_data.payrollHistory}/>
                                </Col>}
                        </Row>
                        <Footer/>
                    </Row>}
                </div>

            </Row>
        </div>
    );
}

export default Dashboard;