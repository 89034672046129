import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import {useNavigate} from "react-router-dom";
import link from "../assets/images/icons/link.svg";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {useSelector, useDispatch} from "react-redux";
import {addSchoolYear, getAllSchoolYear, resetAddSchoolYear} from "../actions/CalendarActions";

const CreateSchoolYear = (props) => {
    const history = useNavigate();
    const titleRef = useRef();
    const linkRef = useRef();
    const [alertModal, setAlertModal] = useState(false)
    const [yearList, setYearList] = useState([])
    const dispatch = useDispatch();

    const addSchoolYearDetails = useSelector(state => state.addSchoolYear)
    const {error, loading, schoolYearInfo} = addSchoolYearDetails

    const getAllSchoolYearDetails = useSelector(state => state.getAllSchoolYear)
    const {errorAllYear, loadingAllYear} = getAllSchoolYearDetails

    useEffect(() => {
        dispatch(getAllSchoolYear())
    }, []);

    useEffect(() => {
        if (!getAllSchoolYearDetails.school_year_list <= 0) {
            setYearList([])
            console.log(getAllSchoolYearDetails.school_year_list)
            getAllSchoolYearDetails.school_year_list.map((item, index) => {
                setYearList((prevData) => [
                    ...prevData,
                    {
                        id: item.yearId,
                        data: `${formatDateYear(item.fromDate)} - ${formatDateYear(item.toDate)}`,
                    }
                ])
            });
            // console.log("",yearList)
        }
    }, [getAllSchoolYearDetails.school_year_list]);

    useEffect(() => {
        if (addSchoolYearDetails.school_year === true) {
            dispatch(resetAddSchoolYear())
            history('/calendar',{state:{success: "1"}})
        } if (addSchoolYearDetails.error) {
            setAlertModal(true)
        }
    }, [addSchoolYearDetails]);

    const [fieldIsValid, setFieldIsValid] = useState({
        isYearValid: false,
        isToDateValid: false,
        isFromDateValid: false,
        isLinkValid: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        yearErrorMessage: "",
        toDateErrorMessage: "",
        fromDateErrorMessage: "",
        linkErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        dataYear: null,
        dataToDate: null,
        dataFromDate: null,
        dataLink: "www.",
    });

    const titleKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,1000}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isYearValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                yearErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isYearValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                yearErrorMessage: ""
            });

            setFieldData({
                ...fieldData,
                dataLink: titleRef.current.value
            });
        }
    }

    const linkKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,1000}$/.test(linkRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isLinkValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                linkErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isLinkValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                linkErrorMessage: ""
            });

            setFieldData({
                ...fieldData,
                dataLink: linkRef.current.value
            });
        }
    }

    const openLink=() => {
        history('/calendar')
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(addSchoolYear(titleRef.current.value, formatDate(fieldData.dataFromDate), formatDate(fieldData.dataToDate), linkRef.current.value));
    }
    const onReset=(e) => {
        e.preventDefault();
        titleRef.current.value = ""
        linkRef.current.value = "www."
        setFieldData({
            ...fieldData,
            dataToDate: null,
            dataFromDate: null,
            dataLink: null,
        })
        setFieldIsValid({
            isYearValid: false,
            isToDateValid: false,
            isFromDateValid: false,
            isLinkValid: false,
        })
    }
    const yearData=(data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isYearValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            yearErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataYear: data.id,
        })
    }
    const fromData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isFromDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            fromDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataFromDate: data
        })
    }
    const toData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isToDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            toDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataToDate: data
        })
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function formatDateYear(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        return `${year}`;
    }
    const closeAlert = (data) => {
        setAlertModal(false)
        dispatch(resetAddSchoolYear())
    }
    const pageName = 'calendar';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal &&
                    <AlertModal heading="School Year couldn't be added" type="error" result={closeAlert}/>}
                {loading && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_school_year" title="Create New School Year" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    School Year<span className="asterics">*</span></label>
                                                <input ref={titleRef} onChange={titleKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={fieldData.dataYear}
                                                       className={!fieldIsValid.isYearValid ? "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter School Year"
                                                       id="document_title"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    From<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select From"
                                                                            value={fieldData.dataFromDate}
                                                                            result={fromData}
                                                                            className={!fieldIsValid.isFromDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label form-spacing-calendar">
                                                    To<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select To"
                                                                            value={fieldData.dataToDate}
                                                                            result={toData}
                                                                            className={!fieldIsValid.isToDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-right-unset padding-left-unset display-none">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="certificateNameLabel"
                                                       className="form-label">
                                                    Link</label>
                                                <img src={link} alt="icon user-icon" className="form-icon"/>
                                                <input ref={linkRef} onChange={linkKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={fieldData.dataLink}
                                                       className={!fieldIsValid.isLinkValid ? "input-error-form" : "input-valid-form"}
                                                       // className={!fieldIsValid.isLinkValid ?
                                                       //     "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Certificate Type"
                                                       id="document_title"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {(
                                            !fieldIsValid.isYearValid
                                            || !fieldIsValid.isToDateValid
                                            || !fieldIsValid.isFromDateValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isYearValid
                                            && fieldIsValid.isToDateValid
                                            && fieldIsValid.isFromDateValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={submitHandler}
                                                >
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default CreateSchoolYear;