import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import document_type from "../assets/json/process_type.json";
import status_type from "../assets/json/admin_status.json";
import {useNavigate, useParams} from "react-router-dom";
import AddCircle from "../components/Icons/AddCircle";
import DynamicProcessTemplate from "../components/DynamicProcessTemplate";
import DeleteModal from "../components/DeleteModal";
import AlertModal from "../components/AlertModal";
import {
    editProcessTemplate,
    getProcessTemplateById,
    resetEditProcessTemplate, resetGetProcessTemplateById
} from "../actions/ProcessActions";
import Loader from "../components/Loader";

const EditProcessTemplate = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const titleRef = useRef();
    const typeRef = useRef();
    const [tasks, setTasks] = useState([]);
    const [count, setCount] = useState(1);
    const [alert, showAlert] = useState({
        display: false,
        data: null
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [showDelete, setShowDelete] = useState({
        display: false,
        data: null
    })

    const [fieldIsValid, setFieldIsValid] = useState({
        isProcessTypeValid: true,
        isProcessTitleValid: true,
        isActiveValid: true,
        isTaskValid: true,
    });

    const [fieldData, setFieldData] = useState({
        processTypeData: "",
        processNameData: "",
        activeData: "",
        taskData: ""
    });

    const getProcessTemplateDetails = useSelector(state => state.getProcessTemplateById)
    const {error, loading, documentInfo} = getProcessTemplateDetails

    useEffect(() => {
        if (!getProcessTemplateDetails.process_template_data <= 0) {
            setFieldData({
                ...fieldData,
                processTypeData: getProcessTemplateDetails.process_template_data.typeId,
                processNameData: getProcessTemplateDetails.process_template_data.title,
                activeData: getProcessTemplateDetails.process_template_data.isActive,
            })
            // setTasks(getProcessTemplateDetails.process_template_data.processTemplateTasks)
            if (!getProcessTemplateDetails.process_template_data.processTemplateTasks <= 0) {
                getProcessTemplateDetails.process_template_data.processTemplateTasks.map((item, index) => {
                    setTasks((prevData) => [
                        ...prevData,
                        {
                            id: index+1,
                            processTemplateTasksId: item.processTemplateTasksId,
                            processTemplateId: item.processTemplateId,
                            templateType: item.templateType,
                            title: item.title,
                            assignToId: item.assignToId,
                            notes: item.notes,
                        }
                    ])
                });
                setCount(getProcessTemplateDetails.process_template_data.processTemplateTasks.length + 1)
            }
        }
    }, [getProcessTemplateDetails]);

    const editProcessTemplateDetails = useSelector(state => state.editProcessTemplate)
    const {errorEdit, loadingEdit, processTemplateEdit} = editProcessTemplateDetails

    useEffect(() => {
        if (editProcessTemplateDetails.process_template === true) {
            dispatch(resetEditProcessTemplate())
            setAlertModal({
                display: true,
                type: "success",
                message: "Process Template updated successfully"
            })
            // history('/documents',{state:{success: "1"}})
        } if (editProcessTemplateDetails.error) {
            dispatch(resetGetProcessTemplateById())
            setAlertModal({
                display: true,
                type: "error",
                message: "Process Template couldn't be updated"
            })
        }
    }, [editProcessTemplateDetails]);

    const getProcessTemplate = () => {
        dispatch(getProcessTemplateById(params.id))
    }

    useEffect(() => {
        getProcessTemplate()
    }, []);

    const titleKeyPress = (event) => {
        event.preventDefault();
        console.log(titleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTitleValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTitleValid: true,
            });
            setFieldData({
                ...fieldData,
                processNameData: titleRef.current.value
            })
        }
    }
    const typeKeyPress = (event) => {
        event.preventDefault();

        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(typeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTypeValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isProcessTypeValid: true,
            });
            setFieldData({
                ...fieldData,
                processTypeData: titleRef.current.value
            })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(editProcessTemplate(typeRef.current.value, fieldData.processNameData, fieldData.activeData, tasks, params.id))
    }
    const openLink = () => {
        history('/processes')
    }
    const onReset = () => {
        setTasks([])
        setCount(1)
        dispatch(getProcessTemplateById(params.id))
    }
    const documentData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isProcessTypeValid: true,
        });
        setFieldData({
            ...fieldData,
            processTypeData: data.id
        })
    }
    const activeData = (data) => {
        console.log("Active", data.id)
        if (data.id == 0) {
            showAlert({
                display: true,
                data: data.id
            })
        }
        if (data.id == 1) {
            setFieldData({
                ...fieldData,
                activeData: data.id == 1 ? true : false
            })
        }
    }
    const pageName = 'processes';

    useEffect(() => {
        console.log(tasks)
        if (tasks) {
            if (tasks.some(item => item.title === '')) {
                setFieldIsValid({
                    ...fieldIsValid,
                    isTaskValid: false,
                });
            }
            else if (tasks.some(item => item.assignToId === '')) {
                setFieldIsValid({
                    ...fieldIsValid,
                    isTaskValid: false,
                });
            }
            else if (tasks.some(item => item.notes === '')) {
                setFieldIsValid({
                    ...fieldIsValid,
                    isTaskValid: false,
                });
            } else {
                setFieldIsValid({
                    ...fieldIsValid,
                    isTaskValid: true,
                });
            }
        }
    }, [tasks]);

    const addTask = () => {
        console.log(count)
        setCount(count + 1)
        const newTask = {
            id: count, // Simple unique ID for demo purposes
            title: "",
            assignToId: "",
            notes: "",
        };

        setTasks([...tasks, newTask]);

    };
    const deleteTask = (data) => {
        // type value task
        if (data[0] === "0") {
            setShowDelete({
                display: true,
                data: data[1]
            })
        }
        if (data[0] === "1") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, title: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "2") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, assignToId: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
        if (data[0] === "3") {
            const updatedTasks = tasks.map(task => {
                if (task.id === data[2]) {
                    return {...task, notes: data[1]};
                }
                return task;
            });
            setTasks(updatedTasks);
        }
    };
    const importResult = (data) => {
        showAlert({
            display: false
        })
        setFieldData({
            ...fieldData,
            activeData: alert.data == 1 ? true : false
        })
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        history('/processes')
    }
    const hideDeleteModal = (data) => {
        if (data === 1) {
            setCount(count - 1)
            setTasks(tasks.filter(task => task.id !== showDelete.data));
        }
        setShowDelete({
            display: false
        })
    }
    return (
        <>
            <Row className="sidebar-background">
                {alert.display && <DeleteModal heading="Process Status - Inactive"
                                       type="import"
                                       button_true="Yes"
                                       button_false="No"
                                       question="You are about to inactivate this template; it will no longer be available for use. Do you wish to continue?"
                                       answer=""
                                       result={importResult}
                />}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type}
                                result={closeAlert}/>}
                {showDelete.display && <DeleteModal heading="Delete Task"
                                                    type="delete"
                                                    question="Are you sure want to delete this Task?"
                                                    answer=""
                                                    result={hideDeleteModal}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Update Process Template" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getProcessTemplateDetails.process_template_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Process Type<span className="asterics">*</span></label>
                                                <input ref={typeRef}
                                                       onChange={typeKeyPress}
                                                       defaultValue={getProcessTemplateDetails.process_template_data.templateType}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isProcessTypeValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Process Type"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Process Name<span className="asterics">*</span></label>
                                                <input ref={titleRef} onChange={titleKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getProcessTemplateDetails.process_template_data.title}
                                                       className={!fieldIsValid.isProcessTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Process Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1 border-bottom-div pb-4">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Process Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={status_type} result={activeData}
                                                                        value={getProcessTemplateDetails.process_template_data.isActive == true ? 1 : 0}
                                                                        className={"background-1 dropdown-input-content-valid"}
                                                                        title="Select Process Type"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {tasks && <div>
                                        {
                                            tasks.map((task, index) => (
                                                <DynamicProcessTemplate key={task.processTemplateTasksId} task={index+1}
                                                                        task_name={task.title}
                                                                        assign={task.assignToId}
                                                                        note={task.notes}
                                                                        count={tasks.length}
                                                                        result={deleteTask}/>
                                            ))
                                        }
                                    </div>}

                                    <Row className="background-1 mb-4 mt-4">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset" onClick={addTask}>
                                            <AddCircle className="sidebar-icon mb-1"
                                                       color="var(--red)"/> <span
                                            className="description-data button-cursor">Add New Task</span>
                                        </Col>
                                    </Row>

                                    {(
                                            !fieldIsValid.isProcessTypeValid
                                            || !fieldIsValid.isProcessTitleValid
                                            || !fieldIsValid.isTaskValid
                                            || !fieldIsValid.isActiveValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isProcessTypeValid
                                            && fieldIsValid.isProcessTitleValid
                                            && fieldIsValid.isTaskValid
                                            && fieldIsValid.isActiveValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={submitHandler}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditProcessTemplate;