import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2395 22.27H15.1095C10.6695 22.27 8.52953 20.52 8.15953 16.6C8.11953 16.19 8.41953 15.82 8.83953 15.78C9.23953 15.74 9.61953 16.05 9.65953 16.46C9.94953 19.6 11.4295 20.77 15.1195 20.77H15.2495C19.3195 20.77 20.7595 19.33 20.7595 15.26V8.73998C20.7595 4.66998 19.3195 3.22998 15.2495 3.22998H15.1195C11.4095 3.22998 9.92953 4.41998 9.65953 7.61998C9.60953 8.02998 9.25953 8.33998 8.83953 8.29998C8.41953 8.26998 8.11953 7.89998 8.14953 7.48998C8.48953 3.50998 10.6395 1.72998 15.1095 1.72998H15.2395C20.1495 1.72998 22.2495 3.82998 22.2495 8.73998V15.26C22.2495 20.17 20.1495 22.27 15.2395 22.27Z"
                fill={props.color}/>
            <path
                d="M15.0001 12.75H3.62012C3.21012 12.75 2.87012 12.41 2.87012 12C2.87012 11.59 3.21012 11.25 3.62012 11.25H15.0001C15.4101 11.25 15.7501 11.59 15.7501 12C15.7501 12.41 15.4101 12.75 15.0001 12.75Z"
                fill={props.color}/>
            <path
                d="M5.85043 16.0998C5.66043 16.0998 5.47043 16.0298 5.32043 15.8798L1.97043 12.5298C1.68043 12.2398 1.68043 11.7598 1.97043 11.4698L5.32043 8.11984C5.61043 7.82984 6.09043 7.82984 6.38043 8.11984C6.67043 8.40984 6.67043 8.88984 6.38043 9.17984L3.56043 11.9998L6.38043 14.8198C6.67043 15.1098 6.67043 15.5898 6.38043 15.8798C6.24043 16.0298 6.04043 16.0998 5.85043 16.0998Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;