import React from 'react';

function Footer(props) {
    return (
        <div className="page-footer">
            <div className="page-inner-footer footer-text">
                <span>© 2023 </span><span className="footer-inner-text-1">Extensis</span>
                <span className="footer-inner-text-2">HR </span><span> All Rights Reserved</span>
            </div>
        </div>
    );
}

export default Footer;
