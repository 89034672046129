import api from './api';
import {
    ADD_LETTERS_TEMPLATE_SUCCESS,
    ADD_LETTERS_TEMPLATE_REQUEST,
    ADD_LETTERS_TEMPLATE_FAIL,
    ADD_LETTERS_TEMPLATE_RESET,

    EDIT_LETTERS_TEMPLATE_SUCCESS,
    EDIT_LETTERS_TEMPLATE_REQUEST,
    EDIT_LETTERS_TEMPLATE_FAIL,
    EDIT_LETTERS_TEMPLATE_RESET,

    GET_LETTERS_TEMPLATE_BY_ID_SUCCESS,
    GET_LETTERS_TEMPLATE_BY_ID_REQUEST,
    GET_LETTERS_TEMPLATE_BY_ID_FAIL,
    GET_LETTERS_TEMPLATE_BY_ID_RESET,

    GET_ALL_LETTERS_TEMPLATES_REQUEST,
    GET_ALL_LETTERS_TEMPLATES_SUCCESS,
    GET_ALL_LETTERS_TEMPLATES_FAIL,
    GET_ALL_LETTERS_TEMPLATES_RESET,

    GET_LETTERS_TEMPLATE_COLUMNS_REQUEST,
    GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS,
    GET_LETTERS_TEMPLATE_COLUMNS_FAIL,
    GET_LETTERS_TEMPLATE_COLUMNS_RESET,

    SEND_LETTERS_NOTICES_REQUEST,
    SEND_LETTERS_NOTICES_SUCCESS,
    SEND_LETTERS_NOTICES_FAIL,
    SEND_LETTERS_NOTICES_RESET,

    GET_LETTERS_NOTICES_BY_ID_REQUEST,
    GET_LETTERS_NOTICES_BY_ID_SUCCESS,
    GET_LETTERS_NOTICES_BY_ID_FAIL,
    GET_LETTERS_NOTICES_BY_ID_RESET,

    GET_ALL_LETTERS_NOTICES_REQUEST,
    GET_ALL_LETTERS_NOTICES_SUCCESS,
    GET_ALL_LETTERS_NOTICES_FAIL,
    GET_ALL_LETTERS_NOTICES_RESET

} from "../constants/LetterNoticesConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addLettersTemplate = (letterTemplateId, title, descriptions, documentTypeId, documentText, isHardcopyRequired) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_LETTERS_TEMPLATE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/LetterTemplate/CreateLetterTemplate`,
            {
                title: title,
                descriptions: descriptions,
                documentTypeId: documentTypeId,
                documentText: documentText,
                isHardcopyRequired: isHardcopyRequired,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_LETTERS_TEMPLATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_LETTERS_TEMPLATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_LETTERS_TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editLettersTemplate = (letterTemplateId, title, descriptions, documentText, isHardcopyRequired, isActive) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_LETTERS_TEMPLATE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/LetterTemplate/UpdateLetterTemplate`,
            {
                letterTemplateId: letterTemplateId,
                title: title,
                descriptions: descriptions,
                documentText: documentText,
                isHardcopyRequired: isHardcopyRequired,
                isActive: isActive
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_LETTERS_TEMPLATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_LETTERS_TEMPLATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_LETTERS_TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getLettersTemplateById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_LETTERS_TEMPLATE_BY_ID_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterTemplate/GetLetterTemplateById?LetterTemplateId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_LETTERS_TEMPLATE_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_LETTERS_TEMPLATE_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_LETTERS_TEMPLATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllLetterTemplate = (pageSize, pageNumber) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_LETTERS_TEMPLATES_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterTemplate/GetAllLetterTemplate?PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_LETTERS_TEMPLATES_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_LETTERS_TEMPLATES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_LETTERS_TEMPLATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getTemplateColumns = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_LETTERS_TEMPLATE_COLUMNS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterTemplate/GetLetterTemplateByIdWithColumn?LetterTemplateId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_LETTERS_TEMPLATE_COLUMNS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_LETTERS_TEMPLATE_COLUMNS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// Letters Notices
export const sendLettersNotices = (letterTemplateId, documentText, sentToList) => async (dispatch, getState) => {
    try {
        dispatch({type: SEND_LETTERS_NOTICES_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/LetterNotice/SendLetterNotice`,
            {
                letterTemplateId: letterTemplateId,
                documentText: documentText,
                sentToList: sentToList,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: SEND_LETTERS_NOTICES_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: SEND_LETTERS_NOTICES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_LETTERS_TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getLettersNoticesById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_LETTERS_NOTICES_BY_ID_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterNotice/GetLetterNoticeById?LetterNoticeId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_LETTERS_NOTICES_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_LETTERS_NOTICES_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_LETTERS_NOTICES_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllLetterNotices = (pageSize, pageNumber, templates, fromDate, toDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_LETTERS_NOTICES_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }
        var templatesString = templates == undefined || null ? '' : templates
        var fromDateString = fromDate === "NaN-NaN-NaN" ? '' : fromDate
        var toDateString = toDate === "NaN-NaN-NaN" ? '' : toDate

        const {data} = await api.get(`${url}/LetterNotice/GetAllLetterNotice?PageNumber=${pageNumber}&PageSize=${pageSize}&LetterTemplateId=${templatesString}&StartDate=${fromDateString}&EndDate=${toDateString}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_LETTERS_NOTICES_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_LETTERS_NOTICES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_LETTERS_NOTICES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// Reset
export const resetAddLettersTemplate = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_LETTERS_TEMPLATE_RESET,
    })
};
export const resetEditLettersTemplate = () => async  (dispatch, getState) => {
    dispatch({
        type: EDIT_LETTERS_TEMPLATE_RESET,
    })
};
export const resetGetLettersTemplateById  = () => async   (dispatch, getState) => {
    dispatch({
        type: GET_LETTERS_TEMPLATE_BY_ID_RESET,
    })
};
export const resetGetAllLettersTemplate = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_LETTERS_TEMPLATES_RESET,
    })
};
export const resetSendLettersNotices = () => (dispatch, getState) => {
    dispatch({
        type: SEND_LETTERS_NOTICES_RESET,
    })
};
export const resetGetLettersNoticesById = () => (dispatch, getState) => {
    dispatch({
        type: GET_LETTERS_NOTICES_BY_ID_RESET,
    })
};
export const resetGetAllLettersNotices = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_LETTERS_NOTICES_RESET,
    })
};