import {
    ADD_PROCESS_TEMPLATE_SUCCESS,
    ADD_PROCESS_TEMPLATE_REQUEST,
    ADD_PROCESS_TEMPLATE_FAIL,
    ADD_PROCESS_TEMPLATE_RESET,

    EDIT_PROCESS_TEMPLATE_SUCCESS,
    EDIT_PROCESS_TEMPLATE_REQUEST,
    EDIT_PROCESS_TEMPLATE_FAIL,
    EDIT_PROCESS_TEMPLATE_RESET,

    GET_PROCESS_TEMPLATE_BY_ID_SUCCESS,
    GET_PROCESS_TEMPLATE_BY_ID_REQUEST,
    GET_PROCESS_TEMPLATE_BY_ID_FAIL,
    GET_PROCESS_TEMPLATE_BY_ID_RESET,

    GET_ALL_PROCESS_TEMPLATES_REQUEST,
    GET_ALL_PROCESS_TEMPLATES_SUCCESS,
    GET_ALL_PROCESS_TEMPLATES_FAIL,
    GET_ALL_PROCESS_TEMPLATES_RESET,

    ADD_PROCESS_SUCCESS,
    ADD_PROCESS_REQUEST,
    ADD_PROCESS_FAIL,
    ADD_PROCESS_RESET,

    GET_PROCESS_BY_ID_SUCCESS,
    GET_PROCESS_BY_ID_REQUEST,
    GET_PROCESS_BY_ID_FAIL,
    GET_PROCESS_BY_ID_RESET,

    GET_TASK_BY_PROCESS_ID_REQUEST,
    GET_TASK_BY_PROCESS_ID_SUCCESS,
    GET_TASK_BY_PROCESS_ID_FAIL,
    GET_TASK_BY_PROCESS_ID_RESET,

    GET_ALL_PROCESS_REQUEST,
    GET_ALL_PROCESS_SUCCESS,
    GET_ALL_PROCESS_FAIL,
    GET_ALL_PROCESS_RESET,

    EDIT_TASK_BY_ID_REQUEST,
    EDIT_TASK_BY_ID_SUCCESS,
    EDIT_TASK_BY_ID_FAIL,
    EDIT_TASK_BY_ID_RESET,

    COMPLETE_ALL_TASK_REQUEST,
    COMPLETE_ALL_TASK_SUCCESS,
    COMPLETE_ALL_TASK_FAIL,
    COMPLETE_ALL_TASK_RESET,

    CANCEL_ALL_TASK_REQUEST,
    CANCEL_ALL_TASK_SUCCESS,
    CANCEL_ALL_TASK_FAIL,
    CANCEL_ALL_TASK_RESET,

    GET_UPCOMING_TASK_REQUEST,
    GET_UPCOMING_TASK_SUCCESS,
    GET_UPCOMING_TASK_FAIL,
    GET_UPCOMING_TASK_RESET,

    SEND_NUDGE_REQUEST,
    SEND_NUDGE_SUCCESS,
    SEND_NUDGE_FAIL,
    SEND_NUDGE_RESET,

    MARK_TASK_AS_COMPLETE_REQUEST,
    MARK_TASK_AS_COMPLETE_SUCCESS,
    MARK_TASK_AS_COMPLETE_FAIL,
    MARK_TASK_AS_COMPLETE_RESET

} from "../constants/ProcessConstants"

const initialState = {};
export const addProcessTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_PROCESS_TEMPLATE_REQUEST:
            return { loading: true }

        case ADD_PROCESS_TEMPLATE_SUCCESS:
            return {
                loading: false,
                process_template: action.payload,
            }

        case ADD_PROCESS_TEMPLATE_FAIL:
            return { loading: false, error: action.payload }

        case ADD_PROCESS_TEMPLATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const editProcessTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_PROCESS_TEMPLATE_REQUEST:
            return { loadingEdit: true }

        case EDIT_PROCESS_TEMPLATE_SUCCESS:
            return {
                loadingEdit: false,
                process_template: action.payload,
            }

        case EDIT_PROCESS_TEMPLATE_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_PROCESS_TEMPLATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const getProcessTemplateByIdReducer = (state = {process_template_data: []}, action) => {
    switch (action.type) {
        case GET_PROCESS_TEMPLATE_BY_ID_REQUEST:
            return { loading: true }

        case GET_PROCESS_TEMPLATE_BY_ID_SUCCESS:
            return {
                loading: false,
                process_template_data: action.payload,
            }

        case GET_PROCESS_TEMPLATE_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_PROCESS_TEMPLATE_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getTaskProcessByIdReducer = (state = {process_task_data: []}, action) => {
    switch (action.type) {
        case GET_TASK_BY_PROCESS_ID_REQUEST:
            return { loadingTask: true }

        case GET_TASK_BY_PROCESS_ID_SUCCESS:
            return {
                loadingTask: false,
                process_task_data: action.payload,
            }

        case GET_TASK_BY_PROCESS_ID_FAIL:
            return { loadingTask: false, errorTask: action.payload }

        case GET_TASK_BY_PROCESS_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllProcessTemplateReducer = (state = {process_template_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_PROCESS_TEMPLATES_REQUEST:
            return { loading: true }

        case GET_ALL_PROCESS_TEMPLATES_SUCCESS:
            return {
                loading: false,
                process_template_list: action.payload,
                records: action.records
            }

        case GET_ALL_PROCESS_TEMPLATES_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_PROCESS_TEMPLATES_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllProcessesReducer = (state = {process_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_PROCESS_REQUEST:
            return { loadingProcess: true }

        case GET_ALL_PROCESS_SUCCESS:
            return {
                loadingProcess: false,
                process_list: action.payload,
                records: action.records
            }

        case GET_ALL_PROCESS_FAIL:
            return { loadingProcess: false, errorProcess: action.payload }

        case GET_ALL_PROCESS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getProcessByIdReducer = (state = {process_data: [], process_task:[]}, action) => {
    switch (action.type) {
        case GET_PROCESS_BY_ID_REQUEST:
            return { loading: true }

        case GET_PROCESS_BY_ID_SUCCESS:
            return {
                loading: false,
                process_data: action.payload,
                process_task: action.payload.processTasks,
            }

        case GET_PROCESS_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_PROCESS_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const addProcessReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_PROCESS_REQUEST:
            return { loadingAdd: true }

        case ADD_PROCESS_SUCCESS:
            return {
                loadingAdd: false,
                processesAdd: action.payload,
            }

        case ADD_PROCESS_FAIL:
            return { loadingAdd: false, errorAdd: action.payload }

        case ADD_PROCESS_RESET:
            return initialState;

        default:
            return state
    }
}
export const editTaskByIdReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_TASK_BY_ID_REQUEST:
            return { loadingEdit: true }

        case EDIT_TASK_BY_ID_SUCCESS:
            return {
                loadingEdit: false,
                taskEdit: action.payload,
            }

        case EDIT_TASK_BY_ID_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_TASK_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const completeAllTaskReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPLETE_ALL_TASK_REQUEST:
            return { loadingComplete: true }

        case COMPLETE_ALL_TASK_SUCCESS:
            return {
                loadingComplete: false,
                taskComplete: action.payload,
            }

        case COMPLETE_ALL_TASK_FAIL:
            return { loadingComplete: false, errorComplete: action.payload }

        case COMPLETE_ALL_TASK_RESET:
            return initialState;

        default:
            return state
    }
}
export const cancelAllTaskReducer = (state = {}, action) => {
    switch (action.type) {
        case CANCEL_ALL_TASK_REQUEST:
            return { loadingCancel: true }

        case CANCEL_ALL_TASK_SUCCESS:
            return {
                loadingCancel: false,
                taskCancel: action.payload,
            }

        case CANCEL_ALL_TASK_FAIL:
            return { loadingCancel: false, errorCancel: action.payload }

        case CANCEL_ALL_TASK_RESET:
            return initialState;

        default:
            return state
    }
}
export const getUpcomingTaskReducer = (state = {upcoming_task_list: [], records: ""}, action) => {
    switch (action.type) {
        case GET_UPCOMING_TASK_REQUEST:
            return { loadingUpcoming: true }

        case GET_UPCOMING_TASK_SUCCESS:
            return {
                loadingUpcoming: false,
                upcoming_task_list: action.payload,
                records: action.records
            }

        case GET_UPCOMING_TASK_FAIL:
            return { loadingUpcoming: false, errorUpcoming: action.payload }

        case GET_UPCOMING_TASK_RESET:
            return initialState;

        default:
            return state
    }
}
export const sendTaskNudgeReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_NUDGE_REQUEST:
            return { loadingNudge: true }

        case SEND_NUDGE_SUCCESS:
            return {
                loadingNudge: false,
                nudge_data: action.payload,
            }

        case SEND_NUDGE_FAIL:
            return { loadingNudge: false, errorNudge: action.payload }

        case SEND_NUDGE_RESET:
            return initialState;

        default:
            return state
    }
}
export const markTaskAsCompleterReducer = (state = {}, action) => {
    switch (action.type) {
        case MARK_TASK_AS_COMPLETE_REQUEST:
            return { loading: true }

        case MARK_TASK_AS_COMPLETE_SUCCESS:
            return {
                loading: false,
                mark_task: action.payload,
            }

        case MARK_TASK_AS_COMPLETE_FAIL:
            return { loading: false, error: action.payload }

        case MARK_TASK_AS_COMPLETE_RESET:
            return initialState;

        default:
            return state
    }
}