import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../actions/LoginActions";

function ErrorPage() {
    const history = useNavigate()
    const dispatch = useDispatch()

    const redirect = (event) => {
        event.preventDefault();
        history(-1)
    };
    const signOut = (event) => {
        event.preventDefault();
        dispatch(logout())
    };

    return (
        <Row className="login-background">
            <div id="main">
                <div className="fof">
                    <h1>Unauthorized Access</h1>
                    <div className="button-cursor">
                        <span className="span-error-page" onClick={redirect}>Go Back</span> OR <span className="span-error-page" onClick={signOut}>Go to Sign In</span>
                    </div>
                </div>
            </div>
        </Row>
    );
}

export default ErrorPage;