import React, {useEffect, useState} from 'react';
import "../assets/css/calendar.css"
import "../datatable/css/jquery.dataTables.css"
import {useSelector, useDispatch} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Col, Row, Card} from "react-bootstrap";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import {useLocation, useNavigate} from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import PencilEdit from "../components/Icons/PencilEdit";
import DocumentUpload from "../components/Icons/DocumentUpload";
import DeleteModal from "../components/DeleteModal";
import FormModal1 from "../components/FormModal1";
import UploadModal from "../components/UploadModal";
import AlertModal from "../components/AlertModal";
import {
    getAllSchoolYear,
    getAllSchoolYearExist, getSchoolYearById,
    resetAddSchoolYear, resetDeleteSchoolYear,
    resetUploadCalendarEvents,
    uploadEvent
} from "../actions/CalendarActions";
import {
    addEvents,
    deleteEvent, editEvents,
    getAllEvent, getEventsBySingleDate, resetEditEvents,
} from "../actions/EventActions";
import Loader from "../components/Loader";
import DropdownItemsInput from "../components/DropdownItemsInput";
import DocumentDownload from "../components/Icons/DocumentDownload";
import {decoded} from "../components/PrivateRoute";


function CalendarView(props) {
    const pageName = 'calendar';
    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [addEventDetails, setAddEventDetails] = useState({
        display: false,
        dateData: null
    })
    const getOffsetValues = (now) => {
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        return now.toISOString().split('T')[0];
    }
    const [showDelete, setShowDelete] = useState(false)
    const [calendarKey, setCalendarKey] = useState(0);
    const [yearItemKey, setYearItemKey] = useState(0);
    const [currentDate, setCurrentDate] = useState(() => getOffsetValues(new Date()))
    const [accessLevel, setAccessLevel] = useState(false)
    const [showEvent, setShowEvent] = useState(false)
    const [yearList, setYearList] = useState([])
    const [showYearList, setShowYearList] = useState(false)
    const [uploadSpreadsheet, setUploadSpreadsheet] = useState(false)
    const [importCalendar, setImportCalendar] = useState(false)
    const [updateEvents, setUpdateEvents] = useState({
        display: false,
        dateData: null
    })
    const [viewEvents, setViewEvents] = useState({
        display: false,
        dateData: null
    })
    const [formData, setFormData] = useState({
        file: "",
        schoolYearId: ""
    })
    const [schoolYear, setSchoolYear] = useState({
        schoolYearId: "",
        yearId: "",
        fromDate: "",
        toDate: "",
    })
    const [events, setEvents] = useState([])

    const getAllSchoolYearExistDetails = useSelector(state => state.getAllSchoolYearExist)
    const {errorSchoolYear, loadingSchoolYear, schoolYearExist} = getAllSchoolYearExistDetails

    const uploadCalenderEventsDetails = useSelector(state => state.uploadCalenderEvents)
    const {errorUpload, loadingUpload, upload_events} = uploadCalenderEventsDetails

    const getAllEventsDetails = useSelector(state => state.getAllEvents)
    const {error, loading, eventsInfo} = getAllEventsDetails

    const getFilteredEventsDetails = useSelector(state => state.getEventsByDate)
    const {errorFiltered, loadingFiltered, eventsInfoFiltered} = getFilteredEventsDetails

    const getEventBySingleDateDetails = useSelector(state => state.getEventBySingleDate)
    const {
        errorFilteredSingleDate,
        loadingFilteredSingleDate,
        eventsInfoFilteredSingleDate
    } = getEventBySingleDateDetails

    const deleteEventDetails = useSelector(state => state.deleteEvent)
    const {errorDelete, loadingDelete, eventsInfoDelete} = deleteEventDetails

    const addEventChange = useSelector(state => state.addEvent)
    const {loadingAdd} = addEventChange

    const editEventChange = useSelector(state => state.editEvent)
    const {} = editEventChange

    useEffect(() => {
        setEvents([])
        setYearItemKey(prevKey => prevKey + 1);
        setAccessLevel(decoded.RoleAccessLevels)
        dispatch(resetDeleteSchoolYear())
        dispatch(resetUploadCalendarEvents())
        dispatch(getAllSchoolYear())
        if (getAllSchoolYearExistDetails.school_year_exist <= 0) {
            dispatch(getAllSchoolYearExist())
        } else {
            setCurrentDate(null)
        }
    }, []);

    useEffect(() => {
        if (!showEvent)
            setSchoolYear({
                ...schoolYear,
                schoolYearId: null,
                yearId: null,
                fromDate: null,
                toDate: null,
            })
    }, [yearItemKey]);

    const truncateText = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    useEffect(() => {
        if (uploadCalenderEventsDetails.upload_events === true) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Events uploaded successfully"
            })
            setEvents([])
            dispatch(resetUploadCalendarEvents())
            dispatch(getAllEvent(convertDateTimeToDate(schoolYear.fromDate), convertDateTimeToDate(schoolYear.toDate)))
        }
        if (uploadCalenderEventsDetails.errorUpload === false) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Events not uploaded"
            })
        }
    }, [uploadCalenderEventsDetails]);

    useEffect(() => {
        if (addEventChange.events_data === true) {
            setEvents([])
            dispatch(getAllEvent(convertDateTimeToDate(schoolYear.fromDate), convertDateTimeToDate(schoolYear.toDate)))
        }
        if (addEventChange.error === false) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Event not added"
            })
        }
    }, [addEventChange]);

    useEffect(() => {
        if (getAllSchoolYearExistDetails.school_year_exist !== null) {
            if (!getAllSchoolYearExistDetails.school_year_exist <= 0) {
                setEvents([])
                setSchoolYear({
                    schoolYearId: getAllSchoolYearExistDetails.school_year_exist.schoolYearId,
                    yearId: getAllSchoolYearExistDetails.school_year_exist.title,
                    yearIdFrom: convertToYear(getAllSchoolYearExistDetails.school_year_exist.fromDate),
                    yearIdTo: convertToYear(getAllSchoolYearExistDetails.school_year_exist.toDate),
                    fromDate: convertDateTimeToDate(getAllSchoolYearExistDetails.school_year_exist.fromDate),
                    toDate: convertDateTimeToDate(getAllSchoolYearExistDetails.school_year_exist.toDate),
                })
                setCurrentDate(() => getOffsetValues(new Date(getAllSchoolYearExistDetails.school_year_exist.fromDate)))
                setShowEvent(true)
            }
        }
        if (getAllSchoolYearExistDetails.school_year_exist === null) {
            setEvents([])
            setShowEvent(false)
        }
    }, [getAllSchoolYearExistDetails.school_year_exist]);

    useEffect(() => {
        getEventsFunction()
    }, [schoolYear]);

    const getEventsFunction = () => {
        setEvents([])
        dispatch(getAllEvent(convertDateTimeToDate(schoolYear.fromDate), convertDateTimeToDate(schoolYear.toDate)))
    }

    const getAllSchoolYearDetails = useSelector(state => state.getAllSchoolYear)
    const {errorAllYear, loadingAllYear} = getAllSchoolYearDetails

    useEffect(() => {
        if (!getAllSchoolYearDetails.school_year_list <= 0) {
            if (getAllSchoolYearDetails.school_year_list.length >= 1)
                setShowYearList(true)
            setYearList([])
            getAllSchoolYearDetails.school_year_list.map((item, index) => {
                setYearList((prevData) => [
                    ...prevData,
                    {
                        id: item.schoolYearId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getAllSchoolYearDetails.school_year_list]);

    const getSchoolYearDetails = useSelector(state => state.getSchoolYearById)
    const {schoolYearInfo} = getSchoolYearDetails

    useEffect(() => {
        if (!getSchoolYearDetails.school_year_data <= 0) {
            setEvents([])
            setSchoolYear({
                schoolYearId: getSchoolYearDetails.school_year_data.schoolYearId,
                yearId: getSchoolYearDetails.school_year_data.title,
                yearIdFrom: convertToYear(getSchoolYearDetails.school_year_data.fromDate),
                yearIdTo: convertToYear(getSchoolYearDetails.school_year_data.toDate),
                fromDate: convertDateTimeToDate(getSchoolYearDetails.school_year_data.fromDate),
                toDate: convertDateTimeToDate(getSchoolYearDetails.school_year_data.toDate),
            })

            // setCurrentDate(getSchoolYearDetails.school_year_data.fromDate)
            setCurrentDate(() => getOffsetValues(new Date(getSchoolYearDetails.school_year_data.fromDate)))
        }
    }, [getSchoolYearDetails.school_year_data]);

    function convertDateTimeToDate(dateTimeString) {
        if (dateTimeString !== "") {
            return getOffsetValues(new Date(dateTimeString));
        }
    }

    function convertToYear(dateTimeString) {
        if (dateTimeString !== undefined) {
            const date = new Date(dateTimeString);
            return date.getFullYear();
        }
    }

    useEffect(() => {
        if (editEventChange.events_data === true) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Events updated successfully"
            })
            setEvents([])
            dispatch(resetEditEvents())
            dispatch(getAllEvent(convertDateTimeToDate(schoolYear.fromDate), convertDateTimeToDate(schoolYear.toDate)))
        } else if (editEventChange.error === false) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Events not updated"
            })
        }
    }, [editEventChange.events_data]);

    useEffect(() => {
        if (deleteEventDetails.events_delete === true) {
            setEvents(prevItems => prevItems.filter(events => events.id !== updateEvents.data))
        }
        if (deleteEventDetails.error) {
            // setAlertModal(true)
        }
    }, [deleteEventDetails]);

    useEffect(() => {
        dispatch(resetUploadCalendarEvents())
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "School Year added successfully"
            })
            history('/calendar', {state: {}})
        }
    }, [dispatch, history, location.state?.success]);

    Date.prototype.addHours = function (h) {
        this.setSeconds(this.getSeconds() + h);
        return this;
    }

    useEffect(() => {
        if (!getAllEventsDetails.events_list <= 0) {
            getAllEventsDetails.events_list.map((item, index) => {
                setEvents((prevData) => [
                    ...prevData,
                    {
                        id: item.eventId,
                        title: item.title,
                        start: new Date(item.fromDate),
                        end: new Date(item.toDate).addHours(1)
                    }
                ])
            });
        }
    }, [getAllEventsDetails.events_list]);

    function renderEventContent(eventInfo) {
        return (
            <>
                {/*<b>{eventInfo.timeText}</b>*/}
                <span>{truncateText(eventInfo.event.title, 22)}</span>
            </>
        )
    }

    function showEventData(clickInfo) {
        if (accessLevel == 0) {
            if (formatDate(clickInfo.event.start) && clickInfo.event.end == null) {
                dispatch(getEventsBySingleDate(formatDate(clickInfo.event.start)))
            } else dispatch(getEventsBySingleDate(formatDate(clickInfo.event.start)))

            setViewEvents({
                display: true,
                dateData: `View Events for ${convertDateTime(clickInfo.event.start)}`
            })
            document.body.style.overflow = 'hidden';
        }
        if (accessLevel != 0) {
            if (formatDate(clickInfo.event.start) && clickInfo.event.end == null) {
                dispatch(getEventsBySingleDate(formatDate(clickInfo.event.start)))
            } else dispatch(getEventsBySingleDate(formatDate(clickInfo.event.start)))

            setUpdateEvents({
                display: true,
                dateData: `Manage Events for ${convertDateTime(clickInfo.event.start)}`
            })
            document.body.style.overflow = 'hidden';
        }
    }

    function formatDate(dateString) {

        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const convertDateTime = (data) => {
        const date = new Date(data);
        return (date.toLocaleDateString('en-US'))
    }

    const addEvent = (info) => {
        if (accessLevel != 0) {
            const {start, end} = info;
            setAddEventDetails({
                display: true,
                dateData: start
            })
            document.body.style.overflow = 'hidden';
        }
    }
    const hideFormModal = (data) => {
        setAddEventDetails({
            display: false
        })
        document.body.style.overflow = 'unset';
        if (data[1] !== 1) {
            const [eventName, startDate, endDate, type] = data;
            setEvents([
                ...events,
                {
                    start: startDate,
                    end: endDate,
                    title: eventName,
                }
            ]);
            dispatch(addEvents(eventName, formatAddDate(startDate), formatAddDate(endDate), type, schoolYear.schoolYearId))
        }
    }

    const formatAddDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const hideUpdateFormModal = (data) => {
        setUpdateEvents({
            display: false,
        });
        setViewEvents({
            display: false
        })
        // setShowDelete(true)
        document.body.style.overflow = 'unset';
        if (data[1] === 1) {
            dispatch(editEvents(data[0]))
        }
        if (data[0] === "delete") {
            setUpdateEvents({
                display: false,
                data: data[1]
            });
            setShowDelete(true)
        }
    }

    const uploadDocument = (data) => {
        setUploadSpreadsheet(true)
        document.body.style.overflow = 'hidden';
    }
    const downloadDocument = (data) => {
        window.open("/EventImportTemplate.xlsx", '_blank')
    }
    const hideDeleteModal = (data) => {
        if (data == 1) {
            dispatch(deleteEvent(updateEvents.data))
        }
        setShowDelete(false)
        document.body.style.overflow = 'unset';
    }
    const uploadDocumentResult = (data) => {
        if (data[0] === 0) {
            setUploadSpreadsheet(false)
        }
        if (data[0] === 1) {
            setFormData({
                ...formData,
                file: data[1]
            })
            setUploadSpreadsheet(false)
            setImportCalendar(true)
        }

    }
    const importResult = (data) => {
        if (data == 0) {
            setImportCalendar(false)
        }
        if (data == 1) {
            const form = new FormData();
            form.append('FormFile', formData.file);
            form.append('SchoolYearId', schoolYear.schoolYearId);
            dispatch(uploadEvent(form))
            setImportCalendar(false)
        }

    }
    const editSchoolYear = (data) => {
        history(`/edit-school-year/${schoolYear.schoolYearId}`)
    }

    const closeAlert = (data) => {
        setAlertModal(false)
        dispatch(resetAddSchoolYear())
        dispatch(resetUploadCalendarEvents())
        // window.location.href='/documents'
    }
    const yearData = (data) => {
        setEvents([])
        setShowEvent(true)
        setSchoolYear({
            ...schoolYear,
            schoolYearId: data.id
        })
        dispatch(getSchoolYearById(data.id))
    }
    useEffect(() => {
        setCalendarKey(prevKey => prevKey + 1);
    }, [currentDate]);

    return (<>
        <Row className="sidebar-background">
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {loading && <Loader/>}
            {loadingUpload && <Loader/>}
            {loadingFilteredSingleDate && <Loader/>}
            {loadingAdd && <Loader/>}
            {loadingSchoolYear && <Loader/>}
            {showDelete && <DeleteModal heading="Delete Event"
                                        type="delete"
                                        question="Are you sure want to delete this Event?"
                                        answer=""
                                        result={hideDeleteModal}
            />}
            {addEventDetails.display && <FormModal1 heading="Add New Event"
                                                    type="add_event" value={addEventDetails.dateData}
                                                    result={hideFormModal}
            />}
            {updateEvents.display && getEventBySingleDateDetails.events_single_data &&
                <FormModal1 heading={updateEvents.dateData}
                            type="edit_event" value={getEventBySingleDateDetails.events_single_data}
                            result={hideUpdateFormModal}
                />}
            {viewEvents.display && <FormModal1 heading={viewEvents.dateData}
                                               type="view_event" value={getEventBySingleDateDetails.events_single_data}
                                               result={hideUpdateFormModal}
            />}
            {uploadSpreadsheet && <UploadModal heading="Import Calendar Information"
                                               type="add_event"
                                               question="Current School Year"
                                               answer={`${schoolYear.yearId}`}
                                               result={uploadDocumentResult}
            />}
            {importCalendar && <DeleteModal heading="Import Calendar Information"
                                            type="import"
                                            button_true="Yes"
                                            button_false="No"
                                            question="The events for this school year will be deleted and replaced with the event information in your spreadsheet.  Do you wish to continue?"
                                            answer=""
                                            result={importResult}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                {accessLevel != 0 && <Heading group="school_year_calendar" title="School Year Calendar"/>}
                {accessLevel == 0 && <Heading group="school_year_calendar_teachers" title="School Year Calendar"/>}

                {showEvent && <Row className="app-body-calendar background-1">
                    <Card className="card-body-calendar-elements padding-right-unset padding-left-unset">
                        <Row className="background-1 sub-header-page">
                            <Col lg={{span: 4}}>
                                <div className="calendar-dropdown">
                                    <label htmlFor="document_title" id="teacher-associate"
                                           className="form-label form-spacing">
                                        School Year<span className="asterics">*</span></label>
                                    <div>
                                        <DropdownItemsInput data={yearList}
                                                            result={yearData}
                                                            value={schoolYear.schoolYearId}
                                                            className={"background-1 dropdown-input-content-valid"}
                                                            title="Select School Year"/>
                                    </div>
                                </div>
                            </Col>
                            {accessLevel != 0 && <Col lg={{span: 1}} onClick={editSchoolYear} className="padding-top-subheader">
                                <PencilEdit
                                    className="sidebar-icon-text button-cursor"
                                    color="var(--primary-100)"/>
                            </Col>}

                            {accessLevel != 0 && <Col lg={{span: 7}} className="calendar-buttons">
                                <div className="btn-group">
                                    <div className="float-start add-button-transparent ms-5 me-4"
                                         onClick={uploadDocument}>
                                        <DocumentUpload classname="sidebar-icon" color="var(--primary-100)"/> Upload
                                        Import
                                        Template
                                    </div>
                                    <div className="add-button float-end" onClick={downloadDocument}>
                                        <DocumentDownload classname="sidebar-icon" color="var(--white)"/> Download
                                        Import
                                        Template
                                    </div>
                                </div>
                            </Col>}

                        </Row>
                        <Row className="background-1 calendar-view mt-3">
                            <Card className="card-body-calendar">
                                <FullCalendar
                                    key={calendarKey} // Use the dynamic key
                                    initialDate={currentDate}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    headerToolbar={{
                                        left: '',
                                        // left: 'dayGridMonth ,timeGridDay',
                                        center: "title",
                                        right: "prevYear ,prev ,next ,nextYear"
                                    }}
                                    titleFormat={{
                                        month: 'long',
                                        year: 'numeric',
                                        day: 'numeric',
                                    }}
                                    dayHeaderFormat={{
                                        weekday: 'long'
                                    }}
                                    select={addEvent}
                                    initialView='dayGridMonth'
                                    weekends={true}
                                    events={events}
                                    eventContent={renderEventContent}
                                    slotLabelFormat={{
                                        hour: 'numeric',
                                        minute: '2-digit',
                                        omitZeroMinute: true,
                                        meridiem: 'short'
                                    }}
                                    dayMaxEvents={2}
                                    editable={true}
                                    selectable={true}
                                    selectMirror={true}
                                    eventClick={showEventData}
                                />
                            </Card>
                        </Row>
                        <Footer/>
                    </Card>
                </Row>}
                {!showEvent && <Row className="app-body-calendar background-1">
                    {showYearList && <Col lg={{span: 4}} className="mt-3">
                        <div className="calendar-dropdown">
                            <label htmlFor="document_title" id="teacher-associate"
                                   className="form-label form-spacing">
                                School Year<span className="asterics"></span></label>
                            {showYearList && <div>
                                <DropdownItemsInput data={yearList}
                                                    key={yearItemKey}
                                                    result={yearData}
                                                    value={schoolYear.schoolYearId}
                                                    className={"background-1 dropdown-input-content"}
                                                    title="Select School Year"/>
                            </div>}
                        </div>
                    </Col>}
                    <img src="empty_calendar.svg" alt="No data" className="react-calendar-empty"/>
                </Row>}
            </div>

        </Row>
    </>);
}

export default CalendarView;