import api from './api';
import {
    ADD_EDIT_TRUSTED_ADVISOR_SUCCESS,
    ADD_EDIT_TRUSTED_ADVISOR_REQUEST,
    ADD_EDIT_TRUSTED_ADVISOR_FAIL,
    ADD_EDIT_TRUSTED_ADVISOR_RESET,

    GET_ALL_TRUSTED_ADVISORS_REQUEST,
    GET_ALL_TRUSTED_ADVISORS_SUCCESS,
    GET_ALL_TRUSTED_ADVISORS_FAIL,
    GET_ALL_TRUSTED_ADVISORS_RESET,

    GET_TRUSTED_ADVISOR_BY_ID_REQUEST,
    GET_TRUSTED_ADVISOR_BY_ID_SUCCESS,
    GET_TRUSTED_ADVISOR_BY_ID_FAIL,
    GET_TRUSTED_ADVISOR_BY_ID_RESET,

    DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST,
    DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS,
    DELETE_TRUSTED_ADVISOR_BY_ID_FAIL,
    DELETE_TRUSTED_ADVISOR_BY_ID_RESET,
    

} from "../constants/TrustedAdvisorConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addTrustedAdvisor = (name, userName, clientId, isPayrollEnable, isLettersEnable, isDocumentsEnable, isProcessesEnable, isSchoolIncidentEnable, isTimeEntryEnable) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_EDIT_TRUSTED_ADVISOR_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/InsertUpdateTrustedAdvisorUser`,
            {
                name: name,
                userName: userName,
                clientId: clientId,
                isPayrollEnable: isPayrollEnable,
                isLettersEnable: isLettersEnable,
                isDocumentsEnable: isDocumentsEnable,
                isProcessesEnable: isProcessesEnable,
                isSchoolIncidentEnable: isSchoolIncidentEnable,
                isTimeEntryEnable: isTimeEntryEnable,
                roleId: 1,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_EDIT_TRUSTED_ADVISOR_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_EDIT_TRUSTED_ADVISOR_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_EDIT_TRUSTED_ADVISOR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editTrustedAdvisor = (trustedAdvisorUserId, name, userName, clientId, isPayrollEnable, isLettersEnable, isDocumentsEnable, isProcessesEnable, isSchoolIncidentEnable, isTimeEntryEnable) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_EDIT_TRUSTED_ADVISOR_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',

                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/SuperAdmin/InsertUpdateTrustedAdvisorUser`,
            {
                trustedAdvisorUserId: trustedAdvisorUserId,
                name: name,
                userName: userName,
                clientId: clientId,
                isPayrollEnable: isPayrollEnable,
                isLettersEnable: isLettersEnable,
                isDocumentsEnable: isDocumentsEnable,
                isProcessesEnable: isProcessesEnable,
                isSchoolIncidentEnable: isSchoolIncidentEnable,
                isTimeEntryEnable: isTimeEntryEnable,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_EDIT_TRUSTED_ADVISOR_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_EDIT_TRUSTED_ADVISOR_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_EDIT_TRUSTED_ADVISOR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllTrustedAdvisors = (clientId) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_TRUSTED_ADVISORS_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetTrustedAdvisorUsersByClientId?ClientId=${clientId}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_TRUSTED_ADVISORS_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_TRUSTED_ADVISORS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_TRUSTED_ADVISORS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getTrustedAdvisorById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_TRUSTED_ADVISOR_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/SuperAdmin/GetTrustedAdvisorUsersById?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_TRUSTED_ADVISOR_BY_ID_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_TRUSTED_ADVISOR_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_TRUSTED_ADVISOR_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteTrustedAdvisorById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_TRUSTED_ADVISOR_BY_ID_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/SuperAdmin/DeleteTrustedAdvisorUser?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_TRUSTED_ADVISOR_BY_ID_SUCCESS,
                payload: data.success,
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_TRUSTED_ADVISOR_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_TRUSTED_ADVISOR_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// RESET

export const resetAddTrustedAdvisor = () => async  (dispatch, getState) => {
    dispatch({
        type: ADD_EDIT_TRUSTED_ADVISOR_RESET,
    })
};
export const resetGetAllTrustedAdvisors = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_TRUSTED_ADVISORS_RESET,
    })
};
export const resetGetTrustedAdvisorById = () => (dispatch, getState) => {
    dispatch({
        type: GET_TRUSTED_ADVISOR_BY_ID_RESET,
    })
};
export const resetDeleteTrustedAdvisorById = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_TRUSTED_ADVISOR_BY_ID_RESET,
    })
};