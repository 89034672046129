import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 22.25H9C6.3424 22.25 4.56492 21.6803 3.4423 20.5577C2.31968 19.4351 1.75 17.6576 1.75 15V9C1.75 6.3424 2.31968 4.56492 3.4423 3.4423C4.56492 2.31968 6.3424 1.75 9 1.75H15C17.6576 1.75 19.4351 2.31968 20.5577 3.4423C21.6803 4.56492 22.25 6.3424 22.25 9V15C22.25 17.6576 21.6803 19.4351 20.5577 20.5577C19.4351 21.6803 17.6576 22.25 15 22.25ZM9 2.25C6.65972 2.25 4.92432 2.66107 3.7927 3.7927C2.66107 4.92432 2.25 6.65972 2.25 9V15C2.25 17.3403 2.66107 19.0757 3.7927 20.2073C4.92432 21.3389 6.65972 21.75 9 21.75H15C17.3403 21.75 19.0757 21.3389 20.2073 20.2073C21.3389 19.0757 21.75 17.3403 21.75 15V9C21.75 6.65972 21.3389 4.92432 20.2073 3.7927C19.0757 2.66107 17.3403 2.25 15 2.25H9Z"
                stroke={props.color}/>
            <path
                d="M15.75 9.25H8.25C8.11614 9.25 8 9.13386 8 9C8 8.86614 8.11614 8.75 8.25 8.75H15.75C15.8839 8.75 16 8.86614 16 9C16 9.13386 15.8839 9.25 15.75 9.25Z"
                stroke={props.color}/>
            <path
                d="M15.75 15.25H8.25C8.11614 15.25 8 15.1339 8 15C8 14.8661 8.11614 14.75 8.25 14.75H15.75C15.8839 14.75 16 14.8661 16 15C16 15.1339 15.8839 15.25 15.75 15.25Z"
                stroke={props.color}/>
        </svg>
    );
}

export default Home;