import React from 'react';

function Home(props) {
    return (
        
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                fill={props.color}/>
            <path
                d="M9.17035 15.5799C8.98035 15.5799 8.79035 15.5099 8.64035 15.3599C8.35035 15.0699 8.35035 14.5899 8.64035 14.2999L14.3004 8.63986C14.5904 8.34986 15.0704 8.34986 15.3604 8.63986C15.6504 8.92986 15.6504 9.40986 15.3604 9.69986L9.70035 15.3599C9.56035 15.5099 9.36035 15.5799 9.17035 15.5799Z"
                fill={props.color}/>
            <path
                d="M14.8304 15.5799C14.6404 15.5799 14.4504 15.5099 14.3004 15.3599L8.64035 9.69986C8.35035 9.40986 8.35035 8.92986 8.64035 8.63986C8.93035 8.34986 9.41035 8.34986 9.70035 8.63986L15.3604 14.2999C15.6504 14.5899 15.6504 15.0699 15.3604 15.3599C15.2104 15.5099 15.0204 15.5799 14.8304 15.5799Z"
                fill={props.color}/>
        </svg>


    );
}

export default Home;