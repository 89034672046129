import React from 'react';
import {Navbar, Container, Dropdown} from "react-bootstrap";
import Security from "./Icons/Security";
import Setting from "./Icons/Setting";
import User from "./Icons/User";
import {useNavigate, useNavigation} from "react-router-dom";
import {IoMdArrowDropdown} from "react-icons/io";
import {decoded} from "./PrivateRoute";

function Navigation(props) {
    const history = useNavigate();
    const goToClient = () => {
      history('/client')
    }
    const goToSuperAdmin = () => {
      history('/client-management')
    }
    const goToMfa = () => {
        localStorage.removeItem('client_name')
        localStorage.removeItem('client_id')
        history('/mfa')
    }

    const redirectLink = (data) => {
        window.open(data, '_blank')
    }

    return (
        <Navbar className="nav-body">
            <Container className="nav-container">
                <Navbar.Brand>
                    <div>
                        <span className="school-name-label">SCHOOL NAME</span>
                        {decoded.IsSuperAdmin == "False" && <span className="change-client button-cursor" onClick={goToClient}> Click to Change</span>}
                        {decoded.IsSuperAdmin == "True" && <span className="change-client button-cursor" onClick={goToSuperAdmin}> Back to Super Admin</span>}
                    </div>
                    <div>
                        <span className="school-name-input">{localStorage.getItem('client_name')}</span>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <div className="row nav-row">
                        <div className="col-2" align="right">
                            {/*<Note2 className="sidebar-icon" color='var(--primary-100)'/>*/}
                        </div>
                        <div className="col-2" align="right">
                            <Dropdown>
                                <Dropdown.Toggle className="dropdown-button toggle-dropdown toggle-style">
                                    {/*<button disabled={true} className="dropdown-button" >*/}
                                        <Security className="sidebar-icon" color='var(--primary-100)'/>
                                    {/*</button>*/}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-show">
                                    <Dropdown.Item as="button" className="dropdown-items" onClick={() => redirectLink("https://extfiles.blob.core.windows.net/prism-ess/Privacy%20Policy%20and%20Notice%20-%20ExtensisHR%20-%20Jan%201%202023.pdf")}>
                                        Privacy Policy</Dropdown.Item>
                                    <Dropdown.Item as="button" className="dropdown-items" onClick={() => redirectLink("https://extfiles.blob.core.windows.net/prism-ess/ExtensisHR%20California%20Privacy%20Policy%20to%20Worksite%20Employees%20-%20Jan%201%202023.pdf")}>Privacy Policy - California</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-2" align="right">
                            <div onClick={goToMfa}>
                                <Setting className="sidebar-icon" color='var(--primary-100)'/>
                            </div>
                        </div>
                        <div className="col-6 padding-last-item" align="right">
                            <User className="sidebar-icon" color='var(--red)'/>
                            <span className="user-name">{localStorage.getItem('userName')}</span>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;