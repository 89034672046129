import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {useDispatch} from "react-redux";
import './assets/css/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login';
import VerificationCodeSend from './pages/VerificationCodeSend';
import VerificationCodeAdd from './pages/VerificationCodeAdd';
import MultifactorAuthentication from './pages/MultifactorAuthentication';
import SelectClient from './pages/SelectClient';
import Dashboard from './pages/Dashboard';
import Documents from "./pages/Documents";
import Certifications from "./pages/Certifications";
import AddDocument from "./pages/AddDocument";
import EditDocument from "./pages/EditDocument";
import AddCertificate from "./pages/AddCertificate";
import EditCertificate from "./pages/EditCertificate";
import CalendarView from "./pages/CalendarView";
import CreateSchoolYear from "./pages/CreateSchoolYear";
import EditSchoolYear from "./pages/EditSchoolYear";
import LettersNotices from "./pages/LettersNotices";
import CreateNewTemplate from "./pages/CreateNewTemplate";
import ErrorPage from "./pages/ErrorPage";
import LandingPage from "./pages/LandingPage";
import EditTemplate from "./pages/EditTemplate";
import SendLetters from "./pages/SendLetters";
import LettersPreview from "./pages/LettersPreview";
import LettersAcknowledgement from "./pages/LettersAcknowledgement";
import Processes from "./pages/Processes";
import CreateProcessTemplate from "./pages/CreateProcessTemplate";
import EditProcessTemplate from "./pages/EditProcessTemplate";
import SendProcess from "./pages/SendProcess";
import ManageProcess from "./pages/ManageProcess";
import {decoded, PrivateRoute, token} from './components/PrivateRoute';
import {SuperAdminRoute} from './components/SuperAdminRoute';
import {AdminRoute} from './components/AdminRoute';
import {logout} from "./actions/LoginActions";
import SuperAdminManagement from "./pages/SuperAdmin/SuperAdminManagement";
import AddSuperAdmin from "./pages/SuperAdmin/AddSuperAdmin";
import EditSuperAdmin from "./pages/SuperAdmin/EditSuperAdmin";
import ClientManagement from "./pages/SuperAdmin/ClientManagement";
import AddClient from "./pages/SuperAdmin/AddClient";
import TeacherList from "./pages/SuperAdmin/TeachersList";
import EditClient from "./pages/SuperAdmin/EditClient";
import EditTeacherAssociate from "./pages/SuperAdmin/EditTeacherAssociate";
import AddTrustedAdvisor from "./pages/SuperAdmin/AddTrustedAdvisor";
import EditTrustedAdvisor from "./pages/SuperAdmin/EditTrustedAdvisor";
import {useEffect, useState} from "react";
import ResponsePage from "./pages/ResponsePage";
import {jwtDecode} from "jwt-decode";
import {ClientRoute} from "./components/ClientRoute";
import Analytics from "./pages/SuperAdmin/Analytics";

function App() {
    const dispatch = useDispatch()
    const [accessLevel, setAccessLevel] = useState({
        access: false,
        isPayrollEnable: false,
        isLettersEnable: false,
        isDocumentsEnable: false,
        isProcessesEnable: false,
    })
    useEffect(() => {
        if (decoded) {
            setAccessLevel({
                ...accessLevel,
                access: decoded.RoleAccessLevels,
                isPayrollEnable: decoded.IsPayrollEnable,
                isLettersEnable: decoded.IsLettersEnable,
                isDocumentsEnable: decoded.IsDocumentsEnable,
                isProcessesEnable: decoded.IsProcessesEnable,
            })
        }
    }, [])

    setInterval(() => {
        if (token != null) {
            if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
                dispatch(logout())
            }
        }
    }, 60 * 1000);

    return (
        <Router>
            <Routes>
                <Route path="/sign-in" element={<Login/>}/>
                <Route path="/verification-code" element={<VerificationCodeSend/>}/>
                <Route path="/verification-code-add" element={<VerificationCodeAdd/>}/>
                <Route path="/mfa" element={<MultifactorAuthentication/>}/>
                <Route path="/client" element={<SelectClient/>}/>
                <Route path="/service-response/:id" element={<ResponsePage/>}/>

                <Route path='*' element={<ErrorPage/>}/>
                <Route element={<PrivateRoute/>}>
                    <Route element={<AdminRoute/>}>
                        <Route element={<ClientRoute/>}>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/landing-page/:id" element={<LandingPage/>}/>
                            <Route path="/" element={<Dashboard/>}/>
                            {<Route path="/documents" element={<Documents/>}/>}
                            {<Route path="/add-documents" element={<AddDocument/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsDocumentsEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/edit-documents/:id" element={<EditDocument/>}/>}
                            <Route path="/certifications" element={<Certifications/>}/>
                            <Route path="/add-certificate" element={<AddCertificate/>}/>
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/edit-certificate/:id" element={<EditCertificate/>}/>}

                            <Route path="/calendar" element={<CalendarView/>}/>
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/create-school-year" element={<CreateSchoolYear/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/edit-school-year/:id" element={<EditSchoolYear/>}/>}

                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/letters-notices" element={<LettersNotices/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/create-new-template" element={<CreateNewTemplate/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/edit-template/:id" element={<EditTemplate/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/send-letters-notices" element={<SendLetters/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/letters-preview" element={<LettersPreview/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsLettersEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/letters-acknowledgement" element={<LettersAcknowledgement/>}/>}

                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsProcessesEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/processes" element={<Processes/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsProcessesEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/create-process-template" element={<CreateProcessTemplate/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsProcessesEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/edit-process-template/:id" element={<EditProcessTemplate/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsProcessesEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/send-process" element={<SendProcess/>}/>}
                            {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).IsProcessesEnable != "False" && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                                <Route path="/manage-process/:id/:type" element={<ManageProcess/>}/>}
                        </Route>
                    </Route>
                    <Route element={<SuperAdminRoute/>}>
                        <Route path="/super-admin-management" element={<SuperAdminManagement/>}/>
                        <Route path="/super-admin-add" element={<AddSuperAdmin/>}/>
                        <Route path="/super-admin-edit/:id" element={<EditSuperAdmin/>}/>

                        <Route path="/client-management" element={<ClientManagement/>}/>
                        <Route path="/client-add" element={<AddClient/>}/>
                        <Route path="/client-edit/:id" element={<EditClient/>}/>
                        <Route path="/client-teacher-list/:id" element={<TeacherList/>}/>
                        <Route path="/client-user-edit/:id/:client" element={<EditTeacherAssociate/>}/>

                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/letters-notices" element={<LettersNotices/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/create-new-template" element={<CreateNewTemplate/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/edit-template/:id" element={<EditTemplate/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/send-letters-notices" element={<SendLetters/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/letters-preview" element={<LettersPreview/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/letters-acknowledgement" element={<LettersAcknowledgement/>}/>}

                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/processes" element={<Processes/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/create-process-template" element={<CreateProcessTemplate/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/edit-process-template/:id" element={<EditProcessTemplate/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/send-process" element={<SendProcess/>}/>}
                        {localStorage.getItem("access_token") && jwtDecode(localStorage.getItem("access_token")).RoleAccessLevels != 0 &&
                            <Route path="/manage-process/:id/:type" element={<ManageProcess/>}/>}

                        <Route path="/trusted-advisor-add" element={<AddTrustedAdvisor/>}/>
                        <Route path="/trusted-advisor-edit/:advisor" element={<EditTrustedAdvisor/>}/>

                        <Route path="/analytics" element={<Analytics/>}/>

                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
