import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownItemsInput from "../components/DropdownItemsInput";
import teacher_associate from "../assets/json/teacher_associate.json";
import signature_status from "../assets/json/signature_status.json"
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import document_category from "../assets/json/document_category.json";
import Document from "../components/Icons/Document"
import {useNavigate, useParams} from "react-router-dom";
import DropdownCalendarDisabled from "../components/DropdownCalendarDisabled";
import {
    addDocument,
    editDocument,
    getDocumentById,
    resetAddDocument,
    resetEditDocument
} from "../actions/DocumentActions";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";
import {IoCloudDownloadOutline} from "react-icons/io5";
import {getTeacherAssociates} from "../actions/ConfigActions";

const EditDocument = (props) => {
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    let file_doc
    const documentTitleRef = useRef();
    const documentTypeRef = useRef();
    const [imgFile, uploading] = useState()
    const categoryRef = useRef();
    const hiddenFileInput = React.useRef(null);
    const [alertModal, setAlertModal] = useState(false)
    const [successAlertModal, setSuccessAlertModal] = useState(false)
    const [teacher, setTeacher] = useState([])
    const [category, setCategory] = useState([])
    const [fileSize, setFileSize] = useState('');

    const [fieldIsValid, setFieldIsValid] = useState({
        isDocumentTitleValid: true,
        isTeacherValid: true,
        isDocumentTypeValid: true,
        isDocumentSignatureStatusValid: true,
        isCategoryStatusValid: true,
        isDocumentDateValid: true,
        isFileValid: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        documentTitleErrorMessage: "",
        teacherErrorMessage: "",
        documentTypeErrorMessage: "",
        documentSignatureStatusErrorMessage: "",
        documentDateErrorMessage: "",
        fileErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        dataDocumentTitle: null,
        dataTeacher: null,
        dataDocumentCategory: null,
        dataSignature: null,
        dataDate: null,
        createdDate: null,
        dataFile: null,
        dataUrl: null,
        dataFileSize: null,
    });

    const editDocumentDetails = useSelector(state => state.editDocument)
    const {errorEdit, loadingEdit, documentEdit} = editDocumentDetails

    const getDocument = useSelector(state => state.getDocument)
    const {error, loading, documentInfo} = getDocument

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    const getCategoryList = useSelector(state => state.getCategory)
    const {category_list} = getCategoryList

    useEffect(() => {
        if (editDocumentDetails.document === true) {
            dispatch(resetEditDocument())
            setSuccessAlertModal(true)
        }
        if (editDocumentDetails.error) {
            setAlertModal(true)
        }
    }, [editDocumentDetails]);

    useEffect(() => {
        if (!getDocument.document_data <= 0) {
            setFieldData({
                ...fieldData,
                dataDocumentTitle: getDocument.document_data.title,
                dataTeacher: getDocument.document_data.associateId,
                dataDocumentCategory: getDocument.document_data.documentCategory,
                dataSignature: getDocument.document_data.signatureStatusId,
                dataDate: formatDateServer(getDocument.document_data.docDate),
                createdDate: getDocument.document_data.createdDate,
                dataFile: null,
                dataUrl: getDocument.document_data.docUrl,
                dataFileSize: null,
            })
        }
    }, [getDocument.document_data]);

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    useEffect(() => {
        if (!getCategoryList.category_list <= 0) {
            getCategoryList.category_list.map((item, index) => {
                setCategory((prevData) => [
                    ...prevData,
                    {
                        id: item.documentCategoryId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getCategoryList.category_list]);

    useEffect(() => {
        dispatch(getTeacherAssociates())
        dispatch(getDocumentById(params.id))
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${month}/${day}/${year}`;
    }

    function formatDateServer(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const logoClick = event => {
        hiddenFileInput.current.click();
    };
    const imageFileHandler = (e) => {
        if (e.target.files.length !== 0) {
            file_doc = e.target.files[0]
            console.log(file_doc)
            uploading(URL.createObjectURL(e.target.files[0]))
            setFieldIsValid({
                ...fieldIsValid,
                isFileValid: true
            });
            setFieldData({
                ...fieldData,
                dataFile: file_doc
            })
        }
        console.log(file_doc)
    }

    const documentTitleKeyPress = (event) => {
        event.preventDefault();
        console.log(documentTitleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(documentTitleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentTitleValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                documentTitleErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentTitleValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                documentTitleErrorMessage: ""
            });
        }
    }
    const categoryKeyPress = (event) => {
        event.preventDefault();
        console.log(categoryRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(categoryRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isCategoryStatusValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                categoryStatusErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isCategoryStatusValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                categoryStatusErrorMessage: ""
            });
            setFieldData({
                ...fieldData,
                dataDocumentCategory: categoryRef.current.value
            })
        }
    }
    const goBack = () => {
        history('/documents')
    }
    const openLink = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('DocumentId', params.id);
        formData.append('Title', documentTitleRef.current.value);
        formData.append('AssociateId', fieldData.dataTeacher);
        formData.append('DocDate', fieldData.dataDate);
        formData.append('documentCategory', categoryRef.current.value);
        formData.append('SignatureStatusId', fieldData.dataSignature);

        // for (const [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }
        dispatch(editDocument(formData));
    }
    const onReset = (e) => {
        dispatch(getDocumentById(params.id))
    }
    const teacherData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isTeacherValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            teacherErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const categoryStatusData = (data) => {
        // console.log(data.id)
        setFieldIsValid({
            ...fieldIsValid,
            isCategoryStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            categoryStatusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataDocumentCategory: data.id
        })
    }
    const signatureStatusData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentSignatureStatusValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            documentSignatureStatusErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataSignature: data.id
        })
    }
    const dateData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            documentDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataDate: formatDateServer(data)
        })
    }
    const closeAlert = (data) => {
        setAlertModal(false)
        setSuccessAlertModal(false)
        history('/documents')
    }

    const downloadFile = (url) => {
        window.open(url, '_blank');
    }

    const pageName = 'documents';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal &&
                    <AlertModal heading="Document couldn't be updated" type="error" result={closeAlert}/>}
                {successAlertModal &&
                    <AlertModal heading="Document information updated" type="success" result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    {getDocument.document_data && <Heading group="doc_edit" title="Edit Document"
                                                           import_date={formatDate(getDocument.document_data.createdDate)}
                                                           result={goBack}/>}
                    <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getDocument.document_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Document Title<span className="asterics">*</span></label>
                                                <input ref={documentTitleRef} onChange={documentTitleKeyPress}
                                                       type="text" autoComplete="off"
                                                    // defaultValue="Document Title 1"
                                                       defaultValue={getDocument.document_data.title}
                                                       className={!fieldIsValid.isDocumentTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Document Title"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Teacher/Associate<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={teacher} result={teacherData}
                                                        // value={fieldData.dataTeacherValue}
                                                                        value={getDocument.document_data.associateId}
                                                                        className={!fieldIsValid.isTeacherValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Teacher/Associate"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Document Type<span className="asterics">*</span></label>
                                                <input ref={categoryRef} onChange={categoryKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getDocument.document_data.documentCategory}
                                                       className={!fieldIsValid.isCategoryStatusValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Document Type"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="signature_status"
                                                       className="form-label form-spacing">
                                                    Document Signature Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={signature_status}
                                                                        result={signatureStatusData}
                                                                        value={getDocument.document_data.signatureStatusId}
                                                                        className={!fieldIsValid.isDocumentSignatureStatusValid ?
                                                                            "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                        title="Select Document Signature Status"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing-calendar">
                                                    Document Date<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownCalendarSingle title="Select Document Date"
                                                                            result={dateData}
                                                                            value={getDocument.document_data.docDate}
                                                                            className={!fieldIsValid.isDocumentDateValid ?
                                                                                "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                    />
                                                </div>
                                            </div>
                                        </Col><Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                                   className="padding-right-unset">
                                        <div className="document-form inactive">
                                            <label htmlFor="document_title" id="documentTitleLabel"
                                                   className="form-label form-spacing-calendar">
                                                Import Date<span className="asterics">*</span></label>
                                            <div>
                                                <DropdownCalendarDisabled
                                                    title={formatDate(getDocument.document_data.createdDate)}
                                                    result={dateData}/>
                                            </div>
                                        </div>
                                    </Col>

                                    </Row>

                                    <Row className="background-1 file-box-small inactive">
                                        <Col lg={{span: 11, offset: 0}} md={{span: 11, offset: 0}}>
                                            <Row className="background-1">
                                                <Col lg={{span: 1}} className="doc-icon">
                                                    <Document className="sidebar-icon"
                                                              color="var(--primary-100)"/>
                                                </Col>
                                                <Col lg={{span: 11}} className="doc-name-outer">
                                                    <Row className="background-1">
                                                        {fieldData.dataFile != null ? fieldData.dataFile.name : getDocument.document_data.docName}
                                                    </Row>
                                                    <Row className="background-1 doc-size">
                                                        File
                                                        size: {fieldData.dataFile != null ? Math.round(getDocument.document_data.docSize / 102400).toFixed(2) : '14'} MB
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <input type="file"
                                                   id='image'
                                                   accept='.doc, .docx, .pdf'
                                                   onChange={imageFileHandler}
                                                   ref={hiddenFileInput}
                                                   style={{display: 'none'}}
                                            />
                                        </Col>
                                        <Col lg={{span: 1, offset: 0}} md={{span: 1, offset: 0}}>
                                            <div className="download-icon"
                                                 onClick={() => downloadFile(fieldData.dataUrl)}>
                                                <IoCloudDownloadOutline/>
                                            </div>
                                        </Col>
                                    </Row>
                                    {(
                                            !fieldIsValid.isDocumentTitleValid
                                            || !fieldIsValid.isTeacherValid
                                            || !fieldIsValid.isCategoryStatusValid
                                            || !fieldIsValid.isDocumentSignatureStatusValid
                                            || !fieldIsValid.isDocumentDateValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isDocumentTitleValid
                                            && fieldIsValid.isTeacherValid
                                            && fieldIsValid.isCategoryStatusValid
                                            && fieldIsValid.isDocumentSignatureStatusValid
                                            && fieldIsValid.isDocumentDateValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={openLink}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>
                </div>
            </Row>
        </>
    );
}

export default EditDocument;