import React from 'react';
import {useDispatch} from "react-redux";
import {FiLogOut} from "react-icons/fi";
import {Tooltip} from "react-tooltip";
import Main from "../assets/images/main.png";
import {logout} from "../actions/LoginActions";

function LoginImage(props) {
    const dispatch = useDispatch();
    const signOut = () => {
        dispatch(logout())
    }
    return (
        <>
            <div className="mfa-icon message-4" onClick={() => signOut()}>
                <FiLogOut className="sidebar-icon" data-tooltip-id="tooltip-1"/> Logout
            </div>
            <img src={Main} alt="logo" className="main_image mfa-image"/>
        </>
    );
}

export default LoginImage;