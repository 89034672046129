import {Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../actions/LoginActions";
import {useEffect, useState} from "react";
import {markTaskAsComplete, resetMarkTaskAsComplete} from "../actions/ProcessActions";
import {resetSendLettersNotices} from "../actions/LetterNoticesActions";
import Loader from "../components/Loader";

function ResponsePage() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [showMessage, setShowMessage] = useState({
        display: true,
        message: "",
        text: "",
    });

    const markTaskAsCompleteDetails = useSelector(state => state.markTaskAsComplete)
    const {error, loading, mark_task} = markTaskAsCompleteDetails

    useEffect(() => {
        if (markTaskAsCompleteDetails.mark_task === true) {
            dispatch(resetMarkTaskAsComplete())
            setShowMessage({
                display: true,
                message: "Thank you! Task marked as complete",
                text: "You will be redirected in a few seconds",
            })
            setTimeout(() => {
                history("/")
            }, 5000);
        }
        if (markTaskAsCompleteDetails.error) {
            setShowMessage({
                display: true,
                message: "Error! Task could not be marked as complete",
                text: "You will be redirected in a few seconds",
            })
        }
    }, [markTaskAsCompleteDetails]);

    useEffect(() => {
        dispatch(markTaskAsComplete(params.id))
    }, []);

    return (
        <Row className="login-background">
            {loading && <Loader/>}
            <div id="main">
                {showMessage.display && <div className="fof">
                    <h1>{showMessage.message}</h1>
                    <div className="">
                        <span className="span-error-page">{showMessage.text}</span>
                    </div>
                </div>}
            </div>
        </Row>
    );
}

export default ResponsePage;