import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5999 22.5598H6.39985C4.57984 22.5598 2.91985 21.1598 2.61985 19.3598L1.28984 11.3999C1.07984 10.1599 1.67985 8.56987 2.66985 7.77987L9.59986 2.22982C10.9399 1.14982 13.0498 1.15983 14.3998 2.23983L21.3299 7.77987C22.3099 8.56987 22.9099 10.1599 22.7099 11.3999L21.3799 19.3598C21.0799 21.1298 19.3899 22.5598 17.5999 22.5598ZM11.9899 2.93984C11.4599 2.93984 10.9298 3.09981 10.5398 3.40981L3.60985 8.95986C3.03985 9.41986 2.64986 10.4398 2.76986 11.1598L4.09986 19.1198C4.27986 20.1698 5.32984 21.0598 6.39985 21.0598H17.5999C18.6699 21.0598 19.7198 20.1698 19.8998 19.1098L21.2298 11.1499C21.3498 10.4299 20.9499 9.39985 20.3899 8.94985L13.4599 3.40981C13.0599 3.09981 12.5299 2.93984 11.9899 2.93984Z" fill={props.color}/>
            <path d="M12 16.25C10.21 16.25 8.75 14.79 8.75 13C8.75 11.21 10.21 9.75 12 9.75C13.79 9.75 15.25 11.21 15.25 13C15.25 14.79 13.79 16.25 12 16.25ZM12 11.25C11.04 11.25 10.25 12.04 10.25 13C10.25 13.96 11.04 14.75 12 14.75C12.96 14.75 13.75 13.96 13.75 13C13.75 12.04 12.96 11.25 12 11.25Z" fill={props.color}/>
        </svg>
    );
}

export default Home;