import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import DropdownItemsInput from "./DropdownItemsInput";
import task_type from "../assets/json/task_type.json";
import assign_list from "../assets/json/assign_to_list.json";
import Calendar from "./Icons/Calendar";
import Trash from "./Icons/Trash";
import {useSelector} from "react-redux";

function DynamicProcessTemplate(props) {
    const notesTypeRef = useRef();
    const taskTypeRef = useRef();
    const [teacher, setTeacher] = useState([])
    const [taskType, setTaskType] = useState([])

    const [fieldIsValid, setFieldIsValid] = useState({
        isTaskValid: false,
        isAssignValid: false,
        isNotesValid: false,
    });

    useEffect(() => {
        if (props.task_name !== "") {
            setFieldIsValid(prevState => ({
                ...prevState,
                isTaskValid: true
            }));
        }

        if (props.note !== "") {
            setFieldIsValid(prevState => ({
                ...prevState,
                isNotesValid: true
            }));
        }
    }, [props.task_name, props.note]); // Note: added dependencies to the dependency array


    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);
    const getTaskTypeList = useSelector(state => state.getTaskType)
    const {task_type_list} = getTaskTypeList

    useEffect(() => {
        if (!getTaskTypeList.task_type_list <= 0) {
            getTaskTypeList.task_type_list.map((item, index) => {
                setTaskType((prevData) => [
                    ...prevData,
                    {
                        id: item.taskId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getTaskTypeList.task_type_list]);
    const notesKeyPress = (event, task) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(notesTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: false,
            });
            props.result("3",notesTypeRef.current.value,task)
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: true,
            });
            props.result(["3",notesTypeRef.current.value,task])
        }
    }
    const taskKeyPress = (event, task) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(taskTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: false,
            });
            props.result("1",taskTypeRef.current.value,task)
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTaskValid: true,
            });
            props.result(["1",taskTypeRef.current.value,task])
        }
    }
    const assignData = (data, task) => {
        props.result(["2",data.id, task])
    }
    function taskDelete(task) {
        props.result(["0", task])
    }

    return (
        <>
            <Row className="background-1 border-bottom-div pb-4" key={props.task}>
                <Row className="background-1 pt-4">
                    <div className="col-6 float-left-child template-card-title padding-left-unset">
                        Task {props.task}
                    </div>
                    {props.count > 1 && <div onClick={() => taskDelete(props.task)}
                          className="col-6 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <Trash className="sidebar-icon-form"
                               color="var(--red)"/> <span className="message-6">Delete</span>
                    </div>}
                </Row>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Tasks<span className="asterics">*</span></label>
                        <input ref={taskTypeRef} onKeyUp={(e) => taskKeyPress(e, props.task)}
                               type="text" autoComplete="off"
                               defaultValue={props.task_name}
                               className={!fieldIsValid.isTaskValid ?
                                   "input-error-form input-spacing" : "input-valid-form input-spacing"}
                               placeholder="Enter Task Type"
                               id="document_title"/>
                    </div>
                </Col>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-right-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Assign To<span className="asterics">*</span></label>
                        <div>
                            <DropdownItemsInput data={teacher} result={(data) => assignData(data, props.task)} value={props.assign}
                                                className={props.assign !== '' ?
                                                    "background-1 dropdown-input-content-valid" : "background-1 dropdown-input-content"}
                                                title="Select Assign To"/>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                     className="padding-right-unset padding-left-unset">
                    <div className={!fieldIsValid.isNotesValid ? "document-form-textarea-error"
                        : "document-form-textarea"}
                    >
                                                <span className="text-label form-spacing">
                                                    Note<span className="asterics">*</span></span>
                        <textarea ref={notesTypeRef} onKeyUp={(e) => notesKeyPress(e, props.task)} defaultValue={props.note}
                                  className="textarea-input-error textarea-spacing"
                                  placeholder="Add Notes" rows="4"
                                  id="document_title"/>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default DynamicProcessTemplate;