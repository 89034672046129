import React, {useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card} from "react-bootstrap";
import Logo from "../assets/images/logo.svg"
import Main from "../assets/images/main.png"
import {useNavigate, useLocation} from "react-router-dom";
import OtpInput from 'react-otp-input';
import {TbAlertHexagon} from "react-icons/tb";
import {createVerification, logout, resetVerification, resetVerifyOtp, verifyOtp} from "../actions/LoginActions";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";
import {jwtDecode} from "jwt-decode";
import LoginImage from "../components/LoginImage";

const counterTime = 10

function VerificationCodeSend() {
    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(counterTime);
    const [resetCounter, setResetCounter] = useState(false);
    const [errors, setError] = useState({
        errorMessage: "",
        isError: false
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [verificationType, setVerificationType] = useState({
        type: "",
        value: "",
    });

    const loginUser = useSelector(state => state.login)
    const {userInfo} = loginUser

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            dispatch(logout())
        }
        if (localStorage.getItem('mfa_verify') === "true") {
            history('/client')
        }
    }, []);

    useEffect(() => {
        if (!userInfo) {
            history("/sign-in")
        }
        if (localStorage.getItem("code_verify") === "true") {
            setAlertModal({
                display: true,
                type: "error",
                message: "Already verified, you will be redirected"
            })
            setTimeout(() => {
                history('/mfa')
            }, 3000)
        }
        const decoded = jwtDecode(localStorage.getItem("access_token"));
        const user = decoded.IsSuperAdmin
        if (localStorage.getItem("timestamp_type")) {
            if (user === "True") {
                window.location.href = "/super-admin-management"
            } else {
                history("/client")
            }
        }
        const handleBackButton = () => {
            dispatch(logout());
            history("/sign-in");
        };
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    useEffect(() => {
        setVerificationType({
            type: location.state?.type,
            value: location.state?.value,
        })
        if (counter <= 0) {
            setResetCounter(true)
        } else {
            const timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter]);

    useEffect(() => {
        if (otp.length < 6) {
            setError({
                errorMessage: "",
                isError: false
            });
        }
    }, [otp])

    const resendOtp = () => {
        setOtp("")
        if (verificationType.type == 1) {
            dispatch(createVerification(verificationType.type, null, verificationType.value))
        }
        if (verificationType.type == 2) {
            dispatch(createVerification(verificationType.type, verificationType.value, null))
        }
    };

    const createVerificationDetails = useSelector(state => state.createVerification)
    const {error, loading, createVerificationInfo} = createVerificationDetails

    useEffect(() => {
        if (createVerificationDetails.verificationInfo === true) {
            dispatch(resetVerification())
            setResetCounter(false)
            setCounter(counterTime)
        }
        if (createVerificationDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Failed to request verification code"
            })
        }
    }, [createVerificationDetails]);

    const submitHandler = (event) => {
        event.preventDefault();
        dispatch(verifyOtp(otp))
    };

    const verifyOtpDetails = useSelector(state => state.verifyOtp)
    const {errorOtp, loadingOtp, verificationOtp} = verifyOtpDetails

    useEffect(() => {
        if (verifyOtpDetails.verificationOtp === true) {
            dispatch(resetVerifyOtp())
            localStorage.setItem("code_verify", "true")
            history("/mfa")
        }
        if (verifyOtpDetails.verificationOtp === false) {
            setError({
                errorMessage: "The Verification Code you entered is incorrect. Please try again.",
                isError: true
            });
        }
    }, [verifyOtpDetails]);

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetVerification())
        // window.location.href='/documents'
    }

    return (
        <Row className="login-background">
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {loading && <Loader/>}
            {loadingOtp && <Loader/>}
            <Col lg={{span: 4, offset: 1}} md={{span: 4, offset: 1}}>
                <Card className="card-body">
                    <img src={Logo} alt="logo" className="logo"/>
                    <form className="margin-top">
                        <p className="header">Verification code</p>
                        <p className="sub-header">Please choose any one option to get verification code</p>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType="number"
                            renderSeparator={<span className="separator"></span>}
                            renderInput={(props) => <input {...props} className="otp-input"/>}
                        />
                        <div className="counter float-right-child">
                            {!resetCounter && <span className="message-2">Resend OTP in </span>}
                            {!resetCounter &&
                                <span className="message-3">00:{(counter).toString().padStart(2, '0')}</span>}
                            {resetCounter && <span className="message-4" onClick={() => resendOtp()}>Resend OTP</span>}
                        </div>
                        <div className="login-button">
                            {(otp.length < 6) &&
                                (<button disabled={true} className="button-solid button-inactive">
                                    Submit
                                </button>)}
                            {(otp.length >= 6) &&
                                (<button onClick={submitHandler} disabled={false} type="submit"
                                         className="button-solid button-active">
                                    Submit
                                </button>)}
                        </div>
                        {errors.isError && <div className="error-message">
                            <TbAlertHexagon className="icon error-icon float-left-child"/>
                            <div className="d-inline message-1 ms-1">{errors.errorMessage}</div>
                        </div>}
                    </form>
                    <div className="copyright">
                        <p className="sub-footer">v5.2</p>
                        <p className="sub-footer">copyright text</p>
                    </div>
                </Card>
            </Col>
            <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                <LoginImage/>
            </Col>
        </Row>
    );
}

export default VerificationCodeSend;