import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import {
    editSuperAdmin,
    getSuperAdminById,
    resetEditSuperAdmin,
    resetGetSuperAdminById
} from "../../actions/SuperAdminActions";
import DropdownItemsInput from "../../components/DropdownItemsInput";
import status_type from "../../assets/json/admin_status.json";

const EditSuperAdmin = (props) => {
    const pageName = 'super-admin-management';
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const superAdminNameRef = useRef();
    const superAdminUserIdRef = useRef();
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [fieldIsValid, setFieldIsValid] = useState({
        isSuperAdminName: true,
        isSuperAdminId: true,
        isActive: true,
    });
    const [fieldData, setFieldData] = useState({
        dataName: null,
        dataId: null,
        dataActive: null
    });

    useEffect(() => {
        dispatch(getSuperAdminById(params.id))
    }, [params.id]);

    const getSuperAdminByIdDetails = useSelector(state => state.getSuperAdminById)
    const {error, loading, super_admin_data} = getSuperAdminByIdDetails

    useEffect(() => {
        // setDocuments(getDocuments.document_list)
        if (!getSuperAdminByIdDetails.super_admin_data <= 0) {
            setFieldData({
                ...fieldData,
                dataActive: getSuperAdminByIdDetails.super_admin_data.isActive === true ? 1 : 0,
            })
        }
    }, [])

    const editSuperAdminDetails = useSelector(state => state.editSuperAdmin)
    const {errorEdit, loadingEdit, super_admin} = editSuperAdminDetails
    //
    useEffect(() => {
        if (editSuperAdminDetails.super_admin === true) {
            dispatch(resetEditSuperAdmin())
            setAlertModal({
                display: true,
                type: "success",
                message: "Super admin user updated"
            })
        } if (editSuperAdminDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Could not update User"
            })
        }
    }, [editSuperAdminDetails]);



    const onReset = (e) => {
        e.preventDefault()
        dispatch(resetGetSuperAdminById())
        dispatch(getSuperAdminById(params.id))
    }

    const documentTitleKeyPress = (event) => {
        event.preventDefault();
        console.log(superAdminNameRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&.^*,-]{4,100}$/.test(superAdminNameRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminName: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminName: true,
            });
        }
    }
    const categoryKeyPress = (event) => {
        event.preventDefault();
        console.log(superAdminUserIdRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&.^*,-]{4,100}$/.test(superAdminUserIdRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminId: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminId: true,
            });
            setFieldData({
                ...fieldData,
                dataDocumentCategory: superAdminUserIdRef.current.value
            })
        }
    }
    const goBack = () => {
        history('/super-admin-management')
    }

    const openLink = (e) => {
        e.preventDefault()
        dispatch(editSuperAdmin(params.id, superAdminNameRef.current.value, superAdminUserIdRef.current.value, fieldData.dataActive == 0 ? false : true));
    }

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetEditSuperAdmin())
        dispatch(resetGetSuperAdminById())
        history("/super-admin-management")
    }

    const activeData = (data) => {
        if (data.id == 0) {
            setFieldData({
                ...fieldData,
                dataActive: data.id
            })
        }
        if (data.id == 1) {
            setFieldData({
                ...fieldData,
                dataActive: data.id
            })
        }
    }

    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="super_admin_add" title="Edit Super Admin" result={goBack}/>
                    <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getSuperAdminByIdDetails.super_admin_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Super Admin Name<span className="asterics">*</span></label>
                                                <input ref={superAdminNameRef} onChange={documentTitleKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getSuperAdminByIdDetails.super_admin_data.adminName}
                                                       className={!fieldIsValid.isSuperAdminName ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                {/*categoryStatusData*/}
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Super Admin Username<span className="asterics">*</span></label>
                                                <input ref={superAdminUserIdRef} onChange={categoryKeyPress}
                                                       type="text" autoComplete="off"
                                                       defaultValue={getSuperAdminByIdDetails.super_admin_data.prismaUserId}
                                                       className={!fieldIsValid.isSuperAdminId ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Username"
                                                       id="document_title"/>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Super Admin Status<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={status_type} result={activeData}
                                                                        value={getSuperAdminByIdDetails.super_admin_data.isActive == true ? 1 : 0}
                                                                        className={"background-1 dropdown-input-content-valid"}
                                                                        title="Select Process Type"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {(
                                            !fieldIsValid.isSuperAdminName
                                            || !fieldIsValid.isSuperAdminId
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isSuperAdminName
                                            && fieldIsValid.isSuperAdminId
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={openLink}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>

                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditSuperAdmin;