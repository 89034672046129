import React from 'react';

function Home(props) {
    return (
        
        <svg className={props.className} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.50003 15.2917C7.15837 15.2917 6.87503 15.0084 6.87503 14.6667V11.1751L6.27503 11.7751C6.03337 12.0167 5.63337 12.0167 5.3917 11.7751C5.15003 11.5334 5.15003 11.1334 5.3917 10.8917L7.05837 9.22507C7.23337 9.05007 7.50837 8.99174 7.7417 9.09174C7.97503 9.18341 8.12503 9.41674 8.12503 9.66674V14.6667C8.12503 15.0084 7.8417 15.2917 7.50003 15.2917Z"
                fill={props.color}/>
            <path
                d="M9.16678 11.9585C9.00845 11.9585 8.85011 11.9002 8.72511 11.7752L7.05845 10.1085C6.81678 9.86686 6.81678 9.46686 7.05845 9.2252C7.30011 8.98353 7.70011 8.98353 7.94178 9.2252L9.60845 10.8919C9.85011 11.1335 9.85011 11.5335 9.60845 11.7752C9.48345 11.9002 9.32511 11.9585 9.16678 11.9585Z"
                fill={props.color}/>
            <path
                d="M12.5001 19.4587H7.50008C2.97508 19.4587 1.04175 17.5253 1.04175 13.0003V8.00033C1.04175 3.47533 2.97508 1.54199 7.50008 1.54199H11.6667C12.0084 1.54199 12.2917 1.82533 12.2917 2.16699C12.2917 2.50866 12.0084 2.79199 11.6667 2.79199H7.50008C3.65841 2.79199 2.29175 4.15866 2.29175 8.00033V13.0003C2.29175 16.842 3.65841 18.2087 7.50008 18.2087H12.5001C16.3417 18.2087 17.7084 16.842 17.7084 13.0003V8.83366C17.7084 8.49199 17.9917 8.20866 18.3334 8.20866C18.6751 8.20866 18.9584 8.49199 18.9584 8.83366V13.0003C18.9584 17.5253 17.0251 19.4587 12.5001 19.4587Z"
                fill={props.color}/>
            <path
                d="M18.3334 9.45841H15.0001C12.1501 9.45841 11.0417 8.35007 11.0417 5.50007V2.16674C11.0417 1.91674 11.1917 1.68341 11.4251 1.59174C11.6584 1.49174 11.9251 1.55007 12.1084 1.72507L18.7751 8.39174C18.9501 8.56674 19.0084 8.84174 18.9084 9.07507C18.8084 9.3084 18.5834 9.45841 18.3334 9.45841ZM12.2917 3.67507V5.50007C12.2917 7.65007 12.8501 8.20841 15.0001 8.20841H16.8251L12.2917 3.67507Z"
                fill={props.color}/>
        </svg>

    );
}

export default Home;