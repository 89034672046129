import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import { Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import "../../assets/fontawesome-free/css/all.min.css"
import "../../datatable/css/jquery.dataTables.css";

import {useLocation, useNavigate} from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import {
    resetAddDocument,
} from "../../actions/DocumentActions";
import Loader from "../../components/Loader";
import DataTableClient from "../../components/SuperAdmin/DataTableClient";
import {getAllClients} from "../../actions/ClientManagementActions";

function ClientManagement(props) {
    const pageName = 'client-management';
    const history = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [pageSize, setPageSize] = useState(5)
    const [pageNumber, setPageNumber] = useState(1)
    const [record, setRecord] = React.useState();
    const [documents, setDocuments] = React.useState([]);

    const columns = React.useMemo(() => [
        {
            Header: 'Id',
            accessor: 'clientMappingId',
        },
        {
            Header: 'Name',
            accessor: 'clientName',
        },
        {
            Header: 'Client Id',
            accessor: 'clientId',
        },
        {
            Header: 'Admin count',
            accessor: 'adminUserCount',
        },
        {
            Header: 'Teacher count',
            accessor: 'teacherAssociateCount',
        }
    ], []);

    const getAllClientDetails = useSelector(state => state.getAllClient)
    const {error, loading, client_data} = getAllClientDetails

    useEffect(() => {

        if (!getAllClientDetails.client_data <= 0) {
            setRecord(getAllClientDetails.client_data.length)
            getAllClientDetails.client_data.map((item, index) => {
                setDocuments((prevData) => [
                    ...prevData,
                    {
                        clientMappingId: item.clientMappingId,
                        clientName: item.clientName,
                        clientId: item.clientId,
                        adminUserCount: item.adminUserCount,
                        teacherAssociateCount: item.teacherAssociateCount,
                        isTrainingEnable: item.isTrainingEnable,
                    }
                ])
            });
        }
    }, [getAllClientDetails.client_data]);

    const changePage = (data) => {
        setDocuments([])
        setPageNumber(data)
    }

    useEffect(() => {
        dataTableReload()
        if (location.state?.add) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Client user created"
            })
            history('/client-management', {state: {}})
        }
        if (location.state?.edit) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Client user updated"
            })
            history('/client-management', {state: {}})
        }
        if (location.state?.delete) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Client deleted successfully"
            })
            history('/client-management', {state: {}})
        }
    }, [])


    const dataTableReload = () => {
        setDocuments([])
        dispatch(getAllClients())
    }
    const editDoc = (data) => {
        // history(`/super-admin-edit/${data.superAdminMappingId}`)
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddDocument())
        // window.location.href='/documents'
    }


    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}

            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="client_list" title="Client List"/>

                <Row className="app-body-super-admin background-1 m-3">
                    {/*<div className="pl-5 pb-2 background-1"*/}
                    {/*>*/}
                    {documents && <div className="background-2">
                        <div className="card-body-table mt-4">
                            <DataTableClient defaultPageSize={9}
                                             pageSize={record} columns={columns} data={documents} recordData={record}
                                             result={changePage}
                            />
                        </div>
                    </div>}
                    <Footer/>
                </Row>
            </div>

        </Row>
    </>);
}

export default ClientManagement;