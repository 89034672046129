import React, {useEffect, useState} from 'react';
import { MultiSelect } from 'primereact/multiselect';
import "../assets/css/dropdown-multiselect.css"

function DropdownMultiselect(props) {
    const [selectedCity, setSelectedCity] = useState(props.value);
    const cities = props.data

    let selectedOptionObject = cities.find(cities => cities.id === selectedCity);

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            setSelectedCity([])
        }
    }, [props.value]);

    const setDropdownResult = (e) => {
        setSelectedCity(e.value)
        props.result(e.value)
    }

    return (
        <div className={props.valid === true ? "card flex justify-content-center" : "card flex justify-content-center-error"}>
            <MultiSelect value={selectedOptionObject || selectedCity} onChange={setDropdownResult} options={cities} optionLabel="data"
                         filter placeholder={props.title} maxSelectedLabels={3} className="w-full md:w-14rem" />
            {/*<Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="data"*/}
            {/*          editable placeholder={props.title} className="w-full md:w-14rem" />*/}
        </div>
    );
}

export default DropdownMultiselect;