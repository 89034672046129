import api from './api';
import {
    ADD_PROCESS_TEMPLATE_SUCCESS,
    ADD_PROCESS_TEMPLATE_REQUEST,
    ADD_PROCESS_TEMPLATE_FAIL,
    ADD_PROCESS_TEMPLATE_RESET,

    EDIT_PROCESS_TEMPLATE_SUCCESS,
    EDIT_PROCESS_TEMPLATE_REQUEST,
    EDIT_PROCESS_TEMPLATE_FAIL,
    EDIT_PROCESS_TEMPLATE_RESET,

    GET_PROCESS_TEMPLATE_BY_ID_SUCCESS,
    GET_PROCESS_TEMPLATE_BY_ID_REQUEST,
    GET_PROCESS_TEMPLATE_BY_ID_FAIL,
    GET_PROCESS_TEMPLATE_BY_ID_RESET,

    GET_ALL_PROCESS_TEMPLATES_REQUEST,
    GET_ALL_PROCESS_TEMPLATES_SUCCESS,
    GET_ALL_PROCESS_TEMPLATES_FAIL,
    GET_ALL_PROCESS_TEMPLATES_RESET,

    ADD_PROCESS_SUCCESS,
    ADD_PROCESS_REQUEST,
    ADD_PROCESS_FAIL,
    ADD_PROCESS_RESET,

    EDIT_TASK_BY_ID_REQUEST,
    EDIT_TASK_BY_ID_SUCCESS,
    EDIT_TASK_BY_ID_FAIL,
    EDIT_TASK_BY_ID_RESET,

    GET_PROCESS_BY_ID_SUCCESS,
    GET_PROCESS_BY_ID_REQUEST,
    GET_PROCESS_BY_ID_FAIL,
    GET_PROCESS_BY_ID_RESET,

    GET_ALL_PROCESS_REQUEST,
    GET_ALL_PROCESS_SUCCESS,
    GET_ALL_PROCESS_FAIL,
    GET_ALL_PROCESS_RESET,

    GET_TASK_BY_PROCESS_ID_REQUEST,
    GET_TASK_BY_PROCESS_ID_SUCCESS,
    GET_TASK_BY_PROCESS_ID_FAIL,
    GET_TASK_BY_PROCESS_ID_RESET,

    COMPLETE_ALL_TASK_REQUEST,
    COMPLETE_ALL_TASK_SUCCESS,
    COMPLETE_ALL_TASK_FAIL,
    COMPLETE_ALL_TASK_RESET,

    CANCEL_ALL_TASK_REQUEST,
    CANCEL_ALL_TASK_SUCCESS,
    CANCEL_ALL_TASK_FAIL,
    CANCEL_ALL_TASK_RESET,

    GET_UPCOMING_TASK_REQUEST,
    GET_UPCOMING_TASK_SUCCESS,
    GET_UPCOMING_TASK_FAIL,
    GET_UPCOMING_TASK_RESET,

    SEND_NUDGE_REQUEST,
    SEND_NUDGE_SUCCESS,
    SEND_NUDGE_FAIL,
    SEND_NUDGE_RESET,

    MARK_TASK_AS_COMPLETE_REQUEST,
    MARK_TASK_AS_COMPLETE_SUCCESS,
    MARK_TASK_AS_COMPLETE_FAIL,
    MARK_TASK_AS_COMPLETE_RESET

} from "../constants/ProcessConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addProcessTemplate = (processType, processData, task) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_PROCESS_TEMPLATE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/ProcessTemplate/AddEditProcessTemplate`,
            {
                title: processData,
                templateType: processType,
                tasks: task
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_PROCESS_TEMPLATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_PROCESS_TEMPLATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_PROCESS_TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editProcessTemplate = (processType, processData, active, task, id) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_PROCESS_TEMPLATE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/ProcessTemplate/AddEditProcessTemplate`,
            {
                processTemplateId: id,
                title: processData,
                templateType: processType,
                isActive: active,
                tasks: task
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_PROCESS_TEMPLATE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_PROCESS_TEMPLATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_PROCESS_TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getProcessTemplateById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_PROCESS_TEMPLATE_BY_ID_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/ProcessTemplate/GetProcessTemplateById?ProcessTemplateId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_PROCESS_TEMPLATE_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_PROCESS_TEMPLATE_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PROCESS_TEMPLATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllProcessTemplate = (pageSize, pageNumber) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_PROCESS_TEMPLATES_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/ProcessTemplate/GetAllProcessTemplates?PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_PROCESS_TEMPLATES_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_PROCESS_TEMPLATES_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PROCESS_TEMPLATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getTasksProcessById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_TASK_BY_PROCESS_ID_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/ProcessTemplate/GetProcessTemplateTaskByPTId?ProcessTemplateId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_TASK_BY_PROCESS_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_TASK_BY_PROCESS_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_TASK_BY_PROCESS_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const addProcess = (processTemplateId, title, assignToId, assignToIds, processTasks) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_PROCESS_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/CreateProcess`,
            {
                processTemplateId: processTemplateId,
                title: title,
                assignToId: assignToId,
                assignToIds: assignToIds,
                processTasks: processTasks,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_PROCESS_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_PROCESS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_PROCESS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllProcesses = (pageSize, pageNumber, processName, processStatus) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_PROCESS_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }
        var processNameString = processName == undefined ? '' : processName
        var processStatusString = processStatus == undefined ? '' : processStatus

        const {data} = await api.get(`${url}/Process/GetAllProcess?name=${processNameString}&StatusId=${processStatusString}&PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_PROCESS_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_PROCESS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PROCESS_TEMPLATE_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getProcessesById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_PROCESS_BY_ID_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Process/GetProcessTemplateTaskByPTId?ProcessId=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_PROCESS_BY_ID_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_PROCESS_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PROCESS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editProcessTask = (processTaskId, assignToId, notes, statusId) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_TASK_BY_ID_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/UpdateProcessTaskDetail`,
            {
                processTaskId: processTaskId,
                assignToId: assignToId,
                notes: notes,
                statusId: statusId,
            }, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_TASK_BY_ID_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_TASK_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_TASK_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const completeAllTask = (processId) => async (dispatch, getState) => {
    try {
        dispatch({type: COMPLETE_ALL_TASK_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/CompleteProcess?ProcessId=${processId}`, {}, config
        )
        if (data.success === true) {
            dispatch({
                type: COMPLETE_ALL_TASK_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: COMPLETE_ALL_TASK_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: COMPLETE_ALL_TASK_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const cancelAllTask = (processId) => async (dispatch, getState) => {
    try {
        dispatch({type: CANCEL_ALL_TASK_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/CancelProcess?ProcessId=${processId}`, {}, config
        )
        if (data.success === true) {
            dispatch({
                type: CANCEL_ALL_TASK_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: CANCEL_ALL_TASK_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: CANCEL_ALL_TASK_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getUpcomingTask = (pageNumber, pageSize) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_UPCOMING_TASK_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Process/GetUpcomingTasks?PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_UPCOMING_TASK_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.success === false) {
            dispatch({
                type: GET_UPCOMING_TASK_FAIL,
                payload: data.success
            })
        }

    } catch (error) {
        dispatch({
            type: GET_UPCOMING_TASK_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const sendTaskNudge = (processTaskId) => async (dispatch, getState) => {
    try {
        dispatch({type: SEND_NUDGE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/SendTaskNudge?ProcessTaskId=${processTaskId}`,
            {}, config
        )
        if (data.success === true) {
            dispatch({
                type: SEND_NUDGE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: SEND_NUDGE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: SEND_NUDGE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const markTaskAsComplete = (processTaskId) => async (dispatch, getState) => {
    try {
        dispatch({type: MARK_TASK_AS_COMPLETE_REQUEST})

        const {
            getUserAccessLevel: {userInfo},
        } = getState()

        const config = {
            headers: {
                'TimezoneOffset': new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Process/MarkTaskAsComplete?ProcessTaskId=${processTaskId}`,
            {}, config
        )
        if (data.success === true) {
            dispatch({
                type: MARK_TASK_AS_COMPLETE_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: MARK_TASK_AS_COMPLETE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: MARK_TASK_AS_COMPLETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

// RESET

export const resetAddProcessTemplate = () => async (dispatch, getState) => {
    dispatch({
        type: ADD_PROCESS_TEMPLATE_RESET,
    })
};
export const resetEditProcessTemplate = () => async (dispatch, getState) => {
    dispatch({
        type: EDIT_PROCESS_TEMPLATE_RESET,
    })
};
export const resetGetProcessTemplateById = () => async (dispatch, getState) => {
    dispatch({
        type: GET_PROCESS_TEMPLATE_BY_ID_RESET,
    })
};
export const resetGetAllProcessTemplate = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_PROCESS_TEMPLATES_RESET,
    })
};
export const resetGetAllProcesses = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_PROCESS_RESET,
    })
};
export const resetGetProcessById = () => (dispatch, getState) => {
    dispatch({
        type: GET_PROCESS_BY_ID_RESET,
    })
};
export const resetGetTasksProcessById = () => (dispatch, getState) => {
    dispatch({
        type: GET_TASK_BY_PROCESS_ID_RESET,
    })
};
export const resetAddProcess = () => async (dispatch, getState) => {
    dispatch({
        type: ADD_PROCESS_RESET,
    })
};
export const resetEditTaskById = () => async (dispatch, getState) => {
    dispatch({
        type: EDIT_TASK_BY_ID_RESET,
    })
};
export const resetCompleteAllTasks = () => async (dispatch, getState) => {
    dispatch({
        type: COMPLETE_ALL_TASK_RESET,
    })
};
export const resetCancelAllTasks = () => async (dispatch, getState) => {
    dispatch({
        type: CANCEL_ALL_TASK_RESET
    })
};
export const resetSendTaskNudge = () => async (dispatch, getState) => {
    dispatch({
        type: SEND_NUDGE_RESET
    })
};
export const resetMarkTaskAsComplete = () => async (dispatch, getState) => {
    dispatch({
        type: MARK_TASK_AS_COMPLETE_RESET
    })
};