import React from 'react';
import '../../assets/css/switch.css'
function Switch(props) {
    let isLoggedStatus = props.isToggledStatus
    const [isToggled, setIsToggled] = React.useState(false);
    const onToggle = () => {
        setIsToggled(!isToggled)
        props.result(props.type)
    };
    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={isLoggedStatus} onChange={onToggle}/>
            <span className="switch"/>
        </label>
    );
};

export default Switch;