import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import DropdownItems from "../components/DropdownItems";
import "../assets/fontawesome-free/css/all.min.css"
import "jquery/dist/jquery.min.js";
import "../assets/css/card-table.css"
import "datatables.net-dt/js/dataTables.dataTables";
import {useNavigate, useLocation} from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import TickCircle from "../components/Icons/TickCircle";
import CancelCircle from "../components/Icons/CancelCircle";
import AlertModal from "../components/AlertModal";
import {getAllLetterNotices, getAllLetterTemplate} from "../actions/LetterNoticesActions";
import DataTableCardLetters from "../components/DataTable/DataTableCardLetters";
import Loader from "../components/Loader";
import EmptyTable from "../assets/images/empty_table/letters.svg"
import DataTable from "../components/DataTable/DataTable";
import Eye from "../components/Icons/Eye";
import {GrPowerReset} from "react-icons/gr";
import {getAllLettersTemplatesList} from "../actions/ConfigActions";
import DropdownCalendarRangeSingle from "../components/DropdownCalendarRangeSingle";


function LettersNotices(props) {
    const pageName = 'letters';
    const [showDelete, setShowDelete] = useState(false)
    const [type, setType] = useState("")
    const [templates, setTemplate] = useState(true)
    const [templatesData, setTemplatesData] = useState([])
    const [record, setRecord] = useState(true)
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(9)
    const [letters, setLetters] = useState(false)
    const [lettersData, setLettersData] = useState([])
    const [lettersTemplateList, setLettersTemplateList] = useState([])
    const [recordLetters, setRecordLetters] = useState(true)
    const [pageNumberLetters, setPageNumberLetters] = useState(1);
    const [pageSizeLetters, setPageSizeLetters] = useState(5)
    const [pageSizeLettersList, setPageSizeLettersList] = useState(1000)
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [fieldData, setFieldData] = useState({
        dataTemplate: "",
        dataFrom: "",
        dataTo: "",
    })
    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const reloadTemplate = () => {
        dispatch(getAllLetterTemplate(pageSize, pageNumber))
    }
    const reloadTemplateList = () => {
        dispatch(getAllLettersTemplatesList(pageSizeLettersList, pageNumber))
    }
    const reloadLettersNotices = () => {
        dispatch(getAllLetterNotices(pageSizeLetters, pageNumberLetters, fieldData.dataTemplate, formatDate(fieldData.dataFrom), formatDate(fieldData.dataTo)))
    }

    useEffect(() => {
        reloadTemplate()
    }, [pageNumber]);

    useEffect(() => {
        reloadLettersNotices()
        reloadTemplateList()
    }, [pageNumberLetters, fieldData]);

    const handleResult = (data) => {
        console.log(data)
        if (data[0] === "1") {
            setPageNumber(data[1])
        }
        if (data[0] === "2") {
            history(`/edit-template/${data[1]}`)
        }
    }
    const changePage = (data) => {
        setLettersData([])
        setPageNumberLetters(data)
    }

    const columns = React.useMemo(() => [
        {
            Header: 'Id',
            accessor: 'letterTemplateId',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Description',
            accessor: 'descriptions',
        },
        {
            Header: 'Hard Copy',
            accessor: 'isHardcopyRequired',
        },
        {
            Header: 'Created Date',
            accessor: 'createdDate',
        },
    ], []);

    const columnsLettersNotices = React.useMemo(() => [
        {
            Header: 'Template Name',
            accessor: 'title',
            width: '20%',
        },
        {
            Header: 'Description',
            accessor: 'documentText',
            width: '35%',
        },
        {
            Header: 'Date',
            accessor: 'createdDate',
            width: '15%',
        },
        {
            Header: 'Hard Copy',
            accessor: 'isHardcopyRequired',
            width: '10%',
            Cell: ({row}) => (
                <div className="row background-1">
                    {row.original.isHardcopyRequired == true ? <TickCircle className="sidebar-icon"
                                                          color="var(--primary-100)"/> :
                        <CancelCircle className="sidebar-icon"
                                      color="var(--red)"/>}
                </div>
            ),
        },
        {
            Header: 'Action',
            id: 'actions', // The 'id' is necessary here since this column doesn't correspond to a data field
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editLetters(row.original)} className="col-12">
                        <Eye className="sidebar-icon"
                             color="var(--primary-100)"/>
                    </div>
                </div>
            ),
            width: '10%',
        },
    ], []);

    useEffect(() => {
        if (localStorage.getItem('letters-notices') === "1") {
            setType("1")
            setTemplate(true)
            setLetters(false)
            setTemplatesData([])
        }
        if (localStorage.getItem('letters-notices') == null || localStorage.getItem('letters-notices') === "2") {
            setType("2");
            setTemplate(false)
            setLetters(true)
        }
        if (location.state?.name) {
            setAlertModal({
                display: true,
                type: "success",
                message: location.state?.message
            })
            history('/letters-notices', {state: {}})
        }
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Letter Template created successfully"
            })
            history('/letters-notices', {state: {}})
        }
        // alert(location.state?.toString())
    }, [])

    const getAllLettersTemplate = useSelector(state => state.getAllLettersTemplate)
    const {error, loading, letterTemplateInfo} = getAllLettersTemplate

    useEffect(() => {
        setTemplatesData([])
        if (!getAllLettersTemplate.letters_template_list <= 0) {
            getAllLettersTemplate.letters_template_list.map((item, index) => {
                setTemplatesData((prevData) => [
                    ...prevData,
                    {
                        letterTemplateId: item.letterTemplateId,
                        title: item.title,
                        descriptions: item.descriptions,
                        isHardcopyRequired: item.isHardcopyRequired,
                        createdDate: item.createdDate,
                    }
                ])
            });
            if (getAllLettersTemplate.records !== undefined) {
                setRecord(getAllLettersTemplate.records)
            }
        }
    }, [getAllLettersTemplate.letters_template_list]);

    const getAllLettersTemplateListDetails = useSelector(state => state.getAllLettersTemplateList)
    const {erorAllList, loadingAllList,letter_template} = getAllLettersTemplateListDetails

    useEffect(() => {
        setLettersTemplateList([])
        if (!getAllLettersTemplateListDetails.letter_template_list <= 0) {
            console.log(getAllLettersTemplateListDetails.letter_template_list)
            getAllLettersTemplateListDetails.letter_template_list.map((item, index) => {
                setLettersTemplateList((prevData) => [
                    ...prevData,
                    {
                        id: item.letterTemplateId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getAllLettersTemplateListDetails.letter_template_list]);

    const getAllLettersNoticesDetails = useSelector(state => state.getAllLettersNotices)
    const {errorAll, loadingAll, letters_notices_list} = getAllLettersNoticesDetails

    useEffect(() => {
        setLettersData([])
        if (!getAllLettersNoticesDetails.letters_notices_list <= 0) {
            getAllLettersNoticesDetails.letters_notices_list.map((item, index) => {
                setLettersData((prevData) => [
                    ...prevData,
                    {
                        letterNoticeId: item.letterNoticeId,
                        letterTemplateId: item.letterTemplateId,
                        title: item.title,
                        documentText: item.documentText,
                        isHardcopyRequired: item.isHardcopyRequired,
                        createdDate: item.createdDate,
                    }
                ])
            });
            if (getAllLettersNoticesDetails.records !== undefined) {
                setRecordLetters(getAllLettersNoticesDetails.records)
            }
        }
    }, [getAllLettersNoticesDetails.letters_notices_list]);

    const editLetters = (data) => {
        history(`/letters-acknowledgement`, {
            state: {
                letterNoticeId: data.letterNoticeId,
                letterTemplateId: data.letterTemplateId,
                title: data.title,
                documentText: data.documentText,
                createdDate: data.createdDate,
                isHardcopyRequired: data.isHardcopyRequired,
            }
        })
    }
    function formatDate(dateString) {
        if (dateString !== null) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it is zero-indexed
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`
        } else {
            return ""
        }
    }

    const hideDeleteModal = (data) => {
        setShowDelete(false)
    }
    const templateHeader = (data) => {
        if (data === "1") {
            setTemplate(true)
            setLetters(false)
            console.log(data)
            setType(data)
            reloadTemplate()
            localStorage.setItem('letters-notices', "1")
        }
        if (data === "2") {
            setTemplate(false)
            setLetters(true)
            console.log(data)
            setType(data)
            reloadLettersNotices()
            reloadTemplateList()
            localStorage.setItem('letters-notices', "2")
        }

    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }
    const resetData = (e) => {
        // window.location.href = "/letters-notices"
        setFieldData({
            ...fieldData,
            dataTemplate: null,
            dataFrom: null,
            dataTo: null,
        })
    }
    const templateResult = (data) => {
        setFieldData({
            ...fieldData,
            dataTemplate: data.id
        })
    }
    const fromResult = (data) => {
        setFieldData({
            ...fieldData,
            dataFrom: data
        })
    }
    const toResult = (data) => {
        setFieldData({
            ...fieldData,
            dataTo: data
        })
    }

    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingAll && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type}
                            result={closeAlert}/>}
            {showDelete && <DeleteModal heading="Delete Document"
                                        type="delete"
                                        question="Are you sure want to delete this Document ?"
                                        answer=""
                                        result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="letters" title="Document List" type={type} result={templateHeader}/>
                {letters && <Row className="background-1 m-3 letters">
                    <Col lg={{span: 4}} md={{span: 3}}>
                        <DropdownItems data={lettersTemplateList} title="Select Template" result={templateResult}
                                       value={fieldData.dataTemplate}/>
                    </Col>
                    <Col lg={{span: 3}} md={{span: 3}}>
                        <DropdownCalendarRangeSingle title="From" result={fromResult} value={fieldData.dataFrom}/>
                    </Col>
                    <Col lg={{span: 3}} md={{span: 3}}>
                        <DropdownCalendarRangeSingle title="To" result={toResult} value={fieldData.dataTo}/>
                    </Col>
                    <Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>
                        <div className="float-right-child add-button button-solid reset-filter-button" onClick={resetData}>
                            <GrPowerReset classname="sidebar-icon" color="var(--white)"/> Reset
                        </div>
                    </Col>
                </Row>}

                {templates && <Row className="app-body-template background-1">
                    {templatesData && <Row className="background-1">
                        <div className="card-body-letters mt-4">
                            <DataTableCardLetters
                                pageSize={pageSize} columns={columns} data={templatesData} recordData={record}
                                result={handleResult} empty_table={EmptyTable} empty_text={"You have not created any Letter's template"}
                            />
                        </div>
                    </Row>}
                    <Footer/>
                </Row>}
                {letters && <Row className="app-body-template-letters background-1">
                    <Row className="background-1 ps-2">
                        <DataTable defaultPageSize={5}
                                   pageSize={pageSizeLetters} columns={columnsLettersNotices} data={lettersData}
                                   recordData={recordLetters}
                                   result={changePage} empty_table={EmptyTable} empty_text={"You have not sent any Letters yet"}
                        />
                    </Row>
                    <Footer/>
                </Row>}

            </div>

        </Row>
    </>)
        ;
}

export default LettersNotices;