import React, {useEffect, useMemo, useState} from 'react';
import {useTable, usePagination, useGlobalFilter} from 'react-table';
import "./css/DataTable.css"
import Calendar from "../Icons/Calendar";
import EmptyTable from "../../assets/images/empty_certificate_table.svg"
import HTMLViewer from "../HTMLViewer";

const DataTable = (props) => {
    const [image, setImage] = useState({EmptyTable})
    // ({columns, data, recordData})
    const [pageCount, setPageCount] = useState(1);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: {pageIndex, globalFilter, pageSize, rowCount},
        setGlobalFilter,
    } = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: {pageIndex: 0},
            manualPagination: true, // Tell the usePagination hook that we'll handle pagination ourselves
            pageCount
        },
        useGlobalFilter,
        usePagination
    );

    const memoizedColumns = useMemo(() => props.columns, [props.columns]);

    const startIndex = pageIndex * props.pageSize + 1;
    const endIndex = pageIndex * props.pageSize + page.length;
    const totalRecords = props.recordData;

    useEffect(() => {
        setPageCount(Math.ceil(props.recordData / props.pageSize))
    }, [props.recordData]);

    useEffect(() => {
        setGlobalFilter(props.searchTitle || undefined);
    }, [props.searchTitle, setGlobalFilter]);

    useEffect(() => {
        props.result(pageIndex + 1)
    }, [pageIndex]);

    useEffect(() => {
        if (props.filter === true) {
            gotoPage(0);
        }
    }, [props.filter]);

    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };

    const pagesToShow = 5; // Number of pages to show before and after ellipsis
    let firstPage = Math.max(0, pageIndex - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageCount - 1, firstPage + pagesToShow - 1);
    if (lastPage - firstPage < pagesToShow - 1) {
        firstPage = Math.max(0, lastPage - pagesToShow + 1);
    }
    const pages = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i);

    const statusName = (id) => {
        if (id == process.env.REACT_APP_PROCESS_IN_PROGRESS) {
            return (<span className="text-yellow">In Progress</span>)
        }
        if (id == process.env.REACT_APP_PROCESS_COMPLETED) {
            return (<span className="text-active">Completed</span>)
        }
        if (id == process.env.REACT_APP_PROCESS_CANCELLED) {
            return (<span className="text-inactive">Cancelled</span>)
        }
        if (id == process.env.REACT_APP_PROCESS_EXPIRED) {
            return (<span className="text-error">Expired</span>)
        }
    }

    const description = (data) => {
        return (
            <HTMLViewer htmlContent={truncateText(data, 100)}/>
        )
    }

    const truncateText = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    const roleName = (data) => {
        if (data == 1) {
            return "Admin"
        } else {
            return "Teacher/Associate"
        }
    }
    const nullValue = () => {
        return (<>

            </>
        )
    };

    const renderEmptyState = () => {
        return (
            <tr>
                <td colSpan={props.columns.length}>
                    <div className="react-table-empty">
                        <img src={props.empty_table} alt="No data"/>
                        <p className="empty-table-text">{props.empty_text}</p>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <>
            {/*<div className="m-3">*/}
            {/*    <input*/}
            {/*        value={globalFilter || ''}*/}
            {/*        onChange={e => setGlobalFilter(e.target.value)}*/}
            {/*        placeholder="Search..."*/}
            {/*    />*/}
            {/*</div>*/}
            <table {...getTableProps()} id="table-grid" className="table-react">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={column.width ? { width: column.width } : {}}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {props.recordData > 0 ? (
                    page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if ((cell.column.id === 'createdDate' || cell.column.id === 'docDate' || cell.column.id === 'pay_Date' ||
                                        cell.column.id === 'dateStarted' || cell.column.id === 'dateCompleted' || cell.column.id === 'eventDate' ||
                                        cell.column.id === 'validFromDate' || cell.column.id === 'validToDate' ||
                                        cell.column.id === 'fromDate') && cell.value) {
                                        const formattedDate = formatDateName(cell.value);
                                        return <td {...cell.getCellProps()}>
                                            <Calendar className="sidebar-icon margin-right"
                                                      color="var(--primary-100)"/>{formattedDate}
                                        </td>;
                                    } if ((cell.column.id === 'statusId') && cell.value) {
                                        const formattedStatus = statusName(cell.value);
                                        return <td {...cell.getCellProps()}>
                                            {formattedStatus}
                                        </td>;
                                    } if ((cell.column.id === 'documentText') && cell.value) {
                                        const formattedStatus = description(cell.value);
                                        return <td {...cell.getCellProps()}>
                                            {formattedStatus}
                                        </td>;
                                    }
                                    else
                                        return <td {...cell.getCellProps()}>{cell.value !== null ? cell.render('Cell') : nullValue()}</td>;
                                })}
                            </tr>
                        );
                    })
                ) : (
                    // setTimeout(() => {
                    renderEmptyState()
                    // }, 1000)
                )
                }
                </tbody>
            </table>
            <div className="row background-1">
                <div className="col-6 dataTables_info">
                    <div>
                        Displaying: {startIndex} to {endIndex} of {totalRecords} records
                    </div>
                </div>
                <div className="col-6 react-table-pagination" align="right">
                    <button className="paginate_button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fas fa-chevron-left table-icon"/>
                    </button>
                    {firstPage > 0 && <button onClick={() => gotoPage(firstPage - 1)}>...</button>}
                    {pages.map((pageNumber, index) =>
                        <button
                            key={pageNumber}
                            onClick={() => gotoPage(pageNumber)}
                            disabled={pageIndex === pageNumber}
                            className={`page-button ${pageIndex === pageNumber ? `active` : ``}`}
                        >
                            {pageNumber + 1}
                        </button>
                    )}
                    {lastPage < pageCount - 1 && <button onClick={() => gotoPage(lastPage + 1)}>...</button>}
                    <button className="paginate_button" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fas fa-chevron-right table-icon"/>
                    </button>
                </div>
            </div>
        </>
    );
};

export default DataTable;