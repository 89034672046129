import React, {useEffect, useMemo, useState} from 'react';
import {useTable, usePagination, useGlobalFilter} from 'react-table';
import "./css/DataTable.css"
import Tasks from "../Icons/Tasks";

import edit_button from "../../assets/images/edit_button.svg"

const DataTableCard = (props) => {
    const [pageCount, setPageCount] = useState(1);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: {pageIndex, globalFilter, pageSize, rowCount},
        setGlobalFilter,
    } = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: {pageIndex: 0},
            manualPagination: true, // Tell the usePagination hook that we'll handle pagination ourselves
            pageCount
        },
        useGlobalFilter,
        usePagination
    );

    const memoizedColumns = useMemo(() => props.columns, [props.columns]);

    const startIndex = pageIndex * props.pageSize + 1;
    const endIndex = pageIndex * props.pageSize + page.length;
    const totalRecords = props.recordData;

    useEffect(() => {
        setPageCount(Math.ceil(props.recordData / props.pageSize))
    }, [props.recordData]);

    useEffect(() => {
        props.result(["1", pageIndex + 1])
    }, [pageIndex]);

    useEffect(() => {
        if (props.filter)
            gotoPage(0)
    }, [props.filter]);

    const pagesToShow = 5; // Number of pages to show before and after ellipsis
    let firstPage = Math.max(0, pageIndex - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageCount - 1, firstPage + pagesToShow - 1);
    if (lastPage - firstPage < pagesToShow - 1) {
        firstPage = Math.max(0, lastPage - pagesToShow + 1);
    }
    const pages = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i);

    const renderEmptyState = () => {
        return (
            <tr className="react-table-empty-card">
                <td colSpan={props.columns.length}>
                    <div className="react-calendar-empty">
                        <img src={props.empty_table} alt="No data"/>
                        <p className="empty-table-text">{props.empty_text}</p>
                    </div>
                </td>
            </tr>
        );
    };

    const editTemplate = (id) => {
        props.result(["2", id])
    }

    return (
        <>
            <div className="table-container">
                <table {...getTableProps()} id="table-grid-card" className="table-react">
                    <thead style={{display: "none"}}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {props.recordData > 0 ? (
                        page.map(row => {
                            prepareRow(row);
                            const item = row.original;
                            return (
                                <tr {...row.getRowProps()} className="card">
                                    <td className="template-card-title">
                                        {item.title}
                                    </td>
                                    <td className="template-card-description pb-0">
                                        Process Type: {item.templateType}
                                    </td>
                                    <td>
                                        <div className="float-left-child pt-2">
                                            <Tasks className="sidebar-icon-form mb-1" color="var(--primary-100)"/>
                                            <span className="span-upload-process">{item.processTemplateTasksCount} Tasks Attached</span>
                                        </div>
                                        <div className="float-right-child pb-3"
                                             onClick={() => editTemplate(item.processTemplateId)}
                                        >
                                            <img src={edit_button} alt="edit" className="button-cursor"/>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })) : (
                        // setTimeout(() => {
                            renderEmptyState()
                        // }, 2000)
                        )
                    }
                    </tbody>
                </table>
            </div>
            <div className="row background-1">
                <div className="col-6 dataTables_info">
                    <div>
                        Displaying: {startIndex} to {endIndex} of {totalRecords} records
                    </div>
                </div>
                <div className="col-6 react-table-pagination" align="right">
                    <button className="paginate_button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fas fa-chevron-left table-icon"/>
                    </button>
                    {firstPage > 0 && <button onClick={() => gotoPage(firstPage - 1)}>...</button>}
                    {pages.map((pageNumber, index) =>
                        <button
                            key={pageNumber}
                            onClick={() => gotoPage(pageNumber)}
                            disabled={pageIndex === pageNumber}
                            className={`page-button ${pageIndex === pageNumber ? `active` : ``}`}
                        >
                            {pageNumber + 1}
                        </button>
                    )}
                    {lastPage < pageCount - 1 && <button onClick={() => gotoPage(lastPage + 1)}>...</button>}
                    <button className="paginate_button" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fas fa-chevron-right table-icon"/>
                    </button>
                </div>
            </div>
        </>
    );
};

export default DataTableCard;
