import React, {useRef, useState, useEffect} from 'react';
import "../assets/css/modal.css"
import CancleCircle from "./Icons/CancelCircle";
import InformationError from "./Icons/InformationError";
import TickSquare from "./Icons/TickSquare";

function DeleteModal(props) {

    useEffect(() => {
        setTimeout(() => {
            props.result(1)
        }, 3000);

    }, []);
    function toggleNo() {
        props.result(1)
    }

    return (
        <>
            <div className="darkBG lightBG" onClick={toggleNo}>
                <div className="modal-content model-content-alert">
                    <div className={props.type === "error" ? "modal-header modal-header-alert-error":"modal-header modal-header-alert"}>
                        <div className="modal-heading-title">
                            {props.type === "error" ?
                                <InformationError className="sidebar-icon-form" color="var(--white)"/>
                                :
                                <TickSquare className="sidebar-icon-form" color="var(--white)"/>
                            }
                        </div>
                        <div className="modal-heading-title ps-3 pe-3">
                            {props.heading}
                        </div>
                        <div className="modal-heading-title" onClick={toggleNo}>
                            <CancleCircle className="sidebar-icon-form" color="var(--white)"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeleteModal;