import React, {useRef, useState} from 'react';
import "../assets/css/modal.css"
import {IoClose} from "react-icons/io5";
import Trash from "./Icons/Trash";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import DropdownCalendarSingle from "./DropdownCalendarSingle";
import DropdownItemsInput from "./DropdownItemsInput";
import event_data from "../assets/json/event_type.json";
import link from "../assets/images/icons/link.svg";
import Error from "./Icons/Error";
import User from "./Icons/User";

function DeleteModal(props) {

    const user_list = props.user_list

    function toggleYes() {
        if (props.value) {
            props.result("1", props.value)
        } else {
            props.result(1)
        }
    }

    function toggleNo() {
        props.result(0)
    }

    return (
        <>
            <div className="darkBG" data-backdrop="static">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-heading-title">
                            {props.heading}
                        </div>
                        <div className="modal-heading-title" onClick={toggleNo}>
                            <IoClose/>
                        </div>
                    </div>

                    <div className="modal-body">
                        {props.type === "delete" && <div className="body-image-outer">
                            <Trash className="sidebar-icon"
                                   color="var(--white)"/>
                        </div>}
                        {props.type === "import" && <div className="body-image-outer">
                            <Error className="sidebar-icon"
                                   color="var(--white)"/>
                        </div>}
                        <div className="body-text">
                            <p className="p-question">
                                {props.question}
                            </p>
                            <p className="p-answer">
                                {props.answer}
                            </p>
                            {props.add_on &&
                                <div className="row background-1 email-list-div">
                                    {user_list.map((elm, index) => {
                                        return (
                                            <div className="col-6 padding-left-unset pt-3" key={index}>
                                                <User className="sidebar-icon mb-1"
                                                       color="var(--red)"/> <span> {elm.name}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="margin-right">
                            <button type="button" className="button-solid button-transparent-active modal-button"
                                    onClick={toggleNo}>
                                {props.button_false ? props.button_false : "Cancel"}
                            </button>
                        </div>
                        <div className="margin-left">
                            {props.type === "delete" &&
                                <button type="button" className="button-solid button-active modal-button"
                                        onClick={toggleYes}>
                                    Yes, Delete
                                </button>}
                            {props.type === "import" && !props.add_on &&
                                <button type="button" className="button-solid button-active modal-button"
                                        onClick={toggleYes}>
                                    {props.button_true}
                                </button>}
                            {props.add_on &&
                                <button type="button" className="button-solid button-active modal-button"
                                        onClick={toggleYes}>
                                    {props.button_true}
                                </button>}
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default DeleteModal;