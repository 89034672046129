import {
    ADD_SUPER_ADMIN_SUCCESS,
    ADD_SUPER_ADMIN_REQUEST,
    ADD_SUPER_ADMIN_FAIL,
    ADD_SUPER_ADMIN_RESET,

    EDIT_SUPER_ADMIN_SUCCESS,
    EDIT_SUPER_ADMIN_REQUEST,
    EDIT_SUPER_ADMIN_FAIL,
    EDIT_SUPER_ADMIN_RESET,

    GET_SUPER_ADMIN_BY_ID_SUCCESS,
    GET_SUPER_ADMIN_BY_ID_REQUEST,
    GET_SUPER_ADMIN_BY_ID_FAIL,
    GET_SUPER_ADMIN_BY_ID_RESET,

    GET_ALL_SUPER_ADMINS_REQUEST,
    GET_ALL_SUPER_ADMINS_SUCCESS,
    GET_ALL_SUPER_ADMINS_FAIL,
    GET_ALL_SUPER_ADMINS_RESET,

    DELETE_SUPER_ADMIN_REQUEST,
    DELETE_SUPER_ADMIN_SUCCESS,
    DELETE_SUPER_ADMIN_FAIL,
    DELETE_SUPER_ADMIN_RESET

} from "../constants/SuperAdminConstants"
const initialState = {};
export const addSuperAdminReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_SUPER_ADMIN_REQUEST:
            return { loading: true }

        case ADD_SUPER_ADMIN_SUCCESS:
            return {
                loading: false,
                super_admin_data: action.payload,
            }

        case ADD_SUPER_ADMIN_FAIL:
            return { loading: false, error: action.payload }

        case ADD_SUPER_ADMIN_RESET:
            return initialState;

        default:
            return state
    }
}
export const editSuperAdminReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_SUPER_ADMIN_REQUEST:
            return { loadingEdit: true }

        case EDIT_SUPER_ADMIN_SUCCESS:
            return {
                loadingEdit: false,
                super_admin: action.payload,
            }

        case EDIT_SUPER_ADMIN_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_SUPER_ADMIN_RESET:
            return initialState;

        default:
            return state
    }
}
export const getSuperAdminByIdReducer = (state = {super_admin_data: []}, action) => {
    switch (action.type) {
        case GET_SUPER_ADMIN_BY_ID_REQUEST:
            return { loading: true }

        case GET_SUPER_ADMIN_BY_ID_SUCCESS:
            return {
                loading: false,
                super_admin_data: action.payload,
            }

        case GET_SUPER_ADMIN_BY_ID_FAIL:
            return { loading: false, error: action.payload }

        case GET_SUPER_ADMIN_BY_ID_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllSuperAdminReducer = (state = {super_admin_data: [], records: ""}, action) => {
    switch (action.type) {
        case GET_ALL_SUPER_ADMINS_REQUEST:
            return { loading: true }

        case GET_ALL_SUPER_ADMINS_SUCCESS:
            return {
                loading: false,
                super_admin_data: action.payload,
            }

        case GET_ALL_SUPER_ADMINS_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_SUPER_ADMINS_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteSuperAdminReducer = (state = {super_admin_data: []}, action) => {
    switch (action.type) {
        case DELETE_SUPER_ADMIN_REQUEST:
            return { loadingDelete: true }

        case DELETE_SUPER_ADMIN_SUCCESS:
            return {
                loadingDelete: false,
                super_admin_data: action.payload,
            }

        case DELETE_SUPER_ADMIN_FAIL:
            return { loadingDelete: false, errorDelete: action.payload }

        case DELETE_SUPER_ADMIN_RESET:
            return initialState;

        default:
            return state
    }
}
