import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import {
    addClient,
    deleteClientById,
    editClient,
    getClientById,
    resetAddClient, resetDeleteClientById
} from "../../actions/ClientManagementActions";
import Switch from "../../components/SuperAdmin/Switch";
import DeleteModal from "../../components/DeleteModal";
import {deleteSchoolYear, getAllSchoolYearExist} from "../../actions/CalendarActions";

const EditClient = () => {
    const pageName = 'client-management';
    const history = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const superAdminNameRef = useRef();
    const superAdminUserIdRef = useRef();
    const [showDelete, setShowDelete] = useState(false)
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [fieldIsValid, setFieldIsValid] = useState({
        isSuperAdminName: true,
        isSuperAdminId: true,
        isTrainingData: false,
    });
    const [fieldData, setFieldData] = useState({
        dataName: null,
        dataId: null,
        dataTraining: null,
    });

    const getClientByIdDetails = useSelector(state => state.getClientById)
    const {errorGetClient, loadingGetClient, client_data} = getClientByIdDetails

    useEffect(() => {
        if (!getClientByIdDetails.client_data <= 0) {
            setFieldData({
                ...fieldData,
                dataName: getClientByIdDetails.client_data.clientName,
                dataId: getClientByIdDetails.client_data.clientId,
                dataTraining: getClientByIdDetails.client_data.isTrainingEnable,
            })
        }
    }, [getClientByIdDetails.client_data]);

    const addClientDetails = useSelector(state => state.addClient)
    const {error, loading, super_admin_list} = addClientDetails

    useEffect(() => {
        if (addClientDetails.client_data === true) {
            dispatch(resetAddClient())
            history('/client-management',{state:{edit: "1"}})
        } if (addClientDetails.error) {
            setAlertModal({
                display: true,
                type: error,
                message: "Could not add User"
            })
        }
    }, [addClientDetails]);

    const deleteClientByIdDetails = useSelector(state => state.deleteClientById)
    const {errorDelete, loadingDelete, school_year} = deleteClientByIdDetails

    useEffect(() => {
        if (deleteClientByIdDetails.client_delete === true) {
            dispatch(resetDeleteClientById())
            history('/client-management',{state:{delete: "1"}})
        } if (deleteClientByIdDetails.errorDelete) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Client not deleted"
            })
        }
    }, [deleteClientByIdDetails]);

    useEffect(() => {
        dispatch(getClientById(params.id))
    }, []);

    const onReset = (e) => {
        e.preventDefault()
        dispatch(getClientById(params.id))
    }

    const documentTitleKeyPress = (event) => {
        event.preventDefault();
        console.log(superAdminNameRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$.%&*,-]{4,100}$/.test(superAdminNameRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminName: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminName: true,
            });
        }
    }
    const categoryKeyPress = (event) => {
        event.preventDefault();
        console.log(superAdminUserIdRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$.%&*,-]{4,100}$/.test(superAdminUserIdRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminId: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isSuperAdminId: true,
            });
            setFieldData({
                ...fieldData,
                dataDocumentCategory: superAdminUserIdRef.current.value
            })
        }
    }
    const goBack = () => {
        history('/client-management')
    }

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddClient())
    }
    const toggleResult = (data) => {
        if (data == 1) {
            if (fieldData.dataTraining == true) {
                setFieldData({
                    ...fieldData,
                    dataTraining: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataTraining: true
                })
            }
        }
    }
    const updateClient = (e) => {
        e.preventDefault()
        dispatch(editClient(params.id, superAdminNameRef.current.value, superAdminUserIdRef.current.value, fieldData.dataTraining));
    }
    const openLink = (data) => {
        if (data == 0) {
            setShowDelete(true)
            document.body.style.overflow = 'hidden';
        } else {
            history('/client-management')
        }
    }
    const hideDeleteModal = (data) => {
        if (data == 1) {
            dispatch(deleteClientById(params.id))
        }
        setShowDelete(false)
        document.body.style.overflow = 'unset';
    }

    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingDelete && <Loader/>}
                {loadingGetClient && <Loader/>}
                {showDelete && <DeleteModal heading="Delete client"
                                            type="delete"
                                            question="Are you sure want to delete this client?"
                                            answer=""
                                            result={hideDeleteModal}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="client_edit" title="Edit Client" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getClientByIdDetails.client_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Client Name<span className="asterics">*</span></label>
                                                <input ref={superAdminNameRef} onChange={documentTitleKeyPress}
                                                       defaultValue={getClientByIdDetails.client_data.clientName}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isSuperAdminName ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Client Name"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                {/*categoryStatusData*/}
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Client Number<span className="asterics">*</span></label>
                                                <input ref={superAdminUserIdRef} onChange={categoryKeyPress}
                                                       type="number" autoComplete="off"
                                                       defaultValue={getClientByIdDetails.client_data.clientId}
                                                       className={!fieldIsValid.isSuperAdminId ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Client Number"
                                                       id="document_title"/>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 4, offset: 0}} md={{span: 4, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataTraining ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Training
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataTraining} type={1}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>


                                    {(
                                            !fieldIsValid.isSuperAdminName
                                            || !fieldIsValid.isSuperAdminId
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isSuperAdminName
                                            && fieldIsValid.isSuperAdminId
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={updateClient}
                                                >
                                                    Update
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>

                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditClient;