import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import DropdownItemsInput from "./DropdownItemsInput";
import Send from "./Icons/Send";
import {useSelector} from "react-redux";

function DynamicProcessManage(props) {
    const notesTypeRef = useRef();
    const [teacher, setTeacher] = useState([])
    const [status, setStatus] = useState([])

    const [fieldIsValid, setFieldIsValid] = useState({
        isTaskValid: false,
        isAssignValid: false,
        isNotesValid: false,
    });

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const getStatusList = useSelector(state => state.getStatus)
    const {status_list} = getStatusList

    useEffect(() => {
        if (!getStatusList.status_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getStatusList.status_list.map((item, index) => {
                setStatus((prevData) => [
                    ...prevData,
                    {
                        id: item.processStatusId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getStatusList.status_list]);

    const notesKeyPress = (event, taskId) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*,-]{1,1000}$/.test(notesTypeRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: false,
            });
            props.result(["3", taskId, notesTypeRef.current.value])
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isNotesValid: true,
            });
            props.result(["3", taskId, notesTypeRef.current.value])
        }
    }
    const taskStatus = (taskId, data) => {
        props.result(["1", taskId, data.id])
    }
    const assignData = (taskId, data) => {
        props.result(["2", taskId, data.id])
    }

    function taskUpdate(taskId, data) {
        props.result(["0", taskId, data])
    }

    function taskNudge(taskId, data) {
        props.result(["5", taskId])
    }

    return (
        <>
            <Row className="background-1 border-bottom-div pb-4" key={props.id}>
                <Row className="background-1 pt-4">
                    <div className="col-6 float-left-child template-card-title padding-left-unset">
                        Task {props.id}
                    </div>
                    <div onClick={() => taskNudge(props.id)}
                                             className="col-6 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <Send className="sidebar-icon-form"
                              color="var(--primary-100"/> <span className="message-6 text-blue">Nudge</span>
                    </div>
                </Row>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Tasks<span className="asterics">*</span></label>
                        <input
                               type="text" autoComplete="off"
                               readOnly={true}
                               defaultValue={props.task_name}
                               className={"input-valid-form input-spacing opacity-40"}
                               placeholder="Enter Process Type"
                               id="document_title"/>
                    </div>
                </Col>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-right-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Assign To<span className="asterics">*</span></label>
                        <div>
                            <DropdownItemsInput data={teacher} result={(data) => assignData(props.id, data)}
                                                value={props.assign}
                                                className={props.assign !== '' ?
                                                    "background-1 dropdown-input-content-valid" : "background-1 dropdown-input-content"}
                                                title="Select Assign To"/>
                        </div>
                    </div>
                </Col>

                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="teacher-associate"
                               className="form-label form-spacing">
                            Tasks<span className="asterics">*</span></label>
                        <div>
                            <DropdownItemsInput data={status}
                                                result={(data) => taskStatus(props.id, data)} value={props.status}
                                                className={"background-1 dropdown-input-content-valid"}
                                                title="Select Task"/>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                     className="padding-right-unset padding-left-unset">
                    <div className={props.note == '' ? "document-form-textarea-error"
                        : "document-form-textarea"}
                    >
                                                <span className="text-label form-spacing">
                                                    Note<span className="asterics">*</span></span>
                        <textarea ref={notesTypeRef} onChange={(e) => notesKeyPress(e, props.id)} defaultValue={props.note}
                                  className="textarea-input-error textarea-spacing"
                                  placeholder="Add Notes" rows="4"
                                  id="document_title"/>
                    </div>
                </Col>
                {props.update && props.note == "" && <Row className="background-1 pt-4">
                    <div className="col-6 float-left-child template-card-title padding-left-unset">

                    </div>
                    {<div
                          className="col-6 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <div className="button-solid button-inactive button-size-50 float-right-child"
                        >
                            Update
                        </div>
                    </div>}
                </Row>}
                {props.update && props.note != "" && <Row className="background-1 pt-4">
                    <div className="col-6 float-left-child template-card-title padding-left-unset">

                    </div>
                    {<div onClick={() => taskUpdate(props.id)}
                          className="col-6 float-right-child template-card-title template-card-text-right padding-right-unset button-cursor">
                        <div className="button-solid button-active button-size-50 float-right-child"
                        >
                            Update
                        </div>
                    </div>}
                </Row>}
            </Row>
        </>
    );
}

export default DynamicProcessManage;