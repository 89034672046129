import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import DropdownCalendarSingle from "../components/DropdownCalendarSingle";
import {useNavigate, useParams} from "react-router-dom";
import link from "../assets/images/icons/link.svg";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";
import {
    deleteSchoolYear,
    editSchoolYear,
    getAllSchoolYear, getAllSchoolYearExist,
    getSchoolYearById, resetDeleteSchoolYear,
    resetEditSchoolYear
} from "../actions/CalendarActions";
import DeleteModal from "../components/DeleteModal";
import {deleteEvent} from "../actions/EventActions";

const EditSchoolYear = (props) => {
    const history = useNavigate();
    const linkRef = useRef();
    const titleRef = useRef();
    const params = useParams()
    const dispatch = useDispatch()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [showDelete, setShowDelete] = useState(false)
    const [yearList, setYearList] = useState([])
    const [fieldIsValid, setFieldIsValid] = useState({
        isYearValid: true,
        isToDateValid: true,
        isFromDateValid: true,
        isLinkValid: true,
    });
    const [errorMessages, setErrorMessages] = useState({
        yearErrorMessage: "",
        toDateErrorMessage: "",
        fromDateErrorMessage: "",
        linkErrorMessage: "",
    });
    const [fieldData, setFieldData] = useState({
        schoolYearId: null,
        dataYear: null,
        dataToDate: null,
        dataFromDate: null,
        dataLink: null,
        dataTitle: null,
    });

    const getSchoolYearDetails = useSelector(state => state.getSchoolYearById)
    const {error, loading, schoolYearInfo} = getSchoolYearDetails

    const editSchoolYearDetails = useSelector(state => state.editSchoolYear)
    const {errorEdit, loadingEdit, schoolYearEdit} = editSchoolYearDetails

    useEffect(() => {
        if (editSchoolYearDetails.school_year === true) {
            dispatch(resetEditSchoolYear())
            setAlertModal({
                display: true,
                type: "success",
                message: "School Year updated successfully"
            })
        } if (editSchoolYearDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "School Year not updated "
            })
        }
    }, [editSchoolYearDetails]);

    const deleteSchoolYearDetails = useSelector(state => state.deleteSchoolYear)
    const {errorDelete, loadingDelete, school_year} = deleteSchoolYearDetails

    useEffect(() => {
        if (deleteSchoolYearDetails.school_year === true) {
            dispatch(getAllSchoolYearExist())
            setAlertModal({
                display: true,
                type: "success",
                message: "School Year deleted successfully"
            })
        } if (deleteSchoolYearDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "School Year not deleted"
            })
        }
    }, [deleteSchoolYearDetails]);

    useEffect(() => {
        setFieldData({
            ...fieldData,
            schoolYearId: params.id
        })
        dispatch(resetEditSchoolYear())
        dispatch(getAllSchoolYear())
        dispatch(getSchoolYearById(params.id))
    }, []);

    useEffect(() => {
        if (!getSchoolYearDetails.school_year_data <= 0) {
            setFieldData({
                ...fieldData,
                schoolYearId: getSchoolYearDetails.school_year_data.schoolYearId,
                dataYear: getSchoolYearDetails.school_year_data.title,
                dataToDate: getSchoolYearDetails.school_year_data.toDate,
                dataFromDate: getSchoolYearDetails.school_year_data.fromDate,
                dataLink: getSchoolYearDetails.school_year_data.linkUrl,
                dataTitle: getSchoolYearDetails.school_year_data.title,
            })
        }
    }, [getSchoolYearDetails.school_year_data]);

    const getAllSchoolYearDetails = useSelector(state => state.getAllSchoolYear)
    const {errorAllYear, loadingAllYear} = getAllSchoolYearDetails

    useEffect(() => {
        if (!getAllSchoolYearDetails.school_year_list <= 0) {
            setYearList([])
            getAllSchoolYearDetails.school_year_list.map((item, index) => {
                setYearList((prevData) => [
                    ...prevData,
                    {
                        id: item.schoolYearId,
                        data: item.title,
                    }
                ])
            });
            console.log("Year List",yearList)
        }
    }, [getAllSchoolYearDetails.school_year_list]);
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        return `${year}`;
    }
    function formatDateLong(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const linkKeyPress = (event) => {
        event.preventDefault();
        console.log(linkRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$:/%&*.,-]{1,100}$/.test(linkRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isLinkValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                linkErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isLinkValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                linkErrorMessage: ""
            });
        }
    }
    const titleKeyPress = (event) => {
        event.preventDefault();
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,1000}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isYearValid: false,
            });
            setErrorMessages({
                ...errorMessages,
                yearErrorMessage: ""
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isYearValid: true,
            });
            setErrorMessages({
                ...errorMessages,
                yearErrorMessage: ""
            });

            setFieldData({
                ...fieldData,
                dataTitle: titleRef.current.value
            });
        }
    }

    const openLink = (data) => {
        if (data == 0) {
            setShowDelete(true)
            document.body.style.overflow = 'hidden';
        } else {
            history('/calendar')
        }

    }
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(editSchoolYear(fieldData.schoolYearId, fieldData.dataTitle, fieldData.dataFromDate, fieldData.dataToDate, linkRef.current.value));
    }
    const onReset = () => {
        dispatch(getSchoolYearById(params.id))
    }
    const yearData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isYearValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            yearErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            schoolYearId: data.id
        })
        dispatch(getSchoolYearById(data.id))
    }
    const fromData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isFromDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            fromDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataFromDate: formatDateLong(data)
        })
    }
    const toData = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isToDateValid: true,
        });
        setErrorMessages({
            ...errorMessages,
            toDateErrorMessage: ""
        });
        setFieldData({
            ...fieldData,
            dataToDate: formatDateLong(data)
        })
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false,
        })
        history('/calendar')
    }
    const hideDeleteModal = (data) => {
        if (data == 1) {
            dispatch(deleteSchoolYear(params.id))
        }
        setShowDelete(false)
        document.body.style.overflow = 'unset';
    }
    const pageName = 'calendar';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                {loadingDelete && <Loader/>}
                {showDelete && <DeleteModal heading="Delete School Year"
                                            type="delete"
                                            question="Are you sure want to delete this school year?"
                                            answer=""
                                            result={hideDeleteModal}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="edit_school_year" title="Edit School Year" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getSchoolYearDetails.school_year_data && getAllSchoolYearDetails.school_year_list &&
                                    <form>
                                        <Row className="background-1">
                                            <Row className="background-1">
                                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                     className="padding-left-unset padding-right-unset">
                                                    <div className="document-form">
                                                        <label htmlFor="document_title" id="documentTitleLabel"
                                                               className="form-label form-spacing">
                                                            School Year<span className="asterics">*</span></label>
                                                        <input ref={titleRef} onChange={titleKeyPress}
                                                               type="text" autoComplete="off"
                                                               defaultValue={getSchoolYearDetails.school_year_data.title}
                                                               className={!fieldIsValid.isYearValid ? "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                               placeholder="Enter School Year"
                                                               id="document_title"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                        <Row className="background-1">
                                            <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                                 className="padding-left-unset">
                                                <div className="document-form">
                                                    <label htmlFor="document_title" id="certificateNameLabel"
                                                           className="form-label form-spacing-calendar">
                                                        From<span className="asterics">*</span></label>
                                                    <div>
                                                        <DropdownCalendarSingle title="Select From"
                                                                                value={getSchoolYearDetails.school_year_data.fromDate}
                                                                                result={fromData}
                                                                                className={!fieldIsValid.isFromDateValid ?
                                                                                    "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                                 className="padding-right-unset">
                                                <div className="document-form">
                                                    <label htmlFor="document_title" id="certificateNameLabel"
                                                           className="form-label form-spacing-calendar">
                                                        To<span className="asterics">*</span></label>
                                                    <div>
                                                        <DropdownCalendarSingle title="Select To"
                                                                                value={getSchoolYearDetails.school_year_data.toDate}
                                                                                result={toData}
                                                                                className={!fieldIsValid.isToDateValid ?
                                                                                    "background-1 calendar-content" : "background-1 calendar-content-valid"}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="background-1">
                                            <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                 className="padding-right-unset padding-left-unset display-none">
                                                <div className="document-form">
                                                    <label htmlFor="document_title" id="certificateNameLabel"
                                                           className="form-label">
                                                        Link<span className="asterics">*</span></label>
                                                    <img src={link} alt="icon user-icon" className="form-icon"/>
                                                    <input ref={linkRef} onChange={linkKeyPress}
                                                           type="text" autoComplete="off"
                                                           defaultValue={getSchoolYearDetails.school_year_data.linkUrl}
                                                           className={!fieldIsValid.isLinkValid ? "input-error-form" : "input-valid-form"}
                                                        // className={!fieldIsValid.isLinkValid ?
                                                        //     "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                           placeholder="Enter Url"
                                                           id="document_title"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {(
                                                !fieldIsValid.isYearValid
                                                || !fieldIsValid.isToDateValid
                                                || !fieldIsValid.isFromDateValid
                                            )
                                            &&
                                            <Row className="background-1 document-form submit-buttons">
                                                <Col lg={{span: 2}} className="padding-left-unset">
                                                    <button disabled={true} className="button-solid button-inactive">
                                                        Update
                                                    </button>
                                                </Col>
                                                <Col lg={{span: 2}} className="padding-right-unset">
                                                    <button disabled={true}
                                                            className="button-solid button-transparent-inactive">
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>}
                                        {(
                                                fieldIsValid.isYearValid
                                                && fieldIsValid.isToDateValid
                                                && fieldIsValid.isFromDateValid
                                            )
                                            &&
                                            <Row className="background-1 document-form submit-buttons">
                                                <Col lg={{span: 2}} className="padding-left-unset">
                                                    <button className="button-solid button-active"
                                                            onClick={submitHandler}
                                                    >
                                                        Update
                                                    </button>
                                                </Col>
                                                <Col lg={{span: 2}} className="padding-right-unset">
                                                    <button className="button-solid button-transparent-active"
                                                            onClick={onReset}
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>}
                                    </form>}
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditSchoolYear;