import React, {useEffect, useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import "../assets/css/dropdown.css"
import {Col, Row} from "react-bootstrap";
import DownArrow from "./Icons/DownArrow";

function DropdownItemsInput(props) {
    const [selectedCity, setSelectedCity] = useState(props.value);
    const cities = props.data

    let selectedOptionObject = cities.find(cities => cities.id === selectedCity);

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            setSelectedCity(null)
        }
    }, [props.value]);
    const setDropdownResult = (e) => {
        setSelectedCity(e.value)
        if (props.eventId) {
            props.result([e.value,props.eventId])
        } else {
            props.result(e.value)
        }
    }

    return (
        <Row className={props.className}>
            <Col lg={{span: 11}} md={{span: 11}} className="padding-input-dropdown">
                {props.label && <Dropdown value={selectedOptionObject || selectedCity} onChange={setDropdownResult} options={cities} optionLabel={props.label}
                    // optionValue="id"
                           editable placeholder={props.title} className="w-full md:w-14rem" dropdownIcon="false"/>}
                {!props.label && <Dropdown value={selectedOptionObject || selectedCity} onChange={setDropdownResult} options={cities} optionLabel="data"
                    // optionValue="id"
                           editable placeholder={props.title} className="w-full md:w-14rem" dropdownIcon="false"/>}
            </Col>
            <Col lg={{span: 1}} md={{span: 1}} className="padding-input-caret">
                <DownArrow className="icon-down-arrow-input"
                           color="var(--primary-100)"/>
            </Col>

        </Row>
    );
}

export default DropdownItemsInput;