import React, {useRef, useState, useEffect} from "react";
import {Col, Row, Card, Button, Form} from "react-bootstrap";
import Logo from "../assets/images/logo.svg"
import Main from "../assets/images/main.png"
import mfa from "../assets/json/mfa.json"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";
import LoginImage from "../components/LoginImage";
import {logout} from "../actions/LoginActions";


function MultifactorAuthentication() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [verificationType, setVerificationType] = useState();

    useEffect(() => {
        const decoded = jwtDecode(localStorage.getItem("access_token"));
        const user = decoded.IsSuperAdmin
        if (!localStorage.getItem('access_token')) {
            dispatch(logout())
        }
        if ((localStorage.getItem('mfa_verify') === "true") && (localStorage.getItem('client_id')) && (user !== "True")) {
            history('/client')
        }
    }, []);
    const selectMfa = (data) => {
        localStorage.setItem("timestamp_type", data)
        localStorage.setItem("timestamp", new Date().getTime())
        setVerificationType(data)
    };

    const showContacts = (data) => {
        return data.map((item, index) => {
            try {
                return (
                    <div>
                        <Form.Check
                            name="mfa"
                            label={item.data}
                            type="radio"
                            id={item.id}
                            onClick={() => selectMfa(item.id)}
                        />
                    </div>
                )
            } catch (e) {
                alert(e.message);
            }
        });
    }
    const submitHandler = (event) => {
        event.preventDefault();
        const decoded = jwtDecode(localStorage.getItem("access_token"));
        const user = decoded.IsSuperAdmin
        localStorage.setItem("mfa_verify", "true")
        if (user === "True") {
            window.location.href = "/super-admin-management"
        } else {
            history("/client")
        }
    };

    return (
        <Row className="login-background">
            <Col lg={{span: 4, offset: 1}} md={{span: 4, offset: 1}}>
                <Card className="card-body">
                    <img src={Logo} alt="logo" className="logo"/>
                    <form className="margin-top">
                        <p className="header">Multi-factor Authentication</p>
                        <p className="sub-header">How often do you want to authenticate when signing into the app?</p>
                        {showContacts(mfa)}
                        <div className="login-button">
                            {(!verificationType) &&
                                (<button disabled={true} className="button-solid button-inactive">
                                    Submit
                                </button>)}
                            {(verificationType) &&
                                (<button onClick={submitHandler} disabled={false} type="submit"
                                         className="button-solid button-active">
                                    Submit
                                </button>)}
                        </div>
                    </form>
                    <div className="copyright">
                        <p className="sub-footer">v5.2</p>
                        <p className="sub-footer">copyright text</p>
                    </div>
                </Card>
            </Col>
            <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                <LoginImage/>
            </Col>
        </Row>
    );
}

export default MultifactorAuthentication;