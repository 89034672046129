export const ADD_LETTERS_TEMPLATE_REQUEST = 'ADD_LETTERS_TEMPLATE_REQUEST'
export const ADD_LETTERS_TEMPLATE_SUCCESS = 'ADD_LETTERS_TEMPLATE_SUCCESS'
export const ADD_LETTERS_TEMPLATE_FAIL = 'ADD_LETTERS_TEMPLATE_FAIL'
export const ADD_LETTERS_TEMPLATE_RESET = 'ADD_LETTERS_TEMPLATE_RESET'
export const GET_LETTERS_TEMPLATE_BY_ID_REQUEST = 'GET_LETTERS_TEMPLATE_BY_ID_REQUEST'
export const GET_LETTERS_TEMPLATE_BY_ID_SUCCESS = 'GET_LETTERS_TEMPLATE_BY_ID_SUCCESS'
export const GET_LETTERS_TEMPLATE_BY_ID_FAIL = 'GET_LETTERS_TEMPLATE_BY_ID_FAIL'
export const GET_LETTERS_TEMPLATE_BY_ID_RESET = 'GET_LETTERS_TEMPLATE_BY_ID_RESET'
export const EDIT_LETTERS_TEMPLATE_REQUEST = 'EDIT_LETTERS_TEMPLATE_REQUEST'
export const EDIT_LETTERS_TEMPLATE_SUCCESS = 'EDIT_LETTERS_TEMPLATE_SUCCESS'
export const EDIT_LETTERS_TEMPLATE_FAIL = 'EDIT_LETTERS_TEMPLATE_FAIL'
export const EDIT_LETTERS_TEMPLATE_RESET = 'EDIT_LETTERS_TEMPLATE_RESET'
export const GET_ALL_LETTERS_TEMPLATES_REQUEST = 'GET_ALL_LETTERS_TEMPLATES_REQUEST'
export const GET_ALL_LETTERS_TEMPLATES_SUCCESS = 'GET_ALL_LETTERS_TEMPLATES_SUCCESS'
export const GET_ALL_LETTERS_TEMPLATES_FAIL = 'GET_ALL_LETTERS_TEMPLATES_FAIL'
export const GET_ALL_LETTERS_TEMPLATES_RESET = 'GET_ALL_LETTERS_TEMPLATES_RESET'
export const GET_LETTERS_TEMPLATE_COLUMNS_REQUEST = 'GET_LETTERS_TEMPLATE_COLUMNS_REQUEST'
export const GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS = 'GET_LETTERS_TEMPLATE_COLUMNS_SUCCESS'
export const GET_LETTERS_TEMPLATE_COLUMNS_FAIL = 'GET_LETTERS_TEMPLATE_COLUMNS_FAIL'
export const GET_LETTERS_TEMPLATE_COLUMNS_RESET = 'GET_LETTERS_TEMPLATE_COLUMNS_RESET'
//

export const SEND_LETTERS_NOTICES_REQUEST = 'SEND_LETTERS_NOTICES_REQUEST'
export const SEND_LETTERS_NOTICES_SUCCESS = 'SEND_LETTERS_NOTICES_SUCCESS'
export const SEND_LETTERS_NOTICES_FAIL = 'SEND_LETTERS_NOTICES_FAIL'
export const SEND_LETTERS_NOTICES_RESET = 'SEND_LETTERS_NOTICES_RESET'
export const GET_LETTERS_NOTICES_BY_ID_REQUEST = 'GET_LETTERS_NOTICES_BY_ID_REQUEST'
export const GET_LETTERS_NOTICES_BY_ID_SUCCESS = 'GET_LETTERS_NOTICES_BY_ID_SUCCESS'
export const GET_LETTERS_NOTICES_BY_ID_FAIL = 'GET_LETTERS_NOTICES_BY_ID_FAIL'
export const GET_LETTERS_NOTICES_BY_ID_RESET = 'GET_LETTERS_NOTICES_BY_ID_RESET'
export const GET_ALL_LETTERS_NOTICES_REQUEST = 'GET_ALL_LETTERS_NOTICES_REQUEST'
export const GET_ALL_LETTERS_NOTICES_SUCCESS = 'GET_ALL_LETTERS_NOTICES_SUCCESS'
export const GET_ALL_LETTERS_NOTICES_FAIL = 'GET_ALL_LETTERS_NOTICES_FAIL'
export const GET_ALL_LETTERS_NOTICES_RESET = 'GET_ALL_LETTERS_NOTICES_RESET'