import React from 'react';
import {Navigate, Outlet} from "react-router-dom"
import {jwtDecode} from "jwt-decode";

function ClientRoute() {
    const decoded = jwtDecode(localStorage.getItem("access_token"));
    const user = decoded.IsSuperAdmin
    if (user === "True") {
        return localStorage.getItem('client_id')
            ? <Outlet/>
            : <Navigate to='/client-management'/>
    } else {
        return localStorage.getItem('client_id')
            ? <Outlet/>
            : <Navigate to='/client'/>
    }
}

export {ClientRoute};