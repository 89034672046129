import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import {Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";
import {useLocation, useNavigate} from "react-router-dom";
import PencilEdit from "../components/Icons/PencilEdit";
import Trash from "../components/Icons/Trash";
import DeleteModal from "../components/DeleteModal";
import Heading from "../components/Heading";
import DropdownItems from "../components/DropdownItems";
import status_list from "../assets/json/status.json";
import {resetDeleteDocument} from "../actions/DocumentActions";
import AlertModal from "../components/AlertModal";
import {deleteCertificate, getAllCertificate, resetAddCertificate} from "../actions/CertificateActions";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable/DataTable";
import {GrPowerReset} from "react-icons/gr";
import EmptyTable from "../assets/images/empty_certificate_table.svg"
import {decoded} from "../components/PrivateRoute";
import {getTeacherAssociates} from "../actions/ConfigActions";

function Certifications(props) {
    const [showDelete, setShowDelete] = useState({
        display: false,
        data: null
    })
    const history = useNavigate()
    const dispatch = useDispatch()
    const certificateRef = useRef()
    const location = useLocation()
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const [filter, setFilter] = useState(false)
    const [accessLevel, setAccessLevel] = useState(false)
    const [teacher, setTeacher] = useState([])
    const [certificates, setCertificates] = useState([])
    const [record, setRecord] = React.useState();
    const [pageSize, setPageSize] = useState(5)
    const [pageNumber, setPageNumber] = useState(1)
    const [fieldData, setFieldData] = useState({
        dataTeacher: "",
        dataType: "",
        dataStatus: "",
    });

    const columns = React.useMemo(() => [
        {
            Header: 'Teacher/Associate',
            accessor: 'associateName',
            width: "20%"
        },
        {
            Header: 'Certification',
            accessor: 'title',
            width: "15%"
        },
        {
            Header: 'From',
            accessor: 'validFromDate',
            width: "15%"
        },
        {
            Header: 'To',
            accessor: 'validToDate',
            width: "15%"
        },
        {
            Header: 'Status',
            accessor: 'statusCertificate',
            width: "10%",
            Cell: ({value}) => (value === 0 ? <span className="text-active">Valid</span> :
                value === 2 ? <span className="text-inactive">Expired</span> : <span className="text-yellow">In Progress</span>)
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            width: "15%"
        },
        {
            Header: 'Action',
            id: 'actions',
            width: "10%",
            Cell: ({row}) => (
                <div className="row background-1">
                    <div onClick={() => editDoc(row.original)} className="col-4 padding-left-unset">
                        <PencilEdit className="sidebar-icon"
                                    color="var(--primary-100)"/>
                    </div>
                    <div onClick={() => showDeleteModal(row.original)} className="col-4 padding-left-unset">
                        <Trash className="sidebar-icon margin-left"
                               color="var(--red)"/>
                    </div>
                </div>
            ),
        },
    ], []);
    const columnsTeacher = React.useMemo(() => [
        {
            Header: 'Teacher/Associate',
            accessor: 'associateName',
            width: "20%"
        },
        {
            Header: 'Certification',
            accessor: 'title',
            width: "15%"
        },
        {
            Header: 'From',
            accessor: 'validFromDate',
            width: "15%"
        },
        {
            Header: 'To',
            accessor: 'validToDate',
            width: "15%"
        },
        {
            Header: 'Status',
            accessor: 'statusCertificate',
            width: "10%",
            Cell: ({value}) => (value ? <span className="text-active">Active</span> :
                <span className="text-inactive">Inactive</span>)
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            width: "25%"
        },
    ], []);

    const getCertificate = useSelector(state => state.getAllCertificate)
    const {error, loading, certificateInfo} = getCertificate

    useEffect(() => {
        if (!getCertificate.certificate_list <= 0) {
            setCertificates([])
            getCertificate.certificate_list.map((item, index) => {
                setCertificates((prevData) => [
                    ...prevData,
                    {
                        certificationId: item.certificationId,
                        associateId: item.associateId,
                        associateName: item.associateName,
                        title: item.title,
                        validFromDate: item.validFromDate,
                        validToDate: item.validToDate,
                        type: item.type,
                        notes: item.notes,
                        statusCertificate: item.statusId,
                    }
                ])
            });
            if (getCertificate.records !== undefined) {
                setRecord(getCertificate.records)
            }
        }
    }, [getCertificate.certificate_list]);

    const deleteCertificateDetails = useSelector(state => state.deleteCertificate)
    const {errorDelete, loadingDelete} = deleteCertificateDetails

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    const exportAllCertificateDetails = useSelector(state => state.exportAllCertificate)
    const {loadingCertificateExport} = exportAllCertificateDetails

    useEffect(() => {
        if (deleteCertificateDetails.certificate_delete === true) {
            dispatch(resetDeleteDocument())
            setCertificates([])
            dataTableReload()
        }
        if (deleteCertificateDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Certificate couldn't be deleted"
            })
        }
    }, [deleteCertificateDetails]);

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const teacherResult = (data) => {
        setFieldData({
            ...fieldData,
            dataTeacher: data.id
        })
    }
    const certificateResult = (data) => {
        setFieldData({
            ...fieldData,
            dataType: data.id
        })
    }
    const statusResult = (data) => {
        setFieldData({
            ...fieldData,
            dataStatus: data.id
        })
    }

    const dataTableReload = () => {
        setCertificates([])
        dispatch(getAllCertificate(pageSize, pageNumber, fieldData.dataTeacher, certificateRef.current.value, fieldData.dataStatus))
    }

    useEffect(() => {
        setCertificates([])
        dataTableReload()
    }, [fieldData, pageNumber, filter]);

    useEffect(() => {
        dispatch(getTeacherAssociates())
        setAccessLevel(decoded.RoleAccessLevels)
        setCertificates([])
        if (location.state?.success) {
            setAlertModal({
                display: true,
                type: "success",
                message: "Certificate successfully added"
            })
            history('/certifications', {state: {}})
        }
    }, [])


    const pageName = 'certifications';

    const editDoc = (data) => {
        console.log(data)
        history(`/edit-certificate/${data.certificationId}`)
    }
    const showDeleteModal = (data) => {
        setShowDelete({
            display: true,
            data: data.certificationId
        })
    }
    const hideDeleteModal = (data) => {
        if (data === 1) {
            dispatch(deleteCertificate(showDelete.data))
        }
        setShowDelete({
            display: false
        })
    }

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddCertificate())
    }
    const changePage = (data) => {
        setCertificates([])
        setPageNumber(data)
    }
    const resetData = (e) => {
        e.preventDefault()
        certificateRef.current.value=""
        setFilter(true)
        setFieldData({
            ...fieldData,
            dataType: null,
            dataStatus: null,
            dataTeacher: null
        })
        setPageNumber(1)
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setFieldData({
                ...fieldData,
                dataType: e.target.value,
            })
        }
    };
    const changeSearch = (e) => {
        if (e.target.value == "") {
            setFieldData({
                ...fieldData,
                dataType: certificateRef.current.value
            })
        }
    }

    return (<>
        <Row className="sidebar-background">
            {loading && <Loader/>}
            {loadingDelete && <Loader/>}
            {loadingCertificateExport && <Loader/>}
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {showDelete.display && <DeleteModal heading="Delete Certificate"
                                                type="delete"
                                                question="Are you sure want to delete this Certificate?"
                                                answer=""
                                                result={hideDeleteModal}
            />}
            <div className="float-left-child sidebar app-spacing">
                <Sidebar pageDetails={pageName}/>
            </div>
            <div className="float-right-child app-background app-spacing">
                <Navigation/>
                <Heading group="certificate_list" title="Certificate List"/>
                <Row className="background-1 m-3 certificate-dropdown-group">
                    {accessLevel != 0 && <Col lg={{span: 3}} md={{span: 6}}>
                        <DropdownItems data={teacher} title="Teacher Associate" result={teacherResult}
                                       value={fieldData.dataTeacher}/>
                    </Col>}
                    <Col lg={accessLevel != 0 ? {span: 4} : {span:5}} md={{span: 6}}>
                        <div className="">
                            <input
                                ref={certificateRef}
                                onKeyPress={handleSearch}
                                onChange={changeSearch}
                                type="text" autoComplete="off"
                                className="input-document input-spacing-document"
                                placeholder="Certification"
                                // disabled={loading}
                                id="document_title"/>
                        </div>
                        {/*<DropdownItems data={certificate_list} title="Certificate" result={certificateResult}/>*/}
                    </Col>
                    <Col lg={accessLevel != 0 ? {span: 3} : {span: 5}} md={{span: 6}}>
                        <DropdownItems data={status_list} title="Status" result={statusResult}
                                       value={fieldData.dataStatus}/>
                    </Col>
                    <Col lg={{span: 2}} md={{span: 4}} sm={{span: 4}}>
                        <div className="float-right-child add-button button-solid reset-filter-button"
                             onClick={resetData}>
                            <GrPowerReset classname="button-icon"/> Reset
                        </div>
                    </Col>
                </Row>

                <Row className="app-body-certificate background-1">
                    {certificates && record >= 0 && <div className="background-2">
                        <DataTable defaultPageSize={5}
                                   // filter={filter}
                                   pageSize={pageSize}
                                   columns={accessLevel != 0 ? columns : columnsTeacher}
                                   data={certificates}
                                   recordData={record}
                                   result={changePage} empty_table={EmptyTable}
                        />
                        <Footer/>
                    </div>}
                </Row>
            </div>

        </Row>
    </>);
}

export default Certifications;