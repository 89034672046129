import React from 'react';
import {Row, Col, Card} from "react-bootstrap";

function Metrics(props) {
    return (
        <>
            <Card className={props.className}>
                <Row className="background-1">
                    <Col lg={{span: 4, offset: 0}}>
                        <img src={props.image} alt={props.image} className="metric-logo"/>
                    </Col>
                    <Col lg={{span: 8, offset: 0}}>
                        <p className="metric-header">{props.header}</p>
                        <p className="metric-body">{props.body}</p>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default Metrics;