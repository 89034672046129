import api from './api';
import {
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_REQUEST,
    ADD_DOCUMENT_FAIL,
    ADD_DOCUMENT_RESET,

    EDIT_DOCUMENT_SUCCESS,
    EDIT_DOCUMENT_REQUEST,
    EDIT_DOCUMENT_FAIL,
    EDIT_DOCUMENT_RESET,

    GET_DOCUMENT_BY_ID_SUCCESS,
    GET_DOCUMENT_BY_ID_REQUEST,
    GET_DOCUMENT_BY_ID_FAIL,
    GET_DOCUMENT_BY_ID_RESET,

    GET_ALL_DOCUMENTS_SUCCESS,
    GET_ALL_DOCUMENTS_REQUEST,
    GET_ALL_DOCUMENTS_FAIL,
    GET_ALL_DOCUMENTS_RESET,

    DELETE_DOCUMENTS_REQUEST,
    DELETE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS_FAIL,
    DELETE_DOCUMENTS_RESET,
} from "../constants/DocumentConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const addDocument = (formData) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_DOCUMENT_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Documents/AddEdit`,
            formData, config
        )
        if (data.success === true) {
            dispatch({
                type: ADD_DOCUMENT_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: ADD_DOCUMENT_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_DOCUMENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const editDocument = (formData) => async (dispatch, getState) => {
    try {
        dispatch({type: EDIT_DOCUMENT_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.post(`${url}/Documents/AddEdit`,
            formData, config
        )
        if (data.success === true) {
            dispatch({
                type: EDIT_DOCUMENT_SUCCESS,
                payload: data.success
            })
        }

        if (data.success === false) {
            dispatch({
                type: EDIT_DOCUMENT_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: EDIT_DOCUMENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getDocumentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_DOCUMENT_BY_ID_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Documents/GetDocumentsById?Id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_DOCUMENT_BY_ID_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_DOCUMENT_BY_ID_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_DOCUMENT_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const deleteDocument = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_DOCUMENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.delete(`${url}/Documents/DeleteDocumentById?id=${id}`, config
        )
        if (data.success === true) {
            dispatch({
                type: DELETE_DOCUMENTS_SUCCESS,
                payload: data.success
            })
        }

        if (data.errors) {
            dispatch({
                type: DELETE_DOCUMENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: DELETE_DOCUMENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllDocuments = (pageSize, pageNumber, title,associate,category,signature,docFromDate,docToDate,importFromDate,importToDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_DOCUMENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }
        var titleString = title == undefined || null ? '' : title
        var associateString = associate == undefined || null ? '' : associate
        var categoryString = category == undefined || null ? '' : category
        var signatureString = signature == undefined || null ? '' : signature
        var docFromDateString = docFromDate === "NaN-NaN-NaN" ? '' : docFromDate
        var docToDateString = docToDate === "NaN-NaN-NaN" ? '' : docToDate
        var importFromDateString = importFromDate === "NaN-NaN-NaN" ? '' : importFromDate
        var importToDateString = importToDate === "NaN-NaN-NaN" ? '' : importToDate

        const {data} = await api.get(`${url}/Documents/GetDocumentsByFilter?Title=${titleString}&AssociateId=${associateString}&DocumentCategory=${categoryString}&SignatureStatusId=${signatureString}&StartDocDate=${docFromDateString}&EndDocDate=${docToDateString}&StartImportedDate=${importFromDateString}&EndImportedDate=${importToDateString}&PageNumber=${pageNumber}&PageSize=${pageSize}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_DOCUMENTS_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_DOCUMENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_DOCUMENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetAddDocument = () => (dispatch, getState) => {
    dispatch({
        type: ADD_DOCUMENT_RESET,
    })
};
export const resetEditDocument = () => (dispatch, getState) => {
    dispatch({
        type: EDIT_DOCUMENT_RESET,
    })
};
export const resetGetDocumentById =  () => (dispatch, getState) => {
    dispatch({
        type: GET_DOCUMENT_BY_ID_RESET,
    })
};
export const resetDeleteDocument =  () => (dispatch, getState) => {
    dispatch({
        type: DELETE_DOCUMENTS_RESET,
    })
};
export const resetGetAllDocuments =  () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_DOCUMENTS_RESET,
    })
};