export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST'
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS'
export const ADD_CLIENT_FAIL = 'ADD_CLIENT_FAIL'
export const ADD_CLIENT_RESET = 'ADD_CLIENT_RESET'
export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST'
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS'
export const GET_ALL_CLIENTS_FAIL = 'GET_ALL_CLIENTS_FAIL'
export const GET_ALL_CLIENTS_RESET = 'GET_ALL_CLIENTS_RESET'
export const GET_CLIENT_BY_ID_REQUEST = 'GET_CLIENT_BY_ID_REQUEST'
export const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS'
export const GET_CLIENT_BY_ID_FAIL = 'GET_CLIENT_BY_ID_FAIL'
export const GET_CLIENT_BY_ID_RESET = 'GET_CLIENT_BY_ID_RESET'
export const DELETE_CLIENT_BY_ID_REQUEST = 'DELETE_CLIENT_BY_ID_REQUEST'
export const DELETE_CLIENT_BY_ID_SUCCESS = 'DELETE_CLIENT_BY_ID_SUCCESS'
export const DELETE_CLIENT_BY_ID_FAIL = 'DELETE_CLIENT_BY_ID_FAIL'
export const DELETE_CLIENT_BY_ID_RESET = 'DELETE_CLIENT_BY_ID_RESET'

// Management
export const GET_CLIENT_USERS_BY_CLIENT_REQUEST = 'GET_CLIENT_USERS_BY_CLIENT_REQUEST'
export const GET_CLIENT_USERS_BY_CLIENT_SUCCESS = 'GET_CLIENT_USERS_BY_CLIENT_SUCCESS'
export const GET_CLIENT_USERS_BY_CLIENT_FAIL = 'GET_CLIENT_USERS_BY_CLIENT_FAIL'
export const GET_CLIENT_USERS_BY_CLIENT_RESET = 'GET_CLIENT_USERS_BY_CLIENT_RESET'
export const GET_CLIENT_USER_BY_ID_REQUEST = 'GET_CLIENT_USER_BY_ID_REQUEST'
export const GET_CLIENT_USER_BY_ID_SUCCESS = 'GET_CLIENT_USER_BY_ID_SUCCESS'
export const GET_CLIENT_USER_BY_ID_FAIL = 'GET_CLIENT_USER_BY_ID_FAIL'
export const GET_CLIENT_USER_BY_ID_RESET = 'GET_CLIENT_USER_BY_ID_RESET'
export const UPDATE_CLIENT_USER_REQUEST = 'UPDATE_CLIENT_USER_REQUEST'
export const UPDATE_CLIENT_USER_SUCCESS = 'UPDATE_CLIENT_USER_SUCCESS'
export const UPDATE_CLIENT_USER_FAIL = 'UPDATE_CLIENT_USER_FAIL'
export const UPDATE_CLIENT_USER_RESET = 'UPDATE_CLIENT_USER_RESET'