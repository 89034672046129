export const ADD_SUPER_ADMIN_REQUEST = 'ADD_SUPER_ADMIN_REQUEST'
export const ADD_SUPER_ADMIN_SUCCESS = 'ADD_SUPER_ADMIN_SUCCESS'
export const ADD_SUPER_ADMIN_FAIL = 'ADD_SUPER_ADMIN_FAIL'
export const ADD_SUPER_ADMIN_RESET = 'ADD_SUPER_ADMIN_RESET'
export const GET_SUPER_ADMIN_BY_ID_REQUEST = 'GET_SUPER_ADMIN_BY_ID_REQUEST'
export const GET_SUPER_ADMIN_BY_ID_SUCCESS = 'GET_SUPER_ADMIN_BY_ID_SUCCESS'
export const GET_SUPER_ADMIN_BY_ID_FAIL = 'GET_SUPER_ADMIN_BY_ID_FAIL'
export const GET_SUPER_ADMIN_BY_ID_RESET = 'GET_SUPER_ADMIN_BY_ID_RESET'
export const EDIT_SUPER_ADMIN_REQUEST = 'EDIT_SUPER_ADMIN_REQUEST'
export const EDIT_SUPER_ADMIN_SUCCESS = 'EDIT_SUPER_ADMIN_SUCCESS'
export const EDIT_SUPER_ADMIN_FAIL = 'EDIT_SUPER_ADMIN_FAIL'
export const EDIT_SUPER_ADMIN_RESET = 'EDIT_SUPER_ADMIN_RESET'
export const GET_ALL_SUPER_ADMINS_REQUEST = 'GET_ALL_SUPER_ADMINS_REQUEST'
export const GET_ALL_SUPER_ADMINS_SUCCESS = 'GET_ALL_SUPER_ADMINS_SUCCESS'
export const GET_ALL_SUPER_ADMINS_FAIL = 'GET_ALL_SUPER_ADMINS_FAIL'
export const GET_ALL_SUPER_ADMINS_RESET = 'GET_ALL_SUPER_ADMINS_RESET'
export const DELETE_SUPER_ADMIN_REQUEST = 'DELETE_SUPER_ADMIN_REQUEST'
export const DELETE_SUPER_ADMIN_SUCCESS = 'DELETE_SUPER_ADMIN_SUCCESS'
export const DELETE_SUPER_ADMIN_FAIL = 'DELETE_SUPER_ADMIN_FAIL'
export const DELETE_SUPER_ADMIN_RESET = 'DELETE_SUPER_ADMIN_RESET'