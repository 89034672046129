import React, {useRef, useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import WyEditor from "../components/WYEditor";
import {useNavigate} from "react-router-dom";
import '../assets/css/react-draft.css'
import {addLettersTemplate, resetAddLettersTemplate} from "../actions/LetterNoticesActions";
import AlertModal from "../components/AlertModal";
import Loader from "../components/Loader";

const CreateNewTemplate = (props) => {
    const history = useNavigate();
    const titleRef = useRef();
    const dispatch = useDispatch();
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })
    const desciptionRef = useRef();
    const categoryRef = useRef();
    const textRef = useRef();
    const [category, setCategory] = useState([])

    const getCategoryList = useSelector(state => state.getCategory)
    const {category_list} = getCategoryList

    const addTemplateDetails = useSelector(state => state.addLettersTemplate)
    const {error, loading, letters_template} = addTemplateDetails

    useEffect(() => {
        if (addTemplateDetails.letters_template === true) {
            dispatch(resetAddLettersTemplate())
            history('/letters-notices',{state:{success: "1"}})
        } if (addTemplateDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Couldn't add template"
            })
        }
    }, [addTemplateDetails]);



    const [fieldIsValid, setFieldIsValid] = useState({
        isDocumentValid: false,
        isTitleValid: false,
        isDescriptionValid: false,
        isTextValid: false,
        isCopyValid: true,
    });
    const [fieldData, setFieldData] = useState({
        dataDocument: null,
        dataTitle: null,
        dataDescription: null,
        dataText: "",
        dataCopy: 1,
    });

    const checkEditor = (data) => {
        if (data[0].length > 10) {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: true,
            });
            setFieldData({
                ...fieldData,
                dataText: data[0]
            })
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: false,
            });
        }
    };

    useEffect(() => {
        if (!getCategoryList.category_list <= 0) {
            getCategoryList.category_list.map((item, index) => {
                setCategory((prevData) => [
                    ...prevData,
                    {
                        id: item.documentCategoryId,
                        data: item.title,
                    }
                ])
            });
        }
    }, [getCategoryList.category_list]);

    const titleKeyPress = (event) => {
        event.preventDefault();
        console.log(titleRef.current.value)
        if (!/^(?=.*[a-zA-Z\d\n].*)[a-zA-Z \d!@#$%&*.,-]{1,100}$/.test(titleRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isTitleValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTitleValid: true,
            });
        }
    }
    const descriptionKeyPress = (event) => {
        event.preventDefault();
        console.log(desciptionRef.current.value)
        if (!/.*?$/.test(desciptionRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isDescriptionValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isDescriptionValid: true,
            });
        }
    }
    const categoryKeyPress = (event) => {
        event.preventDefault();
        console.log(categoryRef.current.value)
        if (!/^(?=.*[a-zA-Z\d].*)[a-zA-Z \d!@#$%&*,-]{4,100}$/.test(categoryRef.current.value)) {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentValid: false,
            });
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isDocumentValid: true,
            });
            setFieldData({
                ...fieldData,
                dataDocument: categoryRef.current.value
            })
        }
    }
    const selectCopy = (data) => {
        setFieldIsValid({
            ...fieldIsValid,
            isCopyValid: true,
        });
        setFieldData({
            ...fieldData,
            dataCopy: data
        })
    }

    const openLink = () => {
        history('/letters-notices')
    }

    const submitHandler = (e) => {
        e.preventDefault()
       dispatch(addLettersTemplate(null,titleRef.current.value, desciptionRef.current.value, 1, fieldData.dataText, fieldData.dataCopy == 1 ? true : false))
    }
    const onReset = (e) => {
        e.preventDefault()
        window.location.href = "/create-new-template"
        // e.preventDefault();
        // setFieldIsValid({
        //     ...fieldIsValid,
        //     isDocumentValid: false,
        //     isTitleValid: false,
        //     isDescriptionValid: false,
        //     isTextValid: false,
        //     isCopyValid: false,
        // })
        //
        // titleRef.current.value = ""
        // desciptionRef.current.value = ""
        // setFieldData({
        //     ...fieldData,
        //     dataDocument: null,
        //     dataTitle: null,
        //     dataDescription: null,
        //     dataText: "",
        //     dataCopy: 1,
        // })
    }
    const documentData = (data) => {
        console.log(data.id)
        setFieldIsValid({
            ...fieldIsValid,
            isDocumentValid: true,
        });

        setFieldData({
            ...fieldData,
            dataDocument: data.id
        })
    }
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddLettersTemplate())
    }
    const pageName = 'letters';
    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="create_new_template" title="Create New Template" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1">
                            <Card className="card-body-default">
                                <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Title<span className="asterics">*</span></label>
                                                <input ref={titleRef} onChange={titleKeyPress}
                                                       type="text" autoComplete="off"
                                                       className={!fieldIsValid.isTitleValid ?
                                                           "input-error-form input-spacing" : "input-valid-form input-spacing"}
                                                       placeholder="Enter Title"
                                                       id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset">
                                            <div
                                                className={!fieldIsValid.isDescriptionValid ? "document-form-textarea-error"
                                                    : "document-form-textarea"}
                                            >
                                                <span className="text-label form-spacing">
                                                    Description<span className="asterics">*</span></span>
                                                <textarea ref={desciptionRef} onChange={descriptionKeyPress}
                                                          className="textarea-input-error textarea-spacing"
                                                          placeholder="Enter Description" rows="4"
                                                          id="document_title"/>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="background-1">
                                        <div className="document-form padding-left-unset padding-right-unset">
                                            <WyEditor result={checkEditor} dynamicValue={true}/>
                                        </div>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                             className="padding-left-unset padding-right-unset hardcopy-div">
                                            <div className="document-form">
                                                <div className="label-form-checkbox">
                                                    Hardcopy Required? <span className="asterics">*</span>
                                                </div>
                                                <div className="form-checkbox">
                                                    <Form.Check
                                                        className="float-left-child"
                                                        name="hardcopy"
                                                        label="Yes"
                                                        type="radio"
                                                        id="1"
                                                        defaultChecked={fieldData.dataCopy === 1}
                                                        onClick={() => selectCopy(1)}
                                                    />
                                                    <Form.Check
                                                        className="float-right-child"
                                                        name="hardcopy"
                                                        label="No"
                                                        type="radio"
                                                        id="0"
                                                        defaultChecked={fieldData.dataCopy === 0}
                                                        onClick={() => selectCopy(0)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    {(
                                            !fieldIsValid.isTitleValid
                                            || !fieldIsValid.isDescriptionValid
                                            || !fieldIsValid.isTextValid
                                            || !fieldIsValid.isCopyValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button disabled={true} className="button-solid button-inactive">
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button onClick={onReset}
                                                        className="button-solid button-transparent-active">
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                    {(
                                            fieldIsValid.isTitleValid
                                            && fieldIsValid.isDescriptionValid
                                            && fieldIsValid.isTextValid
                                            && fieldIsValid.isCopyValid
                                        )
                                        &&
                                        <Row className="background-1 document-form submit-buttons">
                                            <Col lg={{span: 2}} className="padding-left-unset">
                                                <button className="button-solid button-active"
                                                        onClick={submitHandler}
                                                >
                                                    Create
                                                </button>
                                            </Col>
                                            <Col lg={{span: 2}} className="padding-right-unset">
                                                <button className="button-solid button-transparent-active"
                                                        onClick={onReset}
                                                >
                                                    Reset
                                                </button>
                                            </Col>
                                        </Row>}
                                </form>
                                <Footer/>
                            </Card>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default CreateNewTemplate;