import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Logo from "../assets/images/logo.svg"
import Main from "../assets/images/main.png"
import {useLocation, useNavigate} from "react-router-dom";
import {createVerification, getUserMfaDetails, logout, resetVerification} from "../actions/LoginActions";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {jwtDecode} from "jwt-decode";
import LoginImage from "../components/LoginImage";



function VerificationCodeSend() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [verificationType, setVerificationType] = useState({
        display: false,
        type: "",
        value: "",
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })

    const loginUser = useSelector(state => state.login)
    const {userInfo} = loginUser

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            dispatch(logout())
        }
        if (localStorage.getItem('mfa_verify') === "true") {
            history('/client')
        }
        if (localStorage.getItem('mfa_verify') === "false") {
            dispatch(getUserMfaDetails())
        }
    }, []);

    useEffect(() => {
        const handleBackNavigation = (event) => {
            event.preventDefault();
            dispatch(logout())
            history.go(-1); // Go back by 1 step
        };
        window.addEventListener('popstate', handleBackNavigation);
        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [history]);

    useEffect(() => {
        if (!userInfo) {
            history("/sign-in")
        }
        const decoded = jwtDecode(localStorage.getItem("access_token"));
        const user = decoded.IsSuperAdmin
        if (localStorage.getItem("timestamp_type")) {
            const item = JSON.parse(localStorage.getItem("timestamp"));
            const now = new Date().getTime();
            if (localStorage.getItem("timestamp_type") == 1) {
                localStorage.removeItem("timestamp")
                localStorage.removeItem("timestamp_type")
            }
            if (localStorage.getItem("timestamp_type") == 2) {
                if (now - item < 15 * 24 * 60 * 60 * 1000) {
                    localStorage.setItem("mfa_verify", "true")
                    if (user === "True") {
                        window.location.href = "/super-admin-management"
                    } else {
                        history("/client")
                    }

                } else {
                    localStorage.removeItem("timestamp")
                    localStorage.removeItem("timestamp_type")
                }
            }
            if (localStorage.getItem("timestamp_type") == 3) {
                if (now - item < 30 * 24 * 60 * 60 * 1000) {
                    localStorage.setItem("mfa_verify", "true")
                    if (user === "True") {
                        window.location.href = "/super-admin-management"
                    } else {
                        history("/client")
                    }
                } else {
                    localStorage.removeItem("timestamp")
                    localStorage.removeItem("timestamp_type")
                }
            }
            if (localStorage.getItem("timestamp_type") == 4) {
                if (now - item < 60 * 24 * 60 * 60 * 1000) {
                    localStorage.setItem("mfa_verify", "true")
                    if (user === "True") {
                        window.location.href = "/super-admin-management"
                    } else {
                        history("/client")
                    }
                } else {
                    localStorage.removeItem("timestamp")
                    localStorage.removeItem("timestamp_type")
                }
            }
            if (localStorage.getItem("timestamp_type") == 5) {
                if (now - item < 90 * 24 * 60 * 60 * 1000) {
                    localStorage.setItem("mfa_verify", "true")
                    if (user === "True") {
                        window.location.href = "/super-admin-management"
                    } else {
                        history("/client")
                    }
                } else {
                    localStorage.removeItem("timestamp")
                    localStorage.removeItem("timestamp_type")
                }
            }

        }
    }, []);

    const formatEmail = (email) => {
        const parts = email.split('@');
        const username = parts[0].substring(0, 1) + '*'.repeat(parts[0].length - 1);
        const hiddenPart = '*'.repeat(parts[1].length - 5);
        const lastFiveCharacters = parts[1].substring(parts[1].length - 5);
        const domain = hiddenPart + lastFiveCharacters;
        return `${username}@${domain}`;
    };
    const formatPhone = (data) => {
        const phone = data.replace("-", "")
        return phone.substring(0, 1) + '*'.repeat(phone.length - 1) + phone.substring(10, 11);
    };
    const selectVerification = (data, type) => {
        setVerificationType({
            display: true,
            type: type,
            value: data,
        })
    };

    const submitHandler = (event) => {
        event.preventDefault();
        if (verificationType.type == 1) {
            dispatch(createVerification(verificationType.type, null, verificationType.value))
        }
        if (verificationType.type == 2) {
            dispatch(createVerification(verificationType.type, verificationType.value, null))
        }
        // history('/verification-code-add')
    };

    const createVerificationDetails = useSelector(state => state.createVerification)
    const {error, loading, createVerificationInfo} = createVerificationDetails

    const getUserMfaData = useSelector(state => state.getUserMfaDetails)
    const {errorMfa, loadingMfa, mfaInfo} = getUserMfaData


    useEffect(() => {
        if (createVerificationDetails.verificationInfo === true) {
            dispatch(resetVerification())
            localStorage.setItem("code_verify", "false")
            history('/verification-code-add', {state: {type: verificationType.type, value: verificationType.value}})
        }
        if (createVerificationDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Failed to request verification code"
            })
        }
    }, [createVerificationDetails]);

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetVerification())
        // window.location.href='/documents'
    }

    return (
        <Row className="login-background">
            {alertModal.display &&
                <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
            {loading && <Loader/>}
            {loadingMfa && <Loader/>}
            <Col lg={{span: 4, offset: 1}} md={{span: 4, offset: 1}}>
                <Card className="card-body">
                    <img src={Logo} alt="logo" className="logo"/>
                    {getUserMfaData.mfaInfo && <form className="margin-top">
                        <p className="header">Verification code</p>
                        <p className="sub-header">Please choose any one option to get verification code</p>
                        {/*{showContacts(contacts)}*/}
                        <div>
                            {getUserMfaData.mfaInfo.personalEmail !== "" && <Form.Check
                                name="contacts"
                                label={`Send Email to: ${formatEmail(getUserMfaData.mfaInfo.personalEmail)}`}
                                type="radio"
                                id="1"
                                onClick={() => selectVerification(getUserMfaData.mfaInfo.personalEmail, 1)}
                            />}
                            {getUserMfaData.mfaInfo.workEmail !== "" && <Form.Check
                                name="contacts"
                                label={`Send Email to : ${formatEmail(getUserMfaData.mfaInfo.workEmail)}`}
                                type="radio"
                                id="2"
                                onClick={() => selectVerification(getUserMfaData.mfaInfo.workEmail, 1)}
                            />}
                            {getUserMfaData.mfaInfo.mobilePhone !== "" && <Form.Check
                                name="contacts"
                                label={`Send SMS to: : ${formatPhone(getUserMfaData.mfaInfo.mobilePhone)}`}
                                type="radio"
                                id="3"
                                onClick={() => selectVerification(getUserMfaData.mfaInfo.mobilePhone, 2)}
                            />}
                        </div>
                        <div className="login-button">
                            {(!verificationType.display) &&
                                (<button disabled={true} className="button-solid button-inactive">
                                    Send Code
                                </button>)}
                            {(verificationType.display) &&
                                (<button onClick={submitHandler} disabled={false} type="submit"
                                         className="button-solid button-active">
                                    Send Code
                                </button>)}
                        </div>
                    </form>}
                    <div className="copyright">
                        <p className="sub-footer">v5.2</p>
                        <p className="sub-footer">copyright text</p>
                    </div>
                </Card>
            </Col>
            <Col lg={{span: 7, offset: 0}} md={{span: 7, offset: 0}}>
                <LoginImage/>
            </Col>
        </Row>
    );
}

export default VerificationCodeSend;