import axios from 'axios';
import {logout} from "./LoginActions";
import store from "../store";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

// Interceptor to handle 200 and 401 errors
api.interceptors.response.use(response => {
        if (response.status === 200) {
        //     Enter your code here
        }
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            // Handle 401 error here, for example, by logging out the user
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

export default api;
