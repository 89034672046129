import React, {useEffect, useState} from 'react';
import {Col, Row, Card} from "react-bootstrap";
import Sidebar from "../../components/SuperAdmin/SidebarSuperAdmin";
import Navigation from "../../components/SuperAdmin/NavigationSuperAdmin";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import user_role from "../../assets/json/user_roles.json"
import {
    getClientUsersById,
    resetAddClient,
    resetUpdateClientUser,
    updateClientUser
} from "../../actions/ClientManagementActions";
import DropdownItemsInput from "../../components/DropdownItemsInput";
import Switch from "../../components/SuperAdmin/Switch";

const EditTeacherAssociate = (props) => {
    const pageName = 'client-management';
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    })

    const [fieldData, setFieldData] = useState({
        dataName: null,
        dataId: null,
        dataRole: null,
        dataPayroll: false,
        dataLetters: false,
        dataDocuments: false,
        dataProcess: false,
        dataSchoolIncident: false,
        dataTimeEntry: false,
    });

    useEffect(() => {
        dispatch(getClientUsersById(params.id))
    }, [params.id]);

    const [displaySwitch, setDisplaySwitch] = useState(false)

    const getClientUserByIdDetails = useSelector(state => state.getClientUserById)
    const {error, loading, client_user_list} = getClientUserByIdDetails

    useEffect(() => {
        if (!getClientUserByIdDetails.client_user_data <= 0) {
            if (getClientUserByIdDetails.client_user_data.roleId > 0) {
                setDisplaySwitch(true)
            } else {
                setDisplaySwitch(false)
            }
            setFieldData({
                ...fieldData,
                dataName: getClientUserByIdDetails.client_user_data.name,
                dataUserId: getClientUserByIdDetails.client_user_data.userId,
                dataId: getClientUserByIdDetails.client_user_data.clientId,
                dataRole: getClientUserByIdDetails.client_user_data.roleId,
                dataPayroll: getClientUserByIdDetails.client_user_data.isPayrollEnable,
                dataLetters: getClientUserByIdDetails.client_user_data.isLettersEnable,
                dataDocuments: getClientUserByIdDetails.client_user_data.isDocumentsEnable,
                dataProcess: getClientUserByIdDetails.client_user_data.isProcessesEnable,
                dataSchoolIncident: getClientUserByIdDetails.client_user_data.isSchoolIncidentEnable,
                dataTimeEntry: getClientUserByIdDetails.client_user_data.isTimeEntryEnable,
            })
        }
    }, [getClientUserByIdDetails.client_user_data]);

    const updateClientUserDetails = useSelector(state => state.updateClientUser)
    const {errorEdit, loadingEdit, client_list} = updateClientUserDetails

    useEffect(() => {
        if (updateClientUserDetails.client_user_data === true) {
            dispatch(resetUpdateClientUser())
            setAlertModal({
                display: true,
                type: "success",
                message: "Client user updated"
            })
        }
        if (updateClientUserDetails.error) {
            setAlertModal({
                display: true,
                type: "error",
                message: "Could not update client user"
            })
        }
    }, [updateClientUserDetails]);


    const onReset = (e) => {
        e.preventDefault()
        dispatch(getClientUsersById(params.id))
    }
    const goBack = () => {
        history(`/client-teacher-list/${params.client}`, {state: {clientName: location.state.clientName}})
    }

    const openLink = (e) => {
        e.preventDefault()
        dispatch(updateClientUser(params.id, fieldData.dataUserId, fieldData.dataRole, fieldData.dataPayroll,
            fieldData.dataLetters, fieldData.dataDocuments, fieldData.dataProcess, fieldData.dataSchoolIncident, fieldData.dataTimeEntry))
    }

    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
        dispatch(resetAddClient())
    }

    const roleData = (data) => {
        if (data.id > 0) {
            setDisplaySwitch(true)
        } else {
            setDisplaySwitch(false)
            setFieldData({
                ...fieldData,
                dataDocuments: true,
                dataProcess: true,
            })
        }
        setFieldData({
            ...fieldData,
            dataRole: data.id
        })
    }

    const toggleResult = (data) => {
        if (data == 1) {
            if (fieldData.dataPayroll == true) {
                setFieldData({
                    ...fieldData,
                    dataPayroll: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataPayroll: true
                })
            }
        }
        if (data == 2) {
            if (fieldData.dataLetters == true) {
                setFieldData({
                    ...fieldData,
                    dataLetters: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataLetters: true
                })
            }
        }
        if (data == 3) {
            if (fieldData.dataDocuments == true) {
                setFieldData({
                    ...fieldData,
                    dataDocuments: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataDocuments: true
                })
            }
        }
        if (data == 4) {
            if (fieldData.dataProcess == true) {
                setFieldData({
                    ...fieldData,
                    dataProcess: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataProcess: true
                })
            }
        }
        if (data == 5) {
            if (fieldData.dataSchoolIncident == true) {
                setFieldData({
                    ...fieldData,
                    dataSchoolIncident: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataSchoolIncident: true
                })
            }
        }
        if (data == 6) {
            if (fieldData.dataTimeEntry == true) {
                setFieldData({
                    ...fieldData,
                    dataTimeEntry: false
                })
            } else {
                setFieldData({
                    ...fieldData,
                    dataTimeEntry: true
                })
            }
        }
    }

    return (
        <>
            <Row className="sidebar-background">
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type} result={closeAlert}/>}
                {loading && <Loader/>}
                {loadingEdit && <Loader/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="teacher_edit" title={location.state.clientName} result={goBack}/>
                    <Row className="app-body-add-document background-1">
                        {/*<div className="pl-5 pb-2 background-1"*/}
                        {/*>*/}
                        <Row className="background-1">
                            <Card className="card-body-default">
                                {getClientUserByIdDetails.client_user_data && <form>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Full Name<span className="asterics">*</span></label>
                                                <input
                                                    defaultValue={getClientUserByIdDetails.client_user_data.name}
                                                    type="text" autoComplete="off" readOnly={true}
                                                    className={"input-valid-form input-spacing inactive"}
                                                    placeholder="Enter Client Name"
                                                    id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                {/*categoryStatusData*/}
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    Client ID<span className="asterics">*</span></label>
                                                <input
                                                    defaultValue={getClientUserByIdDetails.client_user_data.clientId}
                                                    type="text" autoComplete="off" readOnly={true}
                                                    className={"input-valid-form input-spacing inactive"}
                                                    placeholder="Enter Client Number"
                                                    id="document_title"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="background-1">
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="documentTitleLabel"
                                                       className="form-label form-spacing">
                                                    User ID<span className="asterics">*</span></label>
                                                <input
                                                    defaultValue={getClientUserByIdDetails.client_user_data.userId}
                                                    type="text" autoComplete="off" readOnly={true}
                                                    className={"input-valid-form input-spacing inactive"}
                                                    placeholder="Enter Client Name"
                                                    id="document_title"/>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                                             className="padding-left-unset">
                                            <div className="document-form">
                                                <label htmlFor="document_title" id="teacher-associate"
                                                       className="form-label form-spacing">
                                                    Role<span className="asterics">*</span></label>
                                                <div>
                                                    <DropdownItemsInput data={user_role}
                                                                        result={roleData}
                                                                        value={getClientUserByIdDetails.client_user_data.roleId}
                                                                        className={"background-1 dropdown-input-content-valid"}
                                                                        title="Select School Year"/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {displaySwitch && <Row className="background-1">
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataPayroll ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Payroll
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataPayroll} type={1}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataLetters ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Letters
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataLetters} type={2}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataDocuments ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Documents
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataDocuments} type={3}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataProcess ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Processes
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataProcess} type={4}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    {displaySwitch && <Row className="background-1">
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataSchoolIncident ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    School Incident
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataSchoolIncident} type={5}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={{span: 3, offset: 0}} md={{span: 3, offset: 0}}
                                             className="padding-left-unset">
                                            <div
                                                className={fieldData.dataTimeEntry ? "document-form-toggle-active" : "document-form-toggle"}>
                                                <span className="toggle-text">
                                                    Time Entry
                                                </span>
                                                <div>
                                                    <Switch
                                                        isToggledStatus={fieldData.dataTimeEntry} type={6}
                                                        result={toggleResult}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Row className="background-1 document-form submit-buttons">
                                        <Col lg={{span: 2}} className="padding-left-unset">
                                            <button className="button-solid button-active"
                                                    onClick={openLink}
                                            >
                                                Update
                                            </button>
                                        </Col>
                                        <Col lg={{span: 2}} className="padding-right-unset">
                                            <button className="button-solid button-transparent-active"
                                                    onClick={onReset}
                                            >
                                                Reset
                                            </button>
                                        </Col>
                                    </Row>
                                </form>}
                                <Footer/>
                            </Card>
                        </Row>

                    </Row>

                </div>
            </Row>
        </>
    );
}

export default EditTeacherAssociate;