import React, {useEffect, useState} from 'react';
import { Calendar } from 'primereact/calendar';
import {Row, Col} from 'react-bootstrap'
import "../assets/css/dropdown.css"
import Calendar_1 from "../components/Icons/Calendar";
import DownArrow from "../components/Icons/DownArrow";

function DropdownCalendarRangeSingle(props) {
    const [dates, setDates] = useState(props.value);

    useEffect(() => {
        if (props.datesValue == null) {
            setDates([])
        }
    }, [props]);
    const setDropdownResult = (e) => {
        setDates(e.value)
        props.result(e.value)
    }

    return (
        <Row className="background-1 calendar-content">
            <Col lg={{span: 1}} md={{span: 1}}>
                <Calendar_1 className="sidebar-icon"
                            color="var(--primary-100)"/>
            </Col>
            <Col lg={{span: 9}} md={{span: 9}}>
                <Calendar value={props.value} onChange={setDropdownResult}
                          // dateFormat="dd M, yy"
                          numberOfMonths={1}
                          selectionMode="single" readOnlyInput hideOnRangeSelection={true} placeholder={props.title}/>
            </Col>
            <Col lg={{span: 1}} md={{span: 1}}>
                <DownArrow className="sidebar-icon-1"
                            color="var(--primary-100)"/>
            </Col>

        </Row>
    );
}

export default DropdownCalendarRangeSingle;