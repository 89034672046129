import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.50366 7.8542L8.50367 7.8542C8.70947 6.98984 8.94993 6.28389 9.22957 5.70311C9.61362 4.90278 10.1111 4.278 10.7719 3.85803C11.4333 3.43764 12.2151 3.25001 13.1201 3.25001C13.8094 3.25001 14.5894 3.35732 15.4455 3.56352C15.4461 3.56365 15.4466 3.56379 15.4472 3.56393L17.1131 3.95295C17.1131 3.95297 17.1132 3.95299 17.1133 3.95301C19.0933 4.41231 20.4384 5.11412 21.1453 6.25699C21.8521 7.39966 21.8758 8.91599 21.417 10.8836L21.4169 10.8841L20.4371 15.0632C20.4371 15.0634 20.437 15.0635 20.437 15.0636C20.0491 16.7322 19.4905 17.973 18.5984 18.7496C17.6857 19.5442 16.5116 19.7801 15.0736 19.6379C15.0727 19.6378 15.0717 19.6377 15.0707 19.6376L15.1201 19.14C14.6601 19.1 14.1501 19.02 13.6101 18.89L8.50366 7.8542ZM8.50366 7.8542L8.50321 7.85614L7.52338 12.0454C7.52336 12.0455 7.52334 12.0456 7.52332 12.0457C7.0703 13.9699 7.06666 15.4961 7.79393 16.6686C8.52078 17.8403 9.89091 18.5185 11.8143 18.9764L13.493 19.3761C13.4931 19.3761 13.4931 19.3762 13.4932 19.3762L8.50366 7.8542ZM15.0415 20.1427L15.0415 20.1425L15.0284 20.1415C14.541 20.104 13.9785 20.0093 13.3895 19.8645L13.3859 19.8636L11.7059 19.4636L11.7051 19.4634C9.45626 18.9317 8.07902 18.0961 7.36133 16.9379C6.64378 15.7799 6.5101 14.1778 7.03684 11.9343L7.03693 11.9339L8.01693 7.74389L8.01718 7.74282C8.23141 6.81772 8.48017 6.08786 8.77729 5.49362L8.77732 5.49364L8.78004 5.48803C9.57228 3.85288 10.6975 3.14312 11.8808 2.88065C13.0972 2.61086 14.4137 2.80599 15.5657 3.07675L15.5664 3.07691L17.2357 3.46675C17.2358 3.46677 17.2358 3.46679 17.2359 3.46681C19.5151 4.00255 20.845 4.82443 21.5751 5.9935C22.296 7.16346 22.4486 8.71829 21.9133 10.9956L21.9133 10.9959L20.9333 15.1756C20.5152 16.9551 19.8853 18.1992 19.0559 18.9972C18.2396 19.7826 17.1841 20.18 15.8101 20.18C15.5632 20.18 15.3109 20.1705 15.0415 20.1427Z"
                stroke={props.color}/>
            <path
                d="M3.54584 17.8062L3.5457 17.8058L2.26589 13.8564C2.26585 13.8563 2.26582 13.8562 2.26579 13.8561C1.57344 11.7106 1.57532 10.1459 2.17543 8.96966C2.77543 7.79367 4.03884 6.87789 6.17403 6.18563C6.1741 6.1856 6.17418 6.18558 6.17425 6.18556L7.75037 5.67681C8.26847 5.51744 8.63019 5.41663 8.94453 5.36274L8.95297 5.36129L8.96135 5.35956C9.06034 5.33908 9.14385 5.3739 9.19796 5.44712C9.25795 5.52827 9.26974 5.63212 9.22789 5.72638C8.94809 6.29842 8.70813 7.00464 8.51278 7.86782L7.53336 12.0553C7.53331 12.0555 7.53326 12.0558 7.53321 12.056C7.08028 13.98 7.07669 15.5061 7.80391 16.6785C8.53076 17.8503 9.90089 18.5284 11.8242 18.9863L13.503 19.386C13.5033 19.3861 13.5036 19.3862 13.5038 19.3862C14.0667 19.5217 14.6003 19.6056 15.0846 19.6479C15.1918 19.6584 15.2818 19.7284 15.3176 19.842C15.3417 19.9466 15.3013 20.0487 15.2166 20.108C14.6088 20.5221 13.8269 20.8837 12.806 21.2142L12.8037 21.215L11.2272 21.7339C10.1102 22.0832 9.16347 22.2499 8.33005 22.2499C7.15606 22.2499 6.2284 21.9028 5.46806 21.2052C4.69388 20.495 4.05685 19.3879 3.54584 17.8062ZM8.25607 6.87293L8.55541 5.94165L7.62526 6.24449L6.33674 6.66401C6.3365 6.66409 6.33625 6.66417 6.336 6.66425C4.51759 7.25011 3.24803 8.01187 2.6395 9.20677C2.03143 10.4008 2.15854 11.8802 2.74401 13.7028L2.7444 13.7041L4.0244 17.6541L4.02452 17.6544C4.6154 19.4729 5.37969 20.7441 6.57352 21.355C7.76753 21.9661 9.24467 21.8417 11.0634 21.2559L11.0664 21.2549L12.6464 20.7349L12.6482 20.7343C12.6846 20.7221 12.7175 20.7102 12.744 20.7005L12.7459 20.6998C12.7741 20.6896 12.7955 20.6818 12.8182 20.6743L14.4886 20.1175L12.7748 19.7133L11.7151 19.4633C11.715 19.4633 11.7149 19.4633 11.7148 19.4633C9.46613 18.9316 8.08897 18.0959 7.37131 16.9378C6.65376 15.7799 6.52008 14.1777 7.04682 11.9342L7.04691 11.9338L8.02691 7.7438L8.02696 7.74381L8.0285 7.73678C8.09611 7.42771 8.17221 7.13382 8.25607 6.87293Z"
                stroke={props.color}/>
            <path
                d="M12.5733 9.27536L12.5733 9.27536L12.5716 9.27495C12.4456 9.24345 12.3609 9.10955 12.3954 8.97129C12.4269 8.84549 12.5604 8.76077 12.6985 8.79494C12.6987 8.79499 12.6989 8.79504 12.6991 8.79509L17.5474 10.0247L17.5491 10.0251C17.6751 10.0566 17.7599 10.1905 17.7253 10.3288L17.7244 10.3322C17.7012 10.428 17.6 10.51 17.4904 10.51C17.477 10.51 17.4638 10.5083 17.3974 10.4988L12.5733 9.27536Z"
                stroke={props.color}/>
            <path
                d="M14.5601 14.3899C14.5001 14.3899 14.4401 14.3799 14.3701 14.3699L11.4601 13.6299C11.0601 13.5299 10.8201 13.1199 10.9201 12.7199C11.0201 12.3199 11.4301 12.0799 11.8301 12.1799L14.7401 12.9199C15.1401 13.0199 15.3801 13.4299 15.2801 13.8299C15.2001 14.1699 14.9001 14.3899 14.5601 14.3899Z"
                fill={props.color}/>
        </svg>
    );
}

export default Home;