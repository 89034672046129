import api from './api';
import {
    GET_ANALYTICS_REQUEST,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAIL,
    GET_ANALYTICS_RESET,

} from "../constants/AnalyticsConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const getAnalyticsData = (ClientId, fromDate, toDate) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ANALYTICS_REQUEST})

        const {
            login: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        var clientString = ClientId == undefined || null ? '' : ClientId
        var fromDateString = fromDate === "NaN-NaN-NaN" ? '' : fromDate
        var toDateString = toDate === "NaN-NaN-NaN" ? '' : toDate

        const {data} = await api.get(`${url}/Analytics/GetAnalyticsData?ClientId=${clientString}&from=${fromDateString}&To=${toDateString}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ANALYTICS_SUCCESS,
                payload: data.data,
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ANALYTICS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ANALYTICS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const resetGetTrustedAdvisorById = () => (dispatch, getState) => {
    dispatch({
        type: GET_ANALYTICS_RESET,
    })
};