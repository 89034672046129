import React, {useEffect, useState} from 'react';
import { Calendar } from 'primereact/calendar';
import {Row, Col} from 'react-bootstrap'
import "../assets/css/dropdown.css"
import Calendar_1 from "../components/Icons/Calendar";
import DownArrow from "../components/Icons/DownArrow";

function DropdownCalendarSingle(props) {
    const [dates, setDates] = useState(new Date());
    useEffect(() => {
        if (props.value != null) {
            setDates(new Date(props.value))
        }
        else {
            setDates(null)
        }
    }, [props.value]);

    const setDropdownResult = (e) => {
        setDates(e.value)
        if (props.eventId) {
            props.result([e.value, props.eventId])
        } else {
            props.result(e.value)
        }
    }
    return (
        <Row className={props.className}>
            <Col lg={{span: 1}} md={{span: 1}}>
                <Calendar_1 className="sidebar-icon-input"
                            color="var(--primary-100)"/>
            </Col>
            <Col lg={{span: 10}} md={{span: 10}} className="padding-calendar-single">
                <Calendar
                    dateFormat="yy-mm-dd"
                    value={dates} onChange={setDropdownResult}
                    readOnlyInput placeholder={props.title}/>
            </Col>
            <Col lg={{span: 1}} md={{span: 1}}>
                <DownArrow className="icon-down-arrow"
                            color="var(--primary-100)"/>
            </Col>

        </Row>
    );
}

export default DropdownCalendarSingle;