import React from 'react';

function Home(props) {
    return (
        <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                fill={props.color}/>
            <path
                d="M10.5804 15.5801C10.3804 15.5801 10.1904 15.5001 10.0504 15.3601L7.22043 12.5301C6.93043 12.2401 6.93043 11.7601 7.22043 11.4701C7.51043 11.1801 7.99043 11.1801 8.28043 11.4701L10.5804 13.7701L15.7204 8.6301C16.0104 8.3401 16.4904 8.3401 16.7804 8.6301C17.0704 8.9201 17.0704 9.4001 16.7804 9.6901L11.1104 15.3601C10.9704 15.5001 10.7804 15.5801 10.5804 15.5801Z"
                fill={props.color}/>
        </svg>

    );
}

export default Home;