import api from './api';
import {
    GET_TEACHER_ASSOCIATE_SUCCESS,
    GET_TEACHER_ASSOCIATE_REQUEST,
    GET_TEACHER_ASSOCIATE_FAIL,
    GET_TEACHER_ASSOCIATE_RESET,

    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_RESET,

    GET_SIGNATURE_STATUS_REQUEST,
    GET_SIGNATURE_STATUS_SUCCESS,
    GET_SIGNATURE_STATUS_FAIL,
    GET_SIGNATURE_STATUS_RESET,

    GET_EVENT_TYPE_REQUEST,
    GET_EVENT_TYPE_SUCCESS,
    GET_EVENT_TYPE_FAIL,
    GET_EVENT_TYPE_RESET,

    GET_TASK_TYPE_REQUEST,
    GET_TASK_TYPE_SUCCESS,
    GET_TASK_TYPE_FAIL,
    GET_TASK_TYPE_RESET,

    GET_STATUS_REQUEST,
    GET_STATUS_SUCCESS,
    GET_STATUS_FAIL,
    GET_STATUS_RESET,

    GET_DYNAMIC_VALUE_REQUEST,
    GET_DYNAMIC_VALUE_SUCCESS,
    GET_DYNAMIC_VALUE_FAIL,
    GET_DYNAMIC_VALUE_RESET,

    GET_ALL_DEPARTMENTS_REQUEST,
    GET_ALL_DEPARTMENTS_SUCCESS,
    GET_ALL_DEPARTMENTS_FAIL,
    GET_ALL_DEPARTMENTS_RESET,

    GET_DASHBOARD_DATA_REQUEST,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_RESET,

    GET_PAYROLL_DATA_REQUEST,
    GET_PAYROLL_DATA_SUCCESS,
    GET_PAYROLL_DATA_FAIL,
    GET_PAYROLL_DATA_RESET,

    GET_BA_DATA_REQUEST,
    GET_BA_DATA_SUCCESS,
    GET_BA_DATA_FAIL,
    GET_BA_DATA_RESET,

    GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST,
    GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS,
    GET_ALL_LETTERS_TEMPLATES_LIST_FAIL,
    GET_ALL_LETTERS_TEMPLATES_LIST_RESET,

    GET_REDIRECT_URL_REQUEST,
    GET_REDIRECT_URL_SUCCESS,
    GET_REDIRECT_URL_FAIL,
    GET_REDIRECT_URL_RESET

} from "../constants/ConfigConstants"

const url = process.env.REACT_APP_API_BASE_URL

export const getDashboardData = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_DASHBOARD_DATA_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Dashboard/GetDashboardData`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_DASHBOARD_DATA_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_DASHBOARD_DATA_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_DASHBOARD_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getEmployeeBaData = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_BA_DATA_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Employee/GetEmpBirthdaysAnniversaries`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_BA_DATA_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_BA_DATA_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_BA_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getPayrollData = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_PAYROLL_DATA_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Employee/GetEmployeesPayrolls`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_PAYROLL_DATA_SUCCESS,
                payload: data.data,
                records: data.totalRecord
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_PAYROLL_DATA_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PAYROLL_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getTeacherAssociates = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_TEACHER_ASSOCIATE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Employee/GetAllEmployee`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_TEACHER_ASSOCIATE_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_TEACHER_ASSOCIATE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_TEACHER_ASSOCIATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllDepartments = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_DEPARTMENTS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Employee/GetAllDepartment`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_DEPARTMENTS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_DEPARTMENTS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_DEPARTMENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getCategory = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CATEGORY_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Documents/GetDocumentCategories`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_CATEGORY_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_CATEGORY_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getSignatureStatus = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_SIGNATURE_STATUS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Documents/GetDocumentCategories`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_SIGNATURE_STATUS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_SIGNATURE_STATUS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_SIGNATURE_STATUS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getEventType = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_EVENT_TYPE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Calender/GetEventType`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_EVENT_TYPE_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_EVENT_TYPE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_EVENT_TYPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getTasksType = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_TASK_TYPE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/ProcessTemplate/GetTaskList`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_TASK_TYPE_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_TASK_TYPE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_TASK_TYPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getStatus = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_STATUS_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Process/GetProcessStatus`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_STATUS_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_STATUS_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_STATUS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getDynamicValue = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_DYNAMIC_VALUE_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterTemplate/GetDynamicValues`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_DYNAMIC_VALUE_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_DYNAMIC_VALUE_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_DYNAMIC_VALUE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getAllLettersTemplatesList = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_ALL_LETTERS_TEMPLATES_LIST_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/LetterTemplate/GetAllLetterTemplate?PageNumber=1&PageSize=1000`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_ALL_LETTERS_TEMPLATES_LIST_SUCCESS,
                payload: data.data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_ALL_LETTERS_TEMPLATES_LIST_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ALL_LETTERS_TEMPLATES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const getRedirectUrl = (componentId) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_REDIRECT_URL_REQUEST})

        const {
            getUserAccessLevel: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Accept':'text/plain',
                'ClientId': localStorage.getItem('client_id'),
                'Authorization': `Bearer ${userInfo}`,
                'TimezoneOffset' : new Date().getTimezoneOffset()
            }
        }

        const {data} = await api.get(`${url}/Employee/GetRedirectURLbyUser?ComponentId=${componentId}`, config
        )
        if (data.success === true) {
            dispatch({
                type: GET_REDIRECT_URL_SUCCESS,
                payload: data
            })
        }

        if (data.errors) {
            dispatch({
                type: GET_REDIRECT_URL_FAIL,
                payload: data.success,
            })
        }

    } catch (error) {
        dispatch({
            type: GET_REDIRECT_URL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const resetTeacherAssociate = () => (dispatch, getState) => {
    dispatch({
        type: GET_TEACHER_ASSOCIATE_RESET,
    })
};
export const resetAllDepartment = () => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_DEPARTMENTS_RESET,
    })
};
export const resetCategory = () => (dispatch, getState) => {
    dispatch({
        type: GET_CATEGORY_RESET
    })
};
export const resetSignatureStatus = () => (dispatch, getState) => {
    dispatch({
        type: GET_SIGNATURE_STATUS_RESET
    })
};
export const resetEventType = () => (dispatch, getState) => {
    dispatch({
        type: GET_EVENT_TYPE_RESET
    })
};
export const resetTaskType = () => (dispatch, getState) => {
    dispatch({
        type: GET_TASK_TYPE_RESET
    })
};
export const resetStatus = () => (dispatch, getState) => {
    dispatch({
        type: GET_STATUS_RESET
    })
};
export const resetDynamicValues = () => (dispatch, getState) => {
    dispatch({
        type: GET_DYNAMIC_VALUE_RESET
    })
};
export const resetGetRedirectUrl = () => (dispatch, getState) => {
    dispatch({
        type: GET_REDIRECT_URL_RESET
    })
};