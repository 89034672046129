import React from 'react';

function Home(props) {
    return (
        
        <svg className={props.className} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8499 18.5249C10.8666 18.5249 9.47494 17.8333 8.37494 14.5249L7.77494 12.7249L5.97494 12.1249C2.67494 11.0249 1.98328 9.63327 1.98328 8.64994C1.98328 7.67494 2.67494 6.27494 5.97494 5.1666L13.0499 2.80827C14.8166 2.2166 16.2916 2.3916 17.1999 3.2916C18.1083 4.1916 18.2833 5.67494 17.6916 7.4416L15.3333 14.5166C14.2249 17.8333 12.8333 18.5249 11.8499 18.5249ZM6.36661 6.35827C4.04994 7.13327 3.22494 8.04994 3.22494 8.64994C3.22494 9.24994 4.04994 10.1666 6.36661 10.9333L8.46661 11.6333C8.64994 11.6916 8.79994 11.8416 8.85828 12.0249L9.55828 14.1249C10.3249 16.4416 11.2499 17.2666 11.8499 17.2666C12.4499 17.2666 13.3666 16.4416 14.1416 14.1249L16.4999 7.04994C16.9249 5.7666 16.8499 4.7166 16.3083 4.17494C15.7666 3.63327 14.7166 3.5666 13.4416 3.9916L6.36661 6.35827Z"
                fill={props.color}/>
            <path
                d="M8.42504 12.4998C8.26671 12.4998 8.10837 12.4415 7.98337 12.3165C7.74171 12.0748 7.74171 11.6748 7.98337 11.4332L10.9667 8.4415C11.2084 8.19984 11.6084 8.19984 11.85 8.4415C12.0917 8.68317 12.0917 9.08317 11.85 9.32484L8.86671 12.3165C8.75004 12.4415 8.58337 12.4998 8.42504 12.4998Z"
                fill={props.color}/>
        </svg>

    );
}

export default Home;