import {
    ADD_EVENTS_SUCCESS,
    ADD_EVENTS_REQUEST,
    ADD_EVENTS_FAIL,
    ADD_EVENTS_RESET,

    EDIT_EVENTS_SUCCESS,
    EDIT_EVENTS_REQUEST,
    EDIT_EVENTS_FAIL,
    EDIT_EVENTS_RESET,

    GET_EVENTS_SUCCESS,
    GET_EVENTS_REQUEST,
    GET_EVENTS_FAIL,
    GET_EVENTS_RESET,

    GET_ALL_EVENTS_REQUEST,
    GET_ALL_EVENTS_SUCCESS,
    GET_ALL_EVENTS_FAIL,
    GET_ALL_EVENTS_RESET,

    DELETE_EVENTS_SUCCESS,
    DELETE_EVENTS_REQUEST,
    DELETE_EVENTS_FAIL,
    DELETE_EVENTS_RESET,

    GET_EVENTS_BY_DATE_REQUEST,
    GET_EVENTS_BY_DATE_SUCCESS,
    GET_EVENTS_BY_DATE_FAIL,
    GET_EVENTS_BY_DATE_RESET,

    GET_UPCOMING_SCHOOL_EVENTS_REQUEST,
    GET_UPCOMING_SCHOOL_EVENTS_SUCCESS,
    GET_UPCOMING_SCHOOL_EVENTS_FAIL,
    GET_UPCOMING_SCHOOL_EVENTS_RESET
} from "../constants/EventConstants"
const initialState = {};
export const addEventReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_EVENTS_REQUEST:
            return { loadingAdd: true }

        case ADD_EVENTS_SUCCESS:
            return {
                loadingAdd: false,
                events_data: action.payload,
            }

        case ADD_EVENTS_FAIL:
            return { loadingAdd: false, error: action.payload }

        case ADD_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const editEventReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_EVENTS_REQUEST:
            return { loadingEdit: true }

        case EDIT_EVENTS_SUCCESS:
            return {
                loadingEdit: false,
                events_data: action.payload,
            }

        case EDIT_EVENTS_FAIL:
            return { loadingEdit: false, error: action.payload }

        case EDIT_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getEventByDateReducer = (state = {events_data: ""}, action) => {
    switch (action.type) {
        case GET_EVENTS_REQUEST:
            return { loadingFiltered: true }

        case GET_EVENTS_SUCCESS:
            return {
                loadingFiltered: false,
                events_data: action.payload,
            }

        case GET_EVENTS_FAIL:
            return { loadingFiltered: false, error: action.payload }

        case GET_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getUpcomingSchoolEventsReducer = (state = {events_data: [], records: ""}, action) => {
    switch (action.type) {
        case GET_UPCOMING_SCHOOL_EVENTS_REQUEST:
            return { loading: true }

        case GET_UPCOMING_SCHOOL_EVENTS_SUCCESS:
            return {
                loading: false,
                events_data: action.payload,
                records: action.records
            }

        case GET_UPCOMING_SCHOOL_EVENTS_FAIL:
            return { loading: false, error: action.payload }

        case GET_UPCOMING_SCHOOL_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getEventBySingleDateReducer = (state = {events_single_data: ""}, action) => {
    switch (action.type) {
        case GET_EVENTS_BY_DATE_REQUEST:
            return { loadingFilteredSingleDate: true }

        case GET_EVENTS_BY_DATE_SUCCESS:
            return {
                loadingFilteredSingleDate: false,
                events_single_data: action.payload,
            }

        case GET_EVENTS_BY_DATE_FAIL:
            return { loadingFilteredSingleDate: false, errorFilteredSingleDate: action.payload }

        case GET_EVENTS_BY_DATE_RESET:
            return initialState;

        default:
            return state
    }
}
export const deleteEventReducer = (state = {events_delete: []}, action) => {
    switch (action.type) {
        case DELETE_EVENTS_REQUEST:
            return { loadingDelete: true }

        case DELETE_EVENTS_SUCCESS:
            return {
                loadingDelete: false,
                events_delete: action.payload,
            }

        case DELETE_EVENTS_FAIL:
            return { loadingDelete: false, error: action.payload }

        case DELETE_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}
export const getAllEventsReducer = (state = {events_list: []}, action) => {
    switch (action.type) {
        case GET_ALL_EVENTS_REQUEST:
            return { loading: true }

        case GET_ALL_EVENTS_SUCCESS:
            return {
                loading: false,
                events_list: action.payload,
            }

        case GET_ALL_EVENTS_FAIL:
            return { loading: false, error: action.payload }

        case GET_ALL_EVENTS_RESET:
            return initialState;

        default:
            return state
    }
}